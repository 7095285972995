import { useLocation } from 'react-router';
import DatePicker, { ReactDatePickerCustomHeaderProps, registerLocale, setDefaultLocale } from 'react-datepicker';
import { intlFormat, isBefore, isSameDay, isToday, isWeekend, startOfDay } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
// for dates picker
import { enUS, fr } from 'date-fns/locale';
import { isRolloverRoute } from '@/App/routes';
import 'react-datepicker/dist/react-datepicker.css';
import { isDateInvalid, selectErrors } from '@/store/state/errors/errors.selectors';
import { isPredeliverDateInvalid, selectPredeliver } from '@/store/state/predeliver/predeliver.selectors';
import { formChangeAction } from '@/store/state/rfs/rfs.actions';
import { isRolloverDateInvalid, selectRollover } from '@/store/state/rollover/rollover.selectors';
import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { getToday } from '@/utils/today';
import './TradeDatePicker.scss';
import { formatDateLocalized } from '@/utils/locale';

import { fromUtc, isSameOrBefore, toUtc } from '@/utils/dates';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';
import { selectUser } from '@/store/state/user/user.selectors';
import { selectCanForward, selectClosedDates } from '@/store/state/ui/ui.selectors';
import { capitalizeFirstLetter } from '@/utils/strings';
import { isDefined } from '@sgme/fp';
import { useAppDispatch } from '@/store/store';

registerLocale('en', enUS);
registerLocale('fr', fr);

setDefaultLocale('en');

const today = getToday();

interface TradeDatePickerProps {
  className?: 'date-picker' | 'date-picker-predeliver';
  minDate?: Date;
  maxDate?: Date;
}

export const TradeDatePicker = React.memo(({ className = 'date-picker', minDate, maxDate }: TradeDatePickerProps) => {
  const { pathname } = useLocation();

  // TODO: don't create too generic component
  // -> extract specific behavior to the container (Predeliver, rollover...)
  const predeliver = useSelector(selectPredeliver);
  // const isPredeliverDate = predeliver.trade !== undefined;

  const rollover = useSelector(selectRollover);
  const isRolloverDate = rollover.trade !== undefined;

  const rfs = useSelector(selectRfs);
  const errors = useSelector(selectErrors);
  const allClosedDates = useSelector(selectClosedDates);
  const canForward = useSelector(selectCanForward);
  const user = useSelector(selectUser);

  const customLabel = isRolloverRoute(pathname) ? 'rollover' : undefined;
  const spotValue = rfs.spotDate || today;
  const date = isDefined(rfs.date) ? new Date(rfs.date) : undefined;
  const disabled = rfs.status === 'executing' || !user.currentUser?.hasForwardRight;
  const isInvalid = isDateInvalid(errors) || isRolloverDate ? isRolloverDateInvalid({ rollover, rfs }) : isPredeliverDateInvalid({ predeliver, rfs });
  const closedDates = allClosedDates[`${rfs.buyCurrency}/${rfs.sellCurrency}`] || [];

  const dispatch = useAppDispatch();

  const onFocus = () => {
    dispatch(blockExecutionThunk(true));
  };

  const onBlur = () => {
    dispatch(blockExecutionThunk(false));
  };

  const [isFocused, setFocused] = React.useState(false);

  const onDateChange = React.useCallback(
    (date: Date | null) => {
      if (date !== null) {
        dispatch(formChangeAction({ date: fromUtc(startOfDay(date)) }));
      }
    },
    [dispatch],
  );

  const intl = useIntl();

  const changeFocus = (arg: boolean) => {
    setFocused(arg);

    if (arg) onFocus();
    else onBlur();
  };

  const filterDate = (date: Date) =>
    !(
      isBefore(date, minDate ?? today) ||
      (maxDate && isBefore(maxDate, date)) ||
      isWeekend(date) ||
      closedDates.includes(formatDateLocalized(date, 'yyyy-MM-dd')) ||
      (!canForward && !isSameDay(date, spotValue)) ||
      (rollover.trade !== undefined && isSameOrBefore(date, new Date(rollover.trade.maturitydate)))
    ) ||
    (!isRolloverDate && isToday(date));

  return (
    <>
      <label className="text-secondary text-large mb-2">
        {customLabel === undefined ?
          <FormattedMessage id="trader.tradeDatePicker.label" />
        : <FormattedMessage id={`trader.tradeDatePicker.label.${customLabel}`} />}
      </label>

      <div className={`${isDefined(className) ? className : ''} ${isInvalid ? 'is-invalid' : ''}`}>
        <DatePicker
          id="expiry_date"
          selected={isDefined(date) ? toUtc(date) : undefined}
          minDate={minDate}
          onChange={onDateChange}
          autoFocus={isFocused}
          onFocus={() => changeFocus(true)}
          onBlur={() => changeFocus(false)}
          onCalendarClose={() => changeFocus(false)}
          dateFormat="d MMM yyyy"
          disabled={disabled}
          allowSameDay
          filterDate={filterDate}
          locale={intl.locale}
          popperPlacement="bottom-start"
          popperModifiers={[
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
              fn: (state: any) => state /* MiddlewareState from react-datapicker dependency -> floating-ui */,
            },
          ]}
          renderCustomHeader={CustomHeader}
        />

        <i className="icon icon-lg text-secondary">calendar_today</i>

        {isInvalid && (
          <div className="invalid-feedback">
            <FormattedMessage id="trader.tradeDatePicker.selectAnotherDate" />
          </div>
        )}
      </div>
    </>
  );
});

function DateDisplay({ date }: { date: Date }) {
  const intl = useIntl();

  return (
    <p style={{ fontSize: '18px', color: '#484848', fontWeight: 'bolder', margin: 0 }}>
      {capitalizeFirstLetter(intlFormat(date, { month: 'short', year: 'numeric' }, { locale: intl.locale }).toLowerCase())}
    </p>
  );
}

function CustomHeader({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }: ReactDatePickerCustomHeaderProps) {
  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <button
        type="button"
        className=""
        style={{
          display: 'flex',
          borderRadius: '3px',
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#e4e7e7',
          backgroundColor: '#fff',
          color: '#757575',
          height: '33px',
          width: '39px',
          alignItems: 'center',
          textAlign: 'center',
          padding: '6px 9px',
          marginRight: '20px',
        }}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <svg style={{ height: '19px', width: '19px', fill: '#82888a', display: 'block' }} focusable="false" viewBox="0 0 1000 1000">
          <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z" />
        </svg>
      </button>
      <div style={{ width: '35%' }}>
        <DateDisplay date={date} />
      </div>

      <button
        type="button"
        className=""
        style={{
          display: 'flex',
          borderRadius: '3px',
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#e4e7e7',
          backgroundColor: '#fff',
          color: '#757575',
          height: '33px',
          width: '39px',
          alignItems: 'center',
          textAlign: 'center',
          padding: '6px 9px',
          marginLeft: '20px',
        }}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <svg style={{ height: '19px', width: '19px', fill: '#82888a', display: 'block' }} focusable="false" viewBox="0 0 1000 1000">
          <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
        </svg>
      </button>
    </div>
  );
}
