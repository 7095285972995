import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { Alert } from '@/models/alerts';
import { formatDateLocalized } from '@/utils/locale';
import { useFormattingContext, DATE_LONG_FORMAT } from '@/utils/format';
import { Piwik } from '@/utils/piwik';
import { Spinner } from '@/share/Spinner/Spinner';
import { AlertItem } from '@/store/state/alerts/alerts.reducer';

type AlertCardProps = {
  alert: AlertItem;
  startUpdateAlert: (alert: Alert) => void;
  renewAlert: (alert: Alert) => void;
  onDelete: (id: string) => void;
  readonly: boolean;
};

function DeleteButton({disabled, onDelete, isDeleting}: { onDelete: () => void; isDeleting: boolean; disabled: boolean }) {
  return <button
      type="button" 
      disabled={disabled}
      className="btn btn-outline-primary m-1"
      onClick={onDelete}
    >
      {isDeleting && <Spinner />}
      <FormattedMessage id="alerts.actions.delete" />
    </button>
}

export function AlertCard({ alert, startUpdateAlert, renewAlert, onDelete, readonly }: AlertCardProps) {
  const { formatAmountWithoutSign } = useFormattingContext()

  const onEditClick = React.useCallback(() => {
    startUpdateAlert(alert);
  }, [alert, startUpdateAlert]);

  const onDeleteClick = React.useCallback(() => {
    onDelete(alert.id);
  }, [alert.id, onDelete]);

  const onRenewClick = React.useCallback(() => {
    Piwik.trackAction('Start renew alert');
    renewAlert(alert);
  }, [alert, renewAlert]);

  return (
    <div
      className={`card card-bordered mt-3 ${ alert.status !== 'Watched' ? 'bg-lvl3' : '' }`}
      data-e2e="alert-row"
      data-id={alert.id}
    >
      <div className="row m-2">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.currencyPair" />
          </div>
          <div className="mb-1 fw-bold">{alert.currencyPair.replace('/', ' / ')}</div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.rate" />
          </div>
          <div className="mb-1">{formatAmountWithoutSign(alert.limitPrice)}</div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.expiry" />
          </div>
          <div className="mb-1">
            {alert.goodTillCancelled ? (
              <FormattedMessage id="alerts.blotter.goodTillCancelled" />
            ) : alert.expiryDay ? (
              formatDateLocalized(parseISO(alert.expiryDay), DATE_LONG_FORMAT)
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.creation" />
          </div>
          <div className="mb-1">
            {alert.creationDate
              ? formatDateLocalized(parseISO(alert.creationDate), DATE_LONG_FORMAT)
              : ''}
          </div>
        </div>
        {alert.status === 'Watched' ? (
          <div className="col-md-4 col-sm-12 text-end">
            <DeleteButton
              onDelete={onDeleteClick}
              isDeleting={alert.isDeleting}
              disabled={readonly}
            />
            <button type="button" className="btn btn-outline-primary m-1" onClick={onEditClick}>
              <FormattedMessage id="alerts.actions.edit" />
            </button>
          </div>
        ) : (
          <>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <div className="text-secondary fw-medium my-1">
                <FormattedMessage id="alerts.blotter.status" />
              </div>
              <span className="badge rounded-pill badge-light text-secondary">
                <FormattedMessage id={`alerts.blotter.status.${ alert.status }`} />
              </span>
            </div>
            <div className="col-md-2 col-sm-6 ps-0 text-end">
              <button type="button" className="btn btn-outline-primary m-1" onClick={onRenewClick}>
                <FormattedMessage
                  id={
                    alert.status === 'Cancelled'
                      ? 'alerts.actions.reactivate'
                      : 'alerts.actions.renew'
                  }
                />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
