import { useSelector } from 'react-redux';
import { getErrorDetail, getErrorTitle } from '@/App/Trader/components/TradeErrors/errorMessages';
import { selectLastError } from '@/store/state/errors/errors.selectors';

export function OperationAlert() {
  const error = useSelector(selectLastError);

  if (error === undefined) {
    return null;
  }

  const title = getErrorTitle(error);
  const detail = getErrorDetail(error);
  return (
    <div className="border border-danger p-2 pt-4 mt-5 col-md-7">
      <div className="d-flex">
        <div>
          <i className="icon icon-xs line-height-1 me-3 text-danger">error_outline</i>
        </div>
        <div>
          <p className="text-danger">{title}</p>
          {detail && <p>{detail}</p>}
        </div>
      </div>
    </div>
  );
}
