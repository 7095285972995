import { Alert } from '@/models/alerts';
import { Currency } from '@/models/currency';
import { formChangeAction } from '../rfs/rfs.actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { AppState } from '@/store/app.state';

export const realiseAlertThunk = createAsyncThunk<void, Alert, { dispath: AppDispatch; state: AppState }>('startUpdateAlertThunk', (alert, thunkAPI) => {
  const [ccy1, ccy2] = alert.currencyPair.split('/');

  thunkAPI.dispatch(
    formChangeAction({
      buyCurrency: ccy1 as Currency,
      sellCurrency: ccy2 as Currency,
    }),
  );
});
