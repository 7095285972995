import { FormattedMessage } from 'react-intl';
import { GrowlType } from '@/store/state/growls/growls.model';

type GrowlProps = {
  titleId: string;
  textId?: string;
  type: GrowlType;
  close: () => void;
};

export function Growl({titleId, textId, type, close}: GrowlProps) {
    return <div className={`toast toast-${type} fade show`} data-e2e="growl">
      <div className="toast-header">
        <FormattedMessage id={titleId} />
        <button type="button" className="ms-2 mb-1 close" onClick={close}>
          <i className="icon icon-xs">close</i>
        </button>
      </div>
      {textId && (
        <div className="toast-body">
          <FormattedMessage id={textId} />
        </div>
      )}
    </div>
}
