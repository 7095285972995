import { FormattedMessage } from 'react-intl';
import { PropsWithChildren, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export type TooltipProps = {
  labelId: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  value?: { leverage?: number; power?: string };
  shouldShowTooltip?: boolean ;
  className?: string;
};

function CalendarTooltip(props: PropsWithChildren<TooltipProps>): JSX.Element {
  const { labelId, placement = 'top', children, value, shouldShowTooltip, className } = props;

  const ref = useRef<HTMLSpanElement>(null);

  return (
    <>
      <span ref={ref}>{children}</span>
     {shouldShowTooltip ? <UncontrolledTooltip target={ref} placement={placement} className={className}>
        <FormattedMessage
          id={`app.calendar.table.tooltip.${labelId}`}
          values={{ leverage: value?.leverage, power: value?.power }}
        />
      </UncontrolledTooltip> : null}
    </>
  );
}

export default CalendarTooltip;
