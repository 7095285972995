import * as React from 'react';
import {ExecutionErrorModal} from './ExecutionErrorModal';
import {SuccessTraderModal} from './SuccessTraderModal/SuccessTraderModal';
import {TradeCaptureModal} from './TradeCaptureModal';
import {ClientLatencyErrorModal} from './ClientLatencyErrorModal';
import {TimeoutedDealErrorModal} from './TimeoutedDealErrorModal';
import {InactivityTraderModal} from "./InactivityTraderModal";

export function TraderModals() {
  return (
    <>
      <SuccessTraderModal/>
      <ExecutionErrorModal/>
      <TradeCaptureModal/>
      <InactivityTraderModal/>
      <TimeoutedDealErrorModal/>
      <ClientLatencyErrorModal/>
    </>
  );
}
