import { MapDispatchToProps, connect } from 'react-redux';
import { Catch } from './utils/Catch';
import { createAppCrashedAction } from '@/store/state/appCrash/appCrash.actions';
import { AppDispatch } from '@/store/store';

export interface AppCrashConnectOwnProps {
  children: React.ReactNode;
}

export interface AppCrashConnectDispatchProps {
  onCatch(error: Error, errorInfo: React.ErrorInfo): void;
}

export const mapDispatchToPropsAppCrash: MapDispatchToProps<AppCrashConnectDispatchProps, AppCrashConnectOwnProps> = (dispatch: AppDispatch) => ({
  onCatch(_error: Error, errorInfo: React.ErrorInfo) {
    dispatch(createAppCrashedAction('React AppCrash', (errorInfo.componentStack ?? '') + _error.stack));
  },
});

export const AppCrash = connect(null, mapDispatchToPropsAppCrash)(Catch);
