import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectRfsStatus } from '@/store/state/rfs/rfs.selectors';
import GenericErrorModal from '@/App/Trader/components/TraderModals/GenericErrorModal/GenericErrorModal';

function ModalBody() {
  return (
    <>
      <p className="text-secondary">
        <FormattedMessage id="trader.errorModal.description" />
      </p>
      <p>
        <FormattedMessage id="trader.errorModal.description2" />
      </p>
    </>
  );
}

export function ExecutionErrorModal() {
  const isOpen = useSelector(selectRfsStatus) === 'execution-failed';

  return (
    <GenericErrorModal
      isOpen={isOpen}
      modalTitleKey="trader.errorModal.title"
      modalBody={<ModalBody />}
      dataE2E="execution-error-modal"
    />
  );
}
