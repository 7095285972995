import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { NumberInputRenderProps } from './NumberInputRenderProps';
import { noop, pick } from './utils';

export interface NumberInputProps {
  value: string;
  name?: string;
  className?: string;
  readOnly?: boolean;
  placeHolder?: string;
  e2eHandle?: string;
  autoFocus?: boolean;
  onSubmit?(value: string): void;
  onChange?(value: string): void;
  onBlurPristine?(): void;
  onBlur?(): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onDirty?(isDirty: boolean): void;
  onKeyDownSetter?(value:string): void;
}
const pluck = pick(
  'value',
  'onFocus',
  'onSubmit',
  'onChange',
  'onBlurPristine',
  'onKeyDownSetter',
  'onBlur',
  'onDirty',
);

// pure component to prevent component rerender and loosing its value (find another solution ?)
class NumberInputBase extends React.PureComponent<NumberInputProps & WrappedComponentProps, Record<string, unknown>> {
  render() {
    const {props} = this;
    return (
      <NumberInputRenderProps {...pluck(props)} locale={props.intl.locale}>
        {renderProps => (
          <input
            // constants
            type="text"
            autoComplete="off"
            onChange={props.readOnly === true ? undefined : renderProps.onChange}
            // passed props
            name={props.name}
            autoFocus={props.autoFocus}
            className={props.className}
            placeholder={props.placeHolder}
            readOnly={props.readOnly}
            data-e2e={props.e2eHandle ? `${props.e2eHandle}Input` : undefined}
            // controlled number string
            value={renderProps.value}
            // event handlers
            onBlur={props.readOnly === true ? undefined : renderProps.onBlur}
            onFocus={props.readOnly === true ? undefined : renderProps.onFocus}
            onKeyDown={props.readOnly === true ? undefined : renderProps.onKeyDown}
            // onPaste={props.readOnly === true ? undefined : renderProps.onPaste}
            onPaste={props.readOnly === true ? undefined : noop}
            onCopy={props.readOnly === true ? undefined : renderProps.onCopy}
            onCut={props.readOnly === true ? undefined : renderProps.onCut}
          />
        )}
      </NumberInputRenderProps>
    );
  }
}

export const NumberInput = injectIntl(NumberInputBase);
