import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { MyHedgeTrade } from '@/models/trade';
import { isSameOrAfter } from '@/utils/dates';

export const tradeAdapter = createEntityAdapter<MyHedgeTrade, string>({
  selectId: (trade) => trade.reference,
  sortComparer: (t1, t2) => (isSameOrAfter(parseISO(t1.maturitydate), parseISO(t2.maturitydate)) ? 1 : -1),
});

export type TradesResources = { status: 'received'; data: EntityState<MyHedgeTrade, string>; selectedId?: string } | { status: 'fetching' } | { status: 'error' };

export type TradesState = {
  trades: TradesResources;
  spotDates: {
    [currencyPair: string]: string;
  };
};
