import { useCallback } from 'react';
import { DebounceChange } from '@/share/DebounceChange/DebounceChange';
import { useFormattingContext } from '@/utils/format';

function mapChangeEvent(callback: (a: string) => unknown) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    callback(event.target.value);
  };
}

export function Search({ onChange }: { onChange?: (value: string) => void }) {
  const { formatMessage } = useFormattingContext();
  const searchPlaceholder = formatMessage('trades.search');

  const onSearchChanged = useCallback(
    (term: string) => {
      if (onChange) onChange(term);
    },
    [onChange],
  );

  return (
    <div className="form-group">
      <label />
      <div className="input-group input-group-md">
        <div className="input-icon-start">
          <i className="icon icon-xs text-secondary ">search</i>
        </div>
        <DebounceChange onChange={onSearchChanged}>
          {onChangeDebounced => (
            <input
              type="search"
              data-e2e="search"
              className="form-control form-control-md"
              placeholder={searchPlaceholder}
              onChange={mapChangeEvent(onChangeDebounced)}
            />
          )}
        </DebounceChange>
      </div>
    </div>
  );
}
