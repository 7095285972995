/* eslint-disable no-param-reassign */
import { isDefined, isNotDefined } from '@sgme/fp';
import { DecoratedFields, FxoSquareTargetAccumulatorBaseCalendarFields, FxoSquareTargetAccumulatorCalendarFields, possibleIsCheckedFieldsName } from '@/models/calendar';
import { TargetAccumulatorTrade, XOneCalendarEntry } from '@/models/trade';
import { computeWay } from '@/App/utils/computeWay';

export const fxoSquareTargetAccumulatorFieldsConfig: ReadonlyArray<keyof XOneCalendarEntry> = [
  'fixingDate',
  'payDate',
  'amount2',
  'amount1',
  'strike',
  'fixing',
  'leverage',
  'power',
] as const;

const SideImpactOnTarget = ({ fixing, strike }: { fixing: string | number | undefined; strike: string | number | undefined }, trade: TargetAccumulatorTrade): boolean => {
  if (isNotDefined(fixing)) {
    return false;
  }
  if (trade.side === 'sell') {
    // fake does not give us currencyPairSide so it wont work properly in devci
    if (trade.currencyPairSide === 'sell') {
      return fixing > strike!;
    }
    if (trade.currencyPairSide === 'buy') {
      return fixing < strike!;
    }
  }
  if (trade.side === 'buy') {
    if (trade.currencyPairSide === 'sell') {
      return fixing > strike!;
    }
    if (trade.currencyPairSide === 'buy') {
      return fixing < strike!;
    }
  }

  return false;
};

const isAmount = (key: keyof FxoSquareTargetAccumulatorCalendarFields): key is 'amount1' | 'amount2' => ['amount1', 'amount2'].includes(key);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const computeIsChecked = (currentField: Exclude<possibleIsCheckedFieldsName, 'amount3'>, strike: number, fixing: number) => {
  if (currentField === 'amount1') {
    return fixing > strike; // strike is always there
  }

  if (currentField === 'amount2') {
    return fixing < strike; // strike is always there
  }
  throw new Error('shouldn`t be called for other fields than amount 1 and 2');
};

const computeImprovedStrikeField = (rowFields: FxoSquareTargetAccumulatorBaseCalendarFields, trade: TargetAccumulatorTrade): { value: number | undefined; isImproved: boolean } => {
  const { fixing, strike, leverage, power } = rowFields;
  // in the fake the power is always null so we cant test the function
  if (isNotDefined(fixing) || isNotDefined(leverage) || isNotDefined(power)) {
    return { value: strike, isImproved: false };
  }
  if (SideImpactOnTarget({ fixing, strike }, trade)) {
    if (computeWay(trade) === 'buy') {
      return { value: strike + leverage * (strike - fixing) ** Number(power), isImproved: true };
    }
    if (computeWay(trade) === 'sell') {
      return { value: strike - leverage * (strike - fixing) ** Number(power), isImproved: true };
    }
  }
  return { value: strike, isImproved: false };
};

const computeTargetField = ({ fixing, improvedStrike, strike }: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>, trade: TargetAccumulatorTrade): number | undefined => {
  if (isNotDefined(fixing.value) || isNotDefined(improvedStrike.value)) {
    return undefined;
  }

  if (SideImpactOnTarget({ fixing: fixing.value, strike: strike.value }, trade)) {
    return Math.abs(Number(improvedStrike.value) - Number(fixing.value));
  }
  return 0;
};

const computeCumulatedTargetField = (allRows: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[], index: number): number =>
  allRows.reduce((acc, { target }, innerKey) => {
    if (innerKey <= index && isDefined(target?.value)) {
      return acc + (target.value as number);
    }
    return acc;
  }, 0);

const computeCumulatedAmount = (allRows: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[], index: number): number =>
  allRows.reduce((acc, { amount1, amount2 }, innerKey) => {
    if (innerKey <= index) {
      if (amount1?.isChecked) {
        acc += amount1.value as number;
      }

      if (amount2?.isChecked) {
        acc += amount2.value as number;
      }
    }
    return acc;
  }, 0);

const computeRemainingTargetField = (cumulatedTarget: number | undefined, tradeTarget: number) => {
  const remainingTargetValue = isDefined(cumulatedTarget) ? tradeTarget - cumulatedTarget : undefined;
  return {
    value: isDefined(remainingTargetValue) && remainingTargetValue > 0 ? remainingTargetValue : undefined,
  };
};

//
//
//
// ██████╗ ███████╗ ██████╗ ██████╗ ██████╗  █████╗ ████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║  ██║█████╗  ██║     ██║   ██║██████╔╝███████║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║  ██║██╔══╝  ██║     ██║   ██║██╔══██╗██╔══██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ██████╔╝███████╗╚██████╗╚██████╔╝██║  ██║██║  ██║   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
// ╚═════╝ ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const decorateAmountField = (
  rowFields: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>,
  currentField: Exclude<possibleIsCheckedFieldsName, 'amount3'>,
  tradeTarget: number,
  badges: string[] | undefined,
  cumulatedTarget?: number,
): {
  isChecked: boolean;
  value: number | string | undefined;
  isAdjStrike: boolean;
  overriddenPrecision: number;
} => {
  const { fixing, strike, target: rowTarget } = rowFields;
  const defaultAmountField = {
    ...rowFields[currentField],
    isChecked: false,
    isAdjStrike: false,
    overriddenPrecision: 2,
  };
  if (isNotDefined(tradeTarget) || isNotDefined(fixing?.value) || isNotDefined(cumulatedTarget)) {
    return defaultAmountField;
  }

  const computeAmount =
    computeIsChecked(currentField, strike.value as number, fixing.value as number) ?
      ((defaultAmountField.value as number) * (tradeTarget - cumulatedTarget)) / cumulatedTarget
    : (defaultAmountField.value as number);

  if (cumulatedTarget < tradeTarget) {
    if (currentField === 'amount1' || currentField === 'amount2') {
      // TODO 4911 : Should not cast type, we should be able to extract type directly from XoneCalendarEntry
      return {
        ...defaultAmountField,
        isChecked: computeIsChecked(currentField, strike.value as number, fixing.value as number),
      }; // strike is always there
    }
  } else {
    const koConvention = badges?.find((badgeName) => badgeName.includes('KO')); // the information is gotten from regex of the description
    const isCurrentRowKnockOut = cumulatedTarget - (rowTarget.value as number) < tradeTarget;

    if (isCurrentRowKnockOut) {
      if (koConvention?.includes('Full')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, strike.value as number, fixing.value as number),
        };
      }

      if (koConvention?.includes('Adj Amount')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, strike.value as number, fixing.value as number),
          value: computeAmount,
        };
      }

      if (koConvention?.includes('Adj Strike')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, strike.value as number, fixing.value as number),
          isAdjStrike: true,
        };
      }
    }
    // when koconvention is NONE should have zero impact on isChecked logic
    return defaultAmountField;
  }
  return defaultAmountField;
};

const decorateFixingField = (
  cumulatedTarget: number | undefined,
  tradeTarget: number,
  fixing: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>['fixing'],
  strike: string | number | undefined,
  badges?: string[],
) => {
  const koConvention = badges?.find((badgeName) => badgeName.includes('KO')); // the information is gotten from regex of the description
  const isFixingEditable = !koConvention?.includes('Full') && !koConvention?.includes('Adj Strike') && !koConvention?.includes('Adj Amount');

  return {
    ...fixing,
    ...{
      isFixingEditable,
      isKo: isDefined(cumulatedTarget) && cumulatedTarget >= tradeTarget,
      isUncertain: strike === fixing.value,
    },
  };
};

//
//
//
//  █████╗ ██████╗ ██████╗     ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔══██╗██╔══██╗██╔══██╗    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ███████║██║  ██║██║  ██║    █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██╔══██║██║  ██║██║  ██║    ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ██║  ██║██████╔╝██████╔╝    ██║     ██║███████╗███████╗██████╔╝███████║
// ╚═╝  ╚═╝╚═════╝ ╚═════╝     ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const addImprovedStrikeAfterStrikeField = (rowFields: FxoSquareTargetAccumulatorCalendarFields, trade: TargetAccumulatorTrade) =>
  Object.entries(rowFields).reduce((result, [key, value]) => {
    if (key === 'leverage' || key === 'power') {
      // we do not display those fields
      return result;
    }
    result[key as keyof FxoSquareTargetAccumulatorBaseCalendarFields] = { value };
    if (key === 'strike') {
      const improvedStrike = computeImprovedStrikeField(rowFields, trade);
      result.improvedStrike = {
        value: improvedStrike.value,
        strikeImproved: {
          isStrikeImproved: improvedStrike.isImproved,
          leverage: rowFields?.leverage,
          power: rowFields?.power,
        },
      };
    }
    return result;
  }, {} as DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>);

const addTargetAfterFixingField = (rowFields: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>, trade: TargetAccumulatorTrade) =>
  Object.entries(rowFields).reduce((result, [key, value]) => {
    result[key as keyof FxoSquareTargetAccumulatorCalendarFields] = value;
    if (key === 'fixing') {
      result.target = { value: computeTargetField(rowFields, trade) };
    }
    return result;
  }, {} as DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>);

const addCumulatedAmountAndModifyImprovedStrike = (
  rowFields: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>,
  rowIndex: number,
  trade: TargetAccumulatorTrade,
  allRowWithCumulatedTarget: DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[],
) =>
  Object.entries(rowFields).reduce((acc, [key, value]) => {
    const currentKey = key as keyof FxoSquareTargetAccumulatorCalendarFields;
    acc[currentKey] = value;

    if (currentKey === 'improvedStrike' && rowFields.cumulatedTarget.value && (rowFields.amount1.isAdjStrike || rowFields.amount2.isAdjStrike)) {
      const remainingTarget = trade.target - (rowFields.cumulatedTarget.value as number);

      acc[currentKey].value = computeWay(trade) === 'buy' ? (rowFields.fixing.value as number) + remainingTarget : (rowFields.fixing.value as number) - remainingTarget;
    }

    // because we want to add the cumulated amount after amount1
    if (currentKey === 'amount1') {
      const checkedField = Object.values(rowFields).find(({ isChecked }) => isChecked);

      acc.cumulatedAmount = {
        value: checkedField ? computeCumulatedAmount(allRowWithCumulatedTarget, rowIndex) : undefined,
        overriddenPrecision: 2,
        isCumulatedAmount: true,
      };
    }
    return acc;
  }, {} as DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ██████╗  ██████╗ ██╗    ██╗███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔══██╗██╔═══██╗██║    ██║██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      ██████╔╝██║   ██║██║ █╗ ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══██╗██║   ██║██║███╗██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║  ██║╚██████╔╝╚███╔███╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝ ╚══════╝

export const computeRowsFieldsForFxoSquareTargetAccumulator = (
  baseRowsFields: FxoSquareTargetAccumulatorCalendarFields[],
  trade: TargetAccumulatorTrade,
  badges?: string[],
): Array<DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>> => {
  const allRowsWithImprovedStrike = baseRowsFields.map((rowFields) => addImprovedStrikeAfterStrikeField(rowFields, trade));

  const allRowsWithTarget = allRowsWithImprovedStrike.map((rowFields) => addTargetAfterFixingField(rowFields, trade));

  const allRowWithCumulatedTarget = allRowsWithTarget.map((rowFields, rowIndex) =>
    Object.entries(rowFields).reduce((result, [key, value]) => {
      const currentKey = key as keyof FxoSquareTargetAccumulatorCalendarFields;
      result[currentKey] = value;

      const fixing = rowFields.fixing?.value;
      const strike = rowFields.strike?.value;

      const cumulatedTargetValue = fixing ? computeCumulatedTargetField(allRowsWithTarget, rowIndex) : undefined;

      const cumulatedTarget = {
        value: cumulatedTargetValue,
        isKo: isDefined(cumulatedTargetValue) ? cumulatedTargetValue >= trade.target : false,
      };

      // add remaining target after cumulated target field
      if (currentKey === 'target') {
        result.cumulatedTarget = cumulatedTarget;
        result.remainingTarget = computeRemainingTargetField(cumulatedTarget.value, trade.target);
      }

      if (isAmount(currentKey)) {
        result[currentKey] = decorateAmountField(rowFields, currentKey as 'amount1' | 'amount2', trade.target, badges, cumulatedTarget.value);
      }

      if (currentKey === 'fixing') {
        result.fixing = decorateFixingField(cumulatedTarget.value, trade.target, result.fixing, strike, badges);
      }
      return result;
    }, {} as DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>),
  );

  const allRowWithCumulatedAmount = allRowWithCumulatedTarget.map((rowFields, rowIndex) =>
    addCumulatedAmountAndModifyImprovedStrike(rowFields, rowIndex, trade, allRowWithCumulatedTarget),
  );

  return allRowWithCumulatedAmount;
};
