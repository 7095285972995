export interface HighlightedRateProps {
  value: string;
  size?: number;
  offset?: number;
}

const isDigit = (c: string) => c >= '0' && c <= '9';
const getHighlightedDigits = (value: string, offset: number) =>
  Array.from(value).reduceRight(
    ({ digits, index }, cur) => {
      const isCurDigit = isDigit(cur);
      const isHighlighted = isCurDigit && (index === 1 || index === 2);
      return {
        digits: [
          {
            digit: cur,
            isHighlighted,
          },
          ...digits,
        ],
        index: isCurDigit ? index + 1 : index,
      };
    },
    {
      digits: [] as Array<{
        digit: string;
        isHighlighted: boolean;
      }>,
      index: 0 - offset,
    },
  ).digits;

export function HighlightedRate({ value, size, offset }: HighlightedRateProps) {
  return (
    <span data-e2e="highlighted-rate">
      {getHighlightedDigits(value, offset || 0).map(({ digit, isHighlighted }, index) =>
        isHighlighted ? (
          <span
            className={`${
              size !== undefined ? `display-${size}` : ''
            } text-info fw-normal`}
            key={index}
          >
            {digit}
          </span>
        ) : (
          digit
        ),
      )}
    </span>
  );
}
