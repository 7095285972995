import { useSelector } from 'react-redux';
import { Growl } from './Growl';
import { removeGrowlAction } from '@/store/state/growls/growls.actions';
import { selectGrowls } from '@/store/state/growls/growls.selectors';

import './Grolws.scss';
import { useAppDispatch } from '@/store/store';

export function Growls() {
  const growls = useSelector(selectGrowls);
  const dispatch = useAppDispatch();
  const removeGrowl = (id: string) => () => dispatch(removeGrowlAction(id));

  return (
    <div className="Growls">
      {growls.map((g) => (
        <Growl close={removeGrowl(g.id)} key={g.id} titleId={g.titleId} textId={g.textId} type={g.type} />
      ))}
    </div>
  );
}
