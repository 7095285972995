import {MyHedgeTrade} from "@/models/trade";
import {
  ConsolidatedFlow,
  ConsolidatedFlows,
  ExtendedFlow,
  MyFxFlow
} from "@/store/state/upcomingFlows/upcomingFlows.models";
import {indexBy} from "@/utils/fp";
import { isDefined } from "@sgme/fp";

export const extendFlow = (allFlows: ConsolidatedFlows | undefined, allTrades: MyHedgeTrade[] | undefined): ExtendedFlow[] => {
  if (!isDefined(allFlows) || !isDefined(allFlows.flows) || !isDefined(allTrades)) {
    return []
  }

  const tradeByReference = indexBy(trade => trade.reference, allTrades);

  return (
    allFlows.flows
      .map((flow): ExtendedFlow | undefined => {
        const trade = tradeByReference[flow.contractId]

        if (trade === undefined) {
          return undefined
        }

        // first Leg should always be the same as trade side
        const sortedFlow: ConsolidatedFlow = trade === undefined || flow.legs[0].side === trade.side
          ? flow
          : {...flow, legs: [flow.legs[1], flow.legs[0]]}

        return { trade, flow: sortedFlow };
      })
      .filter(isDefined)
  )
}
