import { map } from 'rxjs/operators';
import { MyHedgeTrade } from '@/models/trade';
import { http } from '@/utils/http';

export const getTrades = (mnemonic: string) =>
  http.getWithDefault<MyHedgeTrade[]>(
    `${window.sgmeConfiguration.api_url}myfx/trades/${mnemonic}?setIsGroupFlag=true`,
    []
  );

export const getSpotDates = (currencyPairs: string[]) =>
  http.post(`myfx/dates/spot`, currencyPairs).pipe(
    map(
      res =>
        res.response as {
          [currencyPair: string]: string;
        },
    ),
  );
