/* eslint-disable default-param-last */
import { ofType } from 'redux-observable';
import { catchError, mapTo, repeat, switchMap, withLatestFrom } from 'rxjs/operators';
import { concat, of, SchedulerLike, timer } from 'rxjs';
import { v4 } from 'uuid';
import { AppAction } from '../app.actions';
import { finishedUpdateAlert, UpdateAlert } from '@/store/state/create-alert/create-alert.actions';
import { CreateAlertStateForm } from '@/store/state/create-alert/create-alert.model';
import { http } from '@/utils/http';
import { Growl } from '@/store/state/growls/growls.model';
import { formatDateLocalized } from '@/utils/locale';
import { EpicWithThunk } from '@/store/store';
import { addGrowlAction, autoHideGrowlDelay, removeGrowlAction } from '@/store/state/growls/growls.actions';

export const updateAlertSuccessGrowl: Growl = {
  titleId: 'alerts.updateSuccess.title',
  type: 'success',
};

export const updateAlertErrorGrowl: Growl = {
  titleId: 'alerts.updateError.title',
  type: 'danger',
};

const callOms = (bdrId: string | undefined, email: string | undefined, c: CreateAlertStateForm) =>
  http.post('oms/update/Call?forceDirectPair=true', {
    orderRef: c.id,
    isGtc: c.data.expiryDate === null,
    bdrId,
    ccyPair: `${c.data.baseCurrency}/${c.data.contraCurrency}`,
    base64Emails: email ? btoa(email) : '',
    expiryDay: c.data.expiryDate ? formatDateLocalized(c.data.expiryDate, 'yyyy-MM-dd') : null,
    expiryTime: '17:00:00',
    limitPrice: Number(c.data.limitPrice),
  });

export const updateAlertOmsEpic =
  (request = callOms, _autoHideGrowlDelay = autoHideGrowlDelay, newGuid: () => string = v4, scheduler?: SchedulerLike): EpicWithThunk =>
  (action$, state$) =>
    action$.pipe(
      ofType('UpdateAlert'),
      withLatestFrom(state$),
      switchMap(([_a, s]) =>
        concat(
          request(s.user.selectedCompany?.companyBdrId, s.user.currentUser?.email, s.createAlert).pipe(
            withLatestFrom(state$),
            switchMap(() => {
              const growlId = newGuid();
              return concat(
                of(addGrowlAction(updateAlertSuccessGrowl, growlId), finishedUpdateAlert()),
                timer(_autoHideGrowlDelay, undefined, scheduler).pipe(mapTo(removeGrowlAction(growlId))),
              );
            }),
          ),
        ),
      ),
      catchError((_) => of(addGrowlAction(updateAlertErrorGrowl, newGuid()), finishedUpdateAlert())),
      repeat(),
    );
