import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { cancelStreaming } from '@/services/trade.service';
import { isPredeliverAmountInvalid, isPredeliverDateInvalid } from '@/store/state/predeliver/predeliver.selectors';
import { isRolloverAmountInvalid, isRolloverDateInvalid } from '@/store/state/rollover/rollover.selectors';
import { ChangeCompanyAction } from '@/store/state/user/user.actions';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { FormChangeAction, requestForStreamAction, RequestForStreamAction, StreamStoppedAction, streamStoppedAction } from '../state/rfs/rfs.actions';
import { getNewRfsId } from './request-for-stream.epic';
import { isDefined } from '@sgme/fp';

export const formChangeEpic =
  (cancelRfs = cancelStreaming, newRfsId = getNewRfsId): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, FormChangeAction | ChangeCompanyAction>('FORM_CHANGE', 'CHANGE_COMPANY'),
      withLatestFrom(state$),
      mergeMap(([, { rfs }]) =>
        rfs.status !== 'idle' ? cancelRfs(rfs.id).pipe(map((hasPreviouslyCanceled) => ({ hasPreviouslyCanceled, rfsId: rfs.id }))) : of({ hasPreviouslyCanceled: false }),
      ),
      withLatestFrom(state$),
      mergeMap<[{ hasPreviouslyCanceled: boolean; rfsId?: string }, AppState], Observable<RequestForStreamAction | StreamStoppedAction>>(
        ([{ hasPreviouslyCanceled, rfsId }, state]) => {
          const actions = [];

          if (hasPreviouslyCanceled && rfsId) {
            actions.push(streamStoppedAction(rfsId));
          }

          if (formIsValid(state)) {
            actions.push(requestForStreamAction(newRfsId()));
          }

          return of(...actions);
        },
      ),
    );

export const formIsValid = (state: AppState) => {
  const isPredeliver = isDefined(state.predeliver.trade);
  const isRollover = isDefined(state.rollover.trade);

  if (isPredeliver) {
    return !isPredeliverAmountInvalid(state) && !isPredeliverDateInvalid(state);
  }

  if (isRollover) {
    return !isRolloverAmountInvalid(state) && !isRolloverDateInvalid(state);
  }

  return state.rfs[state.rfs.masterAmount] > 0;
};
