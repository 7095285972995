/* eslint-disable no-underscore-dangle */
import { PiwikPageName, piwikPages } from '@/App/routes';
import { logger } from '../logging/logger';
import { userConsentAsync } from './userConsent';

export interface WindowWithPiwik extends Window {
  // global variable to communicate with piwik tracking code
  _paq: {
    push: (args: any[]) => void;
  };
}

declare const window: WindowWithPiwik;

type PerPageAction =
  | 'Opened page'
  | 'Changed counterparty'
  | 'Changed buy currency'
  | 'Changed sell currency'
  | 'Switched currencies'
  | 'Searched an operation'
  | 'Filtered operations'
  | 'Triggered execution'
  | 'Triggered contact'
  | 'Changed buy amount'
  | 'Changed sell amount'
  | 'Changed value date'
  // widgets
  | 'Opened help center'
  | 'Closed help center'
  | 'Opened notification center'
  | 'Closed notification center'
  | 'Opened my services board'
  | 'Closed my services board'
  | 'Opened account center'
  | 'Closed account center'
  | 'Start renew alert'
  | 'Created alert'
  | 'Updated alert'
  | 'Deleted alert'
  | 'Renewed alert'
  | 'Gone to sellOrBuy from trades'
  | 'Gone to sellOrBuy from upcoming flows'
  | 'Gone to alerts from buySell page';

type RfqCategory = 'RFQs' | 'Execution success' | 'Execution abandoned' | 'Execution errors';

type RfqAction =
  | 'Started RFQ'
  | 'Executed spot'
  | 'Executed forward'
  | 'Executed predeliver on forward'
  | 'Executed rollover on forward'
  | 'Abandoned spot'
  | 'Abandoned forward'
  | 'Abandoned predeliver on forward'
  | 'Abandoned rollover on forward'
  | 'Reached fat finger limit'
  | 'Has been refused to trade bc no CVAR limit defined'
  | 'Reached CVAR limit'
  | 'Reached RDL limit'
  | 'Reached RFQ timeout'
  | 'Reached cut-off'
  | 'Faced an unknown error when triggered execution'
  | 'Go home after Rfq timeout'
  | 'Continue after Rfq timeout';

export interface PiwikEventBase {
  category: string;
  action: string;
  name?: string;
  value?: number; // this can only be a number
}

export interface PiwikPerPageEvent extends PiwikEventBase {
  category: PiwikPageName;
  action: PerPageAction;
  name: string;
}

export const Piwik = {
  _url: undefined as string | undefined,
  _user: undefined as string | undefined,
  trackEvent: (event: PiwikEventBase) => {
    const data = ['trackEvent', event.category, event.action, event.name, event.value];
    if (window._paq) {
      window._paq.push(data);
    }
  },
  trackAction: (action: PerPageAction) => {
    const event: PiwikPerPageEvent = {
      category: piwikPages[window.location.pathname as keyof typeof piwikPages],
      action,
      name: Piwik.formattedCompany,
    };
    Piwik.trackEvent(event);
  },
  trackRfq: (category: RfqCategory, action: RfqAction) => {
    Piwik.trackEvent({ category, action, name: Piwik.formattedCompany });
  },
  formattedCompany: '',
  setup(url: string, id: string, user: string) {
    if (!url) {
      logger.logWarning('Piwik URI was not set; Piwik statistics will not be recordered.');
      return;
    }

    logger.logInformation('Setup Piwik with', { url, id });

    this._url = url;
    this._user = user;
    window._paq = window._paq || [];
    window._paq.push(['setTrackerUrl', `${url}/piwik.php`]);
    window._paq.push(['setSiteId', id]);
    window._paq.push(['requireCookieConsent']);
    window._paq.push(['setUserId', this._user]);

    userConsentAsync.then(isOk => {
      if (isOk) {
        this.setWithUserConsent();
      } else {
        this.setWithoutUserConsent();
      }
      this.appendMatomoScript();
    });
  },
  appendMatomoScript() {
    if (this._url) {
      const g = document.createElement('script');
      const s = document.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = `${this._url}/piwik.js`;
      s.parentNode!.insertBefore(g, s);
    }
  },
  setWithUserConsent() {
    window._paq.push(['rememberCookieConsentGiven', 4320]);
    window._paq.push(['setCustomDimension', 10, true]);
  },

  setWithoutUserConsent() {
    window._paq.push(['forgetCookieConsentGiven']);
    window._paq.push(['setCustomDimension', 10, false]);
  },
  setupWidgets: () => {
    // PIWIK for sgwt widgets

    setTimeout(() => {
      try {
        const helpCenter = document.querySelector('#my-help-center');
        const accountCenter = document.querySelector('#my-account-center');

        if (helpCenter) {
          helpCenter.addEventListener('sgwt-help-center--open', _ =>
            Piwik.trackAction('Opened help center'),
          );
          helpCenter.addEventListener('sgwt-help-center--close', _ =>
            Piwik.trackAction('Closed help center'),
          );
        }

        if (accountCenter) {
          accountCenter.addEventListener('sgwt-account-center--show-user-card', _ => {
            Piwik.trackAction('Opened account center');
          });
          accountCenter.addEventListener('sgwt-account-center--hide-user-card', _ => {
            Piwik.trackAction('Closed account center');
          });

          accountCenter.addEventListener('sgwt-account-center--show-notification-card', _ => {
            Piwik.trackAction('Opened notification center');
          });
          accountCenter.addEventListener('sgwt-account-center--hide-notification-card', _ => {
            Piwik.trackAction('Closed notification center');
          });

          accountCenter.addEventListener('sgwt-account-center--show-my-services-card', _ => {
            Piwik.trackAction('Opened my services board');
          });
          accountCenter.addEventListener('sgwt-account-center--hide-my-services-card', _ => {
            Piwik.trackAction('Closed my services board');
          });
        }
      } catch (error) {
        logger.logError('piwik error', error);
      }
    }, 5000);
  },
};
