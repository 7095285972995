import { Reducer } from '@reduxjs/toolkit';

import { AppAction } from '@/store/app.actions';
import { GrowlsState } from './growls.model';

const defaultState: GrowlsState = {
  growls: [],
};

export const growlsReducer: Reducer<GrowlsState, AppAction> = (
  // eslint-disable-next-line default-param-last
  state: GrowlsState = defaultState,
  action: AppAction,
): GrowlsState => {
  switch (action.type) {
    case 'AddGrowl':
      return {
        ...state,
        growls: [
          ...state.growls,
          {
            id: action.id,

            ...action.growl,
          },
        ],
      };
    case 'RemoveGrowl':
      return {
        ...state,
        growls: state.growls.filter((c) => c.id !== action.id),
      };
    default:
      return state;
  }
};
