import { Piwik } from './piwik';

export interface SgFooterWidget extends Element {
  getUserConsentOnMatomo(): Promise<boolean>;
}

let userConsentAsyncResolve: (value: boolean | PromiseLike<boolean>) => void;
let userConsentAsyncReject: (reason?: any) => void;
export const userConsentAsync = new Promise<boolean>((resolve, reject) => {
  userConsentAsyncResolve = resolve;
  userConsentAsyncReject = reject;
});

export const onFooterSetRef = (_footerWidget: SgFooterWidget | null) => {
  if (_footerWidget) {
    _footerWidget.addEventListener('sgwt-mini-footer--current-user-consent', () => {
      _footerWidget
        .getUserConsentOnMatomo()
        .then(userConsentAsyncResolve)
        .catch(userConsentAsyncReject);
    });

    _footerWidget.addEventListener('sgwt-mini-footer--user-consent-changed', () => {
      _footerWidget.getUserConsentOnMatomo().then(consent => {
        if (consent) {
          Piwik.setWithUserConsent();
        } else {
          Piwik.setWithoutUserConsent();
        }
      });
    });
  }
};
