import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { oppositeWay } from '@/models/way';
import { getCurrencyByWay, selectRfs } from '@/store/state/rfs/rfs.selectors';
import {
  selectOppositeCurrencyForTradeType,
  selectTradeForType,
} from '@/store/state/trades/trades.selectors';
import { DATE_LONG_FORMAT, useFormattingContext } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { TradeActionType } from '@/models/trade';

interface OperationSummaryProps {
  type: TradeActionType;
}

function OperationSummaryLine({
  title,
  valueE2e,
  children,
}: {
  title: string;
  valueE2e?: string;
  children: React.ReactNode;
}) {
  return (
      <div
      className="card bg-lvl2 card-filled full-height d-flex-column me-1 mb-2 p-3"
    >
        <div className="text-secondary mb-lg-3 mb-3 fw-medium" >
          <FormattedMessage id={title}/>
        </div>
        <div className="line-height-1 fw-medium" data-e2e={valueE2e  } >{children}</div>
    </div>
  );
}

function getContraAmount(remainingAmount: number, strike: number) {
  return remainingAmount / strike;
}

export function OperationSummary({ type }: OperationSummaryProps) {
  const { formatAmountWithPrecision } = useFormattingContext();
  const rfs = useSelector(selectRfs);
  const trade = useSelector(selectTradeForType(type))!;
  const oppositeCurrency = useSelector(selectOppositeCurrencyForTradeType(type));

  const { maturitydate, side, remainingAmount, strike: initialStrike } = trade;
  const currency = getCurrencyByWay(rfs, side);

  return (
    <div className="operation-summary">
      <div className="card-grid-row">
          <OperationSummaryLine title="predeliver.maturityDate">
            {formatDateLocalized(parseISO(maturitydate), DATE_LONG_FORMAT)}
          </OperationSummaryLine>
          <OperationSummaryLine title="predeliver.operationOutline" valueE2e={`${type}-available`}>
            <FormattedMessage id={`trader.amountAndCurrency.way.${side}`} />{' '}
            {formatAmountWithPrecision(remainingAmount ?? 0, 2)} {currency}
          </OperationSummaryLine>
          <OperationSummaryLine valueE2e="spot-rate" title="predeliver.initialStrike">
            {formatAmountWithPrecision(initialStrike, 5)}
          </OperationSummaryLine>
          <OperationSummaryLine
            valueE2e={`${type}-contravalue`}
            title="predeliver.contraAmount"
          >
            <FormattedMessage id={`trader.amountAndCurrency.way.${oppositeWay(side)}`} />{' '}
            {formatAmountWithPrecision(getContraAmount(remainingAmount ?? 0, initialStrike), 2)}{' '}
            {oppositeCurrency}
          </OperationSummaryLine>
      </div>
    </div>
  );
}
