import React, { createElement, useState } from 'react';
import HeaderTabs from './HeaderTabs';
import { FormattedMessage } from 'react-intl';

function HeaderCollapsedMenu() {
  const [open, setOpen] = useState(false);

  return (
    <div className="dropdown">
      <button
        onClick={() => setOpen((prevValue) => !prevValue)}
        className="navbar-menu-btn btn btn-flat btn-xl btn-icon dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-offset="0,4"
        aria-expanded="false"
      >
        <FormattedMessage id="header.menu" />
        <i className="icon">arrow_drop_down</i>
      </button>
      <ul className={`navbar-menu-dropdown dropdown-menu w-100 ${open ? 'show' : ''}`}>
        <HeaderTabs />
      </ul>
    </div>
  );
}

export default HeaderCollapsedMenu;
