import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSpotDate } from '@/store/state/ui/ui.selectors';
import { Blinker } from '../Home/Notifications';

export function TradeLoader({ children }: { children: React.ReactNode }) {
  const spotDate = useSelector(selectSpotDate);

  switch (spotDate) {
    case 'EMPTY':
      return <Blinker />;

    case 'ERROR':
      return (
        <div className="text-center text-warning">
          <i className="icon icon-md">error_outline</i>
          <span className="ps-1 pt-1">
            <FormattedMessage id="trader.notAvailableError" />
          </span>
        </div>
      );

    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
  }
}
