import { Epic, ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { cancelRedirectAction } from '../state/ui/ui.actions';
import { LocationChangeAction } from '../state/rfs/rfs.actions';

export const cancelRedirectEpic = (): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, LocationChangeAction>('LOCATION_CHANGE'),
    withLatestFrom(state$),
    mergeMap(([_action, state]) => {
      if (state.ui.pendingRedirectionUrl !== undefined) {
        return of(cancelRedirectAction());
      }
      return EMPTY;
    }),
  );
