import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAllTrades,
  selectReceivedTrades,
  selectTrades,
} from '@/store/state/trades/trades.selectors';
import { Search } from './TradesSearch';
import { TradeFamilyFilter } from './TradeFamilyFilter';
import { TodayOnlyCheckbox } from './TodayOnlycheckbox';
import { TradesExportButton } from './TradesExportButton';
import { TradesCurrencyFilter } from './TradesCurrencyFilter';
import { processTrades, TradesFilters } from './helpers';
import { allTradeFamilies, getTradeFamily, MyHedgeTrade, TradeFamily } from '@/models/trade';
import { allCurrencies, Currency } from '@/models/currency';

const useFilters = (
  search: string,
  isTodayOnly: boolean,
  filters: TradesFilters,
): TradesFilters => {
  const trades = useSelector(selectReceivedTrades);
  const { searchedTrades } = useMemo(
    () => processTrades(trades, isTodayOnly, search, filters),
    [filters, search, isTodayOnly, trades],
  );

  return useMemo(() => {
    const { families, currencyPairs } = filters;
    const unsortedFamilies = searchedTrades
      .filter(t => currencyPairs.length === 0 || currencyPairs.includes(t.currencyPair))
      .reduce((unsorted, trade) => {
        const family = getTradeFamily(trade);

        if (!unsorted.includes(family)) {
          unsorted.push(family);
        }

        return unsorted;
      }, new Array<TradeFamily>());
    const currencyMap = searchedTrades
      .filter(t => families.length === 0 || families.includes(getTradeFamily(t)))
      .reduce((map, trade) => {
        const [ccy1, ccy2] = trade.currencyPair.split('/') as [Currency, Currency];

        if (!map.has(ccy1)) map.set(ccy1, [ccy2]);
        else {
          const currencies = map.get(ccy1)!;

          if (!currencies.includes(ccy2)) currencies.push(ccy2);
        }

        return map;
      }, new Map<Currency, Currency[]>());

    return {
      families: allTradeFamilies.filter(f => unsortedFamilies.includes(f)),
      currencyPairs: allCurrencies
        .filter(c => currencyMap.has(c))
        .map(c1 => {
          const currencies = currencyMap.get(c1)!;

          return allCurrencies.filter(c2 => currencies.includes(c2)).map(c2 => `${c1}/${c2}`);
        })
        .flat(),
    };
  }, [filters, searchedTrades]);
};

interface TradesToolbarProps {
  value: { search: string; isTodayOnly: boolean; filters: TradesFilters };
  currentTrades: MyHedgeTrade[];
  onSearchChange?: (value: string) => void;
  onTodayOnlyChange?: (value: boolean) => void;
  onFilterChange?: (filters: TradesFilters) => void;
}

export function TradesToolbar({
  value: { search, isTodayOnly, filters },
  currentTrades,
  onSearchChange,
  onTodayOnlyChange,
  onFilterChange,
}: TradesToolbarProps) {
  const { families, currencyPairs } = useFilters(search, isTodayOnly, filters);

  const onInnerSearchChange = useCallback(
    (term: string) => {
      if (onSearchChange) onSearchChange(term);
    },
    [onSearchChange],
  );

  const onFamilyChange = useCallback(
    (newFamilies: TradeFamily[]) => {
      if (onFilterChange) onFilterChange({ ...filters, families: newFamilies });
    },
    [filters, onFilterChange],
  );

  const onCurrencyPairChange = useCallback(
    (newCurrencies: string[]) => {
      if (onFilterChange) onFilterChange({ ...filters, currencyPairs: newCurrencies });
    },
    [filters, onFilterChange],
  );

  return (
    <div className="row mb-4">
      <div className="col-12 col-md-3">
        <Search onChange={onInnerSearchChange} />
      </div>
      <div className="col-6 col-md-2">
        <TradeFamilyFilter
          value={filters.families}
          families={families}
          onFilterChange={onFamilyChange}
        />
      </div>
      <div className="col-6 col-md-2">
        <TradesCurrencyFilter
          value={filters.currencyPairs}
          currencyPairs={currencyPairs}
          onCurrencyPairChange={onCurrencyPairChange}
        />
      </div>
      <div className="col-8 col-md-3">
        <TodayOnlyCheckbox
          value={isTodayOnly}
          onChange={onTodayOnlyChange}
          currentTrades={currentTrades}
        />
      </div>
      <div className="col-4 col-md-2 text-end">
        <TradesExportButton />
      </div>
    </div>
  );
}
