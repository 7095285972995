import { CalendarFieldsState, DecoratedFields } from '@/models/calendar';
import { RowField } from './RowField';

interface CalendarRowProps {
  row: DecoratedFields<CalendarFieldsState>;
  rowIndex: number;
  editingRowsId?: number[];
  isCumulatedAmountNull?: boolean;
}

export function CalendarTableRow({ row, rowIndex, editingRowsId, isCumulatedAmountNull }: CalendarRowProps) {
  const isEdited = editingRowsId?.includes(rowIndex);
  return (
    <tr>
      {Object.entries(row).map(([fieldName, fieldContent], fieldIndex) => (
        <RowField
          // safe to use index as it is stable and its the only unique value we have
          // eslint-disable-next-line react/no-array-index-key
          key={fieldIndex}
          fieldContent={fieldContent}
          fieldName={fieldName}
          isEdited={isEdited}
          rowIndex={rowIndex}
          isCumulatedAmountNull={isCumulatedAmountNull}
        />
      ))}
    </tr>
  );
}
