import { Epic, ofType } from 'redux-observable';
import { EMPTY, merge, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  retry,
  startWith,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { isRfsPage } from '@/App/routes';
import { getClosedDates, getSpotDate } from '@/services/trade.service';
import {
  FormChangeAction,
  LocationChangeAction,
  spotDateReceivedAction,
  spotDateRequestedAction,
} from '@/store/state/rfs/rfs.actions';
import {
  clearTradeCaptureErrorAction,
  spotDateErrorAction,
} from '@/store/state/tradeCapture/tradeCapture.actions';
import { closedDatesReceivedAction } from '@/store/state/ui/ui.actions';
import { isNotNullNorUndefined } from '@/utils/predicates';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';

export const datesEpic =
  (
    getSpotDateFromCurrencyPair = getSpotDate,
    getClosedDatesFromCurrencyPair = getClosedDates,
  ): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, LocationChangeAction | FormChangeAction>('LOCATION_CHANGE', 'FORM_CHANGE'),
      filter(
        action =>
          (action.type === 'LOCATION_CHANGE' && isRfsPage(action.pathname)) ||
          (action.type === 'FORM_CHANGE' &&
            (action.formChange.buyCurrency !== undefined ||
              action.formChange.sellCurrency !== undefined)),
      ),
      withLatestFrom(state$),
      mergeMap(([, { rfs, ui }]) => {
        const currencyPair = `${rfs.buyCurrency}/${rfs.sellCurrency}`;

        const spotDate$ = getSpotDateFromCurrencyPair(currencyPair).pipe(
          filter(isNotNullNorUndefined),
          map(date => new Date(date)),
          map(spotDateReceivedAction),
          timeout(10000),
          catchError(err => of(spotDateErrorAction(err))),
          startWith(spotDateRequestedAction()),
        );

        const closedDates$ =
          ui.closedDates[currencyPair] === undefined
            ? getClosedDatesFromCurrencyPair(currencyPair).pipe(
                filter(closedDate => closedDate !== undefined && closedDate !== null),
                map(closedDates => closedDatesReceivedAction(currencyPair, closedDates)),
                catchError(() => of(clearTradeCaptureErrorAction())),
              )
            : EMPTY;

        return merge(spotDate$, closedDates$);
      }),
      retry(),
    );
