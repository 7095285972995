import { Epic, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { StreamingAction } from '@/store/state/streaming/streaming.actions';
import { registerToOrdersBlotter } from '@/services/ordersBlotter.service';
import {
  alertsLoadingFailedAction,
  loadingAlertsAction,
} from '@/store/state/alerts/alerts.actions';
import { UserLoadedAction } from '@/store/state/user/user.actions';
import { logger } from '@/logging/logger';
import { extractError } from '@/utils/errors';

export const blotterRegisterEpic =
  (register = registerToOrdersBlotter): Epic<AppAction, AppAction, AppState> =>
    (action$, state$) =>
      action$.pipe(
        ofType<AppAction, UserLoadedAction>('USER_LOADED'),
        filter(action => action.user.hasCallRight),
        mergeMap(() => {
          const { connectionId } = state$.value.streaming;
          return connectionId
            ? register(connectionId)
            : action$.pipe(
              ofType<AppAction, StreamingAction>('STREAMING_CONNECTED'),
              mergeMap(action => register(action.connectionId)),
            );
        }),
        filter(res => res.status === 200),
        map(() => loadingAlertsAction()),
        catchError(error => {
          logger.logError('Register to blotter failed', { error: extractError(error) });
          return of(alertsLoadingFailedAction());
        }),
      );
