import { Epic, ofType } from 'redux-observable';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { cancelStreaming } from '@/services/trade.service';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import {
  StopStreamAction,
  streamStoppedAction,
  StreamStoppedAction,
} from '../state/rfs/rfs.actions';

export const stopStreamEpic = (
  cancelRfs = cancelStreaming,
): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, StopStreamAction>('STOP_STREAM'),
    withLatestFrom(state$),
    mergeMap(([, { rfs }]) =>
      rfs.status !== 'idle'
        ? cancelRfs(rfs.id).pipe(
            map(hasPreviouslyCanceled => ({ hasPreviouslyCanceled, rfsId: rfs.id })),
          )
        : of({ hasPreviouslyCanceled: false }),
    ),
    mergeMap<{ hasPreviouslyCanceled: boolean; rfsId?: string }, Observable<StreamStoppedAction>>(
      ({ hasPreviouslyCanceled, rfsId }) =>
        hasPreviouslyCanceled && rfsId ? of(streamStoppedAction(rfsId)) : EMPTY,
    ),
  );
