import { Action } from '@reduxjs/toolkit';
import { logger } from '@/logging/logger';
import { addErrorAction } from '@/store/state/errors/errors.actions';
import { actionCreatorTypeExtractor, OnActionNotifierCallback } from './notifyActionMiddleware';
import { AppState } from '@/store/app.state';
import { createAppCrashedAction } from '@/store/state/appCrash/appCrash.actions';

const errorActions: readonly string[] = [addErrorAction, createAppCrashedAction].map(actionCreatorTypeExtractor);

export const logActionCallback: OnActionNotifierCallback<AppState> = (action: Action) => {
  if (errorActions.includes(action.type)) {
    logger.logError(`${action.type} {action}`, action); // we log error action to every loggers including console logger.
  } else {
    logger.logInformation(`${action.type} {action}`, action); // we log info action only to elastic search, not to the console.
  }
};

/**
 * method to log only action type - prevent for to large data
 * @param action {action}
 */
export const resizedLogActionCallback: OnActionNotifierCallback<AppState> = (action: Action) => {
  const resizedAction = { type: action.type };
  logger.logInformation(resizedAction.type);
};
