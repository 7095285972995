/* eslint-disable no-param-reassign */
import {
  CalendarFieldsState,
  FxoPermanentForwardAccumulatorBaseCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorBaseCalendarFields,
  FxTargetAccumulatorCalendarFields,
} from '@/models/calendar';
import {
  AccumulatorInstrumentName,
  AccumulatorTrade,
  ForwardAccumulatorTypes,
  TargetAccumulatorTrade,
} from '@/models/trade';
import { computeRowFieldsForFxoStandardForwardAccumulator } from '@/App/calendar/fieldsComputation/fxoStandardForwardAccumulator';
import { computeRowsFieldsForFxTargetAccumulator } from '@/App/calendar/fieldsComputation/fxTargetAccumulator';
import { computeRowsFieldsForFxoSquareTargetAccumulator } from './fieldsComputation/fxoSquareTargetAccumulator';
import { computeRowFieldsForFxoPermanentForwardAccumulator } from '@/App/calendar/fieldsComputation/fxoPermanentForwardAccumulator';
import { computeRowsFieldsForFxoPauseTargetAccumulator } from './fieldsComputation/fxoPauseTargetAccumulator';

const isFxoPermanentForwardAccu = (
  baseRowsFields: CalendarFieldsState[],
  accumulatorType: ForwardAccumulatorTypes,
): baseRowsFields is FxoPermanentForwardAccumulatorBaseCalendarFields[] =>
  accumulatorType === 'permanent';

const isFxoStandardForwardAccu = (
  baseRowsFields: CalendarFieldsState[],
  accumulatorType: ForwardAccumulatorTypes,
): baseRowsFields is FxoStandardForwardAccumulatorBaseCalendarFields[] =>
  accumulatorType === 'standard';

const isFxTargetAccu = (
  baseRowsFields: CalendarFieldsState[],
  instrumentName: AccumulatorInstrumentName,
): baseRowsFields is FxTargetAccumulatorCalendarFields[] =>
  instrumentName === 'FxoTargetAccumulator';

const isFxoSquareTargetAccu = (
  baseRowsFields: CalendarFieldsState[],
  instrumentName: AccumulatorInstrumentName,
): baseRowsFields is FxoSquareTargetAccumulatorCalendarFields[] =>
  instrumentName === 'FxoSquareTargetAccumulator';

const isFxoPauseTargetAccu = (
  baseRowsFields: CalendarFieldsState[],
  instrumentName: AccumulatorInstrumentName,
): baseRowsFields is FxoSquareTargetAccumulatorCalendarFields[] =>
  instrumentName === 'FxoPauseTargetAccumulator';

export function getComputeDisplayFields(
  baseRowsFields: CalendarFieldsState[],
  trade: AccumulatorTrade,
  badges?: string[],
) {
  if (trade.instrumentName === 'FxoForwardAccumulator') {
    if (isFxoPermanentForwardAccu(baseRowsFields, trade.accumulatorType)) {
      return computeRowFieldsForFxoPermanentForwardAccumulator(baseRowsFields, trade);
    }

    if (isFxoStandardForwardAccu(baseRowsFields, trade.accumulatorType)) {
      return computeRowFieldsForFxoStandardForwardAccumulator(baseRowsFields, trade);
    }
  }

  if (isFxTargetAccu(baseRowsFields, trade.instrumentName)) {
    return computeRowsFieldsForFxTargetAccumulator(
      baseRowsFields,
      trade as TargetAccumulatorTrade,
      badges,
    );
  }

  if (isFxoSquareTargetAccu(baseRowsFields, trade.instrumentName)) {
    return computeRowsFieldsForFxoSquareTargetAccumulator(
      baseRowsFields,
      trade as TargetAccumulatorTrade,
      badges,
    );
  }
  if (isFxoPauseTargetAccu(baseRowsFields, trade.instrumentName)) {
    return computeRowsFieldsForFxoPauseTargetAccumulator(
      baseRowsFields,
      trade as TargetAccumulatorTrade,
      badges,
    );
  }

  return [];
}
