import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { routes } from '@/App/routes';

import './Home.scss';
import { selectWatchedAlertCount } from '@/store/state/alerts/alerts.selectors';
import { Notifications } from './Notifications';
import { UpcomingsPill } from './UpcomingsPill';
import { LastPill } from '@/App/Home/LastPill';
import { canModifyAlert, selectHasCallRight, selectHasSpotRight, selectMnemo, selectUserType } from '@/store/state/user/user.selectors';
import { MainLayout } from '../Layout/MainLayout';
import { ServiceLoader } from '../Shared/ServiceLoader';
import { useTimeout } from '../utils/hooks/timeout';
import { BoostEmailButton } from '../BoostEmail/BoostEmailButton';
import { useTradesQuery } from '@/store/state/upcomingFlows/upcomingFlows.api';

export function AlertsPill() {
  const count = useSelector(selectWatchedAlertCount);

  return count ?
      <span className="badge rounded-pill badge-discreet-info">
        <FormattedMessage id="home.alerts.count" values={{ count }} />
      </span>
    : null;
}

export function BoostingPill() {
  return (
    <span className="badge rounded-pill badge-discreet-info">
      <FormattedMessage id="home.boost.pill" />
    </span>
  );
}

interface TileProps {
  to: string;
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
  additionalInfo?: string | React.ReactElement;
  e2e?: string;
}

function Tile({ to, title, additionalInfo, description, e2e }: TileProps) {
  return (
    <div className="col-md-6 mb-4">
      <Link to={{ pathname: to, search: window.location.search }} className="card card-bordered card-bordering m-0" data-e2e={e2e}>
        <div className="card-body">
          <h2 className="display-3">{title}</h2>
          <div className="text-secondary">{description || 'description'}</div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex flex-column mb-1">{additionalInfo}</div>
            <i className="icon icon-md text-secondary">arrow_forward</i>
          </div>
        </div>
      </Link>
    </div>
  );
}

function BoostCard() {
  return (
    <div className="col-md-6 mb-4">
      <div className="card card-bordered m-0" data-e2e="boost-card">
        <div className="card-body">
          <h2 className="display-3">
            <FormattedMessage id="home.boost.title" />
          </h2>
          <div className="text-secondary">
            <FormattedMessage id="home.boost.description" />
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex flex-column mb-1">
              <BoostingPill />
            </div>
            <BoostEmailButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export function Home() {
  const canSeeTradeTile = useSelector(selectHasSpotRight);
  const readonly = !useSelector(canModifyAlert);
  const isReadonlyTrader = useSelector(selectUserType) === 'Trader' && readonly;
  const canSeeAlertTile = (useSelector(selectHasCallRight) && isReadonlyTrader) || useSelector(selectHasCallRight);

  const timeout = useTimeout(800);

  const mnemonic = useSelector(selectMnemo) ?? '';
  const trades = useTradesQuery({ entityMnemonic: mnemonic });

  const firstRender = React.useRef(true);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  if (!timeout && !firstRender.current) {
    return <ServiceLoader />;
  }

  return (
    <MainLayout>
      <Notifications />
      <div className="Home row">
        {canSeeTradeTile && (
          <Tile to={routes.TRADE} title={<FormattedMessage id="home.trade.title" />} e2e="trade" description={<FormattedMessage id="home.trade.description" />} />
        )}
        <Tile
          to={routes.PORTFOLIO}
          title={<FormattedMessage id="home.blotter.title" />}
          e2e="blotter"
          description={<FormattedMessage id="home.blotter.description" />}
          additionalInfo={
            <>
              {trades !== undefined && trades.data !== undefined && trades.data.length > 0 && (
                <div>
                  <LastPill trades={trades.data!} />
                </div>
              )}
              <div>
                <UpcomingsPill />
              </div>
            </>
          }
        />
        {canSeeAlertTile && (
          <Tile
            to={routes.ALERTS}
            title={<FormattedMessage id="home.alerts.title" />}
            e2e="alerts"
            description={<FormattedMessage id="home.alerts.description" />}
            additionalInfo={<AlertsPill />}
          />
        )}

        <BoostCard />
      </div>
    </MainLayout>
  );
}
