import { Dispatch, Middleware, MiddlewareAPI, Action, ActionCreator, AnyAction } from '@reduxjs/toolkit';
import { noop } from '@/utils/noop';
import { AppAction } from '@/store/app.actions';

export type OnActionNotifierCallback<S> = (action: any, state: S) => void;

interface IActionNotifierWhitelistMatchOrNotConfig<S> {
  didMatch: OnActionNotifierCallback<S>;
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierMatchAll<S> {
  didMatch: OnActionNotifierCallback<S>;
}

interface IActionNotifierWhitelistMatch<S> {
  didMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierWhitelistNoMatch<S> {
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

function configDefault<S>(config: ActionNotifierConfig<S>): Partial<IActionNotifierWhitelistMatchOrNotConfig<S>> {
  const conf = config as IActionNotifierWhitelistMatchOrNotConfig<S>;
  return {
    actionsList: conf.actionsList ?? undefined,
    didMatch: conf.didMatch ?? undefined,
    didNotMatch: conf.didNotMatch ?? undefined,
  };
}

export type ActionNotifierConfig<S> =
  | IActionNotifierWhitelistMatchOrNotConfig<S>
  | IActionNotifierMatchAll<S>
  | IActionNotifierWhitelistMatch<S>
  | IActionNotifierWhitelistNoMatch<S>;

export function actionNotifier<TState>(config: ActionNotifierConfig<TState>) {
  const { actionsList, didMatch = noop, didNotMatch = noop } = configDefault(config);

  return ((api: MiddlewareAPI<Dispatch<AppAction>, TState>) => (next: Dispatch<AppAction>) => (action: AppAction) => {
    if (action.type === undefined) {
      return next(action);
    }

    actionsList === undefined || actionsList.includes(action.type) ? didMatch(action, api.getState()) : didNotMatch(action, api.getState());

    return next(action);
  }) as Middleware;
}

export function actionCreatorTypeExtractor<TActionType extends string>(actionCreator: ActionCreator<Action<TActionType>>): TActionType {
  return actionCreator().type;
}
