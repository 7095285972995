import { FormattedMessage } from 'react-intl';
import { useExtendedFlowsQuery } from '@/store/state/upcomingFlows/upcomingFlows.api';

export function UpcomingsPill() {
  const { data } = useExtendedFlowsQuery();

  if (data === undefined) return null;

  const count = data.length ?? 0;

  return count ? (
    <div>
      <span className="badge rounded-pill badge-discreet-info" data-e2e="upcoming-pill">
        <FormattedMessage id="home.blotter.upcoming" values={{ count }} />
      </span>
    </div>
  ) : null;
}
