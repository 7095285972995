import { toUtc } from "@/utils/dates";
import { DATE_LONG_FORMAT } from "@/utils/format";
import { formatDateLocalized } from "@/utils/locale";

type FiltersProps = {
  selectedDates: Date[],
  onFilterClicked?: (date: Date) => void,
  onClearClicked?: () => void
}

export function Filters({ selectedDates, onFilterClicked, onClearClicked }: FiltersProps) {
  return <div className="d-flex flex-center">
    {selectedDates.map((date, i) => 
      <span
        key={+date}
        className="badge rounded-pill badge-discreet-info badge-dismissible ms-2 p-1 pe-2"
        onClick={() => onFilterClicked?.(date)}
        role="button"
        tabIndex={i}
      >
        <i className="icon icon-xs ms-1">close</i>
        {formatDateLocalized(toUtc(date), DATE_LONG_FORMAT).replace('.', ' ')}
      </span>
    )}
    {selectedDates.length > 0 && <button type="button" className="btn btn-flat ms-2 px-1" onClick={() => onClearClicked?.()}>Effacer les filtres</button>}
  </div>
}