import { FormattedMessage } from 'react-intl';
import { routes } from '@/App/routes';
import { Piwik } from '@/utils/piwik';
import { useNavigate } from 'react-router';

export function TradesEmpty({ source }: { source: 'api' | 'search' }) {
  const isApi = source === 'api';
  const navigate = useNavigate();
  const goToTrade = () => {
    Piwik.trackAction('Gone to sellOrBuy from trades');
    navigate(routes.TRADE);
  };

  return (
    <>
      <div className="Trades text-center text-secondary mt-5">
        <i className="icon icon-md">info_outline</i>
        <h3 className="mb-2">
          <FormattedMessage id={isApi ? 'trades.noTrades' : 'trades.searchFailed'} />
        </h3>
        <div>
          <FormattedMessage id={isApi ? 'trades.displayedHere' : 'trades.modifyCriteria'} />
        </div>
        {isApi && (
          <button type="button" className="btn btn-primary mt-4 btn-lg" onClick={goToTrade}>
            <FormattedMessage id="trades.sellOrBuy" />
          </button>
        )}
      </div>
    </>
  );
}
