/* eslint-disable no-shadow */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IntlShape, PrimitiveType, useIntl } from 'react-intl';
import { differenceInHours, intlFormat, isSameDay, parseISO, subBusinessDays, subDays } from 'date-fns';
import { isDefined, isNotDefined } from '@sgme/fp';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';
import {
  AccumulatorInstrumentName,
  AccumulatorTrade,
  Barrier,
  CsvFormattedTrade,
  FormattedFlow,
  FormattedTrade,
  ForwarAndVanillaAccumulatorTrade,
  ForwardTrade,
  getTradeFamily,
  isAverageTriggerOption,
  isCashCurrency1VanillaOption,
  isCashCurrency2VanillaOption,
  isCashSettlementTriggerOption,
  isForwardVanillaOption,
  isWindowedTriggerOption,
  MyHedgeTrade,
  TargetAccumulatorTrade,
  TradeFamily,
  TriggerOptionTrade,
} from '@/models/trade';
import { MyFxFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import { Way } from '@/models/way';
import { toUtc } from './dates';
import { availableLocales, formatDateLocalized, formatDistanceToNow, formatTime, getProductLocalizationId, Locale } from './locale';
import { getCurrencyPairPrecision } from './currencies';
import { Currency } from '@/models/currency';
import { computeWay } from '@/App/utils/computeWay';
import { isAccumulator } from '@/utils/predicates';
import { getComputeDisplayFields } from '@/App/calendar/getComputeDisplayField';
import { getFieldsByInstrument } from '@/App/calendar/state';
import {
  DecoratedFields,
  FxoPauseTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
  FxTargetAccumulatorCalendarFields,
} from '@/models/calendar';
import { capitalizeFirstLetter } from '@/utils/strings';

export const DATE_SHORT_FORMAT = 'dd/MM/yyyy';
export const DATE_LONG_FORMAT = 'dd MMM yyyy';
export const DATE_FULL_FORMAT = 'dd MMMM yyyy';
export const FILENAME_FORMAT = 'dd-MM-yyyy_HH-mm-ss';

type MessageFormatter = (id: string, values?: Record<string, PrimitiveType>) => string;
const formatMessageForLocale =
  (intl: IntlShape): MessageFormatter =>
  (id, values) =>
    intl.formatMessage({ id }, values);

const getDefaultFormatter = (locale: Locale) =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 5,
  }).format;
const getFormatter = (locale: Locale, precision: number) =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }).format;
const formatters = availableLocales.reduce(
  (agg, locale) => ({
    [locale]: getDefaultFormatter(locale),
    ...agg,
  }),
  {},
) as Record<Locale, Intl.NumberFormat['format']>;

const rangeMap = <T>(from: number, to: number, mapTo: (value: number) => T): readonly T[] => {
  const current: (i: number) => number = from <= to ? (i) => i + from : (i) => from - i;
  return Array.from({ length: from <= to ? to - from + 1 : from - to + 1 }, (_, i) => mapTo(current(i)));
};

const powersOfTen = rangeMap(0, 10, (x) => 10 ** x);

const tenPower = (exponent: number) => powersOfTen[exponent] ?? 10 ** exponent;

export const convertToPointsWithPrecision = (rawValue: number | null, precision: number): number => +((rawValue || 0) * tenPower(precision - 1)).toFixed(2);

type LocalizedFormatter = (locale: Locale) => (n: number, format?: Intl.NumberFormat['format']) => string;

export const formatAmount: LocalizedFormatter =
  (locale) =>
  (amount, format = formatters[locale]) =>
    format(amount);

export const formatAmountWithSign: LocalizedFormatter = (locale) => (amount, format) => {
  const sign = amount > 0 || 1 / amount === Infinity ? '+' : '';
  return sign + formatAmount(locale)(amount, format);
};

const formatAmountWithoutSign: LocalizedFormatter = (locale) => (amount, format) => formatAmount(locale)(Math.abs(amount), format);

export const formatAmountWithPrecision = (locale: Locale) => (amount: number, precision: number) => formatAmount(locale)(amount, getFormatter(locale, precision));

const getFormattedSide = (formatMessage: MessageFormatter) => (side: Way) => {
  switch (side) {
    case 'buy':
      return formatMessage('trades.way.BUY');
    case 'sell':
      return formatMessage('trades.way.SELL');
    default: {
      const exhaustiveCheck: never = side;
      return exhaustiveCheck;
    }
  }
};

const getFormattedWay = (formatMessage: MessageFormatter) => {
  const getLocalizedFormattedSide = getFormattedSide(formatMessage);

  return (trade: MyHedgeTrade, family?: TradeFamily) => {
    const { side, amountCurrency, sub } = trade;

    if (family === 'OptionStrategies') {
      const [ccy1, ccy2] = sub[0].currencyPair.split('/');
      return sub.every((element) => element.side === sub[0].side) ? ccy2 : `${ccy1}, ${ccy2}`;
    }

    if (isDefined(family) && isAccumulator(trade, family)) {
      return side === 'buy' || side === 'sell' ? `${getLocalizedFormattedSide(computeWay(trade))} ${amountCurrency}` : '';
    }

    return side === 'buy' || side === 'sell' ? `${getLocalizedFormattedSide(side)} ${amountCurrency}` : '';
  };
};

const adjustAmountWithSide = (trade: MyHedgeTrade, family?: TradeFamily) => (amount: number) => {
  if (isDefined(family) && isAccumulator(trade, family)) {
    return computeWay(trade) === 'sell' ? -amount : amount;
  }

  return trade.side === 'sell' ? -amount : amount;
};

const getKOBadge = (formatMessage: MessageFormatter) => (targetAcc: TargetAccumulatorTrade) => {
  const match = targetAcc.description?.match(/\s(NONE|FULL|ADJUSTED_BY_AMOUNT|ADJUSTED_BY_STRIKE) (SPOT|FWD|CF1|CF2)\s/);

  switch (match?.[1]) {
    case 'NONE':
      return formatMessage('trades.tradeRow.badges.ko', { koValue: 'None' });
    case 'FULL':
      return formatMessage('trades.tradeRow.badges.ko', { koValue: 'Full' });
    case 'ADJUSTED_BY_AMOUNT':
      return formatMessage('trades.tradeRow.badges.ko', { koValue: 'Adj Amount' });
    case 'ADJUSTED_BY_STRIKE':
      return formatMessage('trades.tradeRow.badges.ko', { koValue: 'Adj Strike' });
    default:
      return '';
  }
};

const getBarrierBadge = (formatMessage: MessageFormatter) => (barriers: Barrier[], formatAmountWithPrecision: (amount: number, precision: number) => string) =>
  barriers && barriers.length ?
    formatMessage('trades.tradeRow.badges.barrier', {
      amount: formatAmountWithPrecision(barriers[0]?.barrierLevel?.[0], 5),
    })
  : '';

export const getExpiryDateFromDescription =
  (locale: string) =>
  (description: string): string | undefined => {
    const expiryDateRegex = /ExpiryDate=([0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4})/;
    const expiryDateString = expiryDateRegex.exec(description)?.[1] ?? '';

    if (expiryDateString !== '') {
      const [month, day, year] = expiryDateString!.split('/');
      const expiryDate = new Date(Number(year), Number(month) - 1, Number(day));
      const formattedExpiryDate = formatDateLocalized(expiryDate, DATE_LONG_FORMAT);
      return formattedExpiryDate;
    }

    return undefined;
  };

const getFormattedBadges = (
  formatMessage: MessageFormatter,
  formatAmountWithPrecision: (amount: number, precision: number) => string,
  getExpiryDateFromDescription: (description: string) => string | undefined,
) => {
  const getLocalizedKOBadge = getKOBadge(formatMessage);
  const getLocalizedBarrierBadge = getBarrierBadge(formatMessage);

  return (trade: MyHedgeTrade, format: (value: number) => string): string[] => {
    const badges: string[] = [];
    const family = getTradeFamily(trade);
    const formatNumber = (value: number | undefined) => (isDefined(value) ? formatAmountWithPrecision(value, 5) : '');
    const [ccy1, ccy2] = trade.currencyPair.split('/');

    switch (family) {
      case 'SimpleOptions': {
        const expiryDateString = getExpiryDateFromDescription(trade?.description ?? '');
        if (expiryDateString !== undefined) {
          const expiryDateLabel = formatMessage('trades.tradeRow.badges.expiry', { date: capitalizeFirstLetter(expiryDateString) });

          badges.push(expiryDateLabel);
        }

        if (trade.instrumentName === 'FxVanillaOption') {
          if (isForwardVanillaOption(trade.optionType)) {
            badges.push(formatMessage('trades.tradeRow.badges.forwardDelivery'));
          }

          if (isCashCurrency1VanillaOption(trade.optionType)) {
            badges.push(formatMessage('trades.tradeRow.badges.cashDelivery', { ccy: ccy1 }));
          }

          if (isCashCurrency2VanillaOption(trade.optionType)) {
            badges.push(formatMessage('trades.tradeRow.badges.cashDelivery', { ccy: ccy2 }));
          }
        }

        break;
      }

      case 'ForwardAccumulators': {
        const { barriers } = trade as ForwarAndVanillaAccumulatorTrade;

        badges.push(getLocalizedBarrierBadge(barriers, formatNumber));

        break;
      }
      case 'TargetAccumulators': {
        const targetAcc = trade as TargetAccumulatorTrade;
        const getRemaining = (target: number | undefined, consumedTarget: number | undefined) =>
          target !== undefined && consumedTarget !== undefined ? formatAmountWithPrecision(target - consumedTarget, 5) : '';

        badges.push(
          formatMessage('trades.tradeRow.badges.target', {
            amount: formatNumber(targetAcc.target),
          }),
          formatMessage('trades.tradeRow.badges.remaining', {
            amount: getRemaining(targetAcc.target, targetAcc.consumedTarget),
          }),
        );

        if (targetAcc.instrumentName === 'FxoPivotTargetAccumulator' || targetAcc.instrumentName === 'FxoPivotEKITargetAccumulator') {
          badges.push(
            formatMessage('trades.tradeRow.badges.pivot', {
              amount: formatNumber(targetAcc.pivot),
            }),
          );
        }

        badges.push(getLocalizedKOBadge(targetAcc));

        break;
      }
      case 'TriggerOptions': {
        const expiryDateString = getExpiryDateFromDescription(trade?.description ?? '');
        if (expiryDateString !== undefined) {
          const expiryDateLabel = formatMessage('trades.tradeRow.badges.expiry', { date: capitalizeFirstLetter(expiryDateString) });

          badges.push(expiryDateLabel);
        }

        const triggerOption = trade as TriggerOptionTrade;
        badges.push(getLocalizedBarrierBadge(triggerOption.barriers, formatAmountWithPrecision));

        if (isWindowedTriggerOption(triggerOption.optionType)) badges.push(formatMessage('trades.tradeRow.badges.window'));

        if (isAverageTriggerOption(triggerOption.optionType)) badges.push(formatMessage('trades.tradeRow.badges.average'));

        if (isCashSettlementTriggerOption(triggerOption.optionType)) badges.push(formatMessage('trades.tradeRow.badges.cashDelivery', { ccy: ccy1 }));

        break;
      }
      default:
    }

    return badges;
  };
};

const getMaturityDateFormat = (csvExport: boolean, fullDateFormat: boolean) => {
  if (csvExport) return DATE_SHORT_FORMAT;
  if (fullDateFormat) return DATE_FULL_FORMAT;
  return DATE_LONG_FORMAT;
};

const getFormattedMaturityDate = (date: string, dateFormat: string) => formatDateLocalized(toUtc(parseISO(date)), dateFormat).replace('.', ' ');

const isTargetAccu = (
  trade: MyHedgeTrade,

  family?: TradeFamily,
): trade is AccumulatorTrade => family === 'TargetAccumulators';

const isNotFxoPauseTargetAccu = (
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[],
  instrumentName: AccumulatorInstrumentName,
): computedFields is
  | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
  | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
  | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[] => instrumentName !== 'FxoPauseTargetAccumulator';

const getFormattedStrike = (formatMessage: MessageFormatter, format: Intl.NumberFormat['format']) => (trade: MyHedgeTrade, family?: TradeFamily, badges?: string[]) => {
  let { strike } = trade;

  if (family === 'OptionStrategies')
    if (trade.sub.length > 1) return formatMessage('trades.tradeRow.multiple');
    else strike = trade.sub[0]?.strike;

  if (isTargetAccu(trade, family)) {
    // we need to check if we have the same strike in every row
    const firstStrike = trade.xOneCalendar?.[0]?.strike;
    const isSameStrikes = trade.xOneCalendar?.every((row) => row?.strike === firstStrike);

    const computedFields = getComputeDisplayFields(getFieldsByInstrument(trade), trade, badges);

    if (!isSameStrikes && isNotFxoPauseTargetAccu(computedFields, trade.instrumentName)) {
      return formatMessage('trades.tradeRow.multiple.target', {
        value: format(
          //@ts-ignore typescript say that is any but it is clearly not any ! and you can see the type
          computedFields.find((row) => isNotDefined(row.fixing.value))?.strike.value as number,
        ),
      });
    }
  }

  //@ts-ignore there is a mistake in the backend side but it cannot corrected so we had to put this condition in
  if (strike === 'NaN' || strike === 0) {
    return '-';
  }

  return !Number.isNaN(strike) ? format(strike) : '';
};

const getFormattedAmounts =
  (formatMessage: MessageFormatter, format: (value: number) => string) =>
  (trade: MyHedgeTrade, family: TradeFamily): [amount: string, secondAmount?: string] => {
    switch (family) {
      case 'Forwards':
      case 'Swaps':
        return [
          format(trade.remainingAmount ?? 0),
          trade.remainingAmount !== trade.nominal ? `${formatMessage('trades.tradeRow.initial', { amount: format(trade.nominal ?? 0) })}` : undefined,
        ];
      case 'SimpleOptions':
      case 'TriggerOptions':
      case 'Other':
        return trade.remainingAmount ?
            [format(trade.remainingAmount), trade.remainingAmount !== trade.nominal ? formatMessage('trades.tradeRow.initial', { amount: format(trade.nominal ?? 0) }) : undefined]
          : [format(trade.nominal ?? 0)];
      case 'OptionStrategies':
        return [formatMessage('trades.tradeRow.multiple')];
      case 'ForwardAccumulators':
      case 'TargetAccumulators': {
        const accumulator = trade as AccumulatorTrade;

        return [
          isDefined(accumulator.accumulatedAmount) ? format(accumulator.accumulatedAmount) : '-',
          formatMessage('trades.tradeRow.maxRemaining', {
            amount: format(trade.remainingAmount ?? 0),
          }),
        ];
      }
      default:
        return [format(trade.nominal ?? 0)];
    }
  };

const formatLocalized =
  <T>(formatMessage: MessageFormatter, getLocalizationId: (item: T) => string) =>
  (item: T | undefined) =>
    item ? formatMessage(getLocalizationId(item)) : '-';

const getFormattedDate =
  (formatMessage: MessageFormatter) =>
  (dateString: string, formatString: string, toNow = false) => {
    const date = parseISO(dateString);
    const diff = Math.abs(differenceInHours(Date.now(), date));

    if (toNow) {
      if (diff < 1) return formatDistanceToNow(date);
      if (isSameDay(date, Date.now()))
        return formatMessage('trades.tradeRow.todayAt', {
          time: formatTime(date),
        });
    }

    return formatDateLocalized(date, formatString).replace('.', '');
  };

const getFormattedCurrencyPair = (formatMessage: MessageFormatter) => (trade: MyHedgeTrade, family: TradeFamily) => {
  if (family === 'OptionStrategies') {
    const pairs = trade.sub.reduce<string[]>((acc, { currencyPair }) => (acc.includes(currencyPair) ? acc : [...acc, currencyPair]), []);

    if (pairs.length > 1) return formatMessage('trades.tradeRow.multipleCurrencies');
  }

  return trade.currencyPair;
};

const cleanupSpacesInNumbers: (csvExport: boolean) => (value: string) => string = (csvExport) => (csvExport ? (value) => value.replace(/\s/g, ' ') : (value) => value);

const doShowInfo = (trade: MyHedgeTrade): trade is ForwardTrade => ['FxOutright', 'FxNdf', 'FxSwap'].includes(trade.instrumentName);

// FORMAT TRADES
const formatTrade = (locale: Locale, intl: IntlShape) => {
  const {
    formatMessage,
    formatAmount: localizedFormatAmount,
    formatAmountWithSign: localizedFormatAmountWithSign,
    formatAmountWithoutSign: localizedFormatAmountWithoutSign,
    formatAmountWithPrecision: localizedFormatAmountWithPrecision,
  } = getLocalizedFormattingContext(locale, intl);

  const getLocalizedFormattedSide = getFormattedSide(formatMessage);
  const getLocalizedFormattedWay = getFormattedWay(formatMessage);
  const getLocalizedFormattedCurrencyPair = getFormattedCurrencyPair(formatMessage);
  const getLocalizedFormattedDate = getFormattedDate(formatMessage);
  const getLocalizedFormattedBadges = getFormattedBadges(formatMessage, formatAmountWithPrecision(locale), getExpiryDateFromDescription(locale));
  const getLocalizedFormattedProductName = formatLocalized(formatMessage, getProductLocalizationId);

  return (trade: MyHedgeTrade, csvExport: boolean, fullDateFormat: boolean, noSign = false): FormattedTrade | CsvFormattedTrade | undefined => {
    const [ccy1, ccy2] = trade?.currencyPair ? (trade?.currencyPair.split('/') as [Currency, Currency]) : [undefined, undefined];
    const precision = getCurrencyPairPrecision(ccy1, ccy2);
    const family = getTradeFamily(trade);

    const localizedFormatRate = (rate: number) => localizedFormatAmount(rate, getFormatter(locale, precision));
    const getLocalizedFormattedStrike = getFormattedStrike(formatMessage, localizedFormatRate);

    const adjustAmount = adjustAmountWithSide(trade, family);
    const adjustSpacesOrNot = cleanupSpacesInNumbers(csvExport);
    const getLocalizedFormattedAmounts = getFormattedAmounts(formatMessage, (amount: number) =>
      noSign ? localizedFormatAmountWithoutSign(adjustAmount(amount), getFormatter(locale, 2)) : localizedFormatAmountWithSign(adjustAmount(amount), getFormatter(locale, 2)),
    );

    const hasSide = trade.side === 'buy' || trade.side === 'sell'; // May be 'none'
    const maturityDateFormat = getMaturityDateFormat(csvExport, fullDateFormat);

    const badges = getLocalizedFormattedBadges(trade, localizedFormatAmount);
    const maturitydate = getFormattedMaturityDate(trade.maturitydate, maturityDateFormat);
    const { reference, instrumentName } = trade;
    const [amount, secondAmount] = getLocalizedFormattedAmounts(trade, family);
    const strike = adjustSpacesOrNot(getLocalizedFormattedStrike(trade, family, badges));
    const [showInfo, fxSpot, farPoints] = doShowInfo(trade) ? [true, localizedFormatRate(trade.fxSpot), localizedFormatAmountWithPrecision(trade.farPoints, 2)] : [false];

    return csvExport ?
        {
          maturitydate,
          reference,
          currencyPair: trade.currencyPair,
          instrumentName,
          nominal: (trade.nominal ?? 0).toString(),
          remainingAmount: (trade.remainingAmount ?? 0).toString(),
          strike,
          way: getLocalizedFormattedWay(trade),
          date: getLocalizedFormattedDate(trade.date, csvExport ? DATE_SHORT_FORMAT : DATE_LONG_FORMAT),
        }
      : {
          family,
          maturitydate,
          twoDaysBeforePaymentDate: getFormattedMaturityDate(subBusinessDays(trade.maturitydate, 2).toISOString(), DATE_LONG_FORMAT),
          reference,
          currencyPair: getLocalizedFormattedCurrencyPair(trade, family),
          productName: getLocalizedFormattedProductName(trade),
          amount,
          secondAmount,
          remainingAmount: localizedFormatAmountWithSign(adjustAmount(trade.remainingAmount ?? 0), getFormatter(locale, 2)),
          nominal: localizedFormatAmountWithSign(adjustAmount(trade.nominal ?? 0), getFormatter(locale, 2)),
          strike,
          way: getLocalizedFormattedWay(trade, family),
          side: hasSide ? getLocalizedFormattedSide(trade.side) : '',
          date: getLocalizedFormattedDate(trade.date, csvExport ? DATE_SHORT_FORMAT : DATE_LONG_FORMAT, true),
          isToday: isSameDay(parseISO(trade.date), new Date()),
          amountCurrency: trade.amountCurrency,
          masterReference: trade.masterReference,
          originalReference: trade.originalReference,
          badges,
          showInfo,
          fxSpot,
          farPoints,
          subCount: family === 'OptionStrategies' ? trade.sub.length : undefined,
        };
  };
};

const mapTrade =
  (locale: Locale, intl: IntlShape) =>
  (fullDateFormat = false, noSign = false): ((trade: MyHedgeTrade) => FormattedTrade) =>
  (trade: MyHedgeTrade) =>
    formatTrade(locale, intl)(trade, false, fullDateFormat, noSign) as FormattedTrade;

const mapTradeCsv =
  (locale: Locale, intl: IntlShape) =>
  (trade: MyHedgeTrade): CsvFormattedTrade =>
    formatTrade(locale, intl)(trade, true, false) as CsvFormattedTrade;

// FORMAT UPCOMING FLOWS
const formatFlow = (locale: Locale, intl: IntlShape) => {
  const {
    formatMessage,
    formatAmountWithSign: localizedFormatAmountWithSign,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } = getLocalizedFormattingContext(locale, intl);

  const getLocalizedFormattedWay = getFormattedWay(formatMessage);

  const getLocalizedFormattedProductName = formatLocalized(formatMessage, getProductLocalizationId);

  return (csvExport = false) =>
    (flow: MyFxFlow & MyHedgeTrade): FormattedFlow => {
      const localizedFormatStrike = new Intl.NumberFormat(locale, {
        maximumFractionDigits: 10,
      }).format;
      const getLocalizedFormattedStrike = getFormattedStrike(formatMessage, localizedFormatStrike);

      const adjustAmount = adjustAmountWithSide(flow);
      const adjustSpacesOrNot = cleanupSpacesInNumbers(csvExport);
      const getLocalizedFormattedAmount = (amount: number) => {
        return isDefined(amount) ? localizedFormatAmountWithSign(adjustAmount(amount), getFormatter(locale, 2)) : '-';
      };
      //@ts-ignore there is a mistake in the backend side but it cannot be corrected so we had to put this condition in
      const shouldStrikeBeEmpty = flow.strike === 'NaN' || flow.strike === 0;
      const strikeForCsv = isDefined(flow.strike) && !shouldStrikeBeEmpty ? String(flow.strike) : '';

      return {
        paymentDate: getFormattedMaturityDate(flow.paymentDate, DATE_LONG_FORMAT),
        twoDaysBeforePaymentDate: getFormattedMaturityDate(subBusinessDays(flow.paymentDate, 2).toISOString(), DATE_LONG_FORMAT),
        contractId: flow.contractId,
        currency: flow.currency,
        currencyPair: flow.currencyPair,
        productName: getLocalizedFormattedProductName(flow),
        amount: !csvExport ? getLocalizedFormattedAmount(flow.amount) : flow.amount.toString(),
        isCertain: flow.isCertain,
        way: !csvExport ? getLocalizedFormattedWay(flow) : flow.side,
        strike: !csvExport ? adjustSpacesOrNot(getLocalizedFormattedStrike(flow)) : strikeForCsv,
      };
    };
};

const mapFlow =
  (locale: Locale, intl: IntlShape) =>
  (csvExport = false) =>
  (flow: MyFxFlow & MyHedgeTrade) =>
    formatFlow(locale, intl)(csvExport)(flow);

// FORMATTING CONTEXT
const getLocalizedFormattingContext = (locale: Locale, intl: IntlShape) => {
  const localizedMapTrade = mapTrade(locale, intl);

  return {
    formatMessage: formatMessageForLocale(intl),
    formatAmount: formatAmount(locale),
    formatAmountWithSign: formatAmountWithSign(locale),
    formatAmountWithoutSign: formatAmountWithoutSign(locale),
    formatAmountWithPrecision: formatAmountWithPrecision(locale),
    mapTrade: localizedMapTrade(),
    mapTradeWithOptions: (trade: MyHedgeTrade, options?: { fullDateFormat?: boolean; noSign?: boolean }) => localizedMapTrade(options?.fullDateFormat, options?.noSign)(trade),
    mapTradeCsv: mapTradeCsv(locale, intl),
    mapFlow: mapFlow(locale, intl)(false),
    mapFlowCsv: mapFlow(locale, intl)(true),
  };
};

export const useFormattingContext = () => {
  const locale = useSelector(selectCurrentLocale);
  const intl = useIntl();

  return useMemo(() => getLocalizedFormattingContext(locale, intl), [intl, locale]);
};
