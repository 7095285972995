import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { selectRolloverTrade } from '@/store/state/rollover/rollover.selectors';
import { getRolloverImpact, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { DATE_LONG_FORMAT, useFormattingContext } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { selectOppositeCurrencyForTradeType, selectSellCurrency } from '@/store/state/trades/trades.selectors';

export function OperationInfo() {
  const { formatAmountWithPrecision } = useFormattingContext();
  const rfs = useSelector(selectRfs);
  const trade = useSelector(selectRolloverTrade);
  const currency = useSelector(selectSellCurrency('rollover'));
  const rolloverImpact = getRolloverImpact(rfs, trade!);

  if (rolloverImpact === undefined) {
    return null;
  }

  const type = rolloverImpact > 0 ? 'greater' : 'lesser';
  const impact = formatAmountWithPrecision(Math.abs(rolloverImpact), 2);
  const date = formatDateLocalized(parseISO(trade!.maturitydate), DATE_LONG_FORMAT);

  return (
    <div className="border border-info p-2 pt-4 mt-5 col-md-7">
      <div>
        <div className="d-flex">
          <div>
            <i className="icon icon-xs line-height-1 me-3 text-info">error_outline</i>
          </div>

          <div>
            <p className="text-info">
              <FormattedMessage id={`rollover.infos.${type}`} values={{ impact, date, currency, b: (content) => <strong>{content}</strong> }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
