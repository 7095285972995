import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { routes } from '@/App/routes';
import { Currency } from '@/models/currency';
import { FeatureToggle } from '@/share/FeatureToggle/FeatureToggle';
import { createAlertFormUpdate } from '@/store/state/create-alert/create-alert.actions';
import { Piwik } from '@/utils/piwik';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '@/store/store';

interface StateProps {
  buyCurrency: Currency;
  sellCurrency: Currency;
}

export function AlertsLink() {
  const { buyCurrency, sellCurrency } = useSelector(selectRfs);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClick = (currencies: StateProps) => {
    Piwik.trackAction('Gone to alerts from buySell page');
    dispatch(
      createAlertFormUpdate({
        baseCurrency: currencies.buyCurrency,
        contraCurrency: currencies.sellCurrency,
      }),
    );
    navigate(routes.CREATE_ALERT);
  };

  return (
    <FeatureToggle feature="alerts">
      {(active) =>
        !active ? null : (
          <div className="mt-3">
            <FormattedMessage
              id="trader.goToAlerts.text"
              values={{
                link: (
                  <a className="text-link" href="#" onClick={() => onClick({ buyCurrency, sellCurrency })}>
                    <FormattedMessage id="trader.goToAlerts.link" />
                  </a>
                ),
              }}
            />
          </div>
        )
      }
    </FeatureToggle>
  );
}
