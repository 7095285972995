import { useLocation } from 'react-router';
import { isRolloverRoute } from '@/App/routes';
import { RolloverInfo } from './RolloverInfo';

export interface TradeInfoProps {
  displayMode: 'TOP' | 'RIGHT' | 'BOTTOM';
}

export function TradeInfo({ displayMode }: TradeInfoProps) {
  const { pathname } = useLocation();

  return isRolloverRoute(pathname) ? <RolloverInfo displayMode={displayMode} /> : null;
};
