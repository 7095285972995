/* eslint-disable no-nested-ternary */
import { isDefined, isNotDefined } from '@sgme/fp';
import { useState } from 'react';
import { isValid, parseISO } from 'date-fns';
import { useFormattingContext, DATE_LONG_FORMAT } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { FixingField } from './FixingField';
import CalendarTooltip from './CalendarTooltip';

interface RowFieldsProps {
  fieldContent: {
    value: number | string | undefined;
    isChecked?: boolean;
    overriddenPrecision?: number;
    isKo?: boolean;
    strikeImproved?: { isStrikeImproved?: boolean; leverage?: number; power?: string };
    isCumulatedAmount?: boolean;
    isUncertain?: boolean;
    isFixingEditable?: boolean;
  };
  fieldName: string;
  isEdited?: boolean;
  rowIndex: number;
  isCumulatedAmountNull?: boolean;
}

const highlightedFields = ['amount1', 'amount2', 'amount3', 'cumulatedAmount', 'target', 'cumulatedTarget', 'remainingTarget', 'improvedStrike'];

export function RowField({ fieldContent, fieldName, isEdited, rowIndex, isCumulatedAmountNull }: RowFieldsProps) {
  const { value, isChecked, overriddenPrecision, isKo, strikeImproved, isCumulatedAmount, isUncertain, isFixingEditable } = fieldContent;
  const [isFixingUndefinedAndEditable] = useState(fieldName === 'fixing' && isNotDefined(value) && isFixingEditable);
  const { formatAmountWithPrecision } = useFormattingContext();
  const isNumberField = typeof value === 'number';
  const isTargetValueZero = fieldName === 'target' && value === 0;
  const shouldHighlightField = isEdited && highlightedFields.includes(fieldName);
  const shouldHideImprovedStrikeValue = fieldName === 'improvedStrike' && isCumulatedAmountNull;
  let renderedField;

  if (isNumberField) {
    renderedField = (
      <>
        {isChecked && (
          <i className="icon icon-xs position-absolute ms-n4" data-e2e="check-icon">
            check &nbsp;
          </i>
        )}
        {isUncertain && (
          <i className="icon icon-md position-absolute ms-n3" style={{ color: '#dd8841', borderColor: '#2B6EFB1A' }} data-e2e="warning-icon">
            warning_amber &nbsp;
          </i>
        )}
        {isKo && (
          <>
            <span className="badge rounded-pill text-info" style={{ backgroundColor: '#2B6EFB1A', color: '#2B6EFB', borderColor: '#2B6EFB1A' }} data-e2e="ko-icon">
              KO
            </span>
            &nbsp;
          </>
        )}
        <CalendarTooltip labelId="improvedIconTooltip" value={{ leverage: strikeImproved?.leverage, power: strikeImproved?.power }} shouldShowTooltip>
          {strikeImproved?.isStrikeImproved && (
            <div className="icon icon-xs position-absolute ms-n3" data-e2e="arrow-icon">
              north_east
            </div>
          )}
        </CalendarTooltip>
        <span className={`${isChecked ? 'fw-bold ' : ''}`}>{formatAmountWithPrecision(value, overriddenPrecision ?? 5)}</span>
      </>
    );
  } else if (isDefined(value) && isValid(parseISO(value))) {
    renderedField = <>{formatDateLocalized(parseISO(value!), DATE_LONG_FORMAT)}</>;
  }

  if (fieldName === 'steps' && isDefined(value)) {
    renderedField = value;
  }

  return (
    <td
      className={`text-end ${shouldHighlightField ? 'text-info ' : ''} ${isCumulatedAmount ? 'fw-bold borders pe-3' : ''} ${isUncertain ? 'text-warning' : ''}`}
      key={fieldName}
      data-e2e={fieldName}
    >
      <CalendarTooltip labelId="warning" placement="right" shouldShowTooltip={isUncertain} className="tooltip-warning">
        {isFixingUndefinedAndEditable ?
          <FixingField value={value as number | undefined} rowIndex={rowIndex} isEdited={isEdited} isUncertain={isUncertain} />
        : shouldHideImprovedStrikeValue ?
          '-'
        : isDefined(value) && !isTargetValueZero ?
          renderedField
        : '-'}
      </CalendarTooltip>
    </td>
  );
}
