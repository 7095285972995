import * as React from 'react';
import './Alerts.scss';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compareDesc, parseISO } from 'date-fns';
import { Alert } from '@/models/alerts';
import { getDisplayableAlerts, isAlertLoading, isAlertLoadingFailed } from '@/store/state/alerts/alerts.selectors';
import { AlertCard } from './AlertCard';
import { Empty } from './Empty';
import { renewAlertThunk, startCreateAlertThunk, startUpdateAlertThunk } from '@/store/state/create-alert/create-alert.thunks';
import { deleteAlertAction } from '@/store/state/create-alert/create-alert.actions';
import { AlertItem } from '@/store/state/alerts/alerts.reducer';
import { canModifyAlert, selectHasCallRight, selectUserType } from '@/store/state/user/user.selectors';
import { MainLayout } from '../Layout/MainLayout';
import { Navigate, useNavigate } from 'react-router';
import { routes } from '../routes';
import { useAppDispatch } from '@/store/store';
import { Blinker } from '../Home/Notifications';

type AlertsDispatchedHandlers = {
  startUpdate: (alert: Alert) => void;
  renewAlert: (alert: Alert) => void;
  deleteAlert: (id: string) => void;
  startCreate: () => void;
};

type AlertsByStatus = [liveAlerts: AlertItem[], historicalAlerts: AlertItem[]];

const useDispatchedHandlers = (): AlertsDispatchedHandlers => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    startUpdate: (alert: Alert) => {
      dispatch(startUpdateAlertThunk(alert));
      navigate(routes.UPDATE_ALERT);
    },
    renewAlert: (alert: Alert) => {
      dispatch(renewAlertThunk(alert));
      navigate(routes.CREATE_ALERT);
    },
    deleteAlert: (id: string) => {
      dispatch(deleteAlertAction(id));
      navigate(routes.ALERTS);
    },
    startCreate: () => {
      dispatch(startCreateAlertThunk());
      navigate(routes.CREATE_ALERT);
    },
  };
};

export function Alerts() {
  const alerts = useSelector(getDisplayableAlerts);
  const isLoading = useSelector(isAlertLoading);
  const isLoadingFailed = useSelector(isAlertLoadingFailed);
  const readonly = !useSelector(canModifyAlert);
  const isReadonlyTrader = useSelector(selectUserType) === 'Trader' && readonly;
  const canSeeAlertTile = (useSelector(selectHasCallRight) && isReadonlyTrader) || useSelector(selectHasCallRight);

  const { startCreate, startUpdate, renewAlert, deleteAlert } = useDispatchedHandlers();

  if (!canSeeAlertTile) {
    return <Navigate replace to={routes.HOME} />;
  }

  const [liveAlerts, historicalAlerts] = React.useMemo(() => {
    const filteredAlerts = alerts.reduce(
      (acc, alert) => {
        if (alert.status === 'Watched') {
          acc[0].push(alert);
        } else {
          acc[1].push(alert);
        }
        return acc;
      },
      [[], []] as AlertsByStatus,
    );
    filteredAlerts[0] = filteredAlerts[0].sort((a, b) => compareDesc(parseISO(a.creationDate), parseISO(b.creationDate)));
    filteredAlerts[1] = filteredAlerts[1].sort((a, b) => compareDesc(parseISO(a.updateTime), parseISO(b.updateTime)));
    return filteredAlerts;
  }, [alerts]);

  const onClickCreate = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      startCreate();
    },
    [startCreate],
  );

  return (
    <MainLayout>
      <div className="alerts">
        {isLoadingFailed ?
          <div className="text-center text-warning">
            <i className="icon icon-lg">error_outline</i>
            <span className="ps-1 pt-1">
              <FormattedMessage id="trades.notAvailableError" />
            </span>
          </div>
        : isLoading ?
          <Blinker />
        : alerts.length === 0 ?
          <Empty startCreate={startCreate} />
        : <div>
            <button type="button" onClick={onClickCreate} className="btn btn-primary float-end" data-e2e="create-alert-btn">
              <FormattedMessage id="alerts.create" />
            </button>
            {liveAlerts.length !== 0 && (
              <div className="mt-3 mb-0" data-e2e="alerts-watched">
                <h2>
                  <FormattedMessage id="alerts.blotter.status.title.watched" /> ({liveAlerts.length})
                </h2>
                {liveAlerts.map((alert) => (
                  <AlertCard alert={alert} key={alert.id} startUpdateAlert={startUpdate} renewAlert={renewAlert} onDelete={deleteAlert} readonly={readonly} />
                ))}
              </div>
            )}
            {historicalAlerts.length ?
              <div className="mt-3 mb-0" data-e2e="alerts-historical">
                <h2>
                  <FormattedMessage id="alerts.blotter.status.histo.title" />
                </h2>
                <div className="mt-3">
                  <FormattedMessage id="alerts.blotter.status.histo.description" />
                </div>
                {historicalAlerts.map((alert) => (
                  <AlertCard alert={alert} key={alert.id} startUpdateAlert={startUpdate} renewAlert={renewAlert} onDelete={deleteAlert} readonly={readonly} />
                ))}
              </div>
            : null}
          </div>
        }
      </div>
    </MainLayout>
  );
}
