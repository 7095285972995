import { Epic, ofType } from 'redux-observable';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { createAlertFormUpdate } from '@/store/state/create-alert/create-alert.actions';
import { isAlertFormPage, isAlertFormPathname } from '@/App/routes';
import { createAlertDefaultState } from '@/store/state/create-alert/create-alert.reducer';
import { LocationChangeAction } from '../state/rfs/rfs.actions';

export const cleanFormEpic = (): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, LocationChangeAction>("LOCATION_CHANGE"),
    withLatestFrom(state$),
    filter(
      ([action, state]) =>
        isAlertFormPage(action.pathname) === false &&
        (state.ui.router.previous ? isAlertFormPathname(state.ui.router.previous) : false),
    ),
    switchMap(() => of(createAlertFormUpdate(createAlertDefaultState.data))),
  );
