import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { routes } from '@/App/routes';
import { ExecuteButton } from '@/App/Trader/components';

export function OperationActionButtons() {
  return <div className="pt-3">
    <ExecuteButton customClass="btn-xl me-2" />
    <Link
      to={{ pathname: routes.PORTFOLIO, search: window.location.search }}
      className="btn btn-xl btn-flat-secondary"
    >
      <FormattedMessage id="alerts.cancel" />
    </Link>
  </div>
}
