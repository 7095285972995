import { useCallback, useEffect, useRef } from 'react';

import type { SgPickerHTMLElement, SgPickerItem } from './sg-picker';
import { isDefined } from '@sgme/fp';

interface SgPickerProps<T extends string | number> {
  items: SgPickerItem<T>[];
  disabled?: boolean;
  placeholder?: string;
  mode?: 'filter' | 'search';
  size?: 'lg' | 'md' | 'sm' | 'xl';
  icon?: string;
  noIcon?: boolean;
  singleSelect?: boolean;
  onChange(items: T[]): void;
  summary?: boolean;
  noClear?: boolean;
  canSelectAll?: boolean;
  selectAllText?: string;
  deselectAllText?: string;
  keepOrder?: boolean;
  labelE2e?: string;
}

export function SgPicker<T extends string | number>({
  items,
  singleSelect = false,
  placeholder,
  mode,
  size,
  icon,
  noIcon,
  onChange,
  disabled = false,
  summary = false,
  noClear = false,
  canSelectAll = false,
  selectAllText,
  deselectAllText,
  keepOrder = false,
  labelE2e,
}: SgPickerProps<T>): JSX.Element {
  const ref = useRef<SgPickerHTMLElement>(null);

  const onReady = useCallback(() => {
    const mappedItems = items.map(({ key, label, selected }) => ({
      key,
      label,
      selected: selected ?? false,
    }));
    if (ref && ref.current && isDefined(ref.current?.setItems) && mappedItems.length > 0) {
      ref.current?.setItems(mappedItems);
    }
  }, [items])

  const onItemsChanged = useCallback(async () => {
    if (onChange) {
      const selection = await ref.current?.getSelection<T>();
      const payload = selection?.map(selected => selected.key) ?? [];
      onChange(payload);
    }
  }, [onChange])

  const onItemsClear = useCallback(() => {
    if (onChange) {
      onChange([]);
    }
  }, [onChange]);

  useEffect(() => {
    const sgPicker = ref.current;

    sgPicker?.addEventListener('ready', onReady);
    sgPicker?.addEventListener('selectItem', onItemsChanged);
    sgPicker?.addEventListener('unselectItem', onItemsChanged);
    sgPicker?.addEventListener('clear', onItemsClear);

    return () => {
      sgPicker?.removeEventListener('ready', onReady);
      sgPicker?.removeEventListener('selectItem', onItemsChanged);
      sgPicker?.removeEventListener('unselectItem', onItemsChanged);
      sgPicker?.removeEventListener('clear', onItemsClear);
    };
  }, [onChange, onReady, onItemsClear, onItemsChanged]);

  useEffect(() => {
    if (isDefined(ref.current?.setItems)) {
      ref.current?.setItems(items);
    }
  }, [items]);

  return (
    <sg-picker
      ref={ref}
      data-e2e={`sg-picker-${labelE2e}`}
      placeholder={placeholder}
      mode={mode}
      size={size}
      icon={icon}
      no-icon={noIcon}
      single-select={singleSelect}
      disabled={disabled}
      summary={summary}
      no-clear={noClear}
      can-select-all={canSelectAll}
      select-all-text={selectAllText}
      deselect-all-text={deselectAllText}
      keep-order={keepOrder}
    />
  );
}
