import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { v4 } from 'uuid';
import { getFakeUser, sgwtConnect, useFake } from '@/sgwtConnect';
import { getApiUrl } from '@/config/app.config';
import { map } from "rxjs/operators";

export const correlationIdHeaderName = 'X-Correlation-ID';

const buildCommonHeaders = (headers?: object) => {
  const correlationId = Object.keys(headers || {}).some(
    header => header === correlationIdHeaderName,
  )
    ? undefined
    : { [correlationIdHeaderName]: v4() };

  return {
    ...headers,
    Application: 'MY-FX',
    ...correlationId,
    Authorization: sgwtConnect.getAuthorizationHeader(),
    ...(useFake ? { 'X-Fake-Authentication': getFakeUser() } : undefined),
  };
};

const buildGetHeaders = (headers?: object) => ({
  ...buildCommonHeaders(headers),
  // don't put 'Content-Type' in get
});

const buildPostHeaders = (headers?: object) => ({
  ...buildCommonHeaders(headers),
  'Content-Type': 'application/json',
});

export interface Http {
  get: <T>(uri: string, headers?: object) => Observable<T>;
  getWithDefault: <T>(uri: string, defaultValue: T, headers?: object) => Observable<T>;
  post: (uri: string, body?: object, headers?: object) => Observable<AjaxResponse>;
}

const apiUrl = getApiUrl();

export const http: Http = {
  get<T>(uri: string, headers?: object): Observable<T> {
    let url: string;

    if (uri.includes('http')) {
      url = uri;
    } else {
      url = apiUrl + uri;
    }

    return ajax.getJSON<T>(url, buildGetHeaders(headers));
  },

  getWithDefault<T>(uri: string, defaultValue: T, headers?: object): Observable<T> {
    let url: string;

    if (uri.includes('http')) {
      url = uri;
    } else {
      url = apiUrl + uri;
    }

    return ajax({
      method: 'GET',
      url,
      headers: buildGetHeaders(headers)
    }).pipe(
      map(response => {
        if (response.status === 204) {
          return defaultValue
        }

        return response.response as T
      })
    )
  },

  post(uri: string, body?: any, headers?: object): Observable<AjaxResponse> {
    return ajax.post(apiUrl + uri, body, buildPostHeaders(headers));
  },
};
