import { Epic, ofType } from 'redux-observable';
import { switchMap, withLatestFrom, catchError, map, startWith } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { CreateAlertFormUpdated, createAlertValidationOk, createAlertValidationError, createAlertValidationStarted } from '@/store/state/create-alert/create-alert.actions';
import { CreateAlertFormData, CreateAlertErrors } from '@/store/state/create-alert/create-alert.model';
import { http } from '@/utils/http';
import { formatDateLocalized } from '@/utils/locale';

type ValidationErrorDto = {
  message: string;
  validationResponseDetailContent: {
    field: 'expiryDay' | 'currency';
    errorDescription: string;
    errorCode: number;
  }[];
};

function convertValidationErrorDto(v: ValidationErrorDto): CreateAlertErrors {
  return {
    baseCurrency: v.validationResponseDetailContent.filter((c) => c.field === 'currency'),
    expiryDate: v.validationResponseDetailContent.filter((c) => c.field === 'expiryDay'),
    contraCurrency: v.validationResponseDetailContent.filter((c) => c.field === 'currency'),
    limitPrice: [],
  };
}
const validateOms = (bdrId: string | undefined, email: string | undefined, c: CreateAlertFormData): Observable<AppAction> =>
  http
    .post('oms/validate/Call?forceDirectPair=true', {
      isGtc: c.expiryDate === null,
      bdrId,
      ccyPair: `${c.baseCurrency}/${c.contraCurrency}`,
      base64Emails: email ? btoa(email) : '',
      ...(c.expiryDate
        ? {
            expiryDay: formatDateLocalized(c.expiryDate, 'yyyy-MM-dd'),
            expiryTime: '17:00:00',
          }
        : {}),
      limitPrice: Number(c.limitPrice),
    })
    .pipe(
      map(() => createAlertValidationOk()),
      catchError((e: AjaxError) => {
        const { response } = e;
        return of(createAlertValidationError(convertValidationErrorDto(response ?? { message: 'Server Error', validationResponseDetailContent: [] })));
      }),
      startWith(createAlertValidationStarted()),
    );

export const validateCreateAlertFormEpic =
  (request = validateOms): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, CreateAlertFormUpdated>('CreateAlertFormUpdated'),
      withLatestFrom(state$),
      switchMap(([_, s]) => request(s.user.selectedCompany?.companyBdrId, s.user.currentUser?.email, s.createAlert.data)),
    );
