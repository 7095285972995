import { useEffect, useReducer } from 'react';
import { CalendarHeader } from './CalendarHeader';
import { CalendarTable } from './CalendarTable';
import { calendarLocalReducer, getFieldsByInstrument } from '@/App/calendar/state';
import { useFormattingContext } from '@/utils/format';
import { getComputeDisplayFields } from './getComputeDisplayField';
import DispatchContext from './CalendarContext';
import { AccumulatorTrade } from '@/models/trade';
import { CalendarSubHeader } from './CalendarSubHeader';

interface CalendarPageProps {
  trade: AccumulatorTrade;
}

export function CalendarPage({ trade }: CalendarPageProps) {
  const [{ rows, editingRowsId }, dispatch] = useReducer(calendarLocalReducer, {
    rows: getFieldsByInstrument(trade),
    editingRowsId: [],
  });

  // TODO 4911  get the infos directly from the format file getKObadges
  const { mapTrade } = useFormattingContext();

  // we need the badges for targets to calculate the Ko
  const { badges } = mapTrade(trade);

  const computedFields = getComputeDisplayFields(rows, trade, badges);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DispatchContext.Provider value={{ dispatch }}>
      <div>
        <CalendarHeader trade={trade} rows={rows} computedFields={computedFields} />

        <CalendarSubHeader trade={trade} editingRowsId={editingRowsId} badges={badges} />

        {rows?.length > 0 && <CalendarTable trade={trade} computedFields={computedFields} editingRowsId={editingRowsId} />}
      </div>
    </DispatchContext.Provider>
  );
}
