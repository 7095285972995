import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { TradeDatePicker, TraderModals } from '@/App/Trader/components';
import { oppositeWay } from '@/models/way';
import { OperationActionButtons } from '../Shared/OperationActionButtons';
import { OperationAmountAndCurrency } from '../Shared/OperationAmountAndCurrency';
import { OperationAlert } from '../Shared/OperationAlert';
import { routes } from '@/App/routes';
import { TradeLoader } from '@/App/Trader/TradeLoader';
import { RolloverRate } from './RolloverRate';
import { OperationInfo } from './RolloverInfo';
import { selectRolloverTrade } from '@/store/state/rollover/rollover.selectors';
import { RolloverHeader } from './RolloverHeader';
import { MainLayout } from '@/App/Layout/MainLayout';
import { isDefined } from '@sgme/fp';

export function Rollover() {
  const type = 'rollover';
  const trade = useSelector(selectRolloverTrade);
  const way = trade?.side;
  const maturityDate = isDefined(trade?.maturitydate) ? new Date(trade.maturitydate) : undefined;

  return way ?
      <MainLayout hasCustomizedHeader>
        <RolloverHeader />

        <TradeLoader>
          <div>
            <OperationAmountAndCurrency type={type} way={way} />

            <OperationAmountAndCurrency type={type} way={oppositeWay(way)} readonly />

            <div className="col-md-4 pt-5 pe-0 ps-0">
              <TradeDatePicker className="date-picker-predeliver" minDate={maturityDate} />
            </div>

            <RolloverRate />
            <OperationInfo />
            <OperationAlert />
            <OperationActionButtons />
            <TraderModals />
          </div>
        </TradeLoader>
      </MainLayout>
    : <Navigate replace to={routes.HOME} />;
}
