import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailedList } from './DetailedList/DetailedList';
import { Timeline } from './Timeline';

import './UpcomingFlows.scss';

import { useExtendedFlowsQuery } from '@/store/state/upcomingFlows/upcomingFlows.api';
import { Filters } from './DetailedList/Filters';
import { EmptyUpcomingFlows } from './EmptyUpcomingFlows';
import { useExportFlows } from '../Trades/TradesList/helpers';
import { TechnicalError } from '../Shared/TechnicalError';
import { FetchingData } from '../Shared/FetchingData';
import { MainLayout } from '../Layout/MainLayout';
import TimeLineHeader from './TimeLineHeader';
import { selectReceivedTrades } from '@/store/state/trades/trades.selectors';
import { useSelector } from 'react-redux';

export function UpcomingFlows() {
  const { data, isLoading, isError, refetch } = useExtendedFlowsQuery();
  const exportFlows = useExportFlows();
  
  const tradesData = useSelector(selectReceivedTrades);

  const onExport = useCallback(
    () =>
      exportFlows(
        data
          .sort(
            (a, b) =>
              new Date(a.flow.paymentDate ?? '').getTime() -
              new Date(b.flow.paymentDate ?? '').getTime(),
          )
          .map(d => [
            { ...d.trade, ...d.flow.legs[0] },
            { ...d.trade, ...d.flow.legs[1] },
          ])
          .flat(),
      ),
    [data, exportFlows],
  );

  const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);

  if (isError) {
    return <TechnicalError refresh={() => refetch()} />;
  }

  if (isLoading) {
    return <FetchingData />;
  }

  if (data === undefined || data.length === 0) {
    return <EmptyUpcomingFlows tradesData={tradesData} />;
  }

  return (
    <MainLayout>
      <div>
        <TimeLineHeader />

        <Timeline value={selectedDates} data={data} onDateSelected={setSelectedDates} />

        <div className="pt-5 pb-3 d-flex justify-content-between align-content-center">
          <div className="d-flex align-content-center">
            <h2>
              <FormattedMessage id="app.portfolio.upcomingFlows.detailed.TITLE" />
            </h2>

            <Filters
              selectedDates={selectedDates}
              onFilterClicked={date => setSelectedDates(selectedDates.filter(d => d !== date))}
              onClearClicked={() => setSelectedDates([])}
            />
          </div>

          <button type="button" className="btn btn-link btn-lg p-0" onClick={onExport}>
            <i className="icon icon-xs me-2 ps-2">save_alt</i>
            <FormattedMessage id="trades.tradesViewer.export" />
          </button>
        </div>

        <DetailedList selectedDates={selectedDates} flows={data} />
      </div>
    </MainLayout>
  );
}
