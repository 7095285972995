import { Reducer } from '@reduxjs/toolkit';
import { TradeCapture } from '@/models/tradeCapture';
import { AppAction } from '@/store/app.actions';

export const tradeCaptureReducer: Reducer<TradeCapture, AppAction> = (
  // eslint-disable-next-line default-param-last
  state: TradeCapture = { status: null },
  action: AppAction,
): TradeCapture => {
  switch (action.type) {
    case 'ADD_TC_ERROR':
      return { status: 'unreachable' };
    case 'CLEAR_TC_ERROR':
      return { status: null };
    default:
      return state;
  }
};
