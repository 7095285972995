import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getRouteName, getPreviousRoute, routes, RouteNames } from '@/App/routes';
import { selectRolloverReference } from '@/store/state/rollover/rollover.selectors';
import { selectPredeliverReference } from '@/store/state/predeliver/predeliver.selectors';

function getTitleProps(routeName: RouteNames, predeliverRef?: string, rolloverRef?: string) {
  switch (routeName) {
    case 'USE_ENVELOPPE':
      return {
        enveloppeId: predeliverRef,
      };
    case 'REPORT_ENVELOPPE':
      return {
        enveloppeId: rolloverRef,
      };
    default:
      return {};
  }
}

export function Title() {
  const router = { location: useLocation() };
  const routeName = getRouteName(router);
  const predeliverRef = useSelector(selectPredeliverReference);
  const rolloverRef = useSelector(selectRolloverReference);
  const titleProps = getTitleProps(routeName, predeliverRef, rolloverRef);

  const previousRoute = getPreviousRoute(routeName);

  return routeName === 'HOME' ?
      <div className="home-title mt-4">
        <h1 className="display-2">MyFX</h1>
      </div>
    : <div className="title">
        <Link
          to={{
            pathname: routes[previousRoute],
            search: window.location.search,
          }}
          className="text-large line-height-1 back my-4"
        >
          <i className="icon icon-xs">arrow_back</i> <FormattedMessage id={`app.previous.${previousRoute}`} />
        </Link>
        <h1 className="display-3" data-e2e={routeName}>
          <FormattedMessage id={`app.routeTitles.${routeName}`} values={titleProps} />
        </h1>
      </div>;
}
