/* eslint-disable no-param-reassign */

import { isNotDefined } from '@sgme/fp';
import {
  DecoratedFields,
  FxoStandardForwardAccumulatorBaseCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
  possibleIsCheckedFieldsName,
} from '@/models/calendar';
import { ForwardAccumulatorTrade, XOneCalendarEntry } from '@/models/trade';

export const fxoStandardForwardAccumulatorFieldsConfig: ReadonlyArray<keyof XOneCalendarEntry> = [
  'fixingDate',
  'payDate',
  'amount2',
  'amount1',
  'step1',
  'fixing',
  'strike',
];

const isAmount = (
  key: keyof FxoStandardForwardAccumulatorBaseCalendarFields,
): key is 'amount1' | 'amount2' => ['amount1', 'amount2'].includes(key);

//
//
//
//  ██████╗ ███████╗████████╗    ██╗   ██╗ █████╗ ██╗     ██╗   ██╗███████╗
// ██╔════╝ ██╔════╝╚══██╔══╝    ██║   ██║██╔══██╗██║     ██║   ██║██╔════╝
// ██║  ███╗█████╗     ██║       ██║   ██║███████║██║     ██║   ██║█████╗
// ██║   ██║██╔══╝     ██║       ╚██╗ ██╔╝██╔══██║██║     ██║   ██║██╔══╝
// ╚██████╔╝███████╗   ██║        ╚████╔╝ ██║  ██║███████╗╚██████╔╝███████╗
//  ╚═════╝ ╚══════╝   ╚═╝         ╚═══╝  ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚══════╝

const getIsCheckedForFxoForwardAccumulator = (
  rowValues: FxoStandardForwardAccumulatorBaseCalendarFields,
  currentField: possibleIsCheckedFieldsName,
): boolean => {
  if (isNotDefined(rowValues.fixing)) {
    return false;
  }
  // TODO ; we shouldn't make non null assertion, we shoudl have specified type for each product
  if (currentField === 'amount1') {
    return rowValues?.fixing >= rowValues.step1!;
  }

  if (currentField === 'amount2') {
    return rowValues?.fixing < rowValues.step1!;
  }

  return false;
};

export const getIsKoForFxStandardForwardAccumulator = (
  { fixing }: FxoStandardForwardAccumulatorBaseCalendarFields,
  { barriers, strike }: ForwardAccumulatorTrade,
): boolean => {
  // 1 <=> KnockIn && 2 <==> KnockOut
  const isKnockOut = barriers[0]?.knockType === 2;
  const barrierLevel = barriers?.[0]?.barrierLevel[0];

  if (isNotDefined(fixing)) {
    return false;
  }

  if (isKnockOut) {
    if (
      (strike <= barrierLevel && fixing >= barrierLevel) ||
      (strike > barrierLevel && fixing <= barrierLevel)
    ) {
      return true;
    }
  }

  return false;
};

const isKoAlreadyTriggered = (
  allRows: DecoratedFields<FxoStandardForwardAccumulatorBaseCalendarFields>[],
  index: number,
): boolean => {
  const NumberOfPreviousKo = allRows.reduce((acc, { fixing }, innerKey) => {
    if (innerKey <= index) {
      if (fixing.isKo) {
        acc += 1 as number;
      }
    }
    return acc;
  }, 0);
  return NumberOfPreviousKo !== 0;
};

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const computeCumulatedAmount = (
  allRows: DecoratedFields<FxoStandardForwardAccumulatorBaseCalendarFields>[],
  index: number,
): number =>
  allRows.reduce((acc, { amount1, amount2 }, innerKey) => {
    if (innerKey <= index) {
      if (amount1?.isChecked) {
        acc += amount1.value as number;
      }

      if (amount2?.isChecked) {
        acc += amount2.value as number;
      }
    }
    return acc;
  }, 0);

//
//
//
// ██████╗ ███████╗ ██████╗ ██████╗ ██████╗  █████╗ ████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║  ██║█████╗  ██║     ██║   ██║██████╔╝███████║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║
// ██║  ██║██╔══╝  ██║     ██║   ██║██╔══██╗██╔══██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██████╔╝███████╗╚██████╗╚██████╔╝██║  ██║██║  ██║   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝
// ╚═════╝ ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const decorateAmountAndFixing = (
  rowsFields: FxoStandardForwardAccumulatorBaseCalendarFields,
  trade: ForwardAccumulatorTrade,
) => {
  const { barriers } = trade;
  // 1 <=> KnockIn && 2 <==> KnockOut
  const isFixingEditable = !(barriers[0]?.knockType === 2) && !(barriers[0]?.knockType === 1);

  return Object.entries(rowsFields).reduce((result, [key, value]) => {
    const currentKey = key as keyof FxoStandardForwardAccumulatorBaseCalendarFields;
    if (isAmount(currentKey)) {
      result[currentKey] = {
        value,
        isChecked: getIsCheckedForFxoForwardAccumulator(
          rowsFields,
          currentKey as 'amount1' | 'amount2',
        ),
      };
    } else if (currentKey === 'fixing') {
      result[currentKey] = {
        value,
        isFixingEditable,
        isKo: getIsKoForFxStandardForwardAccumulator(rowsFields, trade),
        overriddenPrecision: 2,
      };
    } else {
      result[currentKey] = { value };
    }
    return result;
  }, {} as DecoratedFields<FxoStandardForwardAccumulatorBaseCalendarFields>);
};

//
//
//
//  █████╗ ██████╗ ██████╗     ███████╗██╗███████╗██╗     ██████╗
// ██╔══██╗██╔══██╗██╔══██╗    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ███████║██║  ██║██║  ██║    █████╗  ██║█████╗  ██║     ██║  ██║
// ██╔══██║██║  ██║██║  ██║    ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██║  ██║██████╔╝██████╔╝    ██║     ██║███████╗███████╗██████╔╝
// ╚═╝  ╚═╝╚═════╝ ╚═════╝     ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const addCumulatedAmountAndAdaptIsCheked = (
  rowFields: DecoratedFields<FxoStandardForwardAccumulatorBaseCalendarFields>,
  rowsWithIsChecked: DecoratedFields<FxoStandardForwardAccumulatorBaseCalendarFields>[],
  rowIndex: number,
) =>
  Object.entries(rowFields).reduce((acc, [key, value]) => {
    const currentKey = key as keyof FxoStandardForwardAccumulatorBaseCalendarFields;
    const isKoTriggered = isKoAlreadyTriggered(rowsWithIsChecked, rowIndex);

    acc[currentKey] = value;

    if (isAmount(currentKey)) {
      if (isKoTriggered) {
        acc[currentKey].isChecked = false;
      }
    }

    if (currentKey === 'amount1') {
      const checkedField = Object.values(rowFields).find(({ isChecked }) => isChecked);

      acc.cumulatedAmount = {
        value: checkedField ? computeCumulatedAmount(rowsWithIsChecked, rowIndex) : undefined,
        overriddenPrecision: 2,
        isCumulatedAmount: true,
      };
    }
    return acc;
  }, {} as DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

export const computeRowFieldsForFxoStandardForwardAccumulator = (
  baseRowsFields: FxoStandardForwardAccumulatorBaseCalendarFields[],
  trade: ForwardAccumulatorTrade,
): Array<DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>> => {
  const rowsWithIsChecked = baseRowsFields.map(rowsFields =>
    decorateAmountAndFixing(rowsFields, trade),
  );

  const finalRows = rowsWithIsChecked.map((rowFields, rowIndex) =>
    addCumulatedAmountAndAdaptIsCheked(rowFields, rowsWithIsChecked, rowIndex),
  );

  return finalRows;
};
