import React from 'react';

interface Props {
  withBorder?: boolean;
  dataE2E?: string;
  children: React.ReactNode;
}

export function LeftSide({
  children,
  withBorder = true,
  dataE2E,
}: Props) {
  return <div
    data-e2e={dataE2E}
    className={`col-6 p-0 py-2 text-secondary fw-semibold  ${
      withBorder ? 'border-bottom' : ''
    }`}
  >
    {children}
  </div>
};

export function RightSide({
  children,
  withBorder = true,
  dataE2E,
}: Props) {
  return <div
    data-e2e={dataE2E}
    className={`col-6 p-0 py-2 text-end text-dark fw-medium ${
      withBorder ? 'border-bottom' : ''
    }`}
  >
    {children}
  </div>
};
