import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { useEffect, useRef } from 'react';
import { DATE_LONG_FORMAT } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { ToggleButton } from './ToggleButton';

interface CollapsableMaturityDateHeaderProps {
  maturityDate: string;
  isOpen: boolean;
  toggle: () => void;
  setHeaderMaturityDateValue: (value: string) => void;
}

export function CollapsableMaturityDateHeader({
  maturityDate,
  isOpen,
  toggle,
  setHeaderMaturityDateValue,
}: CollapsableMaturityDateHeaderProps) {
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      const element = headerRef.current;

      if (!element) return;

      const { top } = element.getBoundingClientRect();
      const heightPosition = window.scrollY;

      if (heightPosition < 400) {
        setHeaderMaturityDateValue('Echéance');
      }

      if (top < 20) {
        setHeaderMaturityDateValue(formatDateLocalized(parseISO(maturityDate), DATE_LONG_FORMAT));
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div className="d-flex align-items-center mb-2" ref={headerRef}>
      <div>
        <ToggleButton isOpen={isOpen} onClick={toggle} />
      </div>
      <span className="maturity-header text-secondary ms-3" data-e2e="family-header">
        <FormattedMessage
          id="trades.products.maturityDate"
          values={{
            maturityDate: formatDateLocalized(parseISO(maturityDate), DATE_LONG_FORMAT),
          }}
        />
      </span>
    </div>
  );
}
