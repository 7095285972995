import * as React from 'react';
import { Collapse } from 'reactstrap';
import { TradeRow, TradeRowContainer, TradeRowProps } from './TradeRow';
import { getTradeFamily } from '@/models/trade';

type StrategyRowProps = Omit<TradeRowProps, 'family' | 'spotDate'> & {
  spotDates: { [currencyPair: string]: string }
  isLastRow?: boolean
}

export function StrategyRow({ trade, spotDates, onPredeliver, onRollover,onOpenCalendar,isLastRow }: StrategyRowProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => setIsOpen(!isOpen);

  return <div >
    <TradeRowContainer className='strategy'>
      <TradeRow
        family="OptionStrategies"
        trade={trade}
        onPredeliver={onPredeliver}
        onRollover={onRollover}
        onOpenCalendar={onOpenCalendar}
        toggle={toggle}
        isOpen={isOpen}
        isLastRow={isLastRow}
      />
    </TradeRowContainer>
    <Collapse isOpen={isOpen}>
      <TradeRowContainer className="strategy bg-lvl2 border border-top-0 pt-3">
        {trade.sub.map((subTrade) => {
          const family = getTradeFamily(subTrade)

          return <TradeRow
            key={subTrade.reference}
            family={family}
            trade={subTrade}
            spotDate={spotDates[subTrade.currencyPair ?? '']}
            onPredeliver={onPredeliver}
            onRollover={onRollover}
            onOpenCalendar={onOpenCalendar}
            subRow
          />
        })}
      </TradeRowContainer>
    </Collapse>
  </div>
}
