import { ExtendedFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import { FormattedMessage } from 'react-intl';

interface DateLineItemInfosProps {
  flowsAtThisDate: ExtendedFlow[];
  selected: boolean;
  uncertain: number;
}

function DateLineItemInfos({ flowsAtThisDate, selected, uncertain }: DateLineItemInfosProps) {
  return (
    <span
      className={`${selected ? 'selected' : ''}`}
      style={{ paddingLeft: '0.5rem', paddingBottom: '0.5rem' }}
    >
      <p style={{ margin: 0, height: '1.2rem' }}>
        {flowsAtThisDate.length - uncertain !== 0 ? (
          <FormattedMessage
            id="app.portfolio.upcomingFlows.timeline.UPCOMING_FLOWS"
            values={{
              certain: flowsAtThisDate.length - uncertain,
              isSingular: flowsAtThisDate.length - uncertain === 1,
            }}
          />
        ) : (
          uncertain !== 0 && <UncertainFlowMessage uncertain={uncertain} flowsAtThisDate={flowsAtThisDate} />
        )}
      </p>
      <p style={{ margin: 0, height: '1.2rem' }}>
        {uncertain !== 0 && flowsAtThisDate.length - uncertain !== 0 && (
          <UncertainFlowMessage uncertain={uncertain} flowsAtThisDate={flowsAtThisDate} />
        )}
      </p>
    </span>
  );
}

export default DateLineItemInfos;

function UncertainFlowMessage({
  uncertain,
  flowsAtThisDate,
}: {
  uncertain: number;
  flowsAtThisDate: ExtendedFlow[];
}) {
  return (
    <FormattedMessage
      id="app.portfolio.upcomingFlows.timeline.UNCERTAIN_FLOWS"
      values={{
        uncertain,
        isCertainFlowPresent: flowsAtThisDate.length !== 0 && flowsAtThisDate.length - uncertain !== 0,
        isSingular: uncertain === 1,
      }}
    />
  );
}
