import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { getSpotDates } from '@/services/trades.service';
import {
  TradesReceivedAction,
  spotDatesReceivedAction,
  spotDatesRequestFailedAction,
} from '@/store/state/trades/trades.actions';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';

export const tradesSpotDatesEpic =
  (fetchSpotDates = getSpotDates): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, TradesReceivedAction>('TRADES_RECEIVED'),
      map(action => action.trades.map(trade => trade.currencyPair)),
      map(ccyPairs =>
        ccyPairs.filter(ccyPair => !Object.keys(state$.value.trades.spotDates).includes(ccyPair)),
      ),
      map(ccyPairs => ccyPairs.filter((ccyPair, index) => ccyPairs.indexOf(ccyPair) === index)),
      filter(ccyPairs => ccyPairs.length > 0),
      mergeMap(ccyPairs =>
        fetchSpotDates(ccyPairs).pipe(
          mergeMap(spotDates => of(spotDatesReceivedAction(spotDates))),
          catchError(() => of(spotDatesRequestFailedAction())),
        ),
      ),
    );
