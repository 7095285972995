import { Action } from '@reduxjs/toolkit';
import { ForwardTrade } from '@/models/trade';

export type PredeliverAction = StartPredeliverAction;

type StartPredeliverAction = Action<'START_PREDELIVER'> & {
  trade: ForwardTrade;
};
export const startPredeliverAction = (trade: ForwardTrade): StartPredeliverAction => ({
  type: 'START_PREDELIVER',
  trade,
});
