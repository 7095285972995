import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { routes } from '../routes';
import { FeatureToggle } from '@/share/FeatureToggle/FeatureToggle';
import { useSelector } from 'react-redux';
import { selectHasSpotRight } from '@/store/state/user/user.selectors';

const outIcon = { out: <i className="icon icon-xs ms-1">launch</i> };

function HeaderTabs() {
  const canSeeTradeTile = useSelector(selectHasSpotRight);

  return (
    <>
      {canSeeTradeTile && (
        <li className="nav-item">
          <NavLink to={routes.TRADE} className={({ isActive }: { isActive: boolean }) => `${isActive ? 'active' : ''} navbar-link`} data-e2e="trade">
            <FormattedMessage id="header.buySell" />
          </NavLink>
        </li>
      )}
      <li className="nav-item">
        <NavLink to={routes.PORTFOLIO} data-e2e="blotter" className={({ isActive }: { isActive: boolean }) => `${isActive ? 'active' : ''} navbar-link`}>
          <FormattedMessage id="header.operations" />
        </NavLink>
      </li>
      <FeatureToggle feature="alerts">
        {(active) =>
          !active ? null : (
            <li className="nav-item">
              <NavLink to={routes.ALERTS} className={({ isActive }: { isActive: boolean }) => `${isActive ? 'active' : ''} navbar-link`} data-e2e="alerts">
                <FormattedMessage id="header.alerts" />
              </NavLink>
            </li>
          )
        }
      </FeatureToggle>
      <li className="nav-item">
        <NavLink to={routes.CONTACT} className={({ isActive }: { isActive: boolean }) => `${isActive ? 'active' : ''} d-flex align-self-center navbar-link`}>
          <FormattedMessage id="app.contactUs" />
        </NavLink>
      </li>
      <li className="nav-item d-flex align-self-center ms-3">
        <a href="https://digital.sgmarkets.com/fx-calendar/" className="nav-link">
          <FormattedMessage id="header.cambistCalendar" values={outIcon} />
        </a>
      </li>
    </>
  );
}

export default HeaderTabs;
