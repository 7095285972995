import * as React from 'react';

export type FunctionChildren<P> = (props: P) => React.ReactNode;

export interface RenderPropChildren<P> {
  children: FunctionChildren<P>;
}

export const noop = () => undefined;

export type ObjectPick<T, K> = Pick<T, Extract<keyof T, K>>;

export function pick<K extends string>(...properties: K[]) {
  return <T extends Readonly<object>>(obj: T): ObjectPick<T, K> =>
    properties.reduce((handle, property) => {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        const key = property as unknown as keyof T;
        // eslint-disable-next-line no-param-reassign
        handle[key] = obj[key];
      }
      return handle;
    }, {} as T);
}

function findFormElementIndex(elements: HTMLFormControlsCollection, element: Element): number {
  for (let index = 0; index < elements.length; index += 1) {
    if (elements.item(index) === element) {
      return index;
    }
  }
  return -1;
}

export function getNextFocus(currentElement: HTMLInputElement): HTMLElement | undefined {
  if (!currentElement.form) {
    return undefined;
  }
  const { elements } = currentElement.form;
  const currentIndex = findFormElementIndex(elements, currentElement);
  for (let index = currentIndex + 1; index < elements.length; index += 1) {
    const nextElement = elements.item(index);

    if (
      nextElement !== null &&
      !(nextElement.hasAttribute('readonly') || nextElement.hasAttribute('disabled')) &&
      (nextElement.tagName === 'INPUT' || nextElement.tagName === 'SELECT')
    ) {
      return nextElement as HTMLElement;
    }
  }
  return undefined;
}

const collator = new Intl.Collator();
export const compareKey =
  (key: string) =>
  <T extends Record<string, string>>(a: T, b: T) =>
    collator.compare(a[key], b[key]);

export type ArrayValueType<A> = A extends Array<infer U> ? U : never;

/**
 * Returns a strongly typed array containing the arguments.
 * Use-case: defining a union type without having to repeat the values.
 * @example `const values = stronglyTypedArray('Toto', 'Tutu')` returns a `('Toto' | 'Tutu')[]` containing
 * `'Toto'` and `'Tutu'` which can be used to define the union type: `type UnionType = ArrayValueType<typeof values>`
 * @param vals string values of union type
 */
export const stronglyTypedArray = <K extends string>(...vals: K[]): K[] => vals;

/**
 * Higher Order function, return a litteral union type asserter
 * @param enumType Array of litteral values
 * @returns A function taking a string and returns false if the string does not match any values
 *
 */
export const isUnionLitteral =
  <T extends string>(enumType: T[]) =>
  (str: string): str is T =>
    (enumType as string[]).indexOf(str) !== -1;

const { hasOwnProperty } = Object.prototype;

const is = (x: number, y: number) => (x === y ? x !== 0 || 1 / x === 1 / y : false);
const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj !== null && typeof obj === 'object';

export function shallowEqual(objA: unknown, objB: unknown) {
  // early equality detection
  if (is(objA as number, objB as number)) {
    return true;
  }
  // defensive assertion that args are iterable objects
  if (!isObject(objA) || !isObject(objB)) {
    return false;
  }
  // key by key equality assertion
  const A = Object.keys(objA);
  const B = Object.keys(objB);
  // early difference inferable from length difference
  if (A.length !== B.length) {
    return false;
  }
  // compare values for every keys
  return A.every(p => hasOwnProperty.call(objB, p) && is(objA[p] as number, objB[p] as number));
}

/**
 * This unique key should not be exported.
 * It enforces that only code in the scope of this
 * module can access previous props from state
 */
const props = Symbol('previous props');

export interface PreviousProps<T> {
  [props]?: T;
}

export function getPreviousProps<T>(state: PreviousProps<T>): T | undefined {
  return state[props];
}

export function addNextPropsToState<P, S>(state: S | null, nextProps: P): Partial<S> {
  const nextState = { [props]: nextProps };
  return state === null
    ? (nextState as unknown as Partial<S>)
    : ({ ...state, ...nextState } as unknown as Partial<S>);
}

export function deriveStateOnChangedProps<P, S>(
  getDerivedStateFromProps: React.GetDerivedStateFromProps<P, S>,
): React.GetDerivedStateFromProps<P, S> {
  return (nextProps, previousState) =>
    previousState !== null &&
    shallowEqual(nextProps, getPreviousProps(previousState as PreviousProps<P>)) // TODO JLE: Change typing to remove cast
      ? null
      : addNextPropsToState(getDerivedStateFromProps(nextProps, previousState), nextProps);
}
