import { Epic, ofType } from 'redux-observable';
import { switchMap, withLatestFrom, filter, startWith, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import {
  CreateAlertFormUpdated,
  createAlertSetFixingExample,
} from '@/store/state/create-alert/create-alert.actions';
import { http } from '@/utils/http';
import { Currency } from '@/models/currency';
import { isAlertFormPage } from '@/App/routes';
import { LocationChangeAction } from '../state/rfs/rfs.actions';

const callFixing = (currency1: Currency, currency2: Currency) => http
  .get<number>(`myfx/fixing/${currency1}/${currency2}`)
  .pipe(catchError(() => of(undefined)));

export const createAlertFixings = (request = callFixing): Epic<AppAction, AppAction, AppState> => (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<AppAction, CreateAlertFormUpdated | LocationChangeAction>(
      'CreateAlertFormUpdated', 'LOCATION_CHANGE'),
    filter(
      action =>
        (action.type === "LOCATION_CHANGE" && isAlertFormPage(action.pathname)) ||
        (action.type === 'CreateAlertFormUpdated' &&
          (Boolean(action.patch.baseCurrency) || Boolean(action.patch.contraCurrency))),
    ),
    startWith(undefined),
    withLatestFrom(state$),
    switchMap(([_, s]) =>
      request(s.createAlert.data.baseCurrency, s.createAlert.data.contraCurrency),
    ),
    map(result => createAlertSetFixingExample(result)),
  );
