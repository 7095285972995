import { Growl } from './growls.model';

export const autoHideGrowlDelay = 5000;

export type GrowlsActions = AddGrowlAction | RemoveGrowlAction;

export type AddGrowlAction = {
  type: 'AddGrowl';
  growl: Growl;
  id: string;
};

export const addGrowlAction = (growl: Growl, id: string): AddGrowlAction => ({
  type: 'AddGrowl',
  growl,
  id,
});

export type RemoveGrowlAction = {
  type: 'RemoveGrowl';
  id: string;
};

export const removeGrowlAction = (id: string): RemoveGrowlAction => ({
  type: 'RemoveGrowl',
  id,
});
