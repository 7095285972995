import { Action, Dispatch, isRejected, Middleware } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import { AppAction } from '@/store/app.actions';
import { AppState } from '@/store/app.state';

export type OnCatchActionCreator<TState> = (errorMessage: string, action: any, state: TState, error?: Error) => Action;

export function tryCatchMiddleware(onCatchActionCreator?: OnCatchActionCreator<AppState>): Middleware<{}, AppState, Dispatch<AppAction>> {
  return (store) => (next) => (action) => {
    if (onCatchActionCreator === undefined) {
      return next(action);
    }

    // thunk was rejected
    // but only throwed exception in the thunk and not a RTK query error ?
    if (isRejected(action) && !action.type.endsWith('executeQuery/rejected')) {
      // if an exception is just throwed, action.payload is a string with the error message
      // if the exception is catched and a "rejectWithValue" is returned, action.error.message is a string with the error message
      const errorMessage = (isDefined(action.payload) ? action.payload : action.error.message) as string;

      return next(onCatchActionCreator(errorMessage, action, store.getState()));
    }

    // check if the reducer or any other middleware throw an exception
    try {
      return next(action);
    } catch (error: any) {
      const errorMessage = error.message;
      return next(onCatchActionCreator(errorMessage, action, store.getState(), error));
    }
  };
}
