import { SGWTConnectCore } from '@sgwt/connect-core';
import { Http } from '@/utils/http';

export type HelpRequestConfig =
  | 'general'
  | 'access'
  | 'appcrashed'
  | 'dealpending'
  | 'timeouteddeal'
  | 'orderpending'; // support.address.<config> must exist in XOne.Host App.config

export interface IHelpRequestAdditionalParams {
  application: 'SGME-MY-FX';
  config?: HelpRequestConfig;
  details?: string;
  sendConfirmation?: boolean;
  lastActions?: string;
  state?: string;
  data?: object;
}

export const sendHelpRequest =
  (http: Http, sgwtConnectParam: SGWTConnectCore) =>
  (helpRequestParam: IHelpRequestAdditionalParams) => {
    const { userAgent } = navigator;

    // user info is extracted from sgwtConnect
    const login = sgwtConnectParam.getIdTokenClaims()!.sub as string;
    const payload = {
      ...helpRequestParam,
      login,
      userAgent,
    };

    return http.post('help/request', payload, {
      'Content-Type': 'application/json',
    });
  };
