import { Middleware, Dispatch, isAction } from '@reduxjs/toolkit';
import { AppState } from '@/store/app.state';
import { AppAction } from '@/store/app.actions';
import { changeLocale } from '@/utils/locale';
import { LocaleChangedAction } from '@/store/state/ui/ui.actions';

export const localeMiddleware: Middleware<{}, AppState, Dispatch<AppAction>> = (_api) => (next) => (action) => {
  if (isAction(action) && action.type === 'LOCALE_CHANGED') {
    changeLocale((action as LocaleChangedAction).locale);
  }

  return next(action);
};
