import { createSelector, EntityState } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import { Currency } from '@/models/currency';
import { assertUnexpectedValue } from '@/utils/asserts';
import { AppState } from '@/store/app.state';
import { oppositeWay, Way } from '@/models/way';
import { getCurrencyByWay } from '../rfs/rfs.selectors';
import { MyHedgeTrade, TradeActionType } from '@/models/trade';
import { tradeAdapter } from './trades.model';

interface BuySellCurrencies {
  buyCurrency: Currency;
  sellCurrency: Currency;
}

export const getBySellCurrencies = (tradeOrFlow: { currencyPair: string; side: Way; amountCurrency: Currency }): BuySellCurrencies => {
  const [cc1, cc2] = tradeOrFlow.currencyPair.split('/') as [Currency, Currency];
  switch (tradeOrFlow.side) {
    case 'buy':
      return tradeOrFlow.amountCurrency === cc1 ? { buyCurrency: cc1, sellCurrency: cc2 } : { buyCurrency: cc2, sellCurrency: cc1 };
    case 'sell':
      return tradeOrFlow.amountCurrency === cc1 ? { sellCurrency: cc1, buyCurrency: cc2 } : { sellCurrency: cc2, buyCurrency: cc1 };
    default:
      assertUnexpectedValue('trade side', tradeOrFlow.side as never);
  }
};

export const { selectAll: selectAllTrades, selectById: selectTradeById } = tradeAdapter.getSelectors();

export const selectTrades = ({ trades }: AppState) => trades.trades;

export const selectReceivedTrades = createSelector(
  (state: AppState) => state.trades,
  ({ trades }) => (trades.status === 'received' ? selectAllTrades(trades.data) : []),
);

export const selectSelectedId = ({ trades }: AppState) => (trades.trades.status === 'received' ? trades.trades.selectedId : null);

export const selectReceivedTradesEntity = ({ trades }: AppState) => (trades.trades.status === 'received' ? trades.trades.data : []);

export const selectSelectedTrade = () =>
  createSelector(selectReceivedTradesEntity, selectSelectedId, (tradeResources, selectedId) => {
    if (isDefined(selectedId)) {
      return selectTradeById(tradeResources as EntityState<MyHedgeTrade, string>, selectedId);
    }
    return null;
  });

export const selectSpotDates = ({ trades }: AppState) => trades.spotDates;

export const selectOppositeCurrencyForTradeType =
  (tradeType: TradeActionType) =>
  ({ rfs, [tradeType]: { trade } }: AppState) =>
    getCurrencyByWay(rfs, oppositeWay(trade!.side));

export const selectSellCurrency =
  (tradeType: TradeActionType) =>
  ({ rfs, [tradeType]: { trade } }: AppState) =>
    getCurrencyByWay(rfs, 'sell');

export const selectTradeForType =
  (tradeType: TradeActionType) =>
  ({ [tradeType]: { trade } }: AppState) =>
    trade;
