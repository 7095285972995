import { useCallback } from 'react';
import { FILENAME_FORMAT, useFormattingContext } from '@/utils/format';
import { AccumulatorTrade, ForwardAccumulatorTrade, MyHedgeTrade } from '@/models/trade';
import { formatDateLocalized } from '@/utils/locale';
import { MyFxFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import { CalendarFields, DecoratedFields } from '@/models/calendar';
import { IntlShape } from 'react-intl';
import { parseISO } from 'date-fns';
import { isDefined } from '@sgme/fp';

const SEPARATOR = ';';
const TRADES_EXPORT_HEADERS = [
  'Echéance',
  'Réference',
  'Devises',
  'Produit',
  'Initial',
  'Disponible',
  'Strike',
  'Sens',
  'Executé',
].join(SEPARATOR);

function downloadCSV(content: string, isCalendar = false, reference?: string) {
  const usedReference = isCalendar ? reference : '';

  const uri = `data:text/csv;charset=utf-8,${escape(content)}`;
  const link = document.createElement('a');
  link.href = uri;
  link.className = 'd-none';
  link.download = `SG_MyFX_${
    isDefined(usedReference) ? `${usedReference}_` : ''
  }${formatDateLocalized(new Date(), FILENAME_FORMAT)}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const isForwardAccumualtor = (trade: AccumulatorTrade): trade is ForwardAccumulatorTrade =>
  trade.instrumentName === 'FxoForwardAccumulator';

export function exportCalendar<I extends CalendarFields>(
  computedFields: DecoratedFields<I>[],
  trade: AccumulatorTrade,
  intl: IntlShape,
) {
  const { reference, instrumentName } = trade;
  let accumulatorType: 'standard' | 'permanent' | 'soft';

  if (isForwardAccumualtor(trade)) {
    accumulatorType = trade.accumulatorType;
  }
  const getHeaderDataToExport = Object.keys(computedFields[0]).reduce((acc, value) => {
    acc.push(value);

    if (value === 'amount1') {
      acc.push('retainedAmount');
    }

    return acc;
  }, [] as string[]);

  const CalendarHeaderData = getHeaderDataToExport.map(id => {
    const values = { instrumentName, accumulatorType };
    return intl.formatMessage({ id: `app.calendar.table.${id}` }, values);
  });

  const CalendarData = Object.entries(computedFields)
    .map(([_index, row]) => {
      return Object.entries(row)
        .reduce((result: (string | number)[], [key, { value }]) => {
          const CurrentValue = value as string | number;

          if (key === 'fixingDate' || key === 'payDate') {
            result.push(formatDateLocalized(parseISO(value as string), 'dd/MM/yyyy'));
            return result;
          }

          result.push(CurrentValue);

          if (key === 'amount1') {
            const retainedAmount = row.amount1.isChecked
              ? row.amount1.value
              : row.amount2.isChecked
              ? row.amount2.value
              : '';
            result.push(retainedAmount as string | number);
          }

          return result;
        }, [] as (string | number)[])
        .join(SEPARATOR);
    })
    .join('\r\n');

  const output = [`sep=${SEPARATOR}`, CalendarHeaderData.join(SEPARATOR), CalendarData].join(
    '\r\n',
  );

  downloadCSV(output, true, reference);
}

export const useExportTrades = () => {
  const { mapTradeCsv } = useFormattingContext();

  return useCallback(
    (trades: MyHedgeTrade[]) => {
      const output = [
        `sep=${SEPARATOR}`,
        TRADES_EXPORT_HEADERS,
        ...trades
          .map(mapTradeCsv)
          .map(t =>
            [
              t.maturitydate,
              t.reference,
              t.currencyPair,
              t.instrumentName,
              t.nominal,
              t.remainingAmount,
              t.strike.replace(',', '.'),
              t.way,
              t.date,
            ].join(SEPARATOR),
          ),
      ].join('\r\n');

      downloadCSV(output);
    },
    [mapTradeCsv],
  );
};

const FLOWS_EXPORT_HEADERS = [
  'app.portfolio.upcomingFlows.detailed.table.headers.PAYMENT_DATE',
  'app.portfolio.upcomingFlows.detailed.table.headers.REFERENCE',
  'app.portfolio.upcomingFlows.detailed.table.headers.PRODUCT',
  'app.portfolio.upcomingFlows.detailed.table.UNCERTAIN',
  'app.portfolio.upcomingFlows.detailed.table.headers.WAY',
  'app.portfolio.upcomingFlows.detailed.table.headers.AMOUNT',
  'app.portfolio.upcomingFlows.detailed.table.headers.CURRENCY',
  'app.portfolio.upcomingFlows.detailed.table.headers.CHANGE_RATE',
];

export const useExportFlows = () => {
  const { formatMessage, mapFlowCsv } = useFormattingContext();

  return useCallback(
    (flows: (MyFxFlow & MyHedgeTrade)[]) => {
      const output = [
        `sep=${SEPARATOR}`,
        FLOWS_EXPORT_HEADERS.map(id => formatMessage(id)).join(SEPARATOR),
        ...flows.map(mapFlowCsv).map(flow => {
          const usedAmount = flow.way === 'sell' ? `-${flow.amount}` : flow.amount;

          return [
            flow.paymentDate,
            flow.contractId,
            flow.productName,
            !flow.isCertain,
            formatMessage('app.portfolio.upcomingFlows.detailed.table.WAY', { way: flow.way }),
            usedAmount,
            flow.currency,
            flow.strike,
          ].join(SEPARATOR);
        }),
      ].join('\r\n');

      downloadCSV(output);
    },
    [mapFlowCsv, formatMessage],
  );
};
