import { useState, useCallback, useEffect, useRef } from 'react';

type Timeout = ReturnType<typeof setTimeout>;
type ToggleTuple = [boolean, () => void, (delay?: number) => void];

/**
 * Returns a stateful boolean, a function to toggle it, and a function to set it to false after an optional delay
 */
export const useToggle = (initial = false): ToggleTuple => {
  const [state, setState] = useState(initial);
  const handle = useRef<Timeout>();

  const closeNow = () => setState(false);
  const clean = () => clearTimeout(handle.current as Timeout);
  const toggle = useCallback(() => setState(s => !s), []);
  const close = useCallback((delay?: number) => {
    clean();
    // eslint-disable-next-line no-unused-expressions
    delay === undefined ? closeNow() : (handle.current = setTimeout(closeNow, delay));
  }, []);

  useEffect(() => clean, []);

  return [state, toggle, close];
};
