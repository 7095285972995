import { Navigate, Route, RouterProvider, Routes, useLocation } from 'react-router';
import './App.scss';
import { createBrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { ContactUs } from './ContactUs/ContactUs';
import { HelpCenter } from './HelpCenter/HelpCenter';
import { Home } from './Home/Home';
import { routes } from './routes';
import { Trader } from './Trader/Trader';
import { Trades } from './Trades/Trades';
import { AppCrash } from './AppCrash';
import { Alerts } from './Alerts/Alerts';
import { CreateAlertForm } from './Alerts/AlertForms/CreateAlertForm';
import { UpdateAlertForm } from './Alerts/AlertForms/UpdateAlertForm';
import { FeatureToggle } from '@/share/FeatureToggle/FeatureToggle';
import { Predeliver } from './Operation/Predeliver/Predeliver';
import { Rollover } from './Operation/Rollover/Rollover';
import { UpcomingFlows } from './UpcomingFlows';
import { CalendarPage } from './calendar';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { SessionDisconnectedModal } from './utils/SessionDisconnectModal';
import { locationChangeAction } from '@/store/state/rfs/rfs.actions';
import { useAppDispatch } from '@/store/store';


function ProtectWithAlertsRight(Component: React.ComponentType) {
  return <FeatureToggle feature="alerts">{(active) => (active ? <Component /> : <Navigate to={routes.HOME} replace />)}</FeatureToggle>;
}

const router = createBrowserRouter([{ path: '*', Component: Root }]);

export function App() {
  return <RouterProvider router={router} />;
}

export function Root() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(locationChangeAction(location.pathname));
  }, [location]);

  return (
    <AppCrash>
      <HelpCenter />
      <div className="App">
        <Header />
        <Routes>
          <Route path={routes.TRADE} element={<Trader />} />
          <Route path={routes.CONTACT} element={<ContactUs />} />
          <Route path={routes.PORTFOLIO} element={<Trades />} />
          <Route path={routes.UPCOMING_FLOWS} element={<UpcomingFlows />} />
          <Route path={routes.CALENDAR} element={<CalendarPage />} />
          <Route path={routes.USE_ENVELOPPE} element={<Predeliver />} />
          <Route path={routes.REPORT_ENVELOPPE} element={<Rollover />} />
          <Route path={routes.ALERTS} element={ProtectWithAlertsRight(Alerts)} />
          <Route path={routes.CREATE_ALERT} element={ProtectWithAlertsRight(CreateAlertForm)} />
          <Route path={routes.UPDATE_ALERT} element={ProtectWithAlertsRight(UpdateAlertForm)} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <SessionDisconnectedModal />

        <Footer />
      </div>
    </AppCrash>
  );
}
