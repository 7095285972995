import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Currency, allCurrencies } from '@/models/currency';
import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { currencyFlags } from './currencies-data';
import { CurrencyView } from './CurrencyView';
import './CurrencySelector.scss';

interface CurrencySelectorState {
  dropdownOpen: boolean;
}

type CurrencySelectorProps = CurrencySelectorPropsEnabled | CurrencySelectorPropsDisabled;
interface CurrencySelectorPropsBase {
  currencies: readonly Currency[];
  value: Currency;
  onBlockExecution: (blockExecution: boolean) => void;
  children?: React.ReactNode;
}

interface CurrencySelectorPropsEnabled extends CurrencySelectorPropsBase {
  onCurrencyChange: (value: Currency) => void;
  disabled?: false;
}

interface CurrencySelectorPropsDisabled extends CurrencySelectorPropsBase {
  disabled: true;
}

class UnconnectedCurrencySelector extends React.PureComponent<CurrencySelectorProps, CurrencySelectorState> {
  constructor(public props: CurrencySelectorProps) {
    super(props);
    this.state = { dropdownOpen: false };
  }

  toggle = () => {
    const { dropdownOpen } = this.state;
    const { onBlockExecution } = this.props;
    const newOpenState = !dropdownOpen;
    this.setState({ dropdownOpen: newOpenState });
    onBlockExecution(newOpenState);
  };

  select = (event: React.MouseEvent<HTMLElement>) => {
    const { value, disabled } = this.props;
    const val = event.currentTarget.dataset.value!.trim() as Currency;
    if (val !== value && disabled !== true) {
      const { onCurrencyChange } = this.props;
      onCurrencyChange(val);
    }
  };

  render() {
    const { dropdownOpen } = this.state;
    const { disabled, value, currencies, children } = this.props;
    return (
      <Dropdown className="CurrencySelector input-group input-group-xl" isOpen={dropdownOpen} toggle={this.toggle} disabled={disabled}>
        <DropdownToggle caret tag="button" className="form-control form-control-xl bg-transparent border text-start" disabled={disabled}>
          <div className="d-flex justify-content-between align-items-center align-content-center h-100">
            {value ? currencyFlags[value]({ width: '24' }) : null}
            <span>{value && <FormattedMessage id={`currencies.${value}`} defaultMessage={value} />}</span>
            <i className="icon icon-md">keyboard_arrow_down</i>
          </div>
        </DropdownToggle>
        <DropdownMenu className="border w-100">
          {allCurrencies
            .filter((c) => currencies.includes(c))
            .map((c) => (
              <DropdownItem key={c} onClick={this.select} className="form-control form-control-xl" data-value={c}>
                <CurrencyView currency={c} />
              </DropdownItem>
            ))}
        </DropdownMenu>
        {children}
      </Dropdown>
    );
  }
}

const mapDispatchToProps = {
  onBlockExecution: blockExecutionThunk,
};

export const CurrencySelector = connect(null, mapDispatchToProps)(UnconnectedCurrencySelector);
