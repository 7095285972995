import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { isRolloverRoute } from '@/App/routes';
import { errorLimit, selectErrors } from '@/store/state/errors/errors.selectors';
import { selectPredeliver } from '@/store/state/predeliver/predeliver.selectors';
import { getAmountByWay, getCurrencyByWay, isMasterWay, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { amountChangeThunk, amountChangingThunk, currencyChangeThunk, isAllAvailableUsedChangeThunk, wayChangeThunk } from '@/store/state/rfs/rfs.thunks';
import { selectRollover } from '@/store/state/rollover/rollover.selectors';
import { selectUser } from '@/store/state/user/user.selectors';
import { InvalidKind } from '@/models/trade';
import { Currency } from '@/models/currency';
import { Way } from '@/models/way';
import { getInvalidKind, getInvalidLimit, getWayLabel } from './AmountAndCurrency.connectors';
import { useAppDispatch } from '@/store/store';

interface AmountAndCurrencyDispatchProps {
  onAmountChanging: (isDirty: boolean) => void;
  onAmountChange: (value: string) => void;
  onAmountFocus: () => void;
  onCurrencyChange: (value: Currency) => void;
  onIsAllAvailableUsedChange: (isAllAvailableUsed: boolean) => void;
}

interface AmountAndCurrencyStateProps {
  label: string;
  way: string;
  value: string;
  currencies: Currency[];
  selectedCurrency: Currency;
  amountDisabled: boolean;
  currencyDisabled: boolean;
  invalid?: InvalidKind;
  invalidLimit?: number;
  amountIsMaster: boolean;
}

export const useAmountAndCurrencyMappedState = (way: Way): AmountAndCurrencyStateProps => {
  const rfs = useSelector(selectRfs);
  const user = useSelector(selectUser);
  const errors = useSelector(selectErrors);
  const { pathname } = useLocation();
  const predeliver = useSelector(selectPredeliver);
  const rollover = useSelector(selectRollover);

  const amountIsMaster = isMasterWay(rfs, way);
  const amountByWay = getAmountByWay(rfs, way);
  const isRollover = isRolloverRoute(pathname);
  let invalid;
  let label: string = way;

  if (amountIsMaster && amountByWay > 0) {
    invalid = getInvalidKind(errors, rfs, predeliver, rollover);
    label = getWayLabel(way, isRollover);
  }

  const limit = errorLimit(errors);
  const selectedCurrency = getCurrencyByWay(rfs, way);
  const invalidLimit = getInvalidLimit(limit, selectedCurrency);

  return {
    label,
    way,
    value: amountByWay.toString(),
    currencies: user.selectedCompany!.rfsCurrencies,
    selectedCurrency,
    amountDisabled: rfs.status === 'executing',
    currencyDisabled: rfs.status === 'executing' || rfs.postTradeOperation !== undefined,
    invalid,
    invalidLimit,
    amountIsMaster,
  };
};

export const useAmountAndCurrencyDispatchedHandlers = (way: Way): AmountAndCurrencyDispatchProps => {
  const dispatch = useAppDispatch();

  return {
    onAmountChanging: (isDirty) => isDirty && dispatch(amountChangingThunk()),
    onAmountChange: (amount) => dispatch(amountChangeThunk({ way, amount: Number(amount) })),
    onCurrencyChange: (currency) => dispatch(currencyChangeThunk({ way, currency })),
    onAmountFocus: () => dispatch(wayChangeThunk(way)),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    onIsAllAvailableUsedChange: (useAllAvalable) => dispatch(isAllAvailableUsedChangeThunk(useAllAvalable)),
  };
};
