import { format, formatDistanceToNowStrict } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

import enLocales from '@/locales/en.json';
import frLocales from '@/locales/fr.json';
import {
  MyHedgeTrade,
  TradeFamily,
  isEuropeanCall,
  isEuropeanPut,
  isAmericanCall,
  isAmericanPut,
  isAsianCall,
  isAsianPut,
  isEuropeanCallWithEuropeanTriggerDownIn,
  isEuropeanCallWithEuropeanTriggerUpIn,
  isEuropeanCallWithEuropeanTriggerDownOut,
  isEuropeanCallWithEuropeanTriggerUpOut,
  isEuropeanCallWithAmericanTriggerDownIn,
  isEuropeanCallWithAmericanTriggerUpIn,
  isEuropeanCallWithAmericanTriggerDownOut,
  isEuropeanCallWithAmericanTriggerUpOut,
  isEuropeanPutWithEuropeanTriggerDownIn,
  isEuropeanPutWithEuropeanTriggerUpIn,
  isEuropeanPutWithEuropeanTriggerDownOut,
  isEuropeanPutWithEuropeanTriggerUpOut,
  isEuropeanPutWithAmericanTriggerDownIn,
  isEuropeanPutWithAmericanTriggerUpIn,
  isEuropeanPutWithAmericanTriggerDownOut,
  isEuropeanPutWithAmericanTriggerUpOut,
} from '@/models/trade';
import { isNotDefined } from '@sgme/fp';

const locales = { en: enUS, fr };
let currentLocale: Locale = 'fr';
export const availableLocales = ['fr', 'en'] as const;
export type Locale = (typeof availableLocales)[number];

export const messages = {
  en: enLocales,
  fr: frLocales,
};

const localeKey = 'sgme-myfx.locale';
export const initLocale = () => {
  const locale = (localStorage.getItem(localeKey) as Locale) || 'fr';
  currentLocale = locale;
  return locale;
};
export const changeLocale = (locale: Locale) => {
  if (isNotDefined(locale)) {
    return;
  }

  currentLocale = locale;
  localStorage.setItem(localeKey, locale);
};

export const formatDateLocalized = (date: Date, f: string) =>
  format(date, f, { locale: locales[currentLocale] });

export const formatDistanceToNow = (date: Date) =>
  formatDistanceToNowStrict(date, { locale: locales[currentLocale], addSuffix: true });

export const formatTime = (date: Date) => format(date, 'p', { locale: locales[currentLocale] });

export const getProductLocalizationId = (trade: MyHedgeTrade): string => {
  switch (trade.instrumentName) {
    // SPOTS
    case 'FxSpot':
      return 'trades.products.name.FX_SPOT';

    // FORWARDS
    case 'FxOutright':
      return 'trades.products.name.FX_OUTRIGHT';
    case 'FxoAmericanForward':
      return 'trades.products.name.FXO_AMERICAN_FORWARD';
    case 'FxNdf':
      return 'trades.products.name.FX_NDF';
    case 'FxoDoubleAverageForward':
      return 'trades.products.name.FXO_DOUBLE_AVERAGE_FORWARD';

    // SWAPS
    case 'FxSwap':
      return 'trades.products.name.FX_SWAP';
    case 'FxNdSwap':
      return 'trades.products.name.FX_ND_SWAP';

    // SIMPLE OPTIONS
    case 'FxVanillaOption':
      if (isEuropeanCall(trade.optionType)) return 'trades.products.name.FX_VANILLA_OPTION_CE';

      if (isEuropeanPut(trade.optionType)) return 'trades.products.name.FX_VANILLA_OPTION_PE';

      if (isAmericanCall(trade.optionType)) return 'trades.products.name.FX_VANILLA_OPTION_CA';

      if (isAmericanPut(trade.optionType)) return 'trades.products.name.FX_VANILLA_OPTION_PA';

      break;
    case 'FxoAverageOption':
      if (isAsianCall(trade.optionType)) return 'trades.products.name.FXO_AVERAGE_OPTION_CA';

      if (isAsianPut(trade.optionType)) return 'trades.products.name.FXO_AVERAGE_OPTION_PA';

      break;
    case 'FxoDoubleAverageOption':
      return 'trades.products.name.FXO_DOUBLE_AVERAGE_OPTION';

    // TRIGGER OPTIONS
    case 'FxoTriggerOption':
      if (isEuropeanCallWithEuropeanTriggerDownIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEDIE';
      if (isEuropeanCallWithEuropeanTriggerUpIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEUIE';
      if (isEuropeanCallWithEuropeanTriggerDownOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEDOE';
      if (isEuropeanCallWithEuropeanTriggerUpOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEUOE';
      if (isEuropeanCallWithAmericanTriggerDownIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEDI';
      if (isEuropeanCallWithAmericanTriggerUpIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEUI';
      if (isEuropeanCallWithAmericanTriggerDownOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEDO';
      if (isEuropeanCallWithAmericanTriggerUpOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_CEUO';
      if (isEuropeanPutWithEuropeanTriggerDownIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEDIE';
      if (isEuropeanPutWithEuropeanTriggerUpIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEUIE';
      if (isEuropeanPutWithEuropeanTriggerDownOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEDOE';
      if (isEuropeanPutWithEuropeanTriggerUpOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEUOE';
      if (isEuropeanPutWithAmericanTriggerDownIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEDI';
      if (isEuropeanPutWithAmericanTriggerUpIn(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEUI';
      if (isEuropeanPutWithAmericanTriggerDownOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEDO';
      if (isEuropeanPutWithAmericanTriggerUpOut(trade.optionType))
        return 'trades.products.name.FXO_TRIGGER_OPTION_PEUO';

      break;

    // STRATEGIES
    case 'FxOptionStrategy':
      return 'trades.products.name.FX_OPTION_STRATEGY';
    case 'FxOptionStrangle':
      return 'trades.products.name.FX_OPTION_STRANGLE';
    case 'FxOptionStraddle':
      return 'trades.products.name.FX_OPTION_STRADDLE';
    case 'FxOptionRiskReversal':
      return 'trades.products.name.FX_OPTION_RISK_REVERSAL';
    case 'FxOptionOutrightPackage':
      return 'trades.products.name.FX_OPTION_OUTRIGHT_PACKAGE';
    case 'FxOptionKnockingPackage':
      return 'trades.products.name.FX_OPTION_KNOCKING_PACKAGE';

    // OTHER

    // FORWARD ACCUMULATORS
    case 'FxoForwardAccumulator':
    case 'FxoVanillaAccumulator':
      return 'trades.products.name.FXO_FORWARD_ACCUMULATOR';

    // TARGET ACCUMULATORS
    case 'FxoTargetAccumulator':
      return 'trades.products.name.FXO_TARGET_ACCUMULATOR';
    case 'FxoSquareTargetAccumulator':
      return 'trades.products.name.FXO_SQUARE_TARGET_ACCUMULATOR';
    case 'FxoPivotEKITargetAccumulator':
      return 'trades.products.name.FXO_PIVOT_EKI_TARGET_ACCUMULATOR';
    case 'FxoPivotTargetAccumulator':
      return 'trades.products.name.FXO_PIVOT_TARGET_ACCUMULATOR';
    case 'FxoPauseTargetAccumulator':
      return 'trades.products.name.FXO_PAUSE_TARGET_ACCUMULATOR';
    default:
  }

  return trade.instrumentName;
};

export const getFamilyLocalizationId = (family: TradeFamily): string => {
  switch (family) {
    case 'Spots':
      return 'trades.products.family.SPOTS';
    case 'Forwards':
      return 'trades.products.family.FORWARDS';
    case 'Swaps':
      return 'trades.products.family.SWAPS';
    case 'SimpleOptions':
      return 'trades.products.family.SIMPLE_OPTIONS';
    case 'TriggerOptions':
      return 'trades.products.family.TRIGGER_OPTIONS';
    case 'OptionStrategies':
      return 'trades.products.family.STRATEGIES';
    case 'ForwardAccumulators':
      return 'trades.products.family.FORWARD_ACCUMULATORS';
    case 'TargetAccumulators':
      return 'trades.products.family.TARGET_ACCUMULATORS';
    case 'Other':
      return 'trades.products.family.OTHER';
    default: {
      const exhaustiveCheck: never = family;
      return exhaustiveCheck;
    }
  }
};
