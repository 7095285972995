import * as React from 'react';
import { useSelector } from 'react-redux';
import { Feature } from '@/config/app.config';
import { selectFeatureToggleActive } from '@/store/state/ui/ui.selectors';
import { useAppSelector } from '@/store/store';

export interface FeatureToggleProps {
  feature: Feature;
  children: (active: boolean) => React.ReactElement | null;
}

export function FeatureToggle({ children, feature }: FeatureToggleProps) {
  const isFeatureActive = useAppSelector((state) => selectFeatureToggleActive(state, feature));

  return children(isFeatureActive);
}
