import React from 'react';

interface CatchState {
  hasError: boolean;
}

interface CatchProps {
  onCatch(error: Error, errorInfo: React.ErrorInfo): void;
  children: React.ReactNode
}

export class Catch extends React.PureComponent<CatchProps, CatchState> {
  public state = { hasError: false };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const {onCatch} = this.props
    onCatch(error, errorInfo);
  }

  public render() {
    const {hasError} = this.state
    const {children} = this.props
    return hasError ? null : children;
  }
}
