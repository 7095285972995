import { useMemo } from 'react';
import { isSameDay } from 'date-fns';

import { Headers } from './Headers';

import './DetailedList.scss';
import { ExtendedFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import { FlowItem } from './FlowItem';

export type DetailedListProps = {
  flows: ExtendedFlow[];
  selectedDates: Date[];
};

export const isASelectedDate = (flow: ExtendedFlow, selectedDates: Date[]) =>
  selectedDates.reduce((acc, day) => acc || isSameDay(new Date(flow.flow.paymentDate), day), false);

export function DetailedList({ flows, selectedDates }: DetailedListProps) {
  const sortedFlows = useMemo(
    () =>
      [...flows]
        .filter(a => (selectedDates.length !== 0 ? isASelectedDate(a, selectedDates) : true))
        .sort(
          (a, b) =>
            new Date(a.flow.paymentDate ?? '').getTime() -
            new Date(b.flow.paymentDate ?? '').getTime(),
        ),
    [flows, selectedDates],
  );

  return sortedFlows.length === 0 ? null : (
    <div className="card border-bottom DetailedList">
      <div className="card-body p-0">
        <table className="table mb-0">
          <thead className="table-header">
            <Headers />
          </thead>
          <tbody className="table-body">
          {Object.values(sortedFlows).map((flow, index) => (
            <FlowItem flow={flow} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
