import * as React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Collapse } from 'reactstrap';
import { ErrorLimit, errorLimit } from '@/store/state/errors/errors.selectors';
import { logger } from '@/logging/logger';
import { AppError } from '@/models/AppError';

const br = <br />;

function getErrorType(error: AppError) {
  if (error.code.includes('OVERSHOT')) {
    return 'OVERSHOT';
  }
  if (error.code.includes('UNDERSHOT')) {
    return 'UNDERSHOT';
  }
  if (error.code.includes('CREDIT_CHECK')) {
    switch (error.creditCheckType) {
      case 'CVAR':
        return 'CREDIT_CHECK.CVAR';
      case 'NOLIMIT':
        return 'CREDIT_CHECK.NOLIMIT';
      case 'RDL':
        return 'CREDIT_CHECK.RDL';
      default:
        break;
    }
  }
  if (error.code.includes('CUTOFF')) {
    return 'CUTOFF';
  }
  return 'OTHER';
}

const formattedLimit = (limit: ErrorLimit) => (
  <>
    <FormattedNumber value={limit.amount} /> {limit.currency || 'USD'}
  </>
);

export function getErrorTitle(error: AppError) {
  const errorType = getErrorType(error);
  const userLimit = errorLimit([error]);

  return (
    <FormattedMessage
      id={`trader.errorMessages.title.${errorType}${userLimit ? '.withLimit' : ''}`}
      values={{ br, limit: userLimit ? formattedLimit(userLimit) : null }}
    />
  );
}

export function getErrorDetail(error: AppError) {
  const errorType = getErrorType(error);
  if (errorType === 'CUTOFF') {
    return undefined;
  }

  return <FormattedMessage id={`trader.errorMessages.detail.${errorType}`} values={{ br }} />;
}

function Alert({isInfo, title, detail}: AlertProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <h3 className={isInfo ? 'text-info' : 'text-danger'}>{title}</h3>

      {detail && (
        <p className={`fw-bold subtitle ${isOpen ? 'is-open' : ''}`} onClick={toggle}>
          <i className="icon icon-xs">keyboard_arrow_right</i>
          <FormattedMessage id="trader.errorMessages.subtitle" />
        </p>
      )}
      <Collapse isOpen={isOpen}>
        <p className="" id="errorDetail">
          {detail}
        </p>
      </Collapse>
    </div>
  );
};

export function mapError(error: AppError) {
  logger.logInformation(JSON.stringify(error));
  return <Alert title={getErrorTitle(error)} detail={getErrorDetail(error)} />;
}

interface AlertProps {
  title: React.ReactNode;
  detail?: React.ReactNode;
  isInfo?: boolean;
}
