import { getFamilyLocalizationId } from '@/utils/locale';
import { TradeFamily } from '@/models/trade';
import { TradesFilter } from './TradesFilter';
import { useFormattingContext } from '@/utils/format';

interface TradeFamilyFilterProps {
  value: TradeFamily[];
  families: TradeFamily[];
  onFilterChange?: (newFamilies: TradeFamily[]) => void;
}

export function TradeFamilyFilter({ value, families, onFilterChange }: TradeFamilyFilterProps) {
  const { formatMessage } = useFormattingContext();

  const items = families.map<[string, TradeFamily]>(c => [
    formatMessage(getFamilyLocalizationId(c)),
    c,
  ]);

  return (
    <div className="form-group">
      <TradesFilter
        label={formatMessage('trades.toolbar.familyFilter.label')}
        currentSelection={value}
        options={items}
        onChange={onFilterChange}
        labelE2e="families"
      />
    </div>
  );
}
