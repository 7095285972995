import { Action } from '@reduxjs/toolkit';

export type AppCrashActions = AppCrashedAction;

export interface AppCrashedAction extends Action {
  type: 'APP_CRASHED';
  source: string;
  error: any;
}
export function createAppCrashedAction(source: string, error?: any): AppCrashedAction {
  return {
    type: 'APP_CRASHED',
    source,
    error,
  };
}
