/* eslint-disable no-shadow */
import { useContext, useState } from 'react';
import { NumberInput } from '@/share/NumericInput/NumberInput';
import { DebounceChange } from '@/share/DebounceChange/DebounceChange';
import DispatchContext from './CalendarContext';
import { editCalendarFixingAction, resetCalendarRowAction } from './state';
import { useIntl } from 'react-intl';

interface FixingFieldProps {
  value: number | undefined;
  rowIndex: number;
  isEdited?: boolean;
  isUncertain?: boolean;
}

export function FixingField({ value, rowIndex, isEdited, isUncertain }: FixingFieldProps) {
  const { dispatch } = useContext(DispatchContext);
  const [inputValue, setInputValue] = useState<string>(value?.toString() || '');
  const intl = useIntl();

  const handleOnChange = (value: string) => {
    if (value === '') {
      dispatch(resetCalendarRowAction(rowIndex));
    } else if (!value.endsWith('.')) {
      dispatch(editCalendarFixingAction(rowIndex, Number(value)));
    }
    setInputValue(value.startsWith('.') ? `0${value}` : value);
  };

  return (
    <DebounceChange onChange={handleOnChange}>
      {(onChangeDebounced) => (
        <NumberInput
          e2eHandle={`fixing-index-${rowIndex}`}
          value={isEdited ? inputValue : ''}
          className={`form-control form-control-md ${isEdited ? ' text-info ' : ''} ${isUncertain ? 'border-bottom border-warning' : ''}`}
          onChange={onChangeDebounced}
          placeHolder={intl.formatMessage({ id: 'app.calendar.table.Fixing.simulation' })}
        />
      )}
    </DebounceChange>
  );
}
