import { FormattedMessage } from 'react-intl';

export function Headers() {
  return (
    <tr  style={{ position: 'sticky', top: 0, zIndex: 5, backgroundColor: 'white' }}>
      <th className="border-top-0">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.PAYMENT_DATE" />
      </th>
      <th className="border-top-0">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.REFERENCE" />
      </th>
      <th className="border-top-0">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.PRODUCT" />
      </th>
      <th className="border-top-0">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.WAY" />
      </th>
      <th className="border-top-0 text-end">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.AMOUNT" />
      </th>
      <th className="border-top-0 text-end">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.CURRENCY" />
      </th>

      <th className="border-top-0 text-end">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.CHANGE_RATE" />
      </th>
      <th className="border-top-0">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.headers.ACTIONS" />
      </th>
    </tr>
  );
}
