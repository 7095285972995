import { Feature } from '@/config/app.config';
import { InstrumentName } from '@/models/trade';

export const getToggleNameFromInstrument = (
  instrumentName: InstrumentName,
): Feature | undefined => {
  switch (instrumentName) {
    case 'FxoTargetAccumulator':
      return 'targetAccumulator';
    case 'FxoForwardAccumulator':
      return 'forwardAccumulator';
    case 'FxoSquareTargetAccumulator':
      return 'squareTargetAccumulator';
    case 'FxoPauseTargetAccumulator':
      return 'pauseTargetAccumulator';
    case 'FxoVanillaAccumulator':
      return 'vanillaAccumulator';
    default:
      return;
  }
};
