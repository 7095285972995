import { FormattedMessage } from 'react-intl';
import { currencyFlags } from './currencies-data';
import { Currency } from '@/models/currency';
import './CurrencyView.scss';

interface CurrencyProps {
  currency: Currency;
  e2e?: string;
}

export function CurrencyView({ e2e, currency }: CurrencyProps) {
  return (
    <span className="CurrencyView" data-e2e={e2e}>
      {currencyFlags[currency]({ height: '32' })}

      <span>
        <FormattedMessage id={`currencies.${currency}`} defaultMessage={currency} />
      </span>
    </span>
  );
}
