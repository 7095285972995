import { connect } from 'react-redux';
import { sgwtConnect } from '@/sgwtConnect';
import { AppState } from '@/store/app.state';

const e2eUsers = ['sales.myfx@sgcib.com', 'dow.jane.e2e@sgcib.com', 'spot.jane@sgcib.com', 'readonly.myfx@sgcib.com'];

function DebugHeaderRaw({streaming}:{ streaming: string }) {
  const claims = sgwtConnect.getIdTokenClaims()
  return window.sgmeConfiguration.env.toLowerCase().includes('dev') &&
    claims &&
    e2eUsers.includes(claims.sub) ? (
    <div className="sgwt-account-center">
      <div className="sgwt-account-center-tags">
        <div className="" data-e2e="e2e-debug-header">
          {streaming}
        </div>
      </div>
    </div>
  ) : null;
}

export const DebugHeader = connect<{ streaming: string }, Record<string, unknown>, Record<string, unknown>, AppState>((state) => ({
  streaming: JSON.stringify(state.streaming),
}))(DebugHeaderRaw);
