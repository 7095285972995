/* eslint-disable default-param-last */
import { Epic, ofType } from 'redux-observable';
import { merge, of, SchedulerLike, timer } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { quote$, quoteErrors$ } from '@/services/streams.service';
import { addErrorAction, AddErrorAction } from '@/store/state/errors/errors.actions';
import { logger } from '@/logging/logger';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import {
  cashQuoteReceivedAction,
  ExecuteAction,
  quotingTimeoutAction,
  QuotingTimeoutAction,
  RequestForStreamAction,
  requestForStreamAction,
  StreamRequestedAction,
  swapQuoteReceivedAction,
  StreamStoppedAction,
} from '../state/rfs/rfs.actions';
import { getMyHedgeTrade } from '@/store/app.selectors';
import { SgWay } from '@/models/way';
import { getNewRfsId } from './request-for-stream.epic';

const MAX_PREDELIVER_RETRY = 1;
const PREDELIVER_RETRY_DELAY = 1000;

function myHedgeSideToSgWay(side: 'buy' | 'sell' | undefined): SgWay {
  switch (side) {
    case 'buy':
      return 'Buy';
    case 'sell':
      return 'Sell';
    case undefined:
      return 'Both';
    default: {
      const exhaustiveCheck: never = side;
      return exhaustiveCheck;
    }
  }
}

export const quotesEpic =
  (getQuote$ = quote$, getQuoteErrors$ = quoteErrors$, newRfsId = getNewRfsId, scheduler?: SchedulerLike): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, StreamRequestedAction>('STREAM_REQUESTED'),
      mergeMap((action) =>
        merge(
          getQuote$().pipe(
            withLatestFrom(state$),
            filter(([q, state]) => q.rfsId === action.rfsId && (state.rfs.status === 'quoting' || state.rfs.status === 'requesting') && state.rfs.id === q.rfsId),
            map(([quote, state]) =>
              quote.type === 'CASH.QUOTE.REPLY'
                ? cashQuoteReceivedAction(
                    quote.rfsId,
                    quote.quoteId,
                    quote.spotWithMargin,
                    quote.contraAmount,
                    quote.forwardPointsWithMargin,
                    quote.forwardWithMargin,
                    quote.currencyPair,
                    quote.sgWay,
                    quote.timestamp,
                  )
                : swapQuoteReceivedAction(
                    quote.rfsId,
                    quote.quoteId,
                    quote.spot,
                    quote.contraAmount,
                    quote.nearPoints,
                    quote.nearPrice,
                    quote.farPoints,
                    quote.farPrice,
                    quote.currencyPair,
                    myHedgeSideToSgWay(getMyHedgeTrade(state)?.side),
                    quote.historicalDealOffRate,
                    quote.timestamp,
                  ),
            ),
          ),
          getQuoteErrors$().pipe(
            // tap(quote => logger.logInfo('quoteErrors$: {@quote}', quote)),
            tap((quote) => logger.logInformation('quoteErrors$', quote)),
            mergeMap((quote) => {
              if (quote.errorCode === 'EM_TIMEOUT') {
                return of(quotingTimeoutAction());
              }

              const isPredliverLockError =
                action.product === 'FxPredeliver' && quote.errorCode === 'EM_FINANCIAL_CHECKS_FAILED' && quote.errorMessage === 'Historical deal was not returned by XONE';

              const shouldRetry = isPredliverLockError && action.retryCount < MAX_PREDELIVER_RETRY;

              // shouldRetry && logger.logInfo('Predliver lock error, will retry');
              shouldRetry && logger.logInformation('Predliver lock error, will retry');

              return shouldRetry
                ? timer(PREDELIVER_RETRY_DELAY, undefined, scheduler).pipe(map((_) => requestForStreamAction(newRfsId(), action.retryCount + 1)))
                : of(
                    addErrorAction({
                      code: quote.errorCode,
                      message: quote.errorMessage,
                      multipassErrorCode: quote.multipassErrorCode,
                      creditCheckType: quote.creditCheck?.type,
                      creditCheckLimit: quote.creditCheck?.limit,
                    }),
                  );
            }),
          ),
        ).pipe(
          takeUntil(
            action$.pipe(
              ofType<AppAction, QuotingTimeoutAction | ExecuteAction | StreamStoppedAction | AddErrorAction | RequestForStreamAction>(
                'QUOTING_TIMEOUT',
                'EXECUTE',
                'STREAM_STOPPED',
                'ADD_ERROR',
                'REQUEST_FOR_STREAM',
              ),
            ),
          ),
        ),
      ),
    );
