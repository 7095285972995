import { AppState } from '@/store/app.state';
import { getDefaultCurrencies } from '@/utils/currencies';
import { formChangeAction } from '../rfs/rfs.actions';
import { changeCompanyAction, navigateAsUserAction, startNavigateAsAction } from './user.actions';
import { Company } from '@/models/user';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';

export const navigateAsUserThunk = createAsyncThunk<void, string | undefined, { dispath: AppDispatch; state: AppState }>('navigateAsUserThunk', (email, thunkApi) => {
  const state = thunkApi.getState();

  const current = state.user.currentUser!;

  // toggling off navigateAs
  if (email === undefined || email === current.email) {
    thunkApi.dispatch(navigateAsUserAction(undefined));
    thunkApi.dispatch(changeCompanyAction(current.companies[0]));
  } else {
    thunkApi.dispatch(startNavigateAsAction(email));
  }
});

export const changeCompanyThunk = createAsyncThunk<void, Company, { dispath: AppDispatch; state: AppState }>('changeCompanyThunk', (company, thunkApi) => {
  thunkApi.dispatch(changeCompanyAction(company));

  const {
    rfs: { buyCurrency, sellCurrency },
  } = thunkApi.getState();
  const newCurrencies = getDefaultCurrencies(company.rfsCurrencies, [buyCurrency, sellCurrency]);
  if (newCurrencies.buyCurrency !== buyCurrency || newCurrencies.sellCurrency !== sellCurrency) {
    thunkApi.dispatch(formChangeAction(newCurrencies));
  }
});
