import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GenericErrorModal from '@/App/Trader/components/TraderModals/GenericErrorModal/GenericErrorModal';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';

function ModalBody() {
  return (
    <>
      <div className="mb-3">
        <FormattedMessage id="trader.TimeoutedDealModal.description" />
      </div>
      <div className="mb-3">
        <FormattedMessage id="trader.TimeoutedDealModal.description2" />
      </div>
      <div className="mb-3">
        <FormattedMessage id="trader.TimeoutedDealModal.retry" />
      </div>
    </>
  );
}

export function ClientLatencyErrorModal() {
  const rfs = useSelector(selectRfs);
  const isOpen = rfs.status === 'timeouted-deal';

  return (
    <GenericErrorModal
      isOpen={isOpen}
      modalBody={<ModalBody />}
      modalTitleKey="trader.TimeoutedDealModal.title"
      dataE2E="execution-timeout-modal"
    />
  );
}
