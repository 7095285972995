import { Epic, ofType } from 'redux-observable';
import { EMPTY, merge, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { execution$, dealReply$, executionError$ } from '@/services/streams.service';
import { executeRfs } from '@/services/trade.service';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import {
  backOfficeValidatedAction,
  ExecuteAction,
  executedAction,
  executionFailedAction,
  executionSentAction,
  timeoutedDealAction,
} from '../state/rfs/rfs.actions';
import { getSpotPrecision } from '@/store/state/rfs/rfs.selectors';
import { convertToPointsWithPrecision } from '@/utils/format';
import { logger } from '@/logging/logger';
import { DealRejected, DealReply, FillReport } from '@/models/streaming';

const HTTP_TIMEOUT = 408;
const ANY_HTTP_ERROR = 408;

const handleResponseFromSignalR = ([executionResult, state]: [
  FillReport | DealRejected | DealReply,
  AppState,
]) => {

  logger.logInformation('handleResponseFromSignalR - executionResult {executionResult} state {status}', executionResult, {
    status: state.rfs.status
  });

  if (executionResult.type === 'DEAL.REJECTED') {
    return executionFailedAction();
  } if (executionResult.type === 'FILL.REPORT') {
    return backOfficeValidatedAction(executionResult.tradeIds[0]);
  }
  return executedAction();
};


export const executeEpic =
  (
    fillReportStreaming$ = execution$,
    dealReplyStreaming$ = dealReply$,
    dealRejectedStreaming$ = executionError$,
    requestForExecution = executeRfs,
  ): Epic<AppAction, AppAction, AppState> =>
    (action$, state$) => action$.pipe(
      ofType<AppAction, ExecuteAction>('EXECUTE'))
      .pipe(
        withLatestFrom(state$),
        switchMap(([, { rfs, streaming }]) => {
          const handleMultipassReplies$ = merge(fillReportStreaming$(), dealRejectedStreaming$(), dealReplyStreaming$())
            .pipe(
              withLatestFrom(state$),
              tap(([executionResult, state]) => {
                logger.logInformation('Before filter - executionResult {executionResult} state {status}', executionResult, {
                  status: state.rfs.status
                });
              }
              ),
              filter(
                ([executionResult, state]) => (state.rfs.status === 'execution-timeout' ||
                  state.rfs.status === 'executing' ||
                  state.rfs.status === 'executed') &&
                  state.rfs.id === executionResult.rfsId
              ),
              tap(([executionResult, state]) => logger.logInformation('executionResult {executionResult} state {status}', executionResult, {
                status: state.rfs.status
              })
              ),
              map(handleResponseFromSignalR)
            );

          const executeRequest$ = (rfs.status === 'executing'
            ? requestForExecution(
              rfs.id,
              rfs.quoteId,
              rfs.spotRate,
              rfs.product === 'FxFwd'
                ? rfs.forwardPoints
                : rfs.product === 'FxPredeliver'
                  ? convertToPointsWithPrecision(rfs.rolloverPoints, getSpotPrecision(rfs))
                  : 0,
              rfs.sgWay,
              streaming.connectionId!,
              rfs.clientTimestamp,
              rfs.serverTimestamp
            ).pipe(catchError((e: AjaxResponse) => of(e)))
            : EMPTY
          ).pipe(
            mergeMap(resultFromLastPipeFromAjax => resultFromLastPipeFromAjax.status === HTTP_TIMEOUT
              ? of(timeoutedDealAction())
              : resultFromLastPipeFromAjax.status >= ANY_HTTP_ERROR
                ? of(executionFailedAction())
                : of(executionSentAction())
                  .pipe(
                    takeUntil(
                      state$.pipe(
                        filter(
                          _ => rfs.status === 'backOfficeValidated' ||
                            rfs.status === 'execution-timeout' ||
                            rfs.status === 'execution-failed'
                        )
                      )
                    )
                  )
            )
          );

          return merge(
            handleMultipassReplies$,
            executeRequest$
          )
        }
        )
      )
  ;
