import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { HighlightedRate } from '@/share/HighlightedRate/HighlightedRate';
import { Spinner } from '@/share/Spinner/Spinner';
import { getFormattedRolloverPoints, getFormattedRolloverRate, getFormattedSpotRate, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';

export function RolloverRate() {
  const locale = useSelector(selectCurrentLocale);
  const rfs = useSelector(selectRfs);

  const waitingForPrice = rfs.status === 'requesting';
  const spotRate = getFormattedSpotRate(locale)(rfs);
  const rolloverPoints = getFormattedRolloverPoints(locale)(rfs);
  const rolloverRate = getFormattedRolloverRate(locale)(rfs);

  return (
    <>
      <div className="form-group col-6 p-md-0">
        <label className="text-secondary text-large mb-2">
          <FormattedMessage id="trader.rolloverRate.rolloverRate" />
        </label>
        <div className="display-4 tabular-figures fw-normal" data-e2e="rollover-rate">
          {waitingForPrice ?
            <Spinner />
          : rolloverRate !== undefined && <HighlightedRate value={rolloverRate} size={3} offset={1} />}
        </div>
      </div>
      <div className="col-6 p-0">
        <div className="form-group d-flex justify-content-between">
          <label className="text-secondary">
            <FormattedMessage id="trader.rolloverRate.spotRate" />
          </label>
          <span className="display-5 tabular-figures fw-normal" data-e2e="spot-rate">
            {waitingForPrice ?
              <Spinner />
            : spotRate !== undefined && <HighlightedRate value={spotRate} size={4} />}
          </span>
        </div>
        <div className="form-group d-flex justify-content-between">
          <label className="text-secondary">
            <FormattedMessage id="trader.rolloverRate.rolloverPoints" />
          </label>
          <span className="display-5 tabular-figures fw-normal" data-e2e="rollover-points">
            {waitingForPrice ?
              <Spinner />
            : rolloverPoints}
          </span>
        </div>
      </div>
    </>
  );
}
