import { Action } from '@reduxjs/toolkit';

export type TradeCaptureAction = AddTradeCaptureErrorAction | ClearTradeCaptureErrorAction | SpotDateErrorAction;

type SpotDateErrorAction = Action<'SPOT_DATE_ERROR'> & { error: unknown };
export const spotDateErrorAction = (error: unknown): SpotDateErrorAction => ({
  type: 'SPOT_DATE_ERROR',
  error,
});

type AddTradeCaptureErrorAction = Action<'ADD_TC_ERROR'> & { error: unknown };
export const addTradeCaptureErrorAction = (error: unknown): AddTradeCaptureErrorAction => ({
  type: 'ADD_TC_ERROR',
  error,
});

type ClearTradeCaptureErrorAction = Action<'CLEAR_TC_ERROR'>;
export const clearTradeCaptureErrorAction = (): ClearTradeCaptureErrorAction => ({
  type: 'CLEAR_TC_ERROR',
});
