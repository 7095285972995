import React from 'react';
import { useSelector } from 'react-redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isDefined } from '@sgme/fp';
import { getApiUrl } from '@/config/app.config';
import { getFakeUser, sgwtConnect, useFake } from '@/sgwtConnect';
import { ConsolidatedFlow, ConsolidatedFlows, MyFxFlow, MyFxUpcomingFlows } from './upcomingFlows.models';
import { MyHedgeTrade } from '@/models/trade';
import { selectMnemo } from '@/store/state/user/user.selectors';
import { extendFlow } from '@/App/utils/Flows';

export const useExtendedFlowsQuery = () => {
  const mnemo = useSelector(selectMnemo) ?? '';

  const { data: flowsData, isFetching: isLoadingFlows, isError: isErrorFlows, refetch: refetchFlows } = useUpcomingFlowsQuery({ entityMnemonic: mnemo });

  const { data: tradesData, isFetching: isLoadingTrades, isError: isErrorTrades, refetch: refetchTrades } = useTradesQuery({ entityMnemonic: mnemo });

  // TODO: really necessary to use useMemo ?
  const extendedFlows = React.useMemo(() => extendFlow(flowsData, tradesData), [flowsData, tradesData]);

  const refetch = React.useCallback(() => {
    refetchFlows();
    refetchTrades();
  }, [refetchFlows, refetchTrades]);

  return {
    data: extendedFlows,
    isLoading: isLoadingFlows || isLoadingTrades,
    isError: isErrorFlows || isErrorTrades,
    refetch,
  };
};

export const upcomingFlowsApi = createApi({
  reducerPath: 'upcomingFlowsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', sgwtConnect.getAuthorizationHeader() ?? '');
      if (useFake) {
        headers.set('X-Fake-Authentication', getFakeUser());
      }
    },
  }),

  endpoints: (builder) => ({
    upcomingFlows: builder.query<ConsolidatedFlows, { entityMnemonic: string }>({
      query: ({ entityMnemonic }) => ({
        url: `myfx/upcomingFlows/${entityMnemonic}`,
      }),
      transformResponse: (response: MyFxUpcomingFlows) => reduceFlows({ counterparty: undefined, ...response }),
    }),

    trades: builder.query<MyHedgeTrade[] | undefined, { entityMnemonic: string }>({
      query: ({ entityMnemonic }) => ({
        url: `myfx/trades/${entityMnemonic}?setIsGroupFlag=true`,
      }),
      transformResponse: (response: MyHedgeTrade[]) => (isDefined(response) ? response : undefined),
    }),

    spotDates: builder.query<Record<string, string>, string[]>({
      query: (currencyPairs) => ({
        method: 'POST',
        url: `myfx/dates/spot`,
        body: currencyPairs,
      }),
    }),
  }),
});

export const reduceFlows = ({ counterparty, flows }: MyFxUpcomingFlows): ConsolidatedFlows => {
  const usedFlows = flows?.sg
    ? Object.values(
        flows?.sg?.reduce((acc, flow) => {
          const { contractId } = flow;
          acc[contractId] = (acc[contractId] || []).concat(flow);
          return acc;
        }, {} as Record<string, MyFxFlow[]>),
      ).flatMap((flowsWithTheSameId) =>
        flowsWithTheSameId.reduce((acc, flow, index) => {
          const { contractId, paymentDate, isCertain } = flow;
          if (index % 2 === 0)
            acc.push({
              contractId,
              paymentDate,
              isCertain,
              legs: [flow, flowsWithTheSameId[index + 1]],
            });
          return acc;
        }, [] as ConsolidatedFlow[]),
      )
    : [];

  return {
    counterparty,
    flows: usedFlows,
  };
};

export const { useUpcomingFlowsQuery, useTradesQuery, useSpotDatesQuery } = upcomingFlowsApi;
