import { map, tap } from 'rxjs/operators';
import { http } from '@/utils/http';
import { logger } from '@/logging/logger';
import { OrderDto, mapOrderToAlert } from '@/models/alerts';

export function registerToOrdersBlotter(connectionId: string, httpPost = http.post) {
  return httpPost('blotter/register', { dataStreamId: connectionId, showMyMainDeals: false }).pipe(
    tap(() => {
      logger.logInformation('Registered to blotter');
    }),
  );
}

export function getActiveOrders(email?: string, httpPost = http.post) {
  return httpPost('blotter/activeOrderList', {
    navigateAsEmail: email,
    showMyMainDeals: false,
  }).pipe(
    map(res => {
      const orders: OrderDto[] = res.response;
      return orders.map(mapOrderToAlert);
    }),
  );
}
