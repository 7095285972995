import {
  isAmountOver,
  isAmountUnder,
  ErrorLimit,
} from '@/store/state/errors/errors.selectors';
import { PredeliverState } from '@/store/state/predeliver/predeliver.reducer';
import { isPredeliverAmountInvalid } from '@/store/state/predeliver/predeliver.selectors';
import type { RolloverState } from '@/store/state/rollover/rollover.reducer';
import { isRolloverAmountInvalid } from '@/store/state/rollover/rollover.selectors';
import type { AppError } from '@/models/AppError';
import type { Currency } from '@/models/currency';
import type { Rfs } from '@/models/rfs';
import type { Way } from '@/models/way';
import { InvalidKind } from '@/models/trade';

export function getInvalidKind(
  errors: AppError[],
  rfs: Rfs,
  predeliver: PredeliverState,
  rollover: RolloverState,
): InvalidKind | undefined {
  if (
    isAmountOver(errors) ||
    isPredeliverAmountInvalid({ rfs, predeliver }) ||
    isRolloverAmountInvalid({ rfs, rollover })
  ) {
    return 'OVER';
  }

  if (isAmountUnder(errors)) {
    return 'UNDER';
  }

  return undefined;
}

function getLabelSuffix(isRollover: boolean) {
  if (isRollover) {
    return 'Rollover';
  }
  return 'Master';
}

export function getWayLabel(way: Way, isRollover: boolean): string {
  return `${way}${getLabelSuffix(isRollover)}`;
}

export function getInvalidLimit(limit: ErrorLimit | undefined, selectedCurrency: Currency) {
  return limit && limit.currency === selectedCurrency ? limit.amount : undefined;
}
