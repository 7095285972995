import { addMinutes, compareAsc } from 'date-fns';

export function isSameOrAfter(date: Date, toCompare: Date) {
  return compareAsc(date, toCompare) >= 0;
}

export function isSameOrBefore(date: Date, toCompare: Date) {
  return compareAsc(date, toCompare) <= 0;
}

export function startOfUtcDay(date: Date) {
  date.setUTCHours(0, 0, 0, 0);
  return date;
}
export const fromUtc = (date: Date) => addMinutes(date, -date.getTimezoneOffset());
export const toUtc = (date: Date) => addMinutes(date, date.getTimezoneOffset());
