import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, pairwise, tap, withLatestFrom } from 'rxjs/operators';
import { isPredeliverRoute, isRfsPage, isRolloverRoute } from '@/App/routes';
import { Rfs } from '@/models/rfs';
import { cancelStreaming } from '@/services/trade.service';
import { closeLastErrorAction, CloseLastErrorAction } from '@/store/state/errors/errors.actions';
import { Piwik } from '@/utils/piwik';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import {
  LocationChangeAction,
  resetAction,
  ResetAction,
  streamStoppedAction,
  StreamStoppedAction,
} from '../state/rfs/rfs.actions';

const doPiwik = (rfs: Rfs) => {
  if (rfs.status !== 'idle' && rfs.status !== 'starting-request' && rfs.status !== 'cancelling') {
    if (rfs.product === 'FxPredeliver') {
      Piwik.trackRfq('Execution abandoned', 'Abandoned predeliver on forward');
    } else if (rfs.product === 'FxSpot') {
      Piwik.trackRfq('Execution abandoned', 'Abandoned spot');
    } else if (rfs.product === 'FxFwd') {
      Piwik.trackRfq('Execution abandoned', 'Abandoned forward');
    }
  }
};

export const pageChangeEpic = (
  cancelRfs = cancelStreaming,
): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, LocationChangeAction>("LOCATION_CHANGE"),
    filter(action => !isPredeliverRoute(action.pathname) && !isRolloverRoute(action.pathname)),
    withLatestFrom(state$.pipe(pairwise())), // pair with old state, to get the previous route
    mergeMap(([,[{ui}, { rfs }]]) =>
      rfs.status !== 'idle' && isRfsPage(ui.router.current!)
        ? cancelRfs(rfs.id).pipe(
            tap(() => doPiwik(rfs)),
            map(hasPreviouslyCanceled => ({ hasPreviouslyCanceled, rfsId: rfs.id })),
          )
        : of({ hasPreviouslyCanceled: false }),
    ),
    mergeMap<
      { hasPreviouslyCanceled: boolean; rfsId?: string },
      Observable<ResetAction | CloseLastErrorAction | StreamStoppedAction>
    >(({ hasPreviouslyCanceled, rfsId }) =>
      hasPreviouslyCanceled && rfsId
        ? of(streamStoppedAction(rfsId), resetAction(), closeLastErrorAction())
        : of(resetAction(), closeLastErrorAction()),
    ),
  );
