import { Action } from '@reduxjs/toolkit';
import { Locale } from '@/utils/locale';
import { RouteValues } from '../../../App/routes';

export type UiAction = LocaleChangedAction | ToggleNavigateAsModalAction | ClosedDatesReceivedAction | RequestRedirectAction | CancelRedirectAction | BlockExecutionAction;

export type LocaleChangedAction = Action<'LOCALE_CHANGED'> & { locale: Locale };
export const localeChangedAction = (locale: Locale): LocaleChangedAction => ({
  type: 'LOCALE_CHANGED',
  locale,
});

type ToggleNavigateAsModalAction = Action<'TOGGLE_NAVIGATE_AS'>;
export const toggleNavigateAsModalAction = (): ToggleNavigateAsModalAction => ({
  type: 'TOGGLE_NAVIGATE_AS',
});

type ClosedDatesReceivedAction = Action<'CLOSED_DATES_RECEIVED'> & {
  currencyPair: string;
  dates: string[];
};
export const closedDatesReceivedAction = (currencyPair: string, dates: string[]): ClosedDatesReceivedAction => ({
  type: 'CLOSED_DATES_RECEIVED',
  currencyPair,
  dates,
});

type BlockExecutionAction = Action<'BLOCK_EXECUTION'> & {
  blockExecution: boolean;
};
export const blockExecutionAction = (blockExecution = true): BlockExecutionAction => ({
  type: 'BLOCK_EXECUTION',
  blockExecution,
});

export type RequestRedirectAction = Action<'REQUEST_REDIRECT'> & {
  toUrl: RouteValues;
};
export const requestRedirectAction = (toUrl: RouteValues): RequestRedirectAction => ({
  type: 'REQUEST_REDIRECT',
  toUrl,
});

export type CancelRedirectAction = Action<'CANCEL_REDIRECT'>;
export const cancelRedirectAction = (): CancelRedirectAction => ({
  type: 'CANCEL_REDIRECT',
});
