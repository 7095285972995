import { Reducer } from '@reduxjs/toolkit';
import { AppAction } from '@/store/app.actions';
import { extractError } from '@/utils/errors';

export interface AppCrash {
  error: string | null;
}

export const appCrashReducer: Reducer<AppCrash, AppAction> = (
  // eslint-disable-next-line default-param-last
  state: AppCrash = { error: null },
  action: AppAction,
): AppCrash => {
  switch (action.type) {
    case 'APP_CRASHED':
      return {
        ...state,
        error: !state.error ? extractError(action.error) ?? `internal error in ${action.source}` : state.error,
      };

    default:
      return state;
  }
};
