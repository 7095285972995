import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { selectUser } from '@/store/state/user/user.selectors';

const reload = () => window.location.reload();

export function SessionDisconnectedModal() {
  const user = useSelector(selectUser);
  const errorMsg = user.isValid !== true ? user.logoffReason : null

  return <Modal
    isOpen={errorMsg !== null}
    backdrop="static"
    className="modal-dialog-centered"
    contentClassName="shadow-max border border-info"
  >
    <ModalHeader>
      <div
        className="rounded-circle bg-info-opacity-10 d-flex align-items-center justify-content-center"
        style={{ width: '3rem', height: '3rem' }}
      >
        <i className="icon icon-xs text-info line-height-1">today</i>
      </div>
    </ModalHeader>
    <ModalBody className="pb-0">
      <h3 className="text-info">
        <FormattedMessage id="modals.disconnected.title" />
      </h3>
      <p className="text-secondary text-large">
        <FormattedMessage id={`modals.disconnected.reason.${errorMsg}`} />
      </p>
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-lg btn-default" onClick={reload}>
        <FormattedMessage id="modals.disconnected.footer.button" />
      </button>
    </ModalFooter>
  </Modal>
};
