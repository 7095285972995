type BlindUncapitalize<K> = K extends string ? Uncapitalize<K> : K;

export function uncapitalize<PascalCase>(value: PascalCase): BlindUncapitalize<PascalCase> {
  return (
    typeof value === 'string' ? `${value.charAt(0).toLowerCase()}${value.substring(1)}` : value
  ) as BlindUncapitalize<PascalCase>;
}

export type CapitalizeObject<T> = T extends ReadonlyArray<infer U>
  ? Array<CapitalizeObject<U>>
  : T extends Record<string, unknown>
  ? {
      [k in keyof T as k extends string ? Capitalize<k> : k]: CapitalizeObject<T[k]>;
    }
  : T;

export type UncapitalizeObject<T> = T extends ReadonlyArray<infer U>
  ? Array<UncapitalizeObject<U>>
  : T extends Record<string, unknown>
  ? {
      [k in keyof T as k extends string ? Uncapitalize<k> : k]: UncapitalizeObject<T[k]>;
    }
  : T;

export function toCamelCase<T>(value: T): UncapitalizeObject<T> {
  if (value === null) {
    return null as unknown as UncapitalizeObject<T>;
  }
  if (Array.isArray(value)) {
    return value.map(toCamelCase) as UncapitalizeObject<(typeof value)[number]>;
  }
  if (typeof value === 'object') {
    return Object.entries(value).reduce((acc, [objKey, objValue]) => {
      acc[uncapitalize(objKey)] = toCamelCase(objValue);
      return acc;
    }, {} as Record<string, string>) as UncapitalizeObject<T>;
  }
  return value as UncapitalizeObject<T>;
}
