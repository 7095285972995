import { ExtendedFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import FlowLeg from './FlowLeg';

interface FlowItemProps {
  flow: ExtendedFlow;
}

export function FlowItem({
  flow: {
    trade,
    flow: {
      legs: [firstFlow, secondFlow],
    },
  },
}: FlowItemProps) {
  return (
    <>
      <FlowLeg
        key={firstFlow.contractId}
        firstFlow={{ ...trade, ...firstFlow }}
        secondFlow={{ ...trade, ...secondFlow }}
      />
    </>
  );
}
