import { Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { isBlotterRoute } from '@/App/routes';
import { getTrades } from '@/services/trades.service';
import {
  RefreshTradesAction,
  tradesReceivedAction,
  tradesRequestFailedAction,
} from '@/store/state/trades/trades.actions';
import { ChangeCompanyAction } from '@/store/state/user/user.actions';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { LocationChangeAction } from '../state/rfs/rfs.actions';

export const tradesEpic =
  (requestTrades = getTrades): Epic<AppAction, AppAction, AppState> =>
    (action$, state$) =>
      action$.pipe(
        ofType<AppAction, LocationChangeAction | ChangeCompanyAction | RefreshTradesAction>(
          'LOCATION_CHANGE',
          'CHANGE_COMPANY',
          'REFRESH_TRADE',
        ),
        withLatestFrom(state$),
        filter(([_, {ui}]) => isBlotterRoute(ui.router.current!)),
        mergeMap(([, { user }]) =>
          requestTrades(user.selectedCompany!.companyMnemonic).pipe(
            mergeMap(trades => (trades ? of(tradesReceivedAction(trades)) : EMPTY)),
            catchError((error) => {
              if (error.status === 204) {
                return of(tradesReceivedAction([]))
              }
  
              return of(tradesRequestFailedAction())
            }),
          ),
        ),
      );
