import * as React from 'react';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const INPUT_DEBOUNCE_TIME = 500;
export const INPUT_DEBOUNCE_TIME_LONG = 1000;

export class DebounceChange extends React.Component<{
  onChange: (value: string) => void;
  debounceTime?: number;
  children: (onChangeDebounced: (value: string) => void) => React.ReactNode;
}> {
  subject = new Subject<string>();

  componentDidMount() {
    const {onChange, debounceTime: dueTime} = this.props
    this.subject
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(dueTime || INPUT_DEBOUNCE_TIME))
      .subscribe(onChange);
  }

  onChange = (value: string) => {
    this.subject.next(value);
  };

  render() {
    const {children} = this.props;
    return children(this.onChange);
  }
}
