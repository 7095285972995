import { FormattedMessage } from 'react-intl';

export function BottomInfo() {
  return (
    <div className="col-12 text-secondary mt-3">
      <FormattedMessage id="alerts.isntOrder" tagName="div" />
      <FormattedMessage id="alerts.noEngagment" tagName="div" />
    </div>
  );
}
