import { FormattedMessage } from 'react-intl';

interface CalendarHeaderInfosItemProps {
  title: string;
  children: React.ReactNode;
  instrumentName: string;
}

export function CalendarHeaderInfosContainer({ title, children, instrumentName }: CalendarHeaderInfosItemProps) {
  return (
    <div className="card bg-lvl2 card-filled full-height d-flex-column me-2 mb-2 p-3" data-e2e={title}>
      <div className="d-flex justify-content-between align-items-baseline">
        <div className="text-secondary mb-lg-4 mb-3 fw-medium" data-e2e="header-info">
          <FormattedMessage id={`app.calendar.${title}.title`} values={{ instrumentName }} />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-baseline flex-wrap-reverse">
        <div className="line-height-1 fw-medium">{children}</div>
      </div>
    </div>
  );
}
