import { ofType } from 'redux-observable';
import { EMPTY, forkJoin, interval, of } from 'rxjs';
import { catchError, concatMap, first, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 } from 'uuid';
import { logger } from '@/logging/logger';
import { ping$ } from '@/services/streams.service';
import { AppAction } from '@/store/app.actions';
import { AppState } from '@/store/app.state';
import { StreamingConnectedAction } from '@/store/state/streaming/streaming.actions';
import { Epic } from '@/store/store';
import { http } from '@/utils/http';

const measureQuery = (connectionId: string) => {
  const pingId = v4();
  const now = of(performance.now());
  const httpNow = http
    .post('ping', {
      pingId,
      datastreamId: connectionId,
    })
    .pipe(map(() => performance.now()));
  const signalrNow = ping$().pipe(
    first((p) => p.pingId === pingId),
    map(() => performance.now()),
  );

  return forkJoin([now, httpNow, signalrNow]);
};

const sendLogQuery = (request: EventLogRequest) => http.post('structuredLog', request);

interface EventLogRequest {
  type: string;
  data: {
    login: string | undefined;
    userAction: string;
    loggingSessionId: string;
    streamingDriver: string | undefined;
    additionalData: {
      pingHttpMs: number;
      pingStreamingMs: number;
      deltaHttpStreamingMs: number;
      driver: string | undefined;
    };
  };
}
const sessionId = v4();
const toEventLog = ([now, httpNow, signalrNow]: [number, number, number], email: string | undefined, driver: string | undefined) => ({
  type: 'info',
  data: {
    login: email,
    userAction: 'Performance',
    loggingSessionId: sessionId,
    streamingDriver: driver,
    additionalData: {
      pingHttpMs: httpNow - now,
      pingStreamingMs: signalrNow - now,
      deltaHttpStreamingMs: signalrNow - httpNow,
      driver,
    },
  },
});

export const pingEpic =
  (measure = measureQuery, sendLog = sendLogQuery): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, StreamingConnectedAction>('STREAMING_CONNECTED'),
      switchMap((a) => interval(10_000).pipe(mapTo(a))),
      concatMap((a) => measure(a.connectionId)),
      withLatestFrom(state$),
      map(([e, s]) => toEventLog(e, s.user.currentUser?.email, s.streaming.transport)),
      concatMap(sendLog),
      concatMap(() => EMPTY),
      catchError((e) => {
        logger.logError(e);
        return EMPTY;
      }),
    );
