import { FormattedMessage } from 'react-intl';
import { NavLink, useMatch } from 'react-router-dom';
import { useExtendedFlowsQuery } from '@/store/state/upcomingFlows/upcomingFlows.api';
import { routes } from '../routes';

export function PortfolioTabs() {
  const inPortfolio = useMatch(routes.PORTFOLIO);
  const inUpcommingFlow = useMatch(routes.UPCOMING_FLOWS);

  const { data: extendedFlows } = useExtendedFlowsQuery();

  return (
    <ul className="nav nav-underline nav-l">
      <li className="nav-item">
        <NavLink to={routes.PORTFOLIO} className={`nav-link ${inPortfolio ? 'active' : ''}`}>
          <FormattedMessage id="app.portfolio.tabs.PORTFOLIO" />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={routes.UPCOMING_FLOWS}
          className={`nav-link ${inUpcommingFlow ? 'active' : ''}`}
          data-e2e="upcoming-flow"
        >
          <FormattedMessage id="app.portfolio.tabs.UPCOMING_FLOWS" />
          <span
            className={`badge rounded-pill badge-discreet-${
              extendedFlows.length ? 'info' : 'secondary'
            } ms-1`}
          >
            {extendedFlows.length ?? 0}
          </span>
        </NavLink>
      </li>
    </ul>
  );
}
