import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFakeUser, sgwtConnect, useFake } from '@/sgwtConnect';
import { getApiUrl } from '@/config/app.config';

export const boostMailApi = createApi({
  reducerPath: 'boostMailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', sgwtConnect.getAuthorizationHeader() ?? '');
      if (useFake) {
        headers.set('X-Fake-Authentication', getFakeUser());
      }
    },
  }),
  endpoints: (builder) => ({
    sendEmail: builder.query<string, { companyBdrId: string, companyName: string }>({
      query: ({companyBdrId, companyName}) => ({
        url: `myfx/email/boost`,
        method: 'POST',
        body: {companyBdrId, companyName},
      }),
    }),
  }),
});

export const { useLazySendEmailQuery } = boostMailApi;
