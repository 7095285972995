import { ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import './GenericErrorModal.scss';
import { resetAction } from '@/store/state/rfs/rfs.actions';
import { routes } from '@/App/routes';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '@/store/store';

interface GenericErrorModalProps {
  isOpen: boolean;
  modalTitleKey: string;
  dataE2E?: string;
  modalBody: ReactNode;
}

export function GenericErrorModal({ isOpen, modalBody, modalTitleKey, dataE2E }: GenericErrorModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    dispatch(resetAction());
    navigate(routes.HOME);
  };

  return (
    <Modal isOpen={isOpen} data-e2e={dataE2E} className="GenericErrorModal">
      <ModalHeader>
        <i className="icon icon-md">close</i>
        <span>
          <FormattedMessage id={modalTitleKey} />
        </span>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <Button className="btn-lg btn-danger" onClick={onClose} data-e2e={`${dataE2E}-button`}>
          <FormattedMessage id="trader.errorModal.goBackHome" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default GenericErrorModal;
