import { Action } from '@reduxjs/toolkit';
import { MyHedgeTrade } from '@/models/trade';

export type TradeAction = TradesReceivedAction | TradesRequestFailedAction | RefreshTradesAction | SpotDatesReceivedAction | SpotDatesRequestFailedAction | TradesSelectedIdAction;

export type TradesReceivedAction = Action<'TRADES_RECEIVED'> & {
  trades: MyHedgeTrade[];
};
export const tradesReceivedAction = (trades: MyHedgeTrade[]): TradesReceivedAction => ({
  type: 'TRADES_RECEIVED',
  trades,
});

export type TradesRequestFailedAction = Action<'TRADES_REQUEST_FAILED'>;
export const tradesRequestFailedAction = (): TradesRequestFailedAction => ({
  type: 'TRADES_REQUEST_FAILED',
});

export type RefreshTradesAction = Action<'REFRESH_TRADE'>;
export const refreshTradesAction = (): RefreshTradesAction => ({
  type: 'REFRESH_TRADE',
});

export type SpotDatesReceivedAction = Action<'SPOT_DATES_RECEIVED'> & {
  spotDates: {
    [currencyPair: string]: string;
  };
};

export const spotDatesReceivedAction = (spotDates: { [currencyPair: string]: string }): SpotDatesReceivedAction => ({
  type: 'SPOT_DATES_RECEIVED',
  spotDates,
});

export type SpotDatesRequestFailedAction = Action<'SPOT_DATES_REQUEST_FAILED'>;
export const spotDatesRequestFailedAction = (): SpotDatesRequestFailedAction => ({
  type: 'SPOT_DATES_REQUEST_FAILED',
});

export type TradesSelectedIdAction = Action<'TRADE_SELECTED'> & {
  selectedTradeId: string;
};
export const tradesSelectedIdAction = (selectedTradeId: string): TradesSelectedIdAction => ({
  type: 'TRADE_SELECTED',
  selectedTradeId,
});
