import { Reducer } from '@reduxjs/toolkit';
import { StreamingInfo } from '@/models/streaming';
import { StreamingAction } from './streaming.actions';

export const streamingReducer: Reducer<Partial<StreamingInfo>, StreamingAction> = (
  // eslint-disable-next-line default-param-last
  state = {},
  action: StreamingAction,
): Partial<StreamingInfo> => {
  switch (action.type) {
    case 'STREAMING_CONNECTED':
      return action;
    default:
      return state;
  }
};
