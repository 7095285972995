/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Collapse } from 'reactstrap';
import { ForwardTrade, getTradeFamily, MyHedgeTrade } from '@/models/trade';
import { StrategyRow } from './StrategyRow';
import { TradeRow, TradeRowContainer } from './TradeRow';
import './TradesList.scss';
import { TradesEmpty } from '../TradesEmpty';
import { CollapsableMaturityDateHeader } from './CollapsableMaturityDateHeader';

// ████████╗██████╗  █████╗ ██████╗ ███████╗███████╗    ██╗     ██╗███████╗████████╗
// ╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔════╝    ██║     ██║██╔════╝╚══██╔══╝
//    ██║   ██████╔╝███████║██║  ██║█████╗  ███████╗    ██║     ██║███████╗   ██║
//    ██║   ██╔══██╗██╔══██║██║  ██║██╔══╝  ╚════██║    ██║     ██║╚════██║   ██║
//    ██║   ██║  ██║██║  ██║██████╔╝███████╗███████║    ███████╗██║███████║   ██║
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝    ╚══════╝╚═╝╚══════╝   ╚═╝

interface TradesListProps {
  dividedTrades: MyHedgeTrade[];
  spotDates: {
    [currencyPair: string]: string;
  };
  onPredeliver: (trade: ForwardTrade) => void;
  onRollover: (trade: ForwardTrade) => void;
  onOpenCalendar: (reference: string) => void;
  setHeaderMaturityDateValue: (value: string) => void;
}

export function TradesList({ dividedTrades, spotDates, onPredeliver, onRollover, onOpenCalendar, setHeaderMaturityDateValue }: TradesListProps) {
  // Build filters list
  const tradesGroupedByMaturityDate = dividedTrades.reduce((acc, trade) => {
    const { maturitydate, instrumentName, sub } = trade;
    let usedMaturityDate = maturitydate;

    // if we have option strategy we need to use the maturity date of the sublegs and not the master
    if (instrumentName === 'FxOptionStrategy') {
      usedMaturityDate = sub[0].maturitydate;
    }

    if (acc[usedMaturityDate]) {
      acc[usedMaturityDate] = [...acc[usedMaturityDate], trade];
    } else {
      acc[usedMaturityDate] = [trade];
    }

    return acc;
  }, {} as Record<string, MyHedgeTrade[]>);

  const sortedTradesGroupedByMaturityDate = Object.entries(tradesGroupedByMaturityDate)
    .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, MyHedgeTrade[]>);

  return dividedTrades.length === 0 ? (
    <TradesEmpty source="search" />
  ) : (
    <>
      {Object.entries(sortedTradesGroupedByMaturityDate).map(([usedMaturityDate, trades]) =>
        trades.length > 0 ? (
          <TradesByMaturity
            key={usedMaturityDate}
            setHeaderMaturityDateValue={setHeaderMaturityDateValue}
            groupedTradesByMaturityDate={[usedMaturityDate, trades]}
            spotDates={spotDates}
            onPredeliver={onPredeliver}
            onRollover={onRollover}
            onOpenCalendar={onOpenCalendar}
          />
        ) : null,
      )}
    </>
  );
}

// ████████╗██████╗  █████╗ ██████╗ ███████╗███████╗    ██████╗ ██╗   ██╗    ███╗   ███╗ █████╗ ████████╗██╗   ██╗██████╗ ██╗████████╗██╗   ██╗
// ╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔════╝    ██╔══██╗╚██╗ ██╔╝    ████╗ ████║██╔══██╗╚══██╔══╝██║   ██║██╔══██╗██║╚══██╔══╝╚██╗ ██╔╝
//    ██║   ██████╔╝███████║██║  ██║█████╗  ███████╗    ██████╔╝ ╚████╔╝     ██╔████╔██║███████║   ██║   ██║   ██║██████╔╝██║   ██║    ╚████╔╝
//    ██║   ██╔══██╗██╔══██║██║  ██║██╔══╝  ╚════██║    ██╔══██╗  ╚██╔╝      ██║╚██╔╝██║██╔══██║   ██║   ██║   ██║██╔══██╗██║   ██║     ╚██╔╝
//    ██║   ██║  ██║██║  ██║██████╔╝███████╗███████║    ██████╔╝   ██║       ██║ ╚═╝ ██║██║  ██║   ██║   ╚██████╔╝██║  ██║██║   ██║      ██║
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝    ╚═════╝    ╚═╝       ╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝   ╚═╝      ╚═╝

interface TradesByMaturityProps {
  groupedTradesByMaturityDate: [string, MyHedgeTrade[]];
  spotDates: {
    [currencyPair: string]: string;
  };
  onPredeliver: (trade: ForwardTrade) => void;
  onRollover: (trade: ForwardTrade) => void;
  onOpenCalendar: (reference: string) => void;
  setHeaderMaturityDateValue: (value: string) => void;
}

function TradesByMaturity({
  groupedTradesByMaturityDate: [maturityDate, trades],
  spotDates,
  onPredeliver,
  onRollover,
  onOpenCalendar,
  setHeaderMaturityDateValue,
}: TradesByMaturityProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <section className="TradesList border-top pt-3 mb-3">
      <div className="card-grid-container">
        <div>
          <CollapsableMaturityDateHeader maturityDate={maturityDate} setHeaderMaturityDateValue={setHeaderMaturityDateValue} toggle={toggle} isOpen={isOpen} />
        </div>

        <div>
          <Collapse isOpen={isOpen}>
            {trades.map((trade, tradeIndex) => (
              <TradeItem
                key={trade.reference}
                trade={trade}
                tradeIndex={tradeIndex}
                tradeCount={trades.length}
                spotDates={spotDates}
                onPredeliver={onPredeliver}
                onRollover={onRollover}
                onOpenCalendar={onOpenCalendar}
              />
            ))}
          </Collapse>
        </div>
      </div>
    </section>
  );
}

type TradeItemProps = {
  trade: MyHedgeTrade;
  tradeIndex: number;
  tradeCount: number;
  spotDates: {
    [currencyPair: string]: string;
  };
  onPredeliver: (trade: ForwardTrade) => void;
  onRollover: (trade: ForwardTrade) => void;
  onOpenCalendar: (reference: string) => void;
};

const TradeItem = (props: TradeItemProps) => {
  const { trade, tradeIndex, tradeCount, spotDates, onPredeliver, onRollover, onOpenCalendar } = props;
  const family = getTradeFamily(trade);
  let isLastRow = false;

  if (tradeCount - 1 === tradeIndex) {
    isLastRow = true;
  }

  return trade.sub.length > 0 ? (
    <StrategyRow
      key={trade.reference}
      trade={trade}
      spotDates={spotDates}
      onPredeliver={onPredeliver}
      onRollover={onRollover}
      onOpenCalendar={onOpenCalendar}
      isLastRow={isLastRow}
    />
  ) : (
    <TradeRowContainer key={trade.reference}>
      <TradeRow
        family={family}
        trade={trade}
        spotDate={spotDates[trade.currencyPair ?? '']}
        onPredeliver={onPredeliver}
        onRollover={onRollover}
        onOpenCalendar={onOpenCalendar}
        isLastRow={isLastRow}
      />
    </TradeRowContainer>
  );
};
