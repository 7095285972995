export interface ISeparators {
  localeThousandSeparator: string;
  localeDecimalSeparator: string;
}

const localeSeparators: { [key: string]: ISeparators } = {
  en: {
    localeThousandSeparator: ',',
    localeDecimalSeparator: '.',
  },
  fr: {
    localeThousandSeparator: ' ',
    localeDecimalSeparator: ',',
  },
};

export function getSeparatorByLocale(locale?: string): ISeparators {
  return locale === undefined
    ? localeSeparators.en
    : localeSeparators[locale.toLowerCase()] || localeSeparators.en;
}

export function isAccelerator(keyReceived: string) {
  const key = keyReceived.toLowerCase();
  return key === 'k' || key === 'm' || key === 'g' || key === 'b';
}

export function acceleratorOffset(keyReceived: string) {
  const key = keyReceived.toLowerCase();
  switch (key) {
    case 'k':
      return 3;
    case 'm':
      return 6;
    case 'g':
    case 'b':
      return 9;
    default:
      return 0;
  }
}

/**
 * splits a string into an array of three char strings, except the first between 1 and 3
 */
function splitIntegerByThousands(integerNumber: string) {
  const splitNumber: string[] = [];
  // first item length
  const leftHandLength = ((integerNumber.length - 1) % 3) + 1;
  // number of items
  const splitInto = Math.floor((integerNumber.length - 1) / 3);

  for (let splitIndex = 0; splitIndex <= splitInto; splitIndex += 1) {
    // split 3 by 3 (except first)
    splitNumber.push(
      integerNumber.substring(leftHandLength + splitIndex * 3 - 3, leftHandLength + splitIndex * 3),
    );
  }

  return splitNumber;
}

/**
 * given a string representation of a number and separators
 * return a formated string representation number
 */
export function formatNumberAsStringToLocale(
  numberToFormat: string,
  localeThousandSeparator: string,
  localeDecimalSeparator: string,
): string {
  if (numberToFormat.length !== 0 && '0123456789'.indexOf(numberToFormat[0]) === -1) {
    return (
      numberToFormat[0] +
      formatNumberAsStringToLocale(
        numberToFormat.slice(1),
        localeThousandSeparator,
        localeDecimalSeparator,
      )
    );
  }
  // splits integer and decimal part
  const [integer, decimal] = numberToFormat.split('.') as [string, string | undefined];
  // only format integer part
  const localeInteger = splitIntegerByThousands(integer).join(localeThousandSeparator);
  // concatenate decimal part if it exists
  const localeNumber =
    decimal === undefined ? localeInteger : [localeInteger, decimal].join(localeDecimalSeparator);

  return localeNumber;
}

/**
 * calculates the cursor position offset
 * from the localized string (input field)
 * to the non-localized representation of the number
 */
export function getCursorOffsetFromSelectionToValue(
  cursor: number,
  localized: string,
  separator: string,
) {
  let offset = 0;
  for (let index = 0; index < localized.length && index < cursor; index += 1) {
    if (localized[index] === separator) {
      offset += 1;
    }
  }
  return offset;
}

/**
 * calculates the cursor position offset
 * from non-localized representation of the number
 * to the localized string (input field)
 */
export function getCursorOffsetFromValueToSelection(
  cursor: number,
  localized: string,
  separator: string,
) {
  let innerCursor = cursor
  let offset = 0;
  for (let index = 0; index < localized.length && innerCursor > 0; index += 1) {
    if (localized[index] === separator) {
      offset += 1;
    } else {
      innerCursor -= 1;
    }
  }
  return offset;
}

interface Separators {
  localeThousandSeparator: string;
  localeDecimalSeparator: string;
}

interface Boundaries {
  selectionStart: number;
  selectionEnd: number;
}

export interface INumberInputDigitEdit extends Separators, Boundaries {
  value: string;
  valueLocale: string;
  key: string;
}

export interface INumberInputMultiplierEdit extends Separators {
  value: string;
  key: string;
}

export interface ICopy extends Separators, Boundaries {
  value: string;
  valueLocale: string;
}
/**
 * given locale separators and the state of the input field before key press
 * returns the new number, localized number and cursor position
 */
export function insertDigitKey({
  value,
  valueLocale,
  key,
  selectionStart,
  selectionEnd,
  localeThousandSeparator,
  localeDecimalSeparator,
}: INumberInputDigitEdit) {
  // given the localized value and the thousand separator,
  // what is the offset between the input field cursor position
  // and the inner non formatted value position
  const valueStartOffset = getCursorOffsetFromSelectionToValue(
    selectionStart,
    valueLocale,
    localeThousandSeparator,
  );
  const valueEndOffset = getCursorOffsetFromSelectionToValue(
    selectionEnd,
    valueLocale,
    localeThousandSeparator,
  );
  const valueCursorPositionStart = selectionStart - valueStartOffset;
  const valueCursorPositionEnd = selectionEnd - valueEndOffset;

  // a priori, a key is added and cursor moves one to the right
  let valueCursorDelta = key.length;

  // if key is a dot, remove all other dots
  let leftHandString = value.substr(0, valueCursorPositionStart);
  if (key === '.' && leftHandString.split('.').length > 1) {
    leftHandString = leftHandString.split('.').join('');
    // left hand side dots implies cursor remains in position
    valueCursorDelta = 0;
  }
  let rightHandString = value.substr(valueCursorPositionEnd);
  if (key === '.' && rightHandString.split('.').length > 1) {
    rightHandString = rightHandString.split('.').join('');
  }

  const newValue = [leftHandString, key, rightHandString].join('');
  const newValueLocale = formatNumberAsStringToLocale(
    newValue,
    localeThousandSeparator,
    localeDecimalSeparator,
  );

  // update the inner cursor position
  const newValuePosition = valueCursorPositionStart + valueCursorDelta;

  // calculate corresponding cursor position in the input field
  const newSelectionOffset = getCursorOffsetFromValueToSelection(
    newValuePosition,
    newValueLocale,
    localeThousandSeparator,
  );
  const cursorPosition = newValuePosition + newSelectionOffset;

  return {
    cursorPosition,
    state: { value: newValue, valueLocale: newValueLocale },
  };
}

/**
 * given locale separators and the state of the input field before key press
 * returns the new number and localized number
 */
export function insertAcceleratorKey({
  value,
  key,
  localeThousandSeparator,
  localeDecimalSeparator,
}: INumberInputMultiplierEdit) {
  // map each key to a multiplier (power of ten)
  const multiplier = acceleratorOffset(key);

  // split value in integer and decimal parts
  let [integer, decimal] = value.split('.') as [string, string | undefined];
  // if the decimal part has no number, ignore it
  decimal = decimal !== undefined && decimal.length > 0 ? decimal : undefined;
  // if there is no integer and there is no decimal part, ignore key press
  if (integer.length === 0 && decimal === undefined) {
    return null;
  }

  // string multiplication
  for (let index = 0; index < multiplier; index += 1) {
    if (decimal === undefined) {
      // add zero if there is no decimal digit
      integer = integer.concat('0');
    } else {
      // add most significant decimal digit
      integer = integer.concat(decimal[0]);
      // strip it from the decimal part, if its length reaches 0, explicitly remove decimal reference
      decimal = decimal.length > 1 ? decimal.slice(1) : undefined;
    }
  }

  const newValue = decimal === undefined ? integer : [integer, decimal].join('.');
  const newValueLocale = formatNumberAsStringToLocale(
    newValue,
    localeThousandSeparator,
    localeDecimalSeparator,
  );
  return { value: newValue, valueLocale: newValueLocale };
}

export function copy({
  value,
  valueLocale,
  selectionStart,
  selectionEnd,
  localeThousandSeparator,
  localeDecimalSeparator,
}: ICopy) {
  const start =
    selectionStart -
    getCursorOffsetFromSelectionToValue(selectionStart, valueLocale, localeThousandSeparator);
  const end =
    selectionEnd -
    getCursorOffsetFromSelectionToValue(selectionEnd, valueLocale, localeThousandSeparator);
  return value
    .substr(start, end - start)
    .split('.')
    .join(localeDecimalSeparator);
}
