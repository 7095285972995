import { NavLink } from 'react-router-dom';
import { routes } from '../routes';
import sprite from '@/sprite.svg';


function HeaderBrand() {
  return (
    <NavLink className="navbar-title-link" to={routes.HOME}>
      <div className="navbar-logo">
        <img src={`${sprite}#sglogo`} height="32px" alt="logo" className="logo" />
      </div>
      <div className="navbar-title-divider" />
      <div className="navbar-service-name">MyFX</div>
    </NavLink>
  );
}

export default HeaderBrand;
