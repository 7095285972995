import { AppError } from '@/models/AppError';
import { AppState } from '@/store/app.state';

export const lastError = (errors: AppError[]) => errors.length > 0 ? errors[errors.length - 1] : undefined;

export const isAmountInvalid = (errors: AppError[]) => {
  const error = lastError(errors);
  return (
    error !== undefined &&
    (error.code.includes('OVERSHOT') ||
      error.code.includes('UNDERSHOT') ||
      (error.code.includes('CREDIT_CHECK') && error.creditCheckType !== 'NOLIMIT'))
  );
};

export const isAmountOver = (errors: AppError[]) => {
  const error = lastError(errors);
  return error !== undefined && error.code.includes('OVERSHOT');
};

export const isAmountUnder = (errors: AppError[]) => {
  const error = lastError(errors);
  return error !== undefined && error.code.includes('UNDERSHOT');
};

export const isDateInvalid = (errors: AppError[]) => {
  const error = lastError(errors);
  return (
    error !== undefined &&
    ((error.code.includes('CREDIT_CHECK') && error.creditCheckType === 'NOLIMIT') ||
      ((error.code === 'EM_UNAUTHORISED' || error.code === 'EM_UNAUTHORIZED') && // Someone, someday, might fix the typo on backend side
        error.multipassErrorCode === 'RrEntitlementsCheckFailed'))
  );
};
export interface ErrorLimit {
  amount: number;
  currency?: string;
}

export const errorLimit = (errors: AppError[]): ErrorLimit | undefined => {
  const error = lastError(errors);
  // trying to parse amount from text message => Baaad, find another way ?
  if (error && error.code.includes('OVERSHOT')) {
    const userLimit = error.message.match(/user limit (\d*\.?\d*E?\d*) ([A-Z]{3})/);
    return userLimit
      ? {
          amount: parseFloat(userLimit[1]),
          currency: userLimit[2],
        }
      : undefined;
  }
  if (error && error.code.includes('UNDERSHOT')) {
    const userLimit = error.message.match(/\(in ([A-Z]{3})\) .* less than (\d*\.?\d*E?\d*)/);
    return userLimit
      ? {
          currency: userLimit[1],
          amount: parseFloat(userLimit[2]),
        }
      : undefined;
  }
  if (error && error.code.includes('CREDIT_CHECK') && error.creditCheckType === 'RDL') {
    return {
      amount: parseFloat(error.creditCheckLimit!),
    };
  }

  return undefined;
};

export const selectErrors = ({ errors }: AppState) => errors

export const selectLastError = ({ errors }: AppState) => lastError(errors)