import React, { Dispatch } from 'react';
import { CalendarAction } from '@/App/calendar/state';

interface dispatchContextProps {
  dispatch: Dispatch<CalendarAction>;
}

const DispatchContext = React.createContext<dispatchContextProps>({
  // we can create an empty function because later we are going to fill it with the dispatch
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export default DispatchContext;
