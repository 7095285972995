import * as React from 'react';

import {
  copy,
  formatNumberAsStringToLocale,
  getSeparatorByLocale,
  insertAcceleratorKey,
  insertDigitKey,
  isAccelerator,
} from './numberInputUtils';
import { deriveStateOnChangedProps, noop, RenderPropChildren } from './utils';

type Copy = (selectionStart: number, selectionEnd: number) => string;
type Cut = (
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean, newValue: string) => void,
) => string;

type KeyPress = (
  key: string,
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean, newValue: string) => void,
) => void;

type Paste = (
  keys: string[],
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean, newValue: string) => void,
) => void;

interface DecimalKeyProps {
  decimal: string;
  locale?: string;
}
interface DecimalKeyState {
  value: string;
  valueLocale: string;
}

function isDecimalSeparator(key: string) {
  return key === '.' || key === 'Decimal' || key === ',';
}

function isDecimal(key: string) {
  return key.length === 1 && (key <= '9' && key >= '0');
}

function decimalKeyStateFromProps(props: Readonly<DecimalKeyProps>): DecimalKeyState {
  const { locale, decimal } = props;
  const { localeDecimalSeparator, localeThousandSeparator } = getSeparatorByLocale(locale);
  const valueLocale = formatNumberAsStringToLocale(
    decimal,
    localeThousandSeparator,
    localeDecimalSeparator,
  );
  return { value: decimal, valueLocale };
}
export interface DecimalKeyChildrenProps {
  currentValue: string;
  valueLocale: string;
  onKeyDown: KeyPress;
  onPaste: Paste;
  onCopy: Copy;
  onCut: Cut;
  reset(cb?: (v: string) => void): void;
}

export class DecimalKey extends React.Component<
  RenderPropChildren<DecimalKeyChildrenProps> & DecimalKeyProps,
  DecimalKeyState
> {
  public static getDerivedStateFromProps = deriveStateOnChangedProps(decimalKeyStateFromProps);

  public state = decimalKeyStateFromProps(this.props);

  public render() {
    const {children} = this.props
    return children(this.renderProps);
  }

  private get dirty() {
    const {value} = this.state
    const {decimal} = this.props
    return value !== decimal;
  }

  private onCopy: Copy = (selectionStart, selectionEnd) => {
    const {locale} = this.props
    const sep = getSeparatorByLocale(locale);
    const edit = { ...this.state, ...sep, selectionStart, selectionEnd };
    const clipboard = copy(edit);
    return clipboard;
  };

  private onCut: Cut = (selectionStart, selectionEnd, done) => {
    const {locale} = this.props
    const sep = getSeparatorByLocale(locale);
    const edit = { ...this.state, ...sep, selectionStart, selectionEnd };
    const clipboard = copy(edit);
    const { cursorPosition, state } = insertDigitKey({ ...edit, key: '' });
    this.setState(() => state, () => done(cursorPosition, this.dirty, state.value));
    return clipboard;
  };

  private onPaste: Paste = (keys, selectionStart, selectionEnd, done) => {
    const {locale} = this.props
    const sep = getSeparatorByLocale(locale);
    const { value, valueLocale, selectionStart: position } = keys
      .filter(key => key === sep.localeDecimalSeparator || isDecimal(key))
      .reduce(
        (edit, key) => {
          const { cursorPosition, state } = insertDigitKey({
            ...edit,
            key: isDecimalSeparator(key) ? '.' : key,
          });
          return {
            ...edit,
            selectionEnd: cursorPosition,
            selectionStart: cursorPosition,
            ...state,
          };
        },
        { selectionStart, selectionEnd, ...this.state, ...sep },
      );
    this.setState(() => ({ value, valueLocale }), () => done(position, this.dirty, value));
  };

  private onKeyDown: KeyPress = (key, selectionStart, selectionEnd, done) => {
    const {locale} = this.props
    const {value, valueLocale} = this.state
    const edit = {
      key,
      selectionStart,
      selectionEnd,
      value,
      valueLocale,
      ...getSeparatorByLocale(locale),
    };
    if (isDecimal(key)) {
      const { cursorPosition, state } = insertDigitKey(edit);
      this.setState(() => state, () => done(cursorPosition, this.dirty, state.value));
    } else if (isDecimalSeparator(key)) {
      const { cursorPosition, state } = insertDigitKey({ ...edit, key: '.' });
      this.setState(() => state, () => done(cursorPosition, this.dirty, state.value));
    } else if (isAccelerator(key)) {
      const state = insertAcceleratorKey({
        key,
        value,
        ...getSeparatorByLocale(locale),
      });
      if (state !== null) {
        this.setState(() => state, () => done(null, this.dirty, state.value));
      }
    } else if (key === 'Backspace') {
      const start =
        selectionStart === selectionEnd && selectionStart !== 0 ? selectionEnd - 1 : selectionStart;
      const { cursorPosition, state } = insertDigitKey({
        ...edit,
        selectionStart: start,
        key: '',
      });
      this.setState(() => state, () => done(cursorPosition, this.dirty, state.value));
    } else if (key === 'Delete') {
      const end = selectionStart === selectionEnd ? selectionEnd + 1 : selectionEnd;
      const { cursorPosition, state } = insertDigitKey({
        ...edit,
        selectionEnd: end,
        key: '',
      });
      this.setState(() => state, () => done(cursorPosition, this.dirty, state.value));
    }
  };

  private reset = (cb: (v: string) => void = noop) => {
    const {value} = this.state
    this.setState((_, props) => decimalKeyStateFromProps(props), () => cb(value));
  };

  private get renderProps(): DecimalKeyChildrenProps {
    const { value: currentValue, valueLocale } = this.state;
    const { onKeyDown, onPaste, onCopy, onCut, reset } = this;
    const callbacks = { onKeyDown, onPaste, onCopy, onCut, reset };
    return { currentValue, valueLocale, ...callbacks };
  }
}
