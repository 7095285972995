import { FormattedMessage } from 'react-intl';

export function WillSendMailTo({email}: { email: string | undefined }) {
  return <div className="col-12">
    <div className="form-group">
      <FormattedMessage
        id="alerts.willSendMailTo"
        values={{ email, a: e => <a href={`mailto://${e}`}>{e}</a> }}
        tagName="p"
      />
    </div>
  </div>
}
