import { isDefined } from '@sgme/fp';
import {
  DecoratedFields,
  FxoStandardForwardAccumulatorCalendarFields,
  FxTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoPauseTargetAccumulatorCalendarFields,
} from '@/models/calendar';

export const getCumulatedTargetSum = (
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[],
  digitsAfterComma: number,
) =>
  Number(
    Math.max(
      ...Object.values(computedFields as DecoratedFields<FxTargetAccumulatorCalendarFields>[]).map(({ cumulatedTarget }) => {
        if (isDefined(cumulatedTarget.value)) {
          return cumulatedTarget.value as number;
        }
        return 0;
      }),
    ).toFixed(digitsAfterComma),
  );
