import { SgWay } from './way';

interface BidAskOf<T> {
  bid: T;
  ask: T;
}
export type BidAskNumber = BidAskOf<number>;

export type BidAskString = BidAskOf<`${number}`>;

export const getBidAskValue = <T>(bidAskNumber: BidAskOf<T>, sgWay: SgWay) => sgWay === 'Buy' ? bidAskNumber.bid : bidAskNumber.ask;
