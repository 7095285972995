import { CreateAlertFormData, CreateAlertErrors } from './create-alert.model';

export type CreateAlertActions =
  | CreateAlertFormUpdated
  | CreateAlert
  | CreateAlertValidationStarted
  | CreateAlertValidationError
  | CreateAlertValidationOk
  | CreateAlertSucceeded
  | CreateAlertSetFixingExample
  | UpdateAlert
  | DeleteAlert
  | UpdateAlertFormId
  | FinishedCreateAlert
  | FinishedUpdateAlert
  | FinishedDeleteAlert;

export type CreateAlertFormUpdated = {
  type: 'CreateAlertFormUpdated';
  patch: Partial<CreateAlertFormData>;
};

export const createAlertFormUpdate = (
  patch: Partial<CreateAlertFormData>,
): CreateAlertFormUpdated => ({
  type: 'CreateAlertFormUpdated',
  patch,
});

export type CreateAlert = {
  type: 'CreateAlert';
};

export const createAlert = (): CreateAlert => ({
  type: 'CreateAlert',
});

export type CreateAlertSucceeded = {
  type: 'CreateAlertSucceeded';
};

export const createAlertSucceeded = (): CreateAlertSucceeded => ({
  type: 'CreateAlertSucceeded',
});
export type CreateAlertValidationError = {
  type: 'CreateAlertValidationError';
  errors: CreateAlertErrors;
};

export const createAlertValidationError = (
  errors: CreateAlertErrors,
): CreateAlertValidationError => ({
  type: 'CreateAlertValidationError',
  errors,
});
export type CreateAlertValidationOk = {
  type: 'CreateAlertValidationOk';
};

export const createAlertValidationOk = (): CreateAlertValidationOk => ({
  type: 'CreateAlertValidationOk',
});

export type CreateAlertValidationStarted = {
  type: 'CreateAlertValidationStarted';
};

export const createAlertValidationStarted = (): CreateAlertValidationStarted => ({
  type: 'CreateAlertValidationStarted',
});

export type CreateAlertSetFixingExample = {
  type: 'CreateAlertSetFixingExample';
  fixingExample: number | undefined;
};

export const createAlertSetFixingExample = (
  fixingExample: number | undefined,
): CreateAlertSetFixingExample => ({
  type: 'CreateAlertSetFixingExample',
  fixingExample,
});

export type UpdateAlertFormId = {
  type: 'UpdateAlertFormId';
  id: string;
};

export const updateAlertFormId = (id: string): UpdateAlertFormId => ({
  type: 'UpdateAlertFormId',
  id,
});

export type UpdateAlert = {
  type: 'UpdateAlert';
};

export const updateAlert = (): UpdateAlert => ({ type: 'UpdateAlert' });

export type DeleteAlert = {
  type: 'DeleteAlert';
  id: string;
};
export const deleteAlertAction = (id: string): DeleteAlert => ({ type: 'DeleteAlert', id });

type FinishedCreateAlert = {
  type: 'FinishedCreateAlert';
};
export const finishedCreateAlert = (): FinishedCreateAlert => ({ type: 'FinishedCreateAlert' });

type FinishedUpdateAlert = {
  type: 'FinishedUpdateAlert';
};
export const finishedUpdateAlert = (): FinishedUpdateAlert => ({ type: 'FinishedUpdateAlert' });

type FinishedDeleteAlert = {
  type: 'FinishedDeleteAlert';
  id: string;
};

export const finishedDeleteAlert = (id: string): FinishedDeleteAlert => ({
  type: 'FinishedDeleteAlert',
  id,
});
