import { parseISO } from 'date-fns';
import { oppositeWay } from '@/models/way';
import { AppState } from '@/store/app.state';
import { isSameOrAfter } from '@/utils/dates';
import { getCurrencyByWay } from '../rfs/rfs.selectors';
import { TradeActionType } from '@/models/trade';
import { isDefined, isNotDefined } from '@sgme/fp';

export const isRolloverAmountInvalid = (state: Pick<AppState, 'rollover' | 'rfs'>) =>
  state.rollover.trade !== undefined && (state.rollover.trade.remainingAmount ?? 0) < state.rfs.buyAmount;

export const isRolloverDateInvalid = (state: Pick<AppState, 'rollover' | 'rfs'>) =>
  isNotDefined(state.rfs.date) || isNotDefined(state.rollover.trade) || isSameOrAfter(parseISO(state.rollover.trade.maturitydate), state.rfs.date);

export function getCurrency(state: AppState, tradeName: TradeActionType) {
  return getCurrencyByWay(state.rfs, state[tradeName].trade!.side);
}

export function getOppositeCurrency(state: AppState, tradeName: TradeActionType) {
  return getCurrencyByWay(state.rfs, oppositeWay(state[tradeName].trade!.side));
}

export const selectRollover = ({ rollover }: AppState) => rollover;

export const selectRolloverTrade = ({ rollover }: AppState) => rollover.trade;

export const selectRolloverReference = ({ rollover }: AppState) => rollover.trade?.reference;
