import { useSelector } from 'react-redux';
import { selectLastError } from '@/store/state/errors/errors.selectors';
import { mapError } from './errorMessages';
// import { Toast } from './Toast';
import './TradeErrors.scss';

export function TradeErrors() {
  const error = useSelector(selectLastError)

  return error === undefined
  ? null
  : <div className='rfs-errors border border-danger'>
      {/* <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={closeError} >
        <i className="icon">close</i>
      </button> */}
      { mapError(error) }
    </div>
}
