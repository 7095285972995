import { toCamelCase } from '@/utils/utils';
import { Currency } from './currency';

export function mapOrderToAlert(rawOrder: OrderDto): Alert {
  const order = toCamelCase(rawOrder);
  return {
    id: order.id,
    currencyPair: order.currencyPair,
    expiryDay: order.expiryDay,
    status: order.status as AlertStatus,
    limitPrice: +order.limitPrice,
    account: order.account,
    goodTillCancelled: order.goodTillCancelled,
    creationDate: order.originationTimestamp || order.inputDate,
    updateTime: order.updateTime,
  };
}

export const alertStatuses = [
  'Submitted',
  'Watched',
  'Executing',
  'Done',
  'Rejected',
  'InError',
  'Cancelled',
  'Partially done',
  'Expired',
  'Paused',
  'Updating',
  'Cancelling',
  'Running',
] as const;

export type AlertStatus = typeof alertStatuses[number];

export const displayableAlertStatuses: readonly AlertStatus[] = [
  'Watched',
  'Done',
  'Expired',
  'Cancelled',
];

export type Alert = {
  id: string;
  currencyPair: string;
  status: AlertStatus;
  limitPrice: number;
  expiryDay: string;
  account: string;
  goodTillCancelled: boolean;
  creationDate: string;
  updateTime: string;
};

export type OrderDto = {
  type: 'BLOTTER.ORDER';
  id: string;
  status: string;
  updateTime: string;
  inputDate: string;
  originationTimestamp: string;
  goodTillCancelled: boolean;
  orderType: 'Call';
  currencyPair: string;
  yourWay: string;
  notional: number;
  notionalCurrency: Currency;
  executedAmount: number;
  account: string;
  sourceUserLogin: string;
  limitPrice: number;
  customerPrice: number;
  executionPrice: number;
  rejectReason: string;
  expiryDay: string;
  expiryTime: string;
  startTime: string;
  endTime: string;
  clippingMode: null;
  clipSize: number;
  randomize: null;
  spreadCapture: null;
  liquidityPool: string;
  maturityDate: string;
  swapPoints: number;
  forwardPrice: number;
  remainingAmount: number;
  noWorseThan: string;
  alphaSeeker: boolean;
  averagePrice: number;
  speed: string;
};
