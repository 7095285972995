import { Action } from '@reduxjs/toolkit';
import { Alert } from '@/models/alerts';

export type AlertsAction = LoadingAlertsAction | AlertsLoadedAction | AlertReceivedAction | AlertsLoadingFailedAction;

export type LoadingAlertsAction = Action<'LOADING_ALERTS'> & { email?: string };
export const loadingAlertsAction = (email?: string): LoadingAlertsAction => ({
  type: 'LOADING_ALERTS',
  email,
});

type AlertsLoadedAction = Action<'ALERTS_LOADED'> & { alerts: Alert[] };
export const alertsLoadedAction = (alerts: Alert[]): AlertsLoadedAction => ({
  type: 'ALERTS_LOADED',
  alerts,
});

type AlertsLoadingFailedAction = Action<'ALERTS_LOADING_FAILED'>;
export const alertsLoadingFailedAction = (): AlertsLoadingFailedAction => ({
  type: 'ALERTS_LOADING_FAILED',
});

type AlertReceivedAction = Action<'ALERT_RECEIVED'> & { alert: Alert };
export const alertReceivedAction = (alert: Alert): AlertReceivedAction => ({
  type: 'ALERT_RECEIVED',
  alert,
});
