import { addHours, isAfter, parseISO } from 'date-fns';
import { AppState } from '@/store/app.state';
import { displayableAlertStatuses } from '@/models/alerts';
import { AlertItem, AlertsState } from './alerts.reducer';
import { createSelector } from '@reduxjs/toolkit';

const getAlertsSelector = (state: Pick<AppState, 'alerts'>) => state.alerts;

const getAlertsWith = (predicate: (alert: AlertItem) => boolean) => createSelector(getAlertsSelector, (alerts: AlertsState): AlertItem[] => {
  const alertsList = Object.values(alerts);

  return alertsList.filter(predicate);
})

const getUserAlertsWith = (predicate: (alert: AlertItem) => boolean) => (
  state: Pick<AppState, 'alerts' | 'user'>,
) =>
  getAlertsWith(
    alert => alert.account === state.user.selectedCompany?.companyBdrId && predicate(alert),
  )(state);

export const getDisplayableAlerts = getUserAlertsWith(({ status }) =>
  displayableAlertStatuses.includes(status),
);

export const getWatchedAlerts = getUserAlertsWith(alert => alert.status === 'Watched');

export const selectWatchedAlertCount = (state: AppState) => getWatchedAlerts(state)?.length;

/**
 * If status is Done/Déclenché && Date creation is from 24h away
 */
export const getDoneAlerts = getAlertsWith(
  alert => alert.status === 'Done' &&
    isAfter(parseISO(alert.creationDate), addHours(new Date(Date.now()), -24)),
);

/**
 * If status is Expired/Expiré && Date creation is from 24h away
 */
export const getExpiredAlerts = getAlertsWith(
  alert =>
    alert.status === 'Expired' &&
    isAfter(parseISO(alert.creationDate), addHours(new Date(Date.now()), -24)),
);

export const isAlertLoading = (state: AppState) => state.ui.loadingAlerts;

export const isAlertLoadingFailed = (state: AppState) => state.ui.loadingAlertsFailed;

export const selectCreateAlert = (state: AppState) => state.createAlert
