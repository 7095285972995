import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';
import { useEffect } from 'react';
import { useAppDispatch } from '@/store/store';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['sgwt-help-center']: any;
    }
  }
}

export const HelpCenterId = 'my-help-center';

export interface HelpCenterApi extends Element {
  open: () => void;
  setDefaultSendTo: (sendTo: string) => void;
}
const helpCenterQuery = `#${HelpCenterId}`;
export const getHelpCenter = () => document.querySelector<HelpCenterApi>(helpCenterQuery)!;

export function HelpCenter() {
  const dispatch = useAppDispatch();
  const onToggle = (blockExecution: boolean) => dispatch(blockExecutionThunk(blockExecution));

  useEffect(() => {
    const onHelpOpen = () => onToggle(true);
    const onHelpClose = () => onToggle(false);
    const helpCenter = document.getElementById(HelpCenterId);
    helpCenter?.addEventListener('sgwt-help-center--open', onHelpOpen);
    helpCenter?.addEventListener('sgwt-help-center--close', onHelpClose);
    return () => {
      helpCenter?.removeEventListener('sgwt-help-center--open', onHelpOpen);
      helpCenter?.removeEventListener('sgwt-help-center--close', onHelpClose);
    };
  }, []);

  return (
    <aside>
      <SgwtHelpCenter id={HelpCenterId} mailSubject={`[SGME-MYFX:${window.sgmeConfiguration.env}] Help request message`} language="fr" applicationId="sgmarkets-myfx" messageOnly allowScreenshot />
    </aside>
  );
}

HelpCenter.displayName = 'HelpCenter';
