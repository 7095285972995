import { Action } from '@reduxjs/toolkit';
import { BidAskNumber, BidAskString } from '@/models/bidask-price';
import { Currency } from '@/models/currency';
import { ProductName } from '@/models/product';
import { Rfs } from '@/models/rfs';
import { SgWay } from '@/models/way';

export type RfsAction =
  | InitRfsAction
  | FormChangeAction
  | CashQuoteReceivedAction
  | SwapQuoteReceivedAction
  | StreamRequestedAction
  | ExecuteAction
  | ExecutedAction
  | BackOfficeValidatedAction
  | ResetAction
  | StopStreamAction
  | RequestForStreamAction
  | SpotDateReceivedAction
  | SpotDateRequestedAction
  | StreamStoppedAction
  | ExecutionSentAction
  | ExecutionFailedAction
  | ExecutionTimeoutAction
  | QuotingTimeoutAction
  | IsAllAvailableUsedChangeAction
  | TimeoutedDealAction
  | LocationChangeAction;

type InitRfsAction = Action<'INIT_TRADE'> & {
  buyCurrency: Currency;
};
export const initRfsAction = (buyCurrency: Currency): InitRfsAction => ({
  type: 'INIT_TRADE',
  buyCurrency,
});

type FormChangePatch = Partial<Pick<Rfs, 'buyAmount' | 'buyCurrency' | 'sellAmount' | 'sellCurrency' | 'date' | 'masterAmount' | 'postTradeOperation'>>;
export type FormChangeAction = Action<'FORM_CHANGE'> & {
  formChange: FormChangePatch;
};
export const formChangeAction = (formChange: FormChangePatch): FormChangeAction => ({
  type: 'FORM_CHANGE',
  formChange,
});

export type LocationChangeAction = Action<'LOCATION_CHANGE'> & {
  pathname: string;
};

export const locationChangeAction = (pathname: string): LocationChangeAction => ({
  type: 'LOCATION_CHANGE',
  pathname,
});

export type IsAllAvailableUsedChangeAction = Action<'IS_ALL_AVAILABLE_USED_CHANGE'> & {
  isAllAvailableUsed: boolean;
};

export const isAllAvailableUsedChangeAction = (isAllAvailableUsed: boolean): IsAllAvailableUsedChangeAction => ({
  type: 'IS_ALL_AVAILABLE_USED_CHANGE',
  isAllAvailableUsed,
});

type CashQuoteReceivedAction = Action<'CASH_QUOTE_RECEIVED'> & {
  rfsId: string;
  quoteId: string;
  spotRate: BidAskNumber;
  contraAmount: number;
  forwardPoints: BidAskNumber;
  forwardRate: BidAskNumber;
  currencyPair: string;
  sgWay: SgWay;
  timestamp: number;
};
export const cashQuoteReceivedAction = (
  rfsId: string,
  quoteId: string,
  spotRate: BidAskNumber,
  contraAmount: number,
  forwardPoints: BidAskNumber,
  forwardRate: BidAskNumber,
  currencyPair: string,
  sgWay: SgWay,
  timestamp: number,
): CashQuoteReceivedAction => ({
  type: 'CASH_QUOTE_RECEIVED',
  rfsId,
  quoteId,
  contraAmount,
  spotRate,
  forwardPoints,
  forwardRate,
  currencyPair,
  sgWay,
  timestamp,
});

type SwapQuoteReceivedAction = Action<'SWAP_QUOTE_RECEIVED'> & {
  rfsId: string;
  quoteId: string;
  spotRate: BidAskString;
  contraAmount: string;
  nearPoints: BidAskString;
  nearRate: BidAskString;
  farPoints: BidAskString;
  farRate: BidAskString;
  currencyPair: string;
  sgWay: SgWay;
  historicalDealOffRate: number;
  timestamp: number;
};

export const swapQuoteReceivedAction = (
  rfsId: string,
  quoteId: string,
  spotRate: BidAskString,
  contraAmount: string,
  nearPoints: BidAskString,
  nearRate: BidAskString,
  farPoints: BidAskString,
  farRate: BidAskString,
  currencyPair: string,
  sgWay: SgWay,
  historicalDealOffRate: number,
  timestamp: number,
): SwapQuoteReceivedAction => ({
  type: 'SWAP_QUOTE_RECEIVED',
  rfsId,
  quoteId,
  contraAmount,
  spotRate,
  nearPoints,
  nearRate,
  farPoints,
  farRate,
  currencyPair,
  sgWay,
  historicalDealOffRate,
  timestamp,
});

export type RequestForStreamAction = Action<'REQUEST_FOR_STREAM'> & {
  rfsId: string;
  retryCount: number;
};
export const requestForStreamAction = (rfsId: string, retryCount = 0): RequestForStreamAction => ({
  type: 'REQUEST_FOR_STREAM',
  rfsId,
  retryCount,
});

export type StreamRequestedAction = Action<'STREAM_REQUESTED'> & {
  rfsId: string;
  product: ProductName;
  retryCount: number;
};
export const streamRequestedAction = (rfsId: string, product: ProductName, retryCount = 0): StreamRequestedAction => ({
  type: 'STREAM_REQUESTED',
  rfsId,
  product,
  retryCount,
});

export type ExecuteAction = Action<'EXECUTE'>;
export const executeAction = (): ExecuteAction => ({ type: 'EXECUTE' });

export type ExecutedAction = Action<'EXECUTED'>;
export const executedAction = (): ExecutedAction => ({ type: 'EXECUTED' });

export type BackOfficeValidatedAction = Action<'BACK_OFFICE_VALIDATED'> & {
  tradeId: string;
};
export const backOfficeValidatedAction = (tradeId: string): BackOfficeValidatedAction => ({
  type: 'BACK_OFFICE_VALIDATED',
  tradeId,
});

export type ResetAction = Action<'RESET'>;
export const resetAction = (): ResetAction => ({ type: 'RESET' });

export type StopStreamAction = Action<'STOP_STREAM'>;
export const stopStreamAction = (): StopStreamAction => ({ type: 'STOP_STREAM' });

export type SpotDateReceivedAction = Action<'SPOT_DATE_RECEIVED'> & {
  date: Date;
};
export const spotDateReceivedAction = (date: Date): SpotDateReceivedAction => ({
  type: 'SPOT_DATE_RECEIVED',
  date,
});

export type SpotDateRequestedAction = Action<'SPOT_DATE_REQUESTED'>;
export const spotDateRequestedAction = (): SpotDateRequestedAction => ({
  type: 'SPOT_DATE_REQUESTED',
});

export type StreamStoppedAction = Action<'STREAM_STOPPED'> & { rfsId: string };
export const streamStoppedAction = (rfsId: string): StreamStoppedAction => ({
  type: 'STREAM_STOPPED',
  rfsId,
});

export type ExecutionSentAction = Action<'EXECUTION_SENT'>;
export const executionSentAction = (): ExecutionSentAction => ({ type: 'EXECUTION_SENT' });

export type ExecutionFailedAction = Action<'EXECUTION_FAILED'>;
export const executionFailedAction = (): ExecutionFailedAction => ({ type: 'EXECUTION_FAILED' });

export type ExecutionTimeoutAction = Action<'EXECUTION_TIMEOUT'>;
export const executionTimeoutAction = (): ExecutionTimeoutAction => ({ type: 'EXECUTION_TIMEOUT' });

export type TimeoutedDealAction = Action<'TIMEOUTED_DEAL'>;
export const timeoutedDealAction = (): TimeoutedDealAction => ({ type: 'TIMEOUTED_DEAL' });

export type QuotingTimeoutAction = Action<'QUOTING_TIMEOUT'>;
export const quotingTimeoutAction = (): QuotingTimeoutAction => ({ type: 'QUOTING_TIMEOUT' });
