/* eslint-disable no-param-reassign */
import { AccumulatorTrade } from '@/models/trade';
import { CalendarTableHeader } from './CalendarTableHeader';
import { CalendarTableRow } from './CalendarTableRow';
import {
  DecoratedFields,
  FxoPauseTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
  FxTargetAccumulatorCalendarFields,
} from '@/models/calendar';
import { isDefined } from '@sgme/fp';

interface CalendarTableProps {
  trade: AccumulatorTrade;
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[];
  editingRowsId?: number[];
}

export function CalendarTable({ trade, computedFields, editingRowsId }: CalendarTableProps) {
  const accumulatorType =
    // eslint-disable-next-line no-nested-ternary
    trade.instrumentName === 'FxoForwardAccumulator' ?
      trade.accumulatorType === 'permanent' ?
        'permanent'
      : 'standard'
    : undefined;
  // we only need the first element to compute the columns
  const columns = Object.keys(computedFields[0]);
  return (
    <div className="border DetailedList mt-1 p-0 ">
      <div className="table-responsive-sm">
        <table
          className="table mb-0 "
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0',
          }}
        >
          <thead
            className="table-header bg-lvl1 sticky-top"
            style={{
              top: trade.instrumentName === 'FxoSquareTargetAccumulator' || trade.instrumentName === 'FxoTargetAccumulator' ? '125px' : '105px',
            }}
          >
            <CalendarTableHeader columns={columns} accumulatorType={accumulatorType} instrumentName={trade.instrumentName} />
          </thead>
          <tbody className="table-body">
            {computedFields?.map((rowFields, rowIndex) => (
              <CalendarTableRow
                // the order is guaranteed and there is no action which alter the order of the array
                // eslint-disable-next-line react/no-array-index-key
                key={rowIndex}
                row={rowFields}
                rowIndex={rowIndex}
                editingRowsId={editingRowsId}
                isCumulatedAmountNull={!isDefined(rowFields.cumulatedAmount.value)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
