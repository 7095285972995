import { Action, Middleware } from '@reduxjs/toolkit';
import { Piwik } from '@/utils/piwik';
import { Company } from '@/models/user';
import { AppState } from '@/store/app.state';
import { AppAction } from '@/store/app.actions';
import { AppDispatch } from '@/store/store';

function formatCompany(company: Company | undefined) {
  if (company !== undefined) {
    return `${company.companyBdrId} - ${company.companyName}`;
  }
  return '';
}

export const piwikMiddleware: Middleware<{}, AppState, AppDispatch> = (api) => (next) => (currentAction) => {
  const state = api.getState();
  const action = currentAction as AppAction;

  switch (action.type) {
    case 'LOCATION_CHANGE':
      Piwik.trackAction('Opened page');
      break;
    case 'USER_LOADED':
      Piwik.formattedCompany = formatCompany(action.user.companies[0]);
      break;

    case 'CHANGE_COMPANY':
      Piwik.trackAction('Changed counterparty');
      // Piwik.trackEvent({
      //   category: piwikPages[state.router.location.pathname],
      //   action: 'Changed counterparty',
      //   name: formatCompany(state.user.selectedCompany) + ' -> ' + formatCompany(action.company),
      // });
      Piwik.formattedCompany = formatCompany(action.company);
      break;

    case 'STREAM_REQUESTED':
      Piwik.trackRfq('RFQs', 'Started RFQ');
      break;

    case 'EXECUTE':
      Piwik.trackAction('Triggered execution');
      break;

    case 'EXECUTED':
      if (state.rfs.status !== 'idle' && state.rfs.status !== 'starting-request' && state.rfs.status !== 'cancelling') {
        if (state.rfs.product === 'FxPredeliver') {
          Piwik.trackRfq('Execution success', 'Executed predeliver on forward');
        } else if (state.rfs.product === 'FxSpot') {
          Piwik.trackRfq('Execution success', 'Executed spot');
        } else if (state.rfs.product === 'FxFwd') {
          Piwik.trackRfq('Execution success', 'Executed forward');
        }
      }
      break;

    case 'QUOTING_TIMEOUT':
      Piwik.trackRfq('Execution errors', 'Reached RFQ timeout');
      if (state.rfs.status !== 'idle' && state.rfs.status !== 'starting-request' && state.rfs.status !== 'cancelling') {
        if (state.rfs.product === 'FxPredeliver') {
          Piwik.trackRfq('Execution abandoned', 'Abandoned predeliver on forward');
        } else if (state.rfs.product === 'FxSpot') {
          Piwik.trackRfq('Execution abandoned', 'Abandoned spot');
        } else if (state.rfs.product === 'FxFwd') {
          Piwik.trackRfq('Execution abandoned', 'Abandoned forward');
        }
      }
      break;

    case 'ADD_ERROR':
      if (state.rfs.status === 'quoting' || state.rfs.status === 'requesting') {
        const { error } = action;
        if (error.code.includes('OVERSHOT')) {
          Piwik.trackRfq('Execution errors', 'Reached fat finger limit');
        } else if (error.code.includes('CREDIT_CHECK')) {
          if (error.creditCheckType === 'NOLIMIT') {
            Piwik.trackRfq('Execution errors', 'Has been refused to trade bc no CVAR limit defined');
          }
          if (error.creditCheckType === 'CVAR') {
            Piwik.trackRfq('Execution errors', 'Reached CVAR limit');
          }
          if (error.creditCheckType === 'RDL') {
            Piwik.trackRfq('Execution errors', 'Reached RDL limit');
          }
        } else if (error.code.includes('CUTOFF')) {
          Piwik.trackRfq('Execution errors', 'Reached cut-off');
        } else {
          Piwik.trackRfq('Execution errors', 'Faced an unknown error when triggered execution');
        }
      }
      break;

    case 'FORM_CHANGE':
      if (action.formChange.buyCurrency && action.formChange.sellCurrency) {
        Piwik.trackAction('Switched currencies');
      } else if (action.formChange.buyCurrency && action.formChange.sellCurrency === undefined) {
        Piwik.trackAction('Changed buy currency');
      } else if (action.formChange.buyCurrency === undefined && action.formChange.sellCurrency) {
        Piwik.trackAction('Changed sell currency');
      } else if (action.formChange.buyAmount) {
        Piwik.trackAction('Changed buy amount');
      } else if (action.formChange.sellAmount) {
        Piwik.trackAction('Changed sell amount');
      } else if (action.formChange.date) {
        Piwik.trackAction('Changed value date');
      }

      break;

    default:
      break;
  }
  return next(action);
};
