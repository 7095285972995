import { actionNotifier, actionCreatorTypeExtractor } from './logActionMiddlewares/notifyActionMiddleware';
import { createAppCrashedAction, AppCrashedAction } from '@/store/state/appCrash/appCrash.actions';
import { sendHelpRequest } from '@/services/helpRequest.service';
import { http } from '@/utils/http';
import { sgwtConnect } from '@/sgwtConnect';
import { cleanUpSensitiveInformations } from '@/utils/cleanUpSensitiveInformations';
import { logger } from '@/logging/logger';
import { AppStore } from '@/store/app.state';

export const autosendHelpRequestMiddleware = (isDev: boolean, getStore: () => AppStore) => {
  const actionsList = isDev ? [] : [actionCreatorTypeExtractor(createAppCrashedAction)];

  return actionNotifier({
    didMatch: (err: AppCrashedAction) => {
      const { error } = err;
      logger.logDebug('request sent');
      sendHelpRequest(
        http,
        sgwtConnect,
      )({
        application: 'SGME-MY-FX',
        config: 'appcrashed',
        details: `Automatic help request sent after APP_CRASHED${typeof error === 'object' && 'message' in error && error.message !== undefined ? ` (${error.message})` : ''}${
          typeof error === 'object' && 'stack' in error && error.stack !== undefined ? `:\n${error.stack}` : ''
        }`,
        lastActions: JSON.stringify(getStore().getLastActions(), cleanUpSensitiveInformations),
        state: JSON.stringify(getStore().getState()),
        sendConfirmation: false,
      }).subscribe();
    },
    actionsList,
  });
};
