import React from 'react';
import { add, isSameDay } from 'date-fns';

import './Timeline.scss';
import { ExtendedFlow } from '@/store/state/upcomingFlows/upcomingFlows.models';
import { getNextDay } from '@/store/state/rfs/rfs.thunks';
import DateLineItemInfos from './DateLineItemInfos';
import DateLineBox from './DateLineBox';

const DEFAULT_DAY_COUNT = 9;

export type TimelineProps = {
  data: ExtendedFlow[];
  onDateSelected?: (date: Date[]) => void;
  value?: Date[];
};

export function DateLineItem({
  data,
  onDateSelected,
  value,
  currentDate,
}: {
  data: ExtendedFlow[];
  onDateSelected: (date: Date) => void;
  currentDate: Date;
  value?: boolean;
}) {
  const [selected, setSelected] = React.useState<boolean>(value ?? false);

  React.useEffect(() => {
    setSelected(value ?? false);
  }, [value]);

  const flowsAtThisDate = data.filter(date =>
    isSameDay(new Date(date.flow.paymentDate), currentDate),
  );
  const uncertain = flowsAtThisDate.reduce(
    (acc, flows) => acc + (flows.flow.legs[0].isCertain || flows.flow.legs[1].isCertain ? 0 : 1),
    0,
  );
  const inactive = flowsAtThisDate.length + uncertain === 0;

  const onClickHandler = () => {
    if (!inactive) {
      setSelected(!selected);
      onDateSelected?.(currentDate);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClickHandler}
      className={`d-flex flex-grow-1 flex-column`}
    >
      <DateLineBox currentDate={currentDate} inactive={inactive} selected={selected} />

      <span
        className={`${selected ? 'selected' : ''}`}
        style={{ padding: '0.5rem', paddingTop: '1.0rem' }}
      />

      <DateLineItemInfos
        selected={selected}
        uncertain={uncertain}
        flowsAtThisDate={flowsAtThisDate}
      />
    </div>
  );
}

export function Timeline({ data, onDateSelected, value }: TimelineProps) {
  const [selected, setSelected] = React.useState<Date[]>(value ?? []);
  // TODO JLE: Extract function
  const dates = Array.from(Array(DEFAULT_DAY_COUNT - 1)).reduce(
    (p: Date[]) => {
      p.push(getNextDay(p[p.length - 1], []));

      return p;
    },
    [add(Date.now(), { days: 0 })],
  );

  React.useEffect(() => {
    setSelected(value ?? []);
  }, [value]);

  return (
    <div>
      <ul className="timeline">
        {dates.map(currentDate => (
          <DateLineItem
            currentDate={currentDate}
            value={selected.findIndex(d => isSameDay(d, currentDate)) !== -1}
            key={currentDate.toISOString()}
            data={data}
            onDateSelected={date => {
              const idx = selected.findIndex(d => isSameDay(d, date));
              if (idx !== -1) {
                const newSelected = [...selected];
                newSelected.splice(idx, 1);
                setSelected(newSelected);
                onDateSelected?.(newSelected);
              } else {
                const newSelected = [...selected, date];
                setSelected(newSelected);
                onDateSelected?.(newSelected);
              }
            }}
          />
        ))}
      </ul>
    </div>
  );
}
