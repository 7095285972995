import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import styled from 'styled-components';
import './CrashModal.scss';
import { UncontrolledCollapsable } from './Collapsable';
import { noop } from '@/utils/noop';
import { AppState } from '@/store/app.state';
import { logger } from '@/logging/logger';
import { HelpButton, SendHelpStatus } from './HelpButton';

interface CrashModalProps {
  error: string | null;
}

interface CrashModalInternalState {
  stackTrace: string | null;
  userDetails: string;
}

const reload = () => window.location.reload();

const TechnicalDetails = styled.pre`
  min-height: 100px;
  max-height: 500px;
  overflow: auto;
`;

class CrashModalRaw extends React.Component<CrashModalProps & InjectedIntlProps, CrashModalInternalState> {
  public state = {
    userDetails: '',
    stackTrace: null,
  };

  public componentDidMount() {
    const { error } = this.props;
    if (error === null) {
      return;
    }
    try {
      mapStackTrace(error, (stack) => {
        const stackTrace = stack.reduce((acc, curr) => `${acc}\n${curr}`, '');
        this.setState(() => ({
          stackTrace,
        }));
      });
    } catch (e) {
      logger.logError('Unable to translate stack trace ', e);
    }
  }

  private setUserDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ userDetails: event.currentTarget.value });

  private details = () => {
    const { userDetails } = this.state;
    const { error } = this.props;
    return userDetails !== '' ? `${userDetails}\n<b>Technical error:</b> ${error}` : null;
  };

  private sendHelpRequestCallback = (status: SendHelpStatus) => {
    if (status === 'success') {
      reload();
    }
  };

  public render() {
    const { error, intl } = this.props;
    const { stackTrace, userDetails } = this.state;
    if (error === null) {
      return null;
    }
    return (
      <Modal isOpen toggle={noop} className="CrashModal">
        <ModalHeader tag="h4" className="text-danger">
          <FormattedMessage id="app.crash.title" />
        </ModalHeader>

        <ModalBody>
          <FormattedMessage tagName="p" id="app.crash.body" />
          <textarea
            className="form-control w-100"
            rows={4}
            placeholder={intl.formatMessage({
              id: 'app.crash.userDetailsPlaceholder',
            })}
            onChange={this.setUserDetails}
            value={userDetails}
          />
          <UncontrolledCollapsable title={intl.formatMessage({ id: 'app.crash.moreDetails' })} collapsed>
            <TechnicalDetails className="border bg-lvl2 text-primary mt-3 p-3 text-start">
              <code>
                {stackTrace === null
                  ? error.startsWith('{') // Potentially json object
                    ? JSON.stringify(JSON.parse(error), null, 4)
                    : error
                  : `${error.substring(0, error.indexOf('\n'))}\n${stackTrace}`}
              </code>
            </TechnicalDetails>
          </UncontrolledCollapsable>
        </ModalBody>

        <ModalFooter>
          {userDetails !== '' ? (
            <HelpButton labelId="app.crash.sendAndReload" details={this.details} statusUpdateCallBack={this.sendHelpRequestCallback} />
          ) : (
            <button type="button" className="btn btn-primary" onClick={reload}>
              <FormattedMessage id="app.crash.reload" />
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState): CrashModalProps => {
  return {
    error: state.appCrash.error,
  };
};

export const CrashModal = connect(mapStateToProps)(injectIntl(CrashModalRaw));
