import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { HighlightedRate } from '@/share/HighlightedRate/HighlightedRate';
import { getFormattedPredeliverRate, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';
import { Blinker } from '@/App/Home/Notifications';

const content = ({ status, predeliverRate }: { status: 'Idle' | 'Received' | 'Waiting'; predeliverRate?: string }) => {
  switch (status) {
    case 'Idle':
      return <div className="display-4 tabular-figures">-</div>;
    case 'Received':
      return (
        <div className="display-4 tabular-figures">
          <HighlightedRate value={predeliverRate!} size={3} offset={1} />
        </div>
      );
    case 'Waiting':
      return <Blinker />;
    default: {
      const exhaustiveCheck: never = status;
      return exhaustiveCheck;
    }
  }
};

export function PredeliverRate() {
  const rfs = useSelector(selectRfs);
  const locale = useSelector(selectCurrentLocale);
  let status: 'Idle' | 'Received' | 'Waiting' = 'Received';

  if (rfs.status === 'requesting') {
    status = 'Waiting';
  }
  const predeliverRate = getFormattedPredeliverRate(locale)(rfs);
  if (predeliverRate === undefined) {
    status = 'Idle';
  }

  return (
    <div className="pt-5 form-group mb-0">
      <label>
        <FormattedMessage id="trader.predeliverRate.predeliverRate" />
      </label>
      <div data-e2e="predeliver-rate">{content({ status, predeliverRate })}</div>
    </div>
  );
}
