import { Action } from '@reduxjs/toolkit';
import { AppError } from '@/models/AppError';

export type ErrorAction = AddErrorAction | CloseErrorAction | CloseLastErrorAction;

export interface AddErrorAction extends Action {
  type: 'ADD_ERROR';
  error: AppError;
}
export const addErrorAction = (error: AppError): AddErrorAction => ({
  type: 'ADD_ERROR',
  error,
});

interface CloseErrorAction extends Action {
  type: 'CLOSE_ERROR';
  errorIndex: number;
}
export const closeErrorAction = (errorIndex: number): CloseErrorAction => ({
  type: 'CLOSE_ERROR',
  errorIndex,
});

export interface CloseLastErrorAction extends Action {
  type: 'CLOSE_LAST_ERROR';
}
export const closeLastErrorAction = (): CloseLastErrorAction => ({
  type: 'CLOSE_LAST_ERROR',
});
