import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';
import GenericErrorModal from '@/App/Trader/components/TraderModals/GenericErrorModal/GenericErrorModal';

function ModalBody() {
  return (
    <div>
      <div className="text-secondary mb-3">
        <FormattedMessage
          id="trader.executionTimeoutModal.description"
          values={{
            // we're at the lowest level of the tree - impact is negligible vs visibility
            // eslint-disable-next-line react/no-unstable-nested-components
            bold: chunks => <strong>{chunks}</strong>,
          }}
        />
      </div>
      <div className="text-secondary mb-3">
        <FormattedMessage id="trader.executionTimeoutModal.description2" />
      </div>
    </div>
  );
}

export function TimeoutedDealErrorModal() {
  const rfs = useSelector(selectRfs);
  const isOpen = rfs.status === 'execution-timeout';


  return (
    <GenericErrorModal
      isOpen={isOpen}
      modalTitleKey="trader.executionTimeoutModal.title"
      dataE2E="execution-timeout-modal"
      modalBody={<ModalBody />}
    />
  );
}
