import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';

function TimeLineHeader() {
  return (
    <div className="d-flex justify-content-between mt-4">
      <p className="mb-3 mt-3">
        <FormattedMessage id="app.portfolio.upcomingFlows.detailed.CAPTION" />
      </p>
      <p className="mb-3 mt-2 text-info">
        <FormattedMessage id="app.portfolio.upcomingFlows.info" />
        <span>
          <i className="icon icon-xs text-info ms-2" id="info">
            info_outline
          </i>
        </span>
      </p>
      <UncontrolledTooltip placement="top" target="info">
        <div style={{ maxWidth: '200px' }}>
          <FormattedMessage id={`app.portfolio.upcomingFlows.info2`} />
        </div>
      </UncontrolledTooltip>
    </div>
  );
}

export default TimeLineHeader;
