import BigInteger from 'big-integer';

export function uuidToBase36(uuid: string) {
  const cleanUuid = uuid.replace(/-/g, '');
  const encoding = '0123456789abcdefghijklmnopqrstuvwxyz';
  const encodingLength = encoding.length;

  let iUuid = BigInteger(cleanUuid, 16);
  let str = '';

  do {
    str = encoding.substr(iUuid.mod(encodingLength).valueOf(), 1) + str;
    iUuid = iUuid.divide(encodingLength);
  } while (iUuid.greater(0));

  return str;
}
