import { FormattedMessage } from 'react-intl';
import { MainLayout } from '../Layout/MainLayout';
import { Blinker } from '../Home/Notifications';

export function FetchingData() {
  return (
    <MainLayout>
      <Blinker />
    </MainLayout>
  );
}
