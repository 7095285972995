interface PaginatorProps {
  currentPage: number;
  onGoToPage: (page: number) => void;
  nbPages: number
}

function Previous({ onGoToPage, currentPage }: Omit<PaginatorProps, "nbPages">) {
  const disabled = currentPage === 0
  const firstPage = () => !disabled && onGoToPage(0)
  const previous = () => !disabled && onGoToPage(currentPage - 1);

  return (<>
    <div
      role="button"
      tabIndex={0}
      className={`page-item ${disabled ? 'disabled' : ''}`}
      onClick={firstPage}
    >
      <span className="page-link" aria-label="First page">
        <span aria-hidden="true"><i className="icon icon-xs">first_page</i></span>
      </span>
    </div>
    <div
      role="button"
      tabIndex={0}
      className={`page-item me-1 ${disabled ? 'disabled' : ''}`}
      onClick={previous}
    >
      <span className="page-link" aria-label="Previous">
        <span aria-hidden="true"><i className="icon icon-xs">keyboard_arrow_left</i></span>
      </span>
    </div>
  </>);
}

function Next({ onGoToPage, currentPage, nbPages }: PaginatorProps) {
  const disabled = currentPage === nbPages - 1
  const lastPage = () => !disabled && onGoToPage(nbPages - 1)
  const next = () => !disabled && onGoToPage(currentPage + 1);
  return (<>
    <div
      role="button"
      tabIndex={0}
      className={`page-item ms-1 ${disabled ? 'disabled' : ''}`}
      onClick={next}
    >
      <span className="page-link" aria-label="Next">
        <span aria-hidden="true"><i className="icon icon-xs">keyboard_arrow_right</i></span>
      </span>
    </div>
    <div
      role="button"
      tabIndex={0}
      className={`page-item ${disabled ? 'disabled' : ''}`}
      onClick={lastPage}
    >
      <span className="page-link" aria-label="Last page">
        <span aria-hidden="true"><i className="icon icon-xs">last_page</i></span>
      </span>
    </div>
  </>);
}

function Page({
  pageIndex,
  currentPage,
  onGoToPage,
}: Omit<PaginatorProps, "nbPages"> & { pageIndex: number }) {
  const changePage = () => onGoToPage(pageIndex)
  return <div
    role="button"
    tabIndex={0}
    className={`page-item ${currentPage === pageIndex ? 'active' : ''}`}
    id={pageIndex.toString()}
    onClick={changePage}
  >
    <span className="page-link">{pageIndex + 1}</span>
  </div>
}

function Dots() {
  return <div className="page-item">
    <span className="page-link">
      <span aria-hidden="true">...</span>
    </span>
  </div>
}

export function Paginator({ onGoToPage, currentPage, nbPages, className }: PaginatorProps & { className?: string }) {
  const pages = Array(nbPages)
    .fill(0)
    .map((_, i) => i);

  const visiblePages =
    pages.length > 5
      ? pages.slice(
        Math.max(1, currentPage - 1),
        Math.min(pages.length - 1, currentPage + 2),
      )
      : pages.slice(1, pages.length - 1);

  return nbPages > 1 ? (
    <nav aria-label="navigation" className={className}>
      <div className="pagination">
        <Previous {...{ onGoToPage, currentPage, nbPages }} />
        <Page {...{ onGoToPage, currentPage }} pageIndex={0} />
        {currentPage > 2 && pages.length > 5 && <Dots />}
        {visiblePages.map(p => (
          <Page key={p} {...{ onGoToPage, currentPage }} pageIndex={p} />
        ))}
        {currentPage < pages.length - 3 && pages.length > 5 && <Dots />}
        <Page {...{ onGoToPage, currentPage }} pageIndex={pages.length - 1} />
        <Next {...{ onGoToPage, currentPage, nbPages }} />
      </div>
    </nav>
  ) : null;
}
