export type Way = 'buy' | 'sell';
export type MasterAmount = 'buyAmount' | 'sellAmount';
export type SgWay = 'Buy' | 'Sell' | 'Both';
export type ExecWay = 'Bid' | 'Ask';

export function oppositeWay(way: Way) {
  switch (way) {
    case 'buy':
      return 'sell';
    case 'sell':
      return 'buy';
    default: {
      const exhaustiveCheck: never = way;
      return exhaustiveCheck;
    }
  }
}

export function oppositeMasterAmount(way: MasterAmount): MasterAmount {
  switch (way) {
    case 'buyAmount':
      return 'sellAmount';
    case 'sellAmount':
      return 'buyAmount';
    default: {
      const exhaustiveCheck: never = way;
      return exhaustiveCheck;
    }
  }
}

export function wayToMasterAmount(way: Way): MasterAmount {
  switch (way) {
    case 'buy':
      return 'buyAmount';
    case 'sell':
      return 'sellAmount';
    default: {
      const exhaustiveCheck: never = way;
      return exhaustiveCheck;
    }
  }
}

export function wayFromMasterAmount(way: MasterAmount): Way {
  switch (way) {
    case 'buyAmount':
      return 'buy';
    case 'sellAmount':
      return 'sell';
    default: {
      const exhaustiveCheck: never = way;
      return exhaustiveCheck;
    }
  }
}
