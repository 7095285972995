import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { resetAction } from '@/store/state/rfs/rfs.actions';
import {
  getFormattedForwardPoints,
  getFormattedForwardRate,
  getFormattedHistoricalDealOffRate,
  getFormattedPredeliverPoints,
  getFormattedPredeliverRate,
  getFormattedRolloverPoints,
  getFormattedRolloverRate,
  getFormattedSpotRate,
  selectRfs,
} from '@/store/state/rfs/rfs.selectors';
import { DATE_FULL_FORMAT, useFormattingContext } from '@/utils/format';
import { Product, ProductDetails } from './ProductDetails';
import './SuccessTraderModal.scss';
import { formatDateLocalized, Locale } from '@/utils/locale';
import { Rfs } from '@/models/rfs';
import { toUtc } from '@/utils/dates';
import { LeftSide, RightSide } from './Shared';
import { routes } from '@/App/routes';
import { selectPredeliverTrade } from '@/store/state/predeliver/predeliver.selectors';
import { selectRolloverTrade } from '@/store/state/rollover/rollover.selectors';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '@/store/store';
import { isDefined } from '@sgme/fp';

const mapProduct = (rfs: Rfs, locale: Locale): Product | undefined => {
  if (rfs.status !== 'executed' && rfs.status !== 'backOfficeValidated') {
    return undefined;
  }
  const spotRate = getFormattedSpotRate(locale)(rfs) || '';
  switch (rfs.product) {
    case 'FxSpot':
      return { name: 'FxSpot', spotRate };
    case 'FxFwd':
      return {
        name: 'FxFwd',
        spotRate,
        forwardPoints: getFormattedForwardPoints(locale)(rfs) || '',
        forwardRate: getFormattedForwardRate(locale)(rfs) || '',
      };
    case 'FxPredeliver':
      return {
        name: 'FxPredeliver',
        spotRate: getFormattedHistoricalDealOffRate(locale)(rfs) || '',
        predeliverPoints: getFormattedPredeliverPoints(locale)(rfs) || '',
        predeliverRate: getFormattedPredeliverRate(locale)(rfs) || '',
      };
    case 'FxRollover':
      return {
        name: 'FxRollover',
        spotRate,
        rolloverPoints: getFormattedRolloverPoints(locale)(rfs) || '',
        rolloverRate: getFormattedRolloverRate(locale)(rfs) || '',
      };
    default: {
      const exhaustiveCheck: never = rfs;
      return exhaustiveCheck;
    }
  }
};

export function SuccessTraderModal() {
  const { mapTrade, mapTradeWithOptions, formatAmount, formatAmountWithSign } = useFormattingContext();
  const locale = useSelector(selectCurrentLocale);

  const rfs = useSelector(selectRfs);
  const predeliverTrade = useSelector(selectPredeliverTrade);
  const rolloverTrade = useSelector(selectRolloverTrade);

  const isOpen = rfs.status === 'executed' || rfs.status === 'backOfficeValidated';
  const tradeId = rfs.status === 'backOfficeValidated' ? rfs.tradeId : undefined;
  const buyAmount = formatAmount(rfs.buyAmount);
  const { buyCurrency, sellCurrency } = rfs;
  const sellAmount = formatAmount(rfs.sellAmount);
  const date = isDefined(rfs.date) ? formatDateLocalized(toUtc(new Date(rfs.date)), DATE_FULL_FORMAT) : null;
  const product = mapProduct(rfs, locale);
  const predeliver =
    predeliverTrade ?
      {
        ...mapTradeWithOptions(predeliverTrade, { fullDateFormat: true }),
        remainingAmount: formatAmountWithSign((predeliverTrade.remainingAmount ?? 0) - (predeliverTrade.side === 'buy' ? rfs.buyAmount : rfs.sellAmount)),
      }
    : undefined;
  const rollover = rolloverTrade ? mapTrade(rolloverTrade) : undefined;

  const isPredeliverOrRollover = !!predeliver || !!rollover;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClose = (isPredeliver: boolean) => {
    dispatch(resetAction());
    if (isPredeliver) {
      navigate(routes.PORTFOLIO);
    }
  };

  return (
    <Modal isOpen={isOpen} className="SuccessTraderModal">
      <div className="pb-3 flex-column modal-header">
        <h5 className="modal-title display-3">
          <i className="icon icon-xs">check</i>
          <span>
            <FormattedMessage id="trader.successModal.title" />
          </span>
        </h5>

        {predeliver && (
          <div className="p-0 text-secondary fw-semibold">
            <FormattedMessage id="trader.successModal.predeliverInfo.reference" /> {predeliver.reference}
          </div>
        )}

        {rollover && (
          <div className="p-0 text-secondary fw-semibold">
            <FormattedMessage id="trader.successModal.rolloverInfo.reference" /> {rollover.reference}
          </div>
        )}
      </div>

      <ModalBody>
        <LeftSide>
          <FormattedMessage id="trader.successModal.operation" />
        </LeftSide>

        <RightSide>
          <FormattedMessage id="trader.successModal.buy" />
          &nbsp;
          <span data-e2e="buy-amount">{buyAmount}</span>&nbsp;
          <span data-e2e="buy-currency">{buyCurrency}</span>
        </RightSide>

        <LeftSide>
          <FormattedMessage id="trader.successModal.contramount" />
        </LeftSide>

        <RightSide>
          <FormattedMessage id="trader.successModal.sell" />
          &nbsp;
          <span data-e2e="sell-amount">{sellAmount}</span>&nbsp;
          <span data-e2e="sell-currency"> {sellCurrency}</span>
        </RightSide>

        {product && date && <ProductDetails date={date} product={product} />}

        <LeftSide withBorder={false}>
          <FormattedMessage id="trader.successModal.reference" />
        </LeftSide>

        <RightSide withBorder={false}>{tradeId ?? <span className="loading-blink">--</span>}</RightSide>
        {predeliver ?
          <div className="col-12 p-0 pt-4 mt-2">
            <FormattedMessage id="trader.successModal.predeliverInfo.remainingAmount" />
            {`${predeliver.remainingAmount} ${predeliver.amountCurrency}`}
            <FormattedMessage id="trader.successModal.predeliverInfo.maturityDate" />
            {predeliver.maturitydate}
          </div>
        : null}
      </ModalBody>

      <ModalFooter className="text-end">
        <button type="button" className="btn btn-lg btn-default" onClick={() => onClose(isPredeliverOrRollover)}>
          {isPredeliverOrRollover ?
            <FormattedMessage id="trader.successModal.goToOperations" />
          : <FormattedMessage id="trader.successModal.newOperation" />}
        </button>
      </ModalFooter>
    </Modal>
  );
}
