import { useSelector } from 'react-redux';
import { ForwardRate } from './ForwardRate';
import { RolloverRate } from './RolloverRate';
import { SpotRate } from './SpotRate';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';

export function TradeRate() {
  const rfs = useSelector(selectRfs);
  const product = 'product' in rfs ? rfs.product : 'FxSpot';

  return (
    <div className="rate col-md-5 offset-md-2 row ">
      {product === 'FxSpot' && <SpotRate />}
      {product === 'FxFwd' && <ForwardRate />}
      {product === 'FxRollover' && <RolloverRate />}
    </div>
  );
}
