export type RouteValues =
  | '/'
  | '/buy-sell'
  | '/contact'
  | '/portfolio'
  | '/upcomingFlows'
  | '/calendar'
  | '/predeliver'
  | '/rollover'
  | '/'
  | '/alerts'
  | '/create-alert'
  | '/update-alert';

export type RouteNames =
  | 'HOME'
  | 'TRADE'
  | 'CONTACT'
  | 'PORTFOLIO'
  | 'UPCOMING_FLOWS'
  | 'CALENDAR'
  | 'USE_ENVELOPPE'
  | 'REPORT_ENVELOPPE'
  | 'ALERTS'
  | 'CREATE_ALERT'
  | 'UPDATE_ALERT';

export const routes: { [x in RouteNames]: RouteValues } = {
  HOME: '/',
  TRADE: '/buy-sell',
  CONTACT: '/contact',
  PORTFOLIO: '/portfolio',
  UPCOMING_FLOWS: '/upcomingFlows',
  CALENDAR: '/calendar',
  USE_ENVELOPPE: '/predeliver',
  REPORT_ENVELOPPE: '/rollover',
  ALERTS: '/alerts',
  CREATE_ALERT: '/create-alert',
  UPDATE_ALERT: '/update-alert',
};

const previousRoutes: { [x in RouteNames]?: RouteNames } = {
  CREATE_ALERT: 'ALERTS',
  UPDATE_ALERT: 'ALERTS',
  USE_ENVELOPPE: 'PORTFOLIO',
  REPORT_ENVELOPPE: 'PORTFOLIO',
};
export const getPreviousRoute = (x: RouteNames): RouteNames => previousRoutes[x] || 'HOME';

export const getRouteName = (router: { location: { pathname: string } }) =>
  (Object.entries(routes).find(([name, value]) => (value === router.location.pathname ? name : undefined))?.[0] as RouteNames) ?? undefined;

export type PiwikPageName =
  | 'Landing'
  | 'Buy-sell'
  | 'Hedge management'
  | 'Portfolio'
  | 'Upcoming Flows'
  | 'Calendar'
  | 'Contact'
  | 'New hedge'
  | 'Predeliver'
  | 'Rollover'
  | 'Alerts'
  | 'Create Alert'
  | 'Update Alert';

export const piwikPages: { [x in RouteValues]: PiwikPageName } = {
  '/': 'Landing',
  '/buy-sell': 'Buy-sell',
  '/portfolio': 'Portfolio',
  '/upcomingFlows': 'Upcoming Flows',
  '/calendar': 'Calendar',
  '/contact': 'Contact',
  '/predeliver': 'Predeliver',
  '/rollover': 'Rollover',
  '/alerts': 'Alerts',
  '/create-alert': 'Create Alert',
  '/update-alert': 'Update Alert',
};

// interface RouterLocation {
//   location: Pick<RouterState['location'], 'pathname'>;
// }

export const isHomeRoute = (pathname: string) => pathname === routes.HOME;

export const isBlotterRoute = (pathname: string) => pathname === routes.PORTFOLIO || pathname === routes.UPCOMING_FLOWS;

export const isSpotRoute = (pathname: string) => pathname === routes.TRADE;

export const isPredeliverRoute = (pathname: string) => pathname === routes.USE_ENVELOPPE;

export const isRolloverRoute = (pathname: string) => pathname === routes.REPORT_ENVELOPPE;

export const isCalendarRoute = (pathname: string) => pathname === routes.CALENDAR;

export const isRfsPage = (pathname: string) => isSpotRoute(pathname) || isPredeliverRoute(pathname) || isRolloverRoute(pathname);

export const isCreateAlertRoute = (pathname: string) => pathname === routes.CREATE_ALERT;

export const isUpdateFormRoute = (pathname: string) => pathname === routes.UPDATE_ALERT;

export const isAlertFormPage = (pathname: string) => isCreateAlertRoute(pathname) || isUpdateFormRoute(pathname);

export const isAlertFormPathname = (pathname: string) => pathname === routes.CREATE_ALERT || pathname === routes.UPDATE_ALERT;
