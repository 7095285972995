import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MyHedgeTrade } from '@/models/trade';

interface TodayOnlyCheckboxProps {
  value: boolean;
  currentTrades: MyHedgeTrade[]
  onChange?: (todayOnly: boolean) => void;
}

export function TodayOnlyCheckbox({ value, currentTrades, onChange }: TodayOnlyCheckboxProps) {
  const onTodayOnlyChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.checked);
    },
    [onChange],
  );

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="trades.toolbar.todayOnly.label" />
      </label>
      <div className="form-check" >
        <input
          className="form-check-input"
          type="checkbox"
          checked={value}
          onChange={onTodayOnlyChange}
          id="cccb1"
          data-e2e="today-only-checkBox"
        />
        <label className="form-check-label" htmlFor="cccb1">
          <FormattedMessage id="trades.toolbar.todayOnly.checkbox" />
        </label>
        <span
            className={`badge rounded-pill badge-discreet-${
              currentTrades.length ? 'info' : 'secondary'
            } ms-1`}
          >
            {currentTrades.length ?? 0}
          </span>
      </div>
    </div>
  );
}
