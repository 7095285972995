import { parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { DATE_LONG_FORMAT, useFormattingContext } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { isForwardAccu, isTargetAccu } from '@/utils/predicates';
import {
  CalendarFieldsState,
  DecoratedFields,
  FxTargetAccumulatorCalendarFields,
  FxoPauseTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
} from '@/models/calendar';
import { AccumulatorTrade } from '@/models/trade';
import { isDefined, isNotDefined } from '@sgme/fp';
import { computeWay } from '../utils/computeWay';
import { useRef } from 'react';

interface CalendarHeaderInfos2Props {
  trade: AccumulatorTrade;
  rows: CalendarFieldsState[];
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[];
}
export function CalendarHeaderInfos2({ trade, rows, computedFields }: CalendarHeaderInfos2Props) {
  const { mapTrade, formatAmountWithSign, formatAmountWithPrecision } = useFormattingContext();

  const getCumulatedAmount = (cumulatedAmountFromMyFx: number, cumulatedAmountFromMyHedge: string) => {
    const signOfAmount = formatAmountWithSign(cumulatedAmountFromMyFx ?? 0)[0];
    const formattedCumulatedAmountFromMyFx =
      computeWay(trade) === 'buy' ?
        `${signOfAmount}${formatAmountWithPrecision(cumulatedAmountFromMyFx ?? 0, 2)}`
      : `-${formatAmountWithPrecision(cumulatedAmountFromMyFx ?? 0, 2)}`;

    return (
      (
        cumulatedAmountFromMyHedge === '-' // we get '-' if the amount is null
      ) ?
        isNotDefined(cumulatedAmountFromMyFx) ? 0
        : formattedCumulatedAmountFromMyFx
      : cumulatedAmountFromMyHedge
    );
  };

  let computedFieldsRef = useRef(computedFields);

  const { amountCurrency, amount, remainingAmount } = mapTrade(trade);

  const nextPayDate = rows.find((row) => new Date(row.payDate).getTime() >= new Date().getTime())?.payDate;

  const cumulatedAmountSum = Number(
    Math.max(
      ...Object.values(computedFieldsRef.current).map(({ cumulatedAmount }) => {
        if (isDefined(cumulatedAmount.value)) {
          return cumulatedAmount.value as number;
        }
        return 0;
      }),
    ).toFixed(5),
  );

  const cumulatedAmountWithCurrency = `${getCumulatedAmount(cumulatedAmountSum, amount)} ${amountCurrency}`;

  const remainingAmountWithCurrency = `${remainingAmount} ${amountCurrency}`;

  const isForwardAccumulator = isForwardAccu(trade.instrumentName, trade);

  const CumulatedAmountWithCurrencyTile = () => {
    return (
      <div className="border-start px-3 me-3">
        <span className="text-secondary d-block mt-1 mb-1" data-e2e="accumulated-amount">
          <FormattedMessage
            id="app.calendar.amount"
            values={{
              type: isTargetAccu(trade) ? 'accumulatedAmount' : 'availableAmount',
            }}
          />
        </span>
        <h5>{cumulatedAmountWithCurrency}</h5>
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-start mb-4 ">
      {isForwardAccumulator && <CumulatedAmountWithCurrencyTile />}
      {nextPayDate && (
        <div className="border-start px-3 me-3">
          <span className="text-secondary d-block mt-1 mb-1">
            <FormattedMessage id="app.calendar.nextPayDate" />
          </span>
          <h5>{formatDateLocalized(parseISO(nextPayDate), DATE_LONG_FORMAT)}</h5>
        </div>
      )}
      <div className="border-start px-3 me-3">
        <span className="text-secondary d-block mt-1 mb-1" data-e2e="remaining-amount">
          <FormattedMessage id="app.calendar.remainingAmount" />
        </span>
        <h5>{remainingAmountWithCurrency}</h5>
      </div>
      {isTargetAccu(trade) && <CumulatedAmountWithCurrencyTile />}
    </div>
  );
}
