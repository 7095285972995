import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { HighlightedRate } from '@/share/HighlightedRate/HighlightedRate';
import { Spinner } from '@/share/Spinner/Spinner';
import { getFormattedSpotRate, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';

export function SpotRate() {
  const locale = useSelector(selectCurrentLocale);
  const rfs = useSelector(selectRfs);
  const waitingForPrice = rfs.status === 'requesting';
  const rate = getFormattedSpotRate(locale)(rfs);

  return (
    <div className="form-group col-6 p-md-0 ">
      <label className="text-secondary text-large mb-2">
        <FormattedMessage id="trader.spotRate.spotRate" />
      </label>
      <div className="display-4 tabular-figures fw-normal" data-e2e="spot-rate">
        {waitingForPrice ?
          <Spinner />
        : rate !== undefined && <HighlightedRate value={rate} size={3} />}
      </div>
    </div>
  );
}
