import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTradeCaptureStatus } from '@/store/state/tradeCapture/tradeCapture.selectors';
import GenericErrorModal from '@/App/Trader/components/TraderModals/GenericErrorModal/GenericErrorModal';

function ModalBody() {
  return (
    <>
      <p className="text-secondary">
        <FormattedMessage id="trader.tradeCaptureModal.description" />
      </p>
      <p>
        <FormattedMessage id="trader.tradeCaptureModal.description2" />
      </p>
    </>
  );
}

export function TradeCaptureModal() {
  const isOpen = useSelector(selectTradeCaptureStatus) === 'unreachable';

  return (
    <GenericErrorModal
      isOpen={isOpen}
      modalTitleKey="trader.tradeCaptureModal.title"
      modalBody={<ModalBody />}
    />
  );
}
