import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import { noop } from '@/utils/noop';
import { TradeActionType } from '@/models/trade';
import { isDefined } from '@sgme/fp';

type TradeButtonProps = {
  type: TradeActionType | 'calendar';
  reference: string;
  onClick?: (data: any) => void;
  disabledReason?: 'availableSoon' | 'fxExtBook' | 'expiring' | 'empty' | 'noRight';
  className?: string;
  twoDaysBeforePaymentDate?: string;
};

export function TradeButton({ disabledReason, type, reference, onClick, className, twoDaysBeforePaymentDate }: TradeButtonProps) {
  const id = `${type}-${reference}`;

  return (
    <>
      <div id={id} className="d-flex">
        <button
          data-e2e={id}
          type="button"
          className={`btn btn-default mx-0 ${disabledReason ? 'disabled' : ''} ${isDefined(className) ? className : ''}`}
          onClick={!disabledReason && onClick ? onClick : noop}
        >
          <FormattedMessage id={`trades.tradeRow.${type}`} />

          <i className="icon icon-xs ms-1" data-e2e={`button-${type}`}>
            arrow_forward
          </i>
        </button>
      </div>

      {disabledReason && (
        <UncontrolledTooltip placement="bottom" target={id}>
          <span data-e2e={`tooltip-${type}-${reference}`}>
            <FormattedMessage id={`trades.tradeRow.${disabledReason === 'expiring' && type ==="rollover"? 'rollover.' : ''}${disabledReason}`} values={{ date: twoDaysBeforePaymentDate }} />
          </span>
        </UncontrolledTooltip>
      )}
    </>
  );
}
