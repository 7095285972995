import { Reducer } from '@reduxjs/toolkit';

import { CreateAlertState } from './create-alert.model';
import { AppAction } from '@/store/app.actions';
import { getDefaultCurrencies } from '@/utils/currencies';
import { Currency } from '@/models/currency';

export const createAlertDefaultState: CreateAlertState = {
  id: undefined,
  data: {
    baseCurrency: 'USD',
    contraCurrency: 'EUR',
    expiryDate: null,
    limitPrice: '',
  },
  validation: {
    status: 'error',
    errors: {
      baseCurrency: [],
      contraCurrency: [],
      expiryDate: [],
      limitPrice: [],
    },
  },
  fixingExample: undefined,
  curently: 'Idle',
};

const computeUpdatedCurrencies = (allowedCurrencies: Currency[], ccy1: Currency, ccy2: Currency) => {
  const { buyCurrency: baseCurrency, sellCurrency: contraCurrency } = getDefaultCurrencies(allowedCurrencies, [ccy1, ccy2]);
  return {
    baseCurrency,
    contraCurrency,
  };
};

export const createAlertReducer: Reducer<CreateAlertState, AppAction> = (
  // eslint-disable-next-line default-param-last
  state: CreateAlertState = createAlertDefaultState,
  action: AppAction,
): CreateAlertState => {
  switch (action.type) {
    case 'USER_LOADED':
      return {
        ...state,
        data: {
          ...state.data,
          ...computeUpdatedCurrencies(action.user.companies[0].rfsCurrencies, state.data.baseCurrency, state.data.contraCurrency),
        },
      };
    case 'CHANGE_COMPANY':
      return {
        ...state,
        data: {
          ...state.data,
          ...computeUpdatedCurrencies(action.company.rfsCurrencies, state.data.baseCurrency, state.data.contraCurrency),
        },
      };
    case 'CreateAlertFormUpdated':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.patch,
        },
      };
    case 'CreateAlertValidationOk':
      return {
        ...state,
        validation: { status: 'ok' },
      };
    case 'CreateAlertValidationStarted':
      return {
        ...state,
        validation: { status: 'validating' },
      };

    case 'CreateAlertValidationError':
      return {
        ...state,
        validation: { status: 'error', errors: action.errors },
      };
    case 'CreateAlertSetFixingExample':
      return {
        ...state,
        fixingExample: action.fixingExample,
      };
    case 'UpdateAlertFormId':
      return {
        ...state,
        id: action.id,
      };
    case 'CreateAlert':
      return {
        ...state,
        curently: 'Creating',
      };
    case 'UpdateAlert':
      return {
        ...state,
        curently: 'Updating',
      };
    case 'DeleteAlert':
      return {
        ...state,
        curently: action.id === state.id ? 'Deleting' : state.curently,
      };
    case 'FinishedCreateAlert':
    case 'FinishedUpdateAlert':
      return {
        ...state,
        curently: 'Idle',
      };
    case 'FinishedDeleteAlert':
      return {
        ...state,
        curently: action.id === state.id ? 'Idle' : state.curently,
      };

    default:
      return state;
  }
};
