import { useFormattingContext } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';
import { isSameDay } from 'date-fns';

interface DateLineBoxProps {
  selected: boolean;
  inactive: boolean;
  currentDate: Date;
}

function DateLineBox({ selected, currentDate, inactive }: DateLineBoxProps) {
  const { formatMessage } = useFormattingContext();

  return (
    <div
      className={`timeline-item ${selected ? 'selected' : ''}  ${inactive ? 'inactive' : ''}`}
    >
      <span className="timeline-day">
        {isSameDay(currentDate, Date.now())
          ? formatMessage('trader.timeline.today')
          : formatDateLocalized(currentDate, 'EEEE')}
      </span>
      <span className="timeline-date display-4">
        {formatDateLocalized(currentDate, 'd MMM')}
      </span>
    </div>
  );
}

export default DateLineBox;
