import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import type { Way } from '@/models/way';
import { getAmountByWay, getCurrencyByWay, hasWrittenOnSide, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { NumberInput } from '@/share/NumericInput/NumberInput';
import { amountChangeThunk, amountChangingThunk, isAllAvailableUsedChangeThunk, wayChangeThunk } from '@/store/state/rfs/rfs.thunks';
import { errorLimit, selectErrors } from '@/store/state/errors/errors.selectors';
import { getInvalidKind, getInvalidLimit } from '@/App/Trader/components/AmountAndCurrency.connectors';
import { CurrencyView } from '@/App/Trader/components/CurrencySelector/CurrencyView';
import { selectPredeliver } from '@/store/state/predeliver/predeliver.selectors';
import { selectRollover } from '@/store/state/rollover/rollover.selectors';
import { TradeActionType } from '@/models/trade';
import { useDebounce } from '@/App/utils/hooks/debounce';
import { useAppDispatch } from '@/store/store';

interface AmountAndCurrencyProps {
  way: Way;
  type: TradeActionType;
  readonly?: boolean;
}

export function OperationAmountAndCurrency(props: AmountAndCurrencyProps) {
  const { way, type, readonly = false } = props;

  const dispatch = useAppDispatch();

  const rfs = useSelector(selectRfs);

  const predeliver = useSelector(selectPredeliver);
  const rollover = useSelector(selectRollover);

  const tradeAction = type === 'predeliver' ? predeliver : rollover;

  const isRfsMainSide = !readonly || hasWrittenOnSide(rfs, way);
  const isRfsAllAvailableUsed = rfs.useAllAvailable === true;
  const isTradeMainSide = tradeAction.trade?.side === way;

  const amount = getAmountByWay(rfs, way) ?? 0;
  const numberInputValue = isRfsMainSide ? amount.toString() : amount.toFixed(2);
  const selectedCurrency = getCurrencyByWay(rfs, way);

  const isAmountInputDisabled = readonly || isRfsAllAvailableUsed;
  const isExecuting = rfs.status === 'executing';

  const errors = useSelector(selectErrors);

  const limit = errorLimit(errors);
  const invalid = isTradeMainSide ? getInvalidKind(errors, rfs, tradeAction, tradeAction) : undefined; // TODO: refactor to send predeliver or rollover as expected; it works now, but the type is false
  const invalidLimit = getInvalidLimit(limit, selectedCurrency);

  const [localValue, setLocalValue] = useState<string>();

  const onAmountChanging = (isDirty: boolean) => isDirty && dispatch(amountChangingThunk());
  const onAmountFocus = () => dispatch(wayChangeThunk(way));
  const onIsAllAvailableUsedChange = (isAllAvailableUsed: boolean) => dispatch(isAllAvailableUsedChangeThunk(isAllAvailableUsed));

  const debouncedOnChange = useDebounce((value: string) => {
    dispatch(amountChangeThunk({ way, amount: Number(value) }));
  });

  const doOnIsAllAvailableUsedChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onIsAllAvailableUsedChange(event.target.checked);
    },
    [onIsAllAvailableUsedChange],
  );

  return (
    <div className="pt-5">
      <h3 className="mb-3">
        <FormattedMessage id={`trader.amountAndCurrency.way.${way}`} />
      </h3>

      <div className="form-group mb-0">
        <label>
          <FormattedMessage id={`${type}.amount.label.${isRfsMainSide ? 'main.' : ''}${way}`} />
        </label>

        <div className="d-flex">
          <div className="form-group col-md-4 ps-0 mb-0 align-items-center	">
            <NumberInput
              e2eHandle={way}
              value={numberInputValue}
              className={`form-control form-control-xl fw-bold${invalid ? ' is-invalid' : ''}`}
              readOnly={isAmountInputDisabled}
              onChange={debouncedOnChange}
              onFocus={onAmountFocus}
              onDirty={onAmountChanging}
            />

            <div className={`invalid-feedback mb-0${invalid ? ' d-block' : ' d-none'}`}>
              <FormattedMessage id="trader.amountAndCurrency.inputCurrency" />
              <FormattedMessage id={`trader.amountAndCurrency.OVER.${invalid === 'OVER' ? 'low' : 'high'}`} />

              {invalidLimit !== undefined && (
                <>
                  <FormattedMessage id="trader.amountAndCurrency.OVER.equalTo" /> {invalidLimit}
                </>
              )}
            </div>
          </div>

          <div className="form-group col-md-8  mb-0">
            <div className="form-control-xl bg-transparent py-2">
              <CurrencyView currency={selectedCurrency} e2e={`currency-${way}`} />
            </div>
          </div>
        </div>
      </div>

      {isTradeMainSide && (
        <div className="form-group pt-3 mb-0">
          <div className="form-check">
            <input
              type="checkbox"
              checked={rfs.useAllAvailable ?? false}
              onChange={doOnIsAllAvailableUsedChange}
              disabled={isExecuting}
              id="isAllAvailableUsedInput"
              className="form-check-input"
            />

            <label htmlFor="isAllAvailableUsedInput" className="form-check-label">
              <FormattedMessage id="trader.amountAndCurrency.isAllAvailableUsed" values={{ type }} />
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
