import * as React from 'react';
import { createEventHandler } from './createEventHandler';
import { DecimalKey, DecimalKeyChildrenProps } from './DecimalKey';
import {
  ArrayValueType,
  getNextFocus,
  noop,
  ObjectPick,
  pick,
  RenderPropChildren,
  stronglyTypedArray,
} from './utils';

export interface NumberInputProps {
  value: string;
  locale: string;
  onSubmit?(value: string): void;
  onChange?(value: string): void;
  onBlurPristine?(): void;
  onBlur?(): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onDirty?(isDirty: boolean): void;
  onKeyDownSetter?(value: string): void;
}
export interface NumberInputChildrenProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  onPaste: React.ClipboardEventHandler<HTMLInputElement>;
  onCopy: React.ClipboardEventHandler<HTMLInputElement>;
  onCut: React.ClipboardEventHandler<HTMLInputElement>;
}

function eventSetSelectionRange(event: React.SyntheticEvent<HTMLInputElement>) {
  event.persist();
  const { currentTarget } = event;
  const setSelectionRange = currentTarget.setSelectionRange.bind(currentTarget);
  return (position: number | null) => {
    if (position === null) {
      return;
    }
    event.preventDefault();
    setSelectionRange(position, position);
  };
}

export const NumberInputHandler = createEventHandler<
  ScopedHandlersValue,
  HTMLInputElement,
  'onFocus' | 'onKeyDown' | 'onBlur' | 'onPaste' | 'onCopy' | 'onCut'
>({
  onCut:
    ({ onCut, onDirty = noop }) =>
    event => {
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart === null || selectionEnd === null) {
        return;
      }
      const setSelectionRange = eventSetSelectionRange(event);
      const clip = onCut(selectionStart, selectionEnd, (position, dirty) => {
        setSelectionRange(position);
        onDirty(dirty);
      });

      // clipoardData is not defined in event but in window in IE
      // No prevent default on IE event
      const clipboarData = (window as any).clipboardData || event.clipboardData;
      clipboarData.setData('text', clip);
      if (event.preventDefault) {
        event.preventDefault();
      }
    },
  onCopy:
    ({ onCopy }) =>
    event => {
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart === null || selectionEnd === null) {
        return;
      }
      const clip = onCopy(selectionStart, selectionEnd);
      // clipoardData is not defined in event but in window in IE
      // No prevent default on IE event
      const clipboarData = (window as any).clipboardData || event.clipboardData;
      clipboarData.setData('text', clip);
      if (event.preventDefault) {
        event.preventDefault();
      }
    },
  onPaste:
    ({ onPaste, onDirty = noop }) =>
    event => {
      // clipoardData is not defined in event but in window in IE
      const clipboarData = (window as any).clipboardData || event.clipboardData;
      if (clipboarData) {
        const clipboard = clipboarData
          .getData('text')
          .trim()
          .split(/[\r\n\t]+/, 1)[0]
          .trim()
          .split('');
        const { selectionStart, selectionEnd } = event.currentTarget;
        if (selectionStart !== null && selectionEnd !== null) {
          const setSelectionRange = eventSetSelectionRange(event);
          onPaste(clipboard, selectionStart, selectionEnd, (position, dirty) => {
            setSelectionRange(position);
            onDirty(dirty);
          });
        }
      }
    },
  onFocus:
    ({ onFocus = noop }) =>
    event => {
      event.currentTarget.select();
      onFocus(event);
    },
  onKeyDown:
    ({ value, onDirty = noop, reset, onKeyDown, onChange, onKeyDownSetter }) =>
    event => {
      const next = getNextFocus(event.currentTarget);
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          if (next) {
            next.focus();
            next.focus();
          } else {
            event.currentTarget.blur();
          }
          return;
        case 'Escape':
          reset(c => {
            onDirty(c !== value);
          });
          return;
        default:
          break;
      }
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart !== null && selectionEnd !== null) {
        const setSelectionRange = eventSetSelectionRange(event);
        onKeyDown(event.key, selectionStart, selectionEnd, (position, dirty, newValue) => {
          setSelectionRange(position);
          onDirty(dirty);
          if (onChange) {
            onChange(newValue);

            if (onKeyDownSetter) {
              onKeyDownSetter(newValue);
            }
          }
        });
      }
    },
  onBlur:
    ({ value, currentValue, onSubmit, onBlurPristine, onBlur }) =>
    () => {
      if (value !== currentValue) {
        if (onSubmit !== undefined) {
          onSubmit(currentValue);
        }
      } else {
        if (onBlurPristine !== undefined) {
          onBlurPristine();
        }
      }
      if (onBlur !== undefined) {
        onBlur();
      }
    },
});

/**
 * handlers
 */
const valueFromDecimalKey = stronglyTypedArray(
  'onKeyDown',
  'onPaste',
  'onCopy',
  'onCut',
  'reset',
  'currentValue',
);

const valueFromProp = stronglyTypedArray(
  'onFocus',
  'value',
  'onDirty',
  'onSubmit',
  'onChange',
  'onBlur',
  'onBlurPristine',
  'onKeyDownSetter',
);

export const getNumberInputHandlerValueFromDecimalKey = pick(...valueFromDecimalKey);
export const getNumberInputHandlerValueFromProps = pick(...valueFromProp);
export function NumberInputRenderProps({
  children,
  ...props
}: RenderPropChildren<NumberInputChildrenProps> & NumberInputProps) {
  return (
    <DecimalKey decimal={props.value} locale={props.locale}>
      {numberInput => (
        <NumberInputHandler
          value={{
            ...getNumberInputHandlerValueFromDecimalKey(numberInput),
            ...getNumberInputHandlerValueFromProps(props),
          }}
        >
          {events =>
            children({
              value: numberInput.valueLocale,
              onChange: noop,
              ...events,
            })
          }
        </NumberInputHandler>
      )}
    </DecimalKey>
  );
}

type ScopedHandlersValueFromDecimalkey = ObjectPick<
  DecimalKeyChildrenProps,
  ArrayValueType<typeof valueFromDecimalKey>
>;
type ScopedHandlersValueFromProps = ObjectPick<
  NumberInputProps,
  ArrayValueType<typeof valueFromProp>
>;
type ScopedHandlersValue = ScopedHandlersValueFromDecimalkey & ScopedHandlersValueFromProps;
