import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const ErrorModal = ({ open, toggle }: { open: boolean; toggle: () => void }) => {
  return (
    <Modal isOpen={open} className="ErrorBoostModal" data-e2e="error-modal">
      <div className="pb-3 flex-column modal-header">
        <h5 className="modal-title display-3">
          <i className="icon icon-md">power_off</i>
          <span>
            <FormattedMessage id="home.boost.errorModal.title" />
          </span>
        </h5>
      </div>

      <ModalBody className="modal-body">
        <FormattedMessage id="home.boost.errorModal.description" />
      </ModalBody>

      <ModalFooter className="text-end">
        <button type="button" className="btn btn-danger btn-lg" onClick={toggle}>
          <FormattedMessage id="home.boost.errorModal.button" />
        </button>
      </ModalFooter>
    </Modal>
  );
};
