import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import {  selectReceivedTrades } from "@/store/state/trades/trades.selectors"
import { useExportTrades } from "../TradesList/helpers"

export function TradesExportButton() {
  const exportTrades = useExportTrades()
  const trades = useSelector(selectReceivedTrades)

  return <div className="form-group">
    <label />
    <div>
      <button type='button' className="btn btn-link btn-lg p-0" onClick={() => exportTrades(trades)}>
        <i className="icon icon-xs me-2 ps-2">save_alt</i>
        <FormattedMessage id="trades.tradesViewer.export" />
      </button>
    </div>
  </div>
}