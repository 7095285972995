import { MyHedgeTrade, OptionStrategy } from '@/models/trade';

export const divideTradesWithMultipleMaturityDateSubs = (filteredTrades: MyHedgeTrade[]) =>
  filteredTrades.reduce((result, trade) => {
    if (trade.instrumentName === 'FxOptionStrategy' || trade.instrumentName === 'FxOptionOutrightPackage' || trade.instrumentName === 'FxOptionRiskReversal' || trade.instrumentName === 'FxOptionKnockingPackage') {
      const subsGroupedByMaturityDate = trade.sub.reduce((acc, subElements) => {
        const key = subElements.maturitydate;
        (acc[key] = acc[key] || []).push(subElements);
        return acc;
      }, {} as Record<string, Exclude<MyHedgeTrade, OptionStrategy>[]>);

      const TradesSplitBySubs = Object.values(subsGroupedByMaturityDate).map(element => ({
        ...trade,
        sub: element,
      }));

      result = [...result, ...TradesSplitBySubs];
    } else {
      result = [...result, trade];
    }
    return result;
  }, [] as MyHedgeTrade[]);
