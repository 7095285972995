import { FormattedMessage } from 'react-intl';
import { NumberInput } from '@/share/NumericInput/NumberInput';
import { CurrencySelector } from './CurrencySelector/CurrencySelector';
import { useAmountAndCurrencyDispatchedHandlers, useAmountAndCurrencyMappedState } from './AmountAndCurrency.hooks';
import { Way } from '@/models/way';
import { useState } from 'react';
import { useDebounce } from '@/App/utils/hooks/debounce';
import { isDefined } from '@sgme/fp';

interface AmountAndCurrencyProps {
  way: Way;
}

export function AmountAndCurrency({ way: wayProp }: AmountAndCurrencyProps) {
  const {
    way,
    value: storeValue,
    currencies,
    selectedCurrency,
    currencyDisabled,
    label,
    amountDisabled,
    invalid,
    invalidLimit,
    amountIsMaster,
  } = useAmountAndCurrencyMappedState(wayProp);
  const { onCurrencyChange, onAmountFocus, onAmountChange, onAmountChanging } = useAmountAndCurrencyDispatchedHandlers(wayProp);

  // we need the local value to avoid overriding the input value from user with the stored value
  // during the debounce.

  // i.e:
  // let's say there is already a value of 1000 in the input
  // then the user selects it, and quickly type in 444
  // the input would become 100044 instead of 444 because of the debounce logic and overriden value
  // coming from the store
  const [localValue, setLocalValue] = useState<string>();

  const usedValue = storeValue !== localValue && isDefined(localValue) && amountIsMaster ? localValue : storeValue;

  const debouncedOnChange = useDebounce((value: string) => {
    onAmountChange(value);
  });

  return (
    <div>
      <div className="form-group">
        <label className="labeler text-secondary text-large mb-2">
          <FormattedMessage id={`trader.amountAndCurrency.way.${way}`} />
        </label>
        <CurrencySelector currencies={currencies} value={selectedCurrency} disabled={currencyDisabled} onCurrencyChange={onCurrencyChange} />
      </div>
      <div className="form-group mt-3 ">
        <label className="labeler text-secondary text-large mb-2">
          <FormattedMessage id={`trader.amountAndCurrency.label.${label}`} />
        </label>

        <NumberInput
          onBlur={() => setLocalValue(undefined)}
          onKeyDownSetter={setLocalValue}
          e2eHandle={way}
          value={usedValue}
          className={`form-control form-control-xl fw-bold${invalid ? ' is-invalid' : ''}`}
          readOnly={amountDisabled}
          onChange={debouncedOnChange}
          onFocus={onAmountFocus}
          onDirty={onAmountChanging}
        />
        <div className={`invalid-feedback d-block${invalid ? ' visible' : ' invisible'}`}>
          <FormattedMessage id="trader.amountAndCurrency.inputCurrency" />
          <FormattedMessage id={`trader.amountAndCurrency.OVER.${invalid === 'OVER' ? 'low' : 'high'}`} />

          {invalidLimit !== undefined && (
            <>
              <FormattedMessage id="trader.amountAndCurrency.OVER.equalTo" /> {invalidLimit}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
