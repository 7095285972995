import { Action } from '@reduxjs/toolkit';
import { StreamingInfo } from '@/models/streaming';

export type StreamingAction = StreamingConnectedAction;

export type StreamingConnectedAction = Action<'STREAMING_CONNECTED'> & StreamingInfo;

export const streamingConnectedAction = (info: StreamingInfo): StreamingConnectedAction => ({
  type: 'STREAMING_CONNECTED',
  ...info,
});
