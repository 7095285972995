import { useSelector } from 'react-redux';
import { swapThunk } from '@/store/state/rfs/rfs.thunks';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';
import { useAppDispatch } from '@/store/store';

export function SwapButton() {
  const dispatch = useAppDispatch();

  const rfs = useSelector(selectRfs);
  const disabled = rfs.status === 'executing' || rfs.postTradeOperation !== undefined;

  const onSwap = () => dispatch(swapThunk());

  return (
    <button type="button" className="btn btn-default btn-xl btn-swap-currencies" onClick={onSwap} disabled={disabled}>
      <i className="icon icon-md d-md-inline-block d-none">compare_arrows</i>
      <i className="icon icon-md d-md-none d-sm-inline-block">swap_vert</i>
    </button>
  );
}
