import { AppState } from '@/store/app.state';

export const canModifyAlert = ({ user }: AppState) => user.currentUser?.canExecute ?? false;

export const selectHasCallRight = ({ user }: AppState) => user.currentUser?.hasCallRight ?? false;

export const selectHasSwapRight = ({ user }: AppState) => user.currentUser?.hasSwapRight ?? false;

export const selectHasSpotRight = ({ user }: AppState) => user.currentUser?.hasSpotRight ?? false;

export const selectUser = ({ user }: AppState) => user;

export const selectSelectedCompany = ({ user }: AppState) => user.selectedCompany;

export const selectRfsCurrencies = ({ user }: AppState) => user.selectedCompany!.rfsCurrencies;

export const selectCompanies = ({ user }: AppState) => (user.navigateAsUser ? user.navigateAsUser.companies : user.currentUser?.companies ?? []);

export const selectCurrentUser = ({ user }: AppState) => user.currentUser;

export const selectUserType = (app: AppState) => app.user.currentUser?.userType;

export const selectCurrentUserEmail = ({ user }: AppState) => user.currentUser?.email;

export const selectNavigateAsUser = ({ user }: AppState) => user.navigateAsUser;

export const selectNavigableAsUsers = ({ user }: AppState) => user.navigableAsUsers;

export const selectMnemo = ({ user }: AppState) => user.selectedCompany?.companyMnemonic;
