import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from '@/store/app.state';
import { getAmountByWay, getCurrencyByWay, selectRfs } from '@/store/state/rfs/rfs.selectors';
import { useFormattingContext } from '@/utils/format';

export function PredeliverRemaining() {
  const { formatAmountWithPrecision } = useFormattingContext()
  const rfs = useSelector(selectRfs)
  const trade = useSelector(({ predeliver }: AppState) => predeliver.trade)!
  const newRemaining = (trade.remainingAmount ?? 0) - getAmountByWay(rfs, trade.side);
  const amount = formatAmountWithPrecision(newRemaining, 2)
  const currency = getCurrencyByWay(rfs, trade.side)

  return <div className="pt-5" data-e2e="predeliver-remaining">
      <FormattedMessage
        id="predeliver.newAmount"
        values={{ currency, newAmount: amount }}
      />
    </div>
}
