import { Action } from '@reduxjs/toolkit';
import { ForwardTrade } from '@/models/trade';

export type RolloverAction = StartRolloverAction;

type StartRolloverAction = Action<'START_ROLLOVER'> & {
  trade: ForwardTrade;
};
export const startRolloverAction = (trade: ForwardTrade): StartRolloverAction => ({
  type: 'START_ROLLOVER',
  trade,
});
