import { combineReducers } from '@reduxjs/toolkit';
import { AppState } from './app.state';
import { errorsReducer } from './state/errors/errors.reducer';
import { predeliverReducer } from './state/predeliver/predeliver.reducer';
import { rfsReducer } from './state/rfs/rfs.reducer';
import { rolloverReducer } from './state/rollover/rollover.reducer';
import { tradeCaptureReducer } from './state/tradeCapture/tradeCapture.reducer';
import { tradesReducer } from './state/trades/trades.reducer';
import { uiReducer } from './state/ui/ui.reducer';
import { userReducer } from './state/user/user.reducer';
import { appCrashReducer } from './state/appCrash/appCrash.reducer';
import { createAlertReducer } from './state/create-alert/create-alert.reducer';
import { alertsReducer } from './state/alerts/alerts.reducer';
import { streamingReducer } from './state/streaming/streaming.reducer';
import { growlsReducer } from './state/growls/growls.reducer';
import { upcomingFlowsApi } from './state/upcomingFlows/upcomingFlows.api';
import { tradesApi } from './state/trades/trades.api';
import { boostMailApi } from './boost-mail/boostMail.api';

export const rootReducer = combineReducers({
  appCrash: appCrashReducer,
  errors: errorsReducer,
  rfs: rfsReducer,
  trades: tradesReducer,
  tradeCapture: tradeCaptureReducer,
  ui: uiReducer,
  user: userReducer,
  predeliver: predeliverReducer,
  rollover: rolloverReducer,
  createAlert: createAlertReducer,
  alerts: alertsReducer,
  streaming: streamingReducer,
  growls: growlsReducer,
  upcomingFlowsApi: upcomingFlowsApi.reducer,
  tradesApi: tradesApi.reducer,
  boostMailApi: boostMailApi.reducer,
});
