import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import {
  catchError,
  mergeMap,
  mapTo,
} from 'rxjs/operators';
import { logger } from '@/logging/logger';
import { disconnect$ } from '@/services/streams.service';
import { AppAction } from '@/store/app.actions';
import { AppState } from '@/store/app.state';
import { StreamingConnectedAction } from '@/store/state/streaming/streaming.actions';
import { Epic } from '@/store/store';
import { disconnectAction } from '@/store/state/user/user.actions'

const listenDisconnect = () => {
  const signalrNow = disconnect$().pipe(
    mapTo(disconnectAction('force_logoff_time'))
  );

  return signalrNow;
};


export const disconnectEpic =
  (listen = listenDisconnect): Epic<AppAction, AppAction, AppState> =>
    (action$) =>
      action$.pipe(
        ofType<AppAction, StreamingConnectedAction>('STREAMING_CONNECTED'),
        mergeMap(listen),
        catchError(e => {
          logger.logError(e);
          return EMPTY;
        }),
      );
