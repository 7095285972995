import { routes } from '@/App/routes';
import { selectHasSpotRight } from '@/store/state/user/user.selectors';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { MainLayout } from '../Layout/MainLayout';
import { TradeLoader } from './TradeLoader';
import './Trader.scss';
import { AmountAndCurrency, ExecuteButton, SwapButton, TradeDatePicker, TradeErrors, TradeRate, TraderModals } from './components';
import { AlertsLink } from './components/AlertsLink';
import { TradeInfo } from './components/TradeInfo/TradeInfo';

export function Trader() {
  const canSeeTradeTile = useSelector(selectHasSpotRight);

  if (!canSeeTradeTile) {
    return <Navigate replace to={routes.HOME} />;
  }
  return (
    <MainLayout>
      <TradeLoader>
        <div className="Trader row mt-4" data-e2e="trade-form">
          <div className="form col-lg-8">
            <div className="d-lg-none">
              <TradeInfo displayMode="TOP" />
            </div>
            <div className="row">
              <div className="col-md-5">
                <AmountAndCurrency way="buy" />
              </div>
              <div className="col-md-2 d-flex align-items-center justify-content-center">
                <SwapButton />
              </div>
              <div className="col-md-5">
                <AmountAndCurrency way="sell" />
              </div>
            </div>
            <div className="row">
              <div className="form-group date col-md-5">
                <TradeDatePicker />
              </div>
              <TradeRate />
            </div>
            <div className="d-lg-none ">
              <TradeInfo displayMode="BOTTOM" />
            </div>
            <TradeErrors />
            <ExecuteButton />
            <AlertsLink />
          </div>
          <div className="col-lg-4 ps-xl-5 d-none d-lg-flex">
            <TradeInfo displayMode="RIGHT" />
          </div>
          <TraderModals />
        </div>
      </TradeLoader>
    </MainLayout>
  );
}
