import { OnCatchActionCreator } from './tryCatchMiddleware';
import { AppState } from '@/store/app.state';
import { createAppCrashedAction } from '@/store/state/appCrash/appCrash.actions';

export const crashHandler: OnCatchActionCreator<AppState> = (errorMessage, action, state, err) => {
  let source: any;

  if (err?.stack !== undefined) {
    source = err.stack;
  } else {
    try {
      source = JSON.stringify(err);
    } catch (e) {
      source = errorMessage;
    }
  }

  return createAppCrashedAction(action.type || action.name, source);
};
