import * as React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isCalendarRoute, isPredeliverRoute, isRolloverRoute } from '@/App/routes';
import { Paginator } from '@/share/Paginator/Paginator';
import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { changeCompanyThunk } from '@/store/state/user/user.thunks';
import { selectCompanies, selectSelectedCompany } from '@/store/state/user/user.selectors';

import './AccountSelector.scss';
import { Company } from '@/models/user';
import { useAppDispatch } from '@/store/store';

const ACCOUNTS_PER_PAGE = 8;

interface AccountSelectorHandlers {
  onSelect: (account: Company) => void;
  onBlockExecution: (blockExecution: boolean) => void;
}

const useDispatchedHandlers = (): AccountSelectorHandlers => {
  const dispatch = useAppDispatch();

  return {
    onSelect: (account: Company) => dispatch(changeCompanyThunk(account)),
    onBlockExecution: (blockExecution: boolean) => dispatch(blockExecutionThunk(blockExecution)),
  };
};

export function AccountSelector() {
  const accounts = useSelector(selectCompanies);
  const selectedAccount = useSelector(selectSelectedCompany);
  const { pathname } = useLocation();
  const disabled = isPredeliverRoute(pathname) || isRolloverRoute(pathname) || isCalendarRoute(pathname);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const { onSelect, onBlockExecution } = useDispatchedHandlers();

  const getAccounts = React.useCallback(() => {
    if (!search) {
      return accounts;
    }
    const lowerSearch = search.toLowerCase();
    return accounts.filter((a) => a.companyName.toLowerCase().includes(lowerSearch) || a.companyBdrId.toLowerCase().includes(lowerSearch));
  }, [accounts, search]);

  const visibleAccounts = React.useCallback(() => {
    const p = currentPage;
    return getAccounts().slice(p * ACCOUNTS_PER_PAGE, (p + 1) * ACCOUNTS_PER_PAGE);
  }, [currentPage, getAccounts]);

  const nbPages = React.useCallback(() => Math.ceil(getAccounts().length / ACCOUNTS_PER_PAGE), [getAccounts]);

  const toggle = React.useCallback(() => {
    const newDropdownOpen = !dropdownOpen;
    onBlockExecution(newDropdownOpen);
    setDropdownOpen(newDropdownOpen);
  }, [dropdownOpen, onBlockExecution]);

  const select = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const valueId = event.currentTarget.dataset.id;
      const selectedCompany = getAccounts().find((a) => a.companyBdrId === valueId);
      if (selectedCompany) onSelect(selectedCompany);
      toggle();
    },
    [getAccounts, onSelect, toggle],
  );

  const goTo = React.useCallback((page: number) => setCurrentPage(page), []);

  const onSearch = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  React.useEffect(() => {
    if (currentPage > nbPages() - 1) {
      setCurrentPage(0);
    }
  }, [currentPage, nbPages, search]);

  return (
    <div className="AccountSelector">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret={false} tag="button" className="btn btn-flat-primary btn-icon-text py-0" disabled={disabled}>
          <i className="icon icon-xs me-1">business</i>
          {selectedAccount?.companyName}
          <i className="icon icon-xs ms-1">arrow_drop_down</i>
        </DropdownToggle>
        <DropdownMenu>
          <div className="account-list">
            {(search || getAccounts().length > 2) && (
              <div className="input-group input-group-lg">
                <div className="input-group-prepend input-group-merged">
                  <i className="icon mt-1">search</i>
                </div>
                <FormattedMessage id="accountSelector.search">
                  {([msg]) => (
                    <input
                      type="search"
                      className="form-control border bg-transparent"
                      value={search}
                      onChange={onSearch}
                      placeholder={msg?.toString() ?? ''}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                  )}
                </FormattedMessage>
              </div>
            )}
            {visibleAccounts().map((a) => (
              <button type="button" className="btn dropdown-item" onClick={select} key={a.companyBdrId} data-id={a.companyBdrId} title={a.companyName}>
                {a.companyName}
              </button>
            ))}
            <Paginator currentPage={currentPage} nbPages={nbPages()} onGoToPage={goTo} className="m-3 justify-content-end row" />
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
