import { Epic, ofType } from 'redux-observable';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { alertReceivedAction, LoadingAlertsAction } from '@/store/state/alerts/alerts.actions';
import { blotterOrders$ } from '@/services/streams.service';

export const blotterRealTimeEpic = (
  getBlotterOrders$ = blotterOrders$,
): Epic<AppAction, AppAction, AppState> => action$ =>
  action$.pipe(
    ofType<AppAction, LoadingAlertsAction>('LOADING_ALERTS'),
    take(1),
    mergeMap(() => getBlotterOrders$().pipe(map(alertReceivedAction))),
  );
