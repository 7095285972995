import 'core-js/es/array';
import 'core-js/es/symbol';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';

import * as React from 'react';

import { createRoot } from 'react-dom/client';

import { Provider, useSelector } from 'react-redux';
import { throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { IntlProvider } from 'react-intl';
import { EVENT_AUTH_DISCARDED, EVENT_AUTH_EXPIRED, EVENT_AUTH_RENEW_ERROR, EVENT_AUTH_RENEW_SUCCESS } from '@sgwt/connect-core';

import { App } from '@/App/App';
import { connectToStreaming } from '@/services/streaming.service';
import { UserService } from '@/services/user.service';
import { sgwtConnect } from '@/sgwtConnect';
import { toggleNavigateAsModalAction } from '@/store/state/ui/ui.actions';
import { navigableAsUsersLoadedAction, userLoadedAction } from '@/store/state/user/user.actions';
import { getStore } from '@/store/store';
import { logger } from '@/logging/logger';
import { Piwik } from '@/utils/piwik';
import '@/index.scss';
import { messages } from '@/utils/locale';
import { CrashModal } from '@/App/utils/CrashModal';
import { streamingConnectedAction } from '@/store/state/streaming/streaming.actions';
import { toLogEvents$ } from '@/services/streams.service';
import { SgwtConnectHTMLElement } from '@/typings/sgwt-widgets';
import { selectCurrentLocale } from './store/state/ui/ui.selectors';
import { initSgBootstrap } from './utils/theme';
import { useTimeout } from './App/utils/hooks/timeout';
import { ServiceLoader } from './App/Shared/ServiceLoader';

function Connecting() {
  const timeout = useTimeout(800);

  if (!timeout) {
    return <ServiceLoader />;
  }

  return <ServiceLoader />;
}

function NotAllowed() {
  // eslint-disable-next-line no-restricted-globals
  location.href = window.sgmeConfiguration.redirect_uri;
  return <div className="center-screen">{`Vous n'avez pas les droits d'accéder à la plateforme`}</div>;
}

function NoCompany() {
  return <div className="center-screen">{`Vous n'avez pas de société rattachée à votre compte`}</div>;
}

function ErrorComponent() {
  return <div className="center-screen">Une erreur est survenue sur la plateforme, nous vous invitons à réessayer plus tard</div>;
}

function ConnectedIntlProvider({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const locale = useSelector(selectCurrentLocale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

const AppElement = (
  <Provider store={getStore()}>
    <ConnectedIntlProvider>
      <CrashModal />
      <App />
    </ConnectedIntlProvider>
  </Provider>
);

function setupPiwik(user: string) {
  const env = window.sgmeConfiguration.env.toLowerCase();
  if (env.includes('prod') || env.includes('uat') || env.includes('demo')) {
    Piwik.setup(window.sgmeConfiguration.piwik.url, window.sgmeConfiguration.piwik.site_id, user);
  } else {
    logger.logInformation(`Piwik statistics will not be recordered in ${env} environment.`);
  }
}

function setupSgwtConnectWidget() {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        // Remove the event listener
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

if (sgwtConnect.isAuthorized()) {
  initSgBootstrap();
  connectToStreaming().then((streamingInfo) => {
    getStore().dispatch(streamingConnectedAction(streamingInfo));
    // logs some important events, useful for support
    toLogEvents$().subscribe((event) => logger.logInformation('Received event: {event}', event));
  });

  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('no root');

  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Connecting />
    </React.StrictMode>,
  );

  UserService.getCurrentUser().subscribe(
    (user) => {
      setupPiwik(user.email);
      if (user.myFx) {
        if (user.companies.length > 0) {
          getStore().dispatch(userLoadedAction(user));
          setupSgwtConnectWidget();

          if (user.canNavigateAs) {
            UserService.getNavigableAsUsers()
              .pipe(
                tap(() => getStore().dispatch(toggleNavigateAsModalAction())),
                finalize(() => root.render(AppElement)),
              )
              .subscribe(
                (users) => getStore().dispatch(navigableAsUsersLoadedAction(users)),
                (err) => logger.logError('Error loading users', err),
              );
          } else {
            root.render(AppElement);
          }
        } else {
          root.render(<NoCompany />);
        }
      } else {
        root.render(<NotAllowed />);
      }
    },
    (error) => {
      if (error.status === 401 || error.status === 403) {
        root.render(<NotAllowed />);
      } else {
        root.render(<ErrorComponent />);
      }
      return throwError(error);
    },
  );

  sgwtConnect.on(EVENT_AUTH_RENEW_SUCCESS, () => logger.logInformation('sgwtConnect token renew'));
  sgwtConnect.on(EVENT_AUTH_DISCARDED, () => logger.logInformation('sgwtConnect token is no longer available on the client side.'));
  sgwtConnect.on(EVENT_AUTH_EXPIRED, () => logger.logInformation('sgwtConnect token is no longer valid.'));
  sgwtConnect.on(EVENT_AUTH_RENEW_ERROR, (error) => logger.logError('sgwtConnect failed to renew the token', JSON.stringify(error)));
} else {
  const authError = sgwtConnect.getAuthorizationError();
  if (authError) {
    // do something meaningful with the error
    // eslint-disable-next-line no-alert
    alert(JSON.stringify(authError));
  } else {
    sgwtConnect.requestAuthorization();
  }
}
