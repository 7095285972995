import { FormattedMessage } from 'react-intl';

interface EmptyProps {
  startCreate: () => void;
}
export function Empty({startCreate}: EmptyProps) {
  return <div className="empty text-center row justify-content-center mt-5">
    <div className="text-secondary col-xl-4 col-md-6 col-sm-9">
      <i className="icon icon-xl">notifications</i>
      <h3 className="mb-2">
        <FormattedMessage id="alerts.empty.title" />
      </h3>
      <div className="mb-4">
        <FormattedMessage id="alerts.empty.description" />
      </div>
      {/* for small mobile */}
      <button
        type="button" 
        className="btn btn-lg btn-primary d-sm-none btn-block"
        data-e2e="create-alert-btn"
        onClick={startCreate}
      >
        <FormattedMessage id="alerts.create" />
      </button>
      {/* for other */}
      <button
        type="button" 
        className="btn btn-lg btn-primary d-none d-sm-inline-block"
        data-e2e="create-alert-btn"
        onClick={startCreate}
      >
        <FormattedMessage id="alerts.create" />
      </button>
    </div>
  </div>
}
