import { FormattedMessage } from 'react-intl';
import { MainLayout } from '../Layout/MainLayout';

export function TechnicalError({ refresh }: { refresh: () => void }) {
  return (
    <MainLayout>
      <div className="row mt-4">
        <div className="col-10 align-self-center text-warning">
          <i className="icon icon-md">error_outline</i>
          <FormattedMessage id="trades.notAvailableError" />
        </div>
        <div className="col-2 align-self-center">
          <button type="button" className="btn btn-default float-end" onClick={refresh}>
            <FormattedMessage id="trades.refresh" />
          </button>
        </div>
      </div>
    </MainLayout>
  );
}
