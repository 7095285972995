import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, repeat } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { StreamingAction } from '@/store/state/streaming/streaming.actions';
import { logger } from '@/logging/logger';
import { extractError } from '@/utils/errors';
import {
  changeCompanyAction,
  navigateAsUserAction,
  navigateAsUserFailedAction,
  StartNavigateAsAction,
} from '@/store/state/user/user.actions';
import { UserService } from '@/services/user.service';
import { loadingAlertsAction as getLoadingAlertsAction } from '@/store/state/alerts/alerts.actions';

export const startNavigateAsEpic =
  (getNavigateAsUser = UserService.getNavigateAsUser): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, StartNavigateAsAction>('START_NAVIGATE_AS'),
      mergeMap(action => {
        const { connectionId } = state$.value.streaming;

        const obs$1 = getNavigateAsUser(action.email).pipe(map(a => [a, true, undefined] as const));
        const obs$2 = action$.pipe(
          ofType<AppAction, StreamingAction>('STREAMING_CONNECTED'),
          mergeMap(connectedAction =>
            getNavigateAsUser(action.email, connectedAction.connectionId).pipe(
              map(a => [a, false, getLoadingAlertsAction(action.email)] as const),
            ),
          ),
        );
        return connectionId
          ? getNavigateAsUser(action.email, connectionId).pipe(
              map(a => [a, true, getLoadingAlertsAction(action.email)] as const),
            )
          : merge(obs$1, obs$2);
      }),
      mergeMap(([currentUser, shouldContinue, loadingAlertsAction]) => {
        const initialActions = [] as AppAction[];

        if (shouldContinue) {
          initialActions.push(
            navigateAsUserAction(currentUser),
            changeCompanyAction(currentUser.companies[0]),
          );
        }

        if (loadingAlertsAction) {
          initialActions.push(loadingAlertsAction);
        }

        return of(...initialActions);
      }),
      catchError(error => {
        logger.logError('Failed to navigateAs', { error: extractError(error) });
        return of(navigateAsUserFailedAction());
      }),
      repeat(),
    );
