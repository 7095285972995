/* eslint-disable default-param-last */
import { ofType } from 'redux-observable';
import { catchError, mapTo, repeat, switchMap, withLatestFrom } from 'rxjs/operators';
import { concat, of, SchedulerLike, throwError, timer } from 'rxjs';
import { v4 } from 'uuid';
import { AppAction } from '../app.actions';
import { DeleteAlert, finishedDeleteAlert } from '@/store/state/create-alert/create-alert.actions';
import { http } from '@/utils/http';
import { Growl } from '@/store/state/growls/growls.model';
import { EpicWithThunk } from '@/store/store';
import {
  addGrowlAction,
  autoHideGrowlDelay,
  removeGrowlAction,
} from '@/store/state/growls/growls.actions';
import { requestRedirectAction } from '../state/ui/ui.actions';
import { routes } from '../../App/routes';

export const deleteAlertSuccessGrowl: Growl = {
  titleId: 'alerts.deleteSuccess.title',
  type: 'success',
};

export const deleteAlertErrorGrowl: Growl = {
  titleId: 'alerts.deleteError.title',
  type: 'danger',
};

const callOms = (id: string) => http.post('oms/cancel/Call?forceDirectPair=true', {
    orderRef: id,
  });

export const deleteAlertOmsEpic = (
  request = callOms,
  _autoHideGrowlDelay = autoHideGrowlDelay,
  newGuid: () => string = v4,
  scheduler?: SchedulerLike,
): EpicWithThunk => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, DeleteAlert>('DeleteAlert'),
    switchMap(a =>
      concat(
        request(a.id).pipe(
          catchError(_ => throwError(a.id)),
          withLatestFrom(state$),
          switchMap(([, state]) => {
            const growlId = newGuid();
            return concat(
              of(addGrowlAction(deleteAlertSuccessGrowl, growlId), finishedDeleteAlert(a.id)),
              timer(_autoHideGrowlDelay, undefined, scheduler).pipe(
                mapTo(removeGrowlAction(growlId)),
              ),
            );
          }),
        ),
      ),
    ),
    catchError((id: string) =>
      of(addGrowlAction(deleteAlertErrorGrowl, newGuid()), finishedDeleteAlert(id)),
    ),
    repeat(),
  );
