import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { routes } from '@/App/routes';
import { useFormattingContext } from '@/utils/format';
import { selectRollover } from '@/store/state/rollover/rollover.selectors';

// needed because in mobile view we have a different display
interface RolloverInfoProps {
  displayMode: 'TOP' | 'BOTTOM' | 'RIGHT';
}

export function RolloverInfo({ displayMode }: RolloverInfoProps) {
  const { formatAmount, mapTrade } = useFormattingContext()
  const { trade } = useSelector(selectRollover)
  const initialRate = trade
    ? formatAmount(Number(trade.fxSpot))
    : undefined

  if (trade !== undefined) {
    const mappedTrade = mapTrade(trade);
    return (
      <div className="alert alert-outline-info" role="alert">
        <h3>
          <i className="icon icon-xs me-2">info</i>
          <FormattedMessage id="trader.rolloverInfo.information" />
        </h3>
        {displayMode !== 'BOTTOM' && (
          <>
            <div className="form-group">
              <label className="text-secondary w-100">
                <FormattedMessage id="trader.rolloverInfo.reference" />
              </label>
              <span className="sgbs-text-figures display-5 w-100 text-primary">
                {mappedTrade.reference}
              </span>
            </div>
            <div className="form-group">
              <label className="text-secondary w-100">
                <FormattedMessage id="trader.rolloverInfo.availableAmount" />
              </label>
              <span
                className="tabular-figures display-5 w-100 text-primary"
                data-e2e="rollover-available"
              >
                {mappedTrade.remainingAmount} {mappedTrade.amountCurrency}
              </span>
            </div>
          </>
        )}
        {displayMode !== 'TOP' && (
          <div className="form-group">
            <label className="text-secondary w-100">
              <FormattedMessage id="trader.rolloverInfo.initialRate" />
            </label>
            <span className="tabular-figures display-5 w-100 text-primary" data-e2e="rollover-rate">
              {initialRate}
            </span>
          </div>
        )}
        {displayMode !== 'BOTTOM' && (
          <div className="form-group">
            <label className="text-secondary w-100">
              <FormattedMessage id="trader.rolloverInfo.initialDate" />
            </label>
            <span className="sgbs-text-figures display-5 w-100 text-primary">
              {mappedTrade.maturitydate}
            </span>
          </div>
        )}
      </div>
    );
  }
  return <Navigate replace to={routes.HOME} />;
};

