import { currencies, CurrencyPair, CurrencyPairName } from '@/config/currencies';
import { Currency } from '@/models/currency';

export const getCurrencyPairPrecision = (ccy1?: Currency, ccy2?: Currency) => {
  if (ccy1 === undefined || ccy2 === undefined) {
    return 1;
  }
  const pair = currencies[`${ccy1}/${ccy2}` as CurrencyPairName] as CurrencyPair;

  return !pair.isInverted
    ? pair.precision
    : (currencies[`${ccy2}/${ccy1}` as CurrencyPairName] as CurrencyPair).precision;
};

export const selectCurrency = (possibleCurrencies: Currency[], ...preferredValues: Currency[]) =>
  preferredValues.find(c => possibleCurrencies.includes(c)) ?? possibleCurrencies[0];

export const getDefaultCurrencies = (
  possibleCurrencies: Currency[],
  [currentBuy, currentSell]: [Currency, Currency],
) => {
  const buyCurrency = selectCurrency(possibleCurrencies, currentBuy, 'USD');
  const sellCurrency = selectCurrency(
    possibleCurrencies.filter(x => x !== buyCurrency),
    currentSell,
    'EUR',
  );
  return {
    buyCurrency,
    sellCurrency,
  };
};
