import { Reducer } from '@reduxjs/toolkit';
import { AppError } from '@/models/AppError';
import { AppAction } from '@/store/app.actions';

export const errorsReducer: Reducer<AppError[], AppAction> = (
  // eslint-disable-next-line default-param-last
  state: AppError[] = [],
  action: AppAction,
): AppError[] => {
  switch (action.type) {
    case 'ADD_ERROR':
      return [...state, action.error];

    case 'CLOSE_ERROR':
      return state.filter((_, idx) => idx !== action.errorIndex);

    case 'CLOSE_LAST_ERROR':
    case 'STREAM_REQUESTED':
      return state.filter((_, idx) => idx !== state.length - 1);

    default:
      return state;
  }
};
