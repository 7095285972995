import { isSameDay } from 'date-fns';
import { Epic, ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, timeout, withLatestFrom } from 'rxjs/operators';
import { v4 } from 'uuid';
import { Rfs } from '@/models/rfs';
import { getTradeCapture, requestForQuotes } from '@/services/trade.service';
import { addTradeCaptureErrorAction } from '@/store/state/tradeCapture/tradeCapture.actions';
import { uuidToBase36 } from '@/utils/base36';
import { formatDateLocalized } from '@/utils/locale';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { addErrorAction } from '../state/errors/errors.actions';
import { RequestForStreamAction, streamRequestedAction } from '../state/rfs/rfs.actions';

export function getNewRfsId() {
  const uuid = v4();
  return `SGM_${uuidToBase36(uuid)}`;
}

function getProductName(rfs: Rfs) {
  if (rfs.postTradeOperation === 'Predeliver') {
    return 'FxPredeliver';
  }
  if (rfs.postTradeOperation === 'Rollover') {
    return 'FxSwap';
  }
  if (isSameDay(rfs.date, rfs.spotDate)) {
    return 'FxSpot';
  }
  return 'FxFwd';
}

export const requestForStreamEpic =
  (doGetTradeCapture = getTradeCapture, requestApiForQuotes = requestForQuotes): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, RequestForStreamAction>('REQUEST_FOR_STREAM'),
      withLatestFrom(state$),
      mergeMap(([{ rfsId, retryCount }, { rfs }]) => {
        if (getProductName(rfs) !== 'FxSpot' && getProductName(rfs) !== 'FxFwd') {
          return of({ rfsId, isReady: true, retryCount });
        }
        const currencyPair = `${rfs.buyCurrency}/${rfs.sellCurrency}`;

        const isReady$ = doGetTradeCapture(getProductName(rfs), rfs.buyAmount, rfs.sellAmount, currencyPair, formatDateLocalized(rfs.date, 'yyyy-MM-dd')).pipe(
          map((tradeCaptureDto) => tradeCaptureDto.changedFields.isReadyToPrice),
          filter((isReady) => isReady !== undefined && isReady !== null),
          map((isReady) => ({ rfsId, isReady: isReady!, retryCount })),
          timeout(10000),
        );

        return isReady$;
      }),
      filter(({ isReady }) => isReady === true),
      withLatestFrom(state$),
      mergeMap(([{ rfsId, retryCount }, { user, rfs, predeliver, rollover, streaming }]) => {
        const product = getProductName(rfs);
        return requestApiForQuotes(
          rfsId,
          rfs.buyCurrency,
          rfs.sellCurrency,
          rfs[rfs.masterAmount],
          rfs.masterAmount === 'buyAmount' ? 1 : 2,
          rfs.date.toISOString(),
          product,
          user.selectedCompany!.companyBdrId,
          streaming.connectionId!,
          rfs.postTradeOperation === 'Predeliver' ? predeliver.trade?.reference
          : rfs.postTradeOperation === 'Rollover' ? rollover.trade?.reference
          : undefined,
          rollover.trade?.maturitydate,
        ).pipe(
          timeout(10000),
          map((_) => streamRequestedAction(rfsId, product, retryCount)),
          catchError((error: AjaxResponse) =>
            of(
              addErrorAction({
                message: error.responseText,
                code: String(error.status),
              }),
            ),
          ),
        );
      }),
      // catchError((err, caught) => merge(of(addTradeCaptureErrorAction(err)), caught)),
      catchError((err, caught) => {
        return merge(of(addTradeCaptureErrorAction(err)), caught);
      }),
    );
