

export const groupBy = <Item> (groupFn: (item: Item) => string, list: Item[]): Record<string, Item[]> => {
  return list.reduce(
    (result, item) => {
      const key = groupFn(item)
      const keyItems = result[key] ??= []

      keyItems.push(item)

      return result
    },
    {} as  Record<string, Item[]>
  )
}

export const indexBy = <Item> (groupFn: (item: Item) => string, list: Item[]): Record<string, Item> => {
  return list.reduce(
    (result, item) => {
      const key = groupFn(item)

      result[key] = item

      return result
    },
    {} as  Record<string, Item>
  )
}
