import { Feature } from '@/config/app.config';
import { AppState } from '@/store/app.state';
import { createSelector } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';

export const selectCurrentLocale = ({ ui }: AppState) => ui.currentLocale;

export const selectShowNavigateAsModal = ({ ui }: AppState) => ui.showNavigateAsModal;

export const selectSpotDate = ({ ui }: AppState) => ui.spotDate;

export const selectBlockExecution = ({ ui }: AppState) => ui.blockExecution;

export const selectFeatureToggleActive = createSelector(
  (state: AppState) => state,
  (_: AppState, feature: Feature | undefined) => feature,
  ({ ui: { featureToggles } }, feature) => (isDefined(feature) ? featureToggles[feature] : false),
);
// export const selectFeatureToggleActive =
//   ({ ui: { featureToggles } }: AppState) =>
//     (feature: Feature) =>
//       featureToggles[feature];

export const selectClosedDates = ({ ui }: AppState) => ui.closedDates;

export const selectCanForward = ({ ui }: AppState) => ui.canForward;

export const selectPendingRedirectionUrl = ({ ui }: AppState) => ui.pendingRedirectionUrl;
