import { map } from 'rxjs/operators';
import { merge } from 'rxjs';
import { getStreaming$ } from './streaming.service';
import {
  QuoteAbort,
  DealRejected,
  FillReport,
  CashQuoteReply,
  SwapQuoteReply,
  DealReply,
} from '@/models/streaming';
import { mapOrderToAlert } from '@/models/alerts';
import { toCamelCase } from '@/utils/utils';

export const quote$ = () =>
  getStreaming$().quoteReply$.pipe(
    map<any, CashQuoteReply | SwapQuoteReply>(event => {
      const data = toCamelCase(event);
      const { cashQuoteReply, swapQuoteReply } = data;
      return cashQuoteReply
        ? {
            ...cashQuoteReply,
            timestamp: data.timestamp,
            forwardPointsWithMargin: cashQuoteReply.forwardPointsWithMargin
              ? {
                  bid: +cashQuoteReply.forwardPointsWithMargin.bid,
                  ask: +cashQuoteReply.forwardPointsWithMargin.ask,
                }
              : { bid: 0, ask: 0 },
            forwardWithMargin: cashQuoteReply.forwardWithMargin
              ? {
                  bid: +cashQuoteReply.forwardWithMargin.bid,
                  ask: +cashQuoteReply.forwardWithMargin.ask,
                }
              : { bid: 0, ask: 0 },
            spotWithMargin: cashQuoteReply.spotWithMargin
              ? {
                  bid: +cashQuoteReply.spotWithMargin.bid,
                  ask: +cashQuoteReply.spotWithMargin.ask,
                }
              : { bid: 0, ask: 0 },
            contraAmount: +cashQuoteReply.contraAmount,
            type: 'CASH.QUOTE.REPLY',
          }
        : {
            ...swapQuoteReply,
            timestamp: data.timestamp,
            farPoints: swapQuoteReply.farPoints
              ? {
                  bid: +swapQuoteReply.farPoints.bid,
                  ask: +swapQuoteReply.farPoints.ask,
                }
              : { bid: 0, ask: 0 },
            farPrice: swapQuoteReply.farPrice
              ? {
                  bid: +swapQuoteReply.farPrice.bid,
                  ask: +swapQuoteReply.farPrice.ask,
                }
              : { bid: 0, ask: 0 },
            nearPoints: swapQuoteReply.nearPoints
              ? {
                  bid: +swapQuoteReply.nearPoints.bid,
                  ask: +swapQuoteReply.nearPoints.ask,
                }
              : { bid: 0, ask: 0 },
            nearPrice: swapQuoteReply.nearPrice
              ? {
                  bid: +swapQuoteReply.nearPrice.bid,
                  ask: +swapQuoteReply.nearPrice.ask,
                }
              : { bid: 0, ask: 0 },
            spot: swapQuoteReply.spot
              ? {
                  bid: +swapQuoteReply.spot.bid,
                  ask: +swapQuoteReply.spot.ask,
                }
              : { bid: 0, ask: 0 },
            contraAmount: +swapQuoteReply.contraAmount,
            historicalDealOffRate: +swapQuoteReply.historicalDealOffRate,
            type: 'SWAP.QUOTE.REPLY',
          };
    }),
  );

export const execution$ = () =>
  getStreaming$().fillReport$.pipe(
    map((event): FillReport => {
      const data = toCamelCase(event);
      return {
        tradeIds: data.backOfficeReply.tradeIds,
        type: 'FILL.REPORT',
        rfsId: data.execute.rfsId,
      };
    }),
  );

export const dealReply$ = () =>
  getStreaming$().dealReply$.pipe(
    map((event): DealReply => {
      const data = toCamelCase(event);
      return {
        type: 'DEAL.REPLY',
        rfsId: data.execute.executionId,
      };
    }),
  );

export const executionError$ = () =>
  getStreaming$().dealRejected$.pipe(
    map((event): DealRejected => {
      const data = toCamelCase(event);
      return {
        ...data.dealReject,
        rfsId: data.execute.rfsId,
        type: 'DEAL.REJECTED',
      };
    }),
  );

export const blotterOrders$ = () => getStreaming$().blotterOrder$.pipe(map(mapOrderToAlert));

export const quoteErrors$ = () =>
  getStreaming$().quoteAbort$.pipe(
    map((event): QuoteAbort => {
      const data = toCamelCase(event);
      return {
        errorCode: 'SOMETHING ELSE',
        errorMessage: 'Received QUOTE.ABORT',
        type: 'QUOTE.ABORT',
        ...data.quoteReject,
      };
    }),
  );

export const ping$ = () => getStreaming$().ping$;

export const disconnect$ = () => getStreaming$().disconnect$;

export const toLogEvents$ = () =>
  merge(getStreaming$().dealReply$, getStreaming$().fillReport$, getStreaming$().dealRejected$);
