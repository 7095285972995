import { Epic, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppAction } from '../app.actions';
import { AppState } from '../app.state';
import { getActiveOrders } from '@/services/ordersBlotter.service';
import {
  alertsLoadedAction,
  alertsLoadingFailedAction,
  LoadingAlertsAction,
} from '@/store/state/alerts/alerts.actions';
import { logger } from '@/logging/logger';
import { extractError } from '@/utils/errors';

export const fetchActiveOrdersEpic = (
  fetchActive = getActiveOrders,
): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, LoadingAlertsAction>('LOADING_ALERTS'),
    filter(a => a.email !== undefined || state$.value.user.navigateAsUser === undefined),
    mergeMap(action =>
      fetchActive(action.email).pipe(
        map(alertsLoadedAction),
        catchError(error => {
          logger.logError('Fetching alerts failed', { error: extractError(error) });
          return of(alertsLoadingFailedAction());
        }),
      ),
    ),
  );
