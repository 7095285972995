import { Action } from '@reduxjs/toolkit';
import { actionNotifier } from './logActionMiddlewares/notifyActionMiddleware';

export const rememberLastActionsMiddleware = (actionsBuffer: Action[] = [], maxActions = 50) =>
  actionNotifier({
    didMatch: (action) => {
      actionsBuffer.unshift(action);
      actionsBuffer.splice(maxActions);
    },
  });
