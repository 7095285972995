import { Currency } from '@/models/currency';

export type CreateAlertActionStatus = 'Creating' | 'Updating' | 'Deleting' | 'Idle';

export type CreateAlertStateForm = {
  id: string | undefined;
  data: CreateAlertFormData;
  validation: CreateAlertValidationState;
  fixingExample: number | undefined;
  curently: CreateAlertActionStatus;
};
export type CreateAlertFormData = {
  baseCurrency: Currency;
  contraCurrency: Currency;
  expiryDate: Date | null;
  limitPrice: string;
};

type CreateAlertValidationState =
  | { status: 'error'; errors: CreateAlertErrors }
  | { status: 'ok' }
  | { status: 'validating' };

export const canSubmit = (c: CreateAlertValidationState) => c.status === 'ok';
export const getErrors = (c: CreateAlertValidationState) =>
  c.status === 'error' ? c.errors : { baseCurrency: [], contraCurrency: [], expiryDate: [] };
export type CreateAlertErrors = {
  [p in keyof CreateAlertFormData]: CreateAlertError[];
};
export type CreateAlertError = {
  errorDescription: string;
  errorCode: number;
};

export type CreateAlertState = CreateAlertStateForm;
