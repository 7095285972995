import { Reducer } from '@reduxjs/toolkit';
import { ForwardTrade } from '@/models/trade';
import { AppAction } from '@/store/app.actions';

export interface RolloverState {
  trade: ForwardTrade | undefined;
}

export const rolloverReducer: Reducer<RolloverState, AppAction> = (
  // eslint-disable-next-line default-param-last
  state = { trade: undefined },
  action: AppAction,
): RolloverState => {
  switch (action.type) {
    case 'START_ROLLOVER':
      return { trade: action.trade };

    case 'RESET':
      return { trade: undefined };

    default:
      return state;
  }
};
