import { Currency } from '@/models/currency';
import { Way } from '@/models/way';

export type SpotInstrumentName = 'FxSpot';
export type ForwardInstrumentName = 'FxOutright' | 'FxoAmericanForward' | 'FxNdf' | 'FxoDoubleAverageForward';
export type SwapInstrumentName = 'FxSwap' | 'FxNdSwap';
export type VanillaOptionInstrumentName = 'FxVanillaOption';
export type AverageOptionInstrumentName = 'FxoAverageOption';
export type DoubleAverageOptionInstrumentName = 'FxoDoubleAverageOption';
export type TriggerOptionInstrumentName = 'FxoTriggerOption';
export type OptionStrategyInstrumentName =
  | 'FxOptionStrategy'
  | 'FxOptionStraddle'
  | 'FxOptionStrangle'
  | 'FxOptionOutrightPackage'
  | 'FxOptionRiskReversal'
  | 'FxOptionKnockingPackage';
export type ForwardAccumulatorInstrumentName = 'FxoForwardAccumulator';
export type VanillaAccumulatorInstrumentName = 'FxoVanillaAccumulator';
export const allTargetAccumulatorInstrumentNames = [
  'FxoTargetAccumulator',
  'FxoSquareTargetAccumulator',
  'FxoPivotTargetAccumulator',
  'FxoPivotEKITargetAccumulator',
  'FxoPauseTargetAccumulator',
] as const;
export type TargetAccumulatorInstrumentName = (typeof allTargetAccumulatorInstrumentNames)[number];
type OtherTradeInstrumentName = 'Other';

export type InstrumentName =
  | SpotInstrumentName
  | ForwardInstrumentName
  | SwapInstrumentName
  | VanillaOptionInstrumentName
  | AverageOptionInstrumentName
  | DoubleAverageOptionInstrumentName
  | TriggerOptionInstrumentName
  | OptionStrategyInstrumentName
  | ForwardAccumulatorInstrumentName
  | VanillaAccumulatorInstrumentName
  | TargetAccumulatorInstrumentName
  | OtherTradeInstrumentName;

type Family<Name extends InstrumentName> =
  Name extends SpotInstrumentName ? 'Spots'
  : Name extends ForwardInstrumentName ? 'Forwards'
  : Name extends SwapInstrumentName ? 'Swaps'
  : Name extends VanillaOptionInstrumentName | AverageOptionInstrumentName | DoubleAverageOptionInstrumentName ? 'SimpleOptions'
  : Name extends TriggerOptionInstrumentName ? 'TriggerOptions'
  : Name extends OptionStrategyInstrumentName ? 'OptionStrategies'
  : Name extends ForwardAccumulatorInstrumentName | VanillaAccumulatorInstrumentName ? 'ForwardAccumulators'
  : Name extends TargetAccumulatorInstrumentName ? 'TargetAccumulators'
  : Name extends OtherTradeInstrumentName ? 'Other'
  : never;
export type TradeFamily = Family<InstrumentName>;

export const allTradeFamilies: readonly TradeFamily[] = [
  'Spots',
  'Forwards',
  'Swaps',
  'SimpleOptions',
  'TriggerOptions',
  'OptionStrategies',
  'ForwardAccumulators',
  'TargetAccumulators',
  'Other',
] as const;

type TradeFamilyGetter<TradeType extends MyHedgeTrade> = (trade: TradeType) => Family<TradeType['instrumentName']>;

export const getTradeFamily: TradeFamilyGetter<MyHedgeTrade> = (trade) => {
  switch (trade.instrumentName) {
    case 'FxSpot':
      return 'Spots';
    case 'FxOutright':
    case 'FxoAmericanForward':
    case 'FxNdf':
    case 'FxoDoubleAverageForward':
      return 'Forwards';
    case 'FxSwap':
    case 'FxNdSwap':
      return 'Swaps';
    case 'FxVanillaOption':
    case 'FxoAverageOption':
    case 'FxoDoubleAverageOption':
      return 'SimpleOptions';
    case 'FxoTriggerOption':
      return 'TriggerOptions';
    case 'FxOptionRiskReversal':
    case 'FxOptionOutrightPackage':
    case 'FxOptionKnockingPackage':
    case 'FxOptionStrategy':
    case 'FxOptionStraddle':
    case 'FxOptionStrangle':
      return 'OptionStrategies';
    case 'FxoForwardAccumulator':
    case 'FxoVanillaAccumulator':
      return 'ForwardAccumulators';
    case 'FxoTargetAccumulator':
    case 'FxoSquareTargetAccumulator':
    case 'FxoPivotTargetAccumulator':
    case 'FxoPivotEKITargetAccumulator':
    case 'FxoPauseTargetAccumulator':
      return 'TargetAccumulators';
    default:
      return 'Other';
  }
};

type RegularVanillaOptionOptionType = 'CE' | 'PE' | 'CA' | 'PA';
const allForwardVanillaOptions = ['CEFWD', 'PEFWD', 'CAFWD', 'PAFWD'] as const;
type ForwardVanillaOptionOptionType = (typeof allForwardVanillaOptions)[number];
const allCashCurrency1VanillaOptions = ['CECSD1', 'PECSD1', 'CACSD1', 'PACSD1'] as const;
const allCashCurrency2VanillaOptions = ['CECSD2', 'PECSD2', 'CACSD2', 'PACSD2'] as const;
type CashCurrency1VanillaOptionOptionType = (typeof allCashCurrency1VanillaOptions)[number];
type CashCurrency2VanillaOptionOptionType = (typeof allCashCurrency2VanillaOptions)[number];
type CashVanillaOptionOptionType = CashCurrency1VanillaOptionOptionType | CashCurrency2VanillaOptionOptionType;

export type VanillaOptionOptionType = RegularVanillaOptionOptionType | ForwardVanillaOptionOptionType | CashVanillaOptionOptionType;

export const isForwardVanillaOption = (type: VanillaOptionOptionType): type is ForwardVanillaOptionOptionType =>
  allForwardVanillaOptions.includes(type as ForwardVanillaOptionOptionType);

export const isCashCurrency1VanillaOption = (type: VanillaOptionOptionType): type is CashCurrency1VanillaOptionOptionType =>
  allCashCurrency1VanillaOptions.includes(type as CashCurrency1VanillaOptionOptionType);

export const isCashCurrency2VanillaOption = (type: VanillaOptionOptionType): type is CashCurrency2VanillaOptionOptionType =>
  allCashCurrency2VanillaOptions.includes(type as CashCurrency2VanillaOptionOptionType);

export const isEuropeanCall = (optionType: VanillaOptionOptionType) => ['CE', 'CEFWD', 'CECSD1', 'CECSD2'].includes(optionType);
export const isEuropeanPut = (optionType: VanillaOptionOptionType) => ['PE', 'PEFWD', 'PECSD1', 'PECSD2'].includes(optionType);
export const isAmericanCall = (optionType: VanillaOptionOptionType) => ['CA', 'CAFWD', 'CACSD1', 'CACSD2'].includes(optionType);
export const isAmericanPut = (optionType: VanillaOptionOptionType) => ['PA', 'PAFWD', 'PACSD1', 'PACSD2'].includes(optionType);

const asianCallOptionTypes = ['CAVG1', 'CAVG2'] as const;
type AsianCall = (typeof asianCallOptionTypes)[number];
const asianPutOptionTypes = ['PAVG1', 'PAVG2'] as const;
type AsianPut = (typeof asianPutOptionTypes)[number];
export type AverageOptionOptionType = AsianCall | AsianPut;

export const isAsianCall = (optionType: AverageOptionOptionType): optionType is AsianCall => asianCallOptionTypes.includes(optionType as AsianCall);
export const isAsianPut = (optionType: AverageOptionOptionType): optionType is AsianPut => asianPutOptionTypes.includes(optionType as AsianPut);

export type DoubleAverageOptionOptionType = 'CDAVG1' | 'CDAVG2' | 'PDAVG1' | 'PDAVG2';

export type RegularTriggerOptionOptionType =
  | 'CEDIE'
  | 'CEUIE'
  | 'CEDOE'
  | 'CEUOE'
  | 'CEDI'
  | 'CEUI'
  | 'CEDO'
  | 'CEUO'
  | 'PEDIE'
  | 'PEUIE'
  | 'PEDOE'
  | 'PEUOE'
  | 'PEDI'
  | 'PEUI'
  | 'PEDO'
  | 'PEUO'
  | 'CEDIEFWD'
  | 'CEUIEFWD'
  | 'CEDOEFWD'
  | 'CEUOEFWD'
  | 'CEDIFWD'
  | 'CEUIFWD'
  | 'CEDOFWD'
  | 'CEUOFWD'
  | 'PEDIEFWD'
  | 'PEUIEFWD'
  | 'PEDOEFWD'
  | 'PEUOEFWD'
  | 'PEDIFWD'
  | 'PEUIFWD'
  | 'PEDOFWD'
  | 'PEUOFWD'
  | 'CEDIECSD1'
  | 'CEUIECSD1'
  | 'CEDOECSD1'
  | 'CEUOECSD1'
  | 'CEDICSD1'
  | 'CEUICSD1'
  | 'CEDOCSD1'
  | 'CEUOCSD1'
  | 'PEDIECSD1'
  | 'PEUIECSD1'
  | 'PEDOECSD1'
  | 'PEUOECSD1'
  | 'PEDICSD1'
  | 'PEUICSD1'
  | 'PEDOCSD1'
  | 'PEUOCSD1';

const allWindowedTriggerOptionOptionTypes = [
  'CEDIW',
  'CEUIW',
  'CEDOW',
  'CEUOW',
  'PEDIW',
  'PEUIW',
  'PEDOW',
  'PEUOW',
  'CEDIWFWD',
  'CEUIWFWD',
  'CEDOWFWD',
  'CEUOWFWD',
  'PEDIWFWD',
  'PEUIWFWD',
  'PEDOWFWD',
  'PEUOWFWD',
  'CEDIWCSD1',
  'CEUIWCSD1',
  'CEDOWCSD1',
  'CEUOWCSD1',
  'PEDIWCSD1',
  'PEUIWCSD1',
  'PEDOWCSD1',
  'PEUOWCSD1',
] as const;
type WindowedTriggerOptionOptionType = (typeof allWindowedTriggerOptionOptionTypes)[number];

export const isWindowedTriggerOption = (value: TriggerOptionOptionType): value is WindowedTriggerOptionOptionType =>
  allWindowedTriggerOptionOptionTypes.includes(value as WindowedTriggerOptionOptionType);

const allAverageTriggerOptionOptionTypes = [
  'CEDIX',
  'CEUIX',
  'CEDOX',
  'CEUOX',
  'PEDIX',
  'PEUIX',
  'PEDOX',
  'PEUOX',
  'CEDIXFWD',
  'CEUIXFWD',
  'CEDOXFWD',
  'CEUOXFWD',
  'PEDIXFWD',
  'PEUIXFWD',
  'PEDOXFWD',
  'PEUOXFWD',
  'CEDIXCSD1',
  'CEUIXCSD1',
  'CEDOXCSD1',
  'CEUOXCSD1',
  'PEDIXCSD1',
  'PEUIXCSD1',
  'PEDOXCSD1',
  'PEUOXCSD1',
] as const;
type AverageTriggerOptionOptionType = (typeof allAverageTriggerOptionOptionTypes)[number];

export const isAverageTriggerOption = (value: TriggerOptionOptionType): value is AverageTriggerOptionOptionType =>
  allAverageTriggerOptionOptionTypes.includes(value as AverageTriggerOptionOptionType);

export type TriggerOptionOptionType = RegularTriggerOptionOptionType | WindowedTriggerOptionOptionType | AverageTriggerOptionOptionType;

export const isCashSettlementTriggerOption = (type: TriggerOptionOptionType) =>
  [
    'CEDIECSD1',
    'CEUIECSD1',
    'CEDOECSD1',
    'CEUOECSD1',
    'CEDICSD1',
    'CEUICSD1',
    'CEDOCSD1',
    'CEUOCSD1',
    'PEDIECSD1',
    'PEUIECSD1',
    'PEDOECSD1',
    'PEUOECSD1',
    'PEDICSD1',
    'PEUICSD1',
    'PEDOCSD1',
    'PEUOCSD1',
    'CEDIWCSD1',
    'CEUIWCSD1',
    'CEDOWCSD1',
    'CEUOWCSD1',
    'PEDIWCSD1',
    'PEUIWCSD1',
    'PEDOWCSD1',
    'PEUOWCSD1',
    'CEDIXCSD1',
    'CEUIXCSD1',
    'CEDOXCSD1',
    'CEUOXCSD1',
    'PEDIXCSD1',
    'PEUIXCSD1',
    'PEDOXCSD1',
    'PEUOXCSD1',
  ].includes(type);

export const isEuropeanCallWithEuropeanTriggerDownIn = (optionType: TriggerOptionOptionType) => ['CEDIE', 'CEDIEFWD', 'CEDIECSD1'].includes(optionType);

export const isEuropeanCallWithEuropeanTriggerUpIn = (optionType: TriggerOptionOptionType) => ['CEUIE', 'CEUIEFWD', 'CEUIECSD1'].includes(optionType);

export const isEuropeanCallWithEuropeanTriggerDownOut = (optionType: TriggerOptionOptionType) => ['CEDOE', 'CEDOEFWD', 'CEDOECSD1'].includes(optionType);

export const isEuropeanCallWithEuropeanTriggerUpOut = (optionType: TriggerOptionOptionType) => ['CEUOE', 'CEUOEFWD', 'CEUOECSD1'].includes(optionType);

export const isEuropeanCallWithAmericanTriggerDownIn = (optionType: TriggerOptionOptionType) =>
  ['CEDI', 'CEDIFWD', 'CEDICSD1', 'CEDIW', 'CEDIWFWD', 'CEDIWCSD1', 'CEDIX', 'CEDIXFWD', 'CEDIXCSD1'].includes(optionType);

export const isEuropeanCallWithAmericanTriggerUpIn = (optionType: TriggerOptionOptionType) =>
  ['CEUI', 'CEUIFWD', 'CEUICSD1', 'CEUIW', 'CEUIWFWD', 'CEUIWCSD1', 'CEUIX', 'CEUIXFWD', 'CEUIXCSD1'].includes(optionType);

export const isEuropeanCallWithAmericanTriggerDownOut = (optionType: TriggerOptionOptionType) =>
  ['CEDO', 'CEDOFWD', 'CEDOCSD1', 'CEDOW', 'CEDOWFWD', 'CEDOWCSD1', 'CEDOX', 'CEDOXFWD', 'CEDOXCSD1'].includes(optionType);

export const isEuropeanCallWithAmericanTriggerUpOut = (optionType: TriggerOptionOptionType) =>
  ['CEUO', 'CEUOFWD', 'CEUOCSD1', 'CEUOW', 'CEUOWFWD', 'CEUOWCSD1', 'CEUOX', 'CEUOXFWD', 'CEUOXCSD1'].includes(optionType);

export const isEuropeanPutWithEuropeanTriggerDownIn = (optionType: TriggerOptionOptionType) => ['PEDIE', 'PEDIEFWD', 'PEDIECSD1'].includes(optionType);

export const isEuropeanPutWithEuropeanTriggerUpIn = (optionType: TriggerOptionOptionType) => ['PEUIE', 'PEUIEFWD', 'PEUIECSD1'].includes(optionType);

export const isEuropeanPutWithEuropeanTriggerDownOut = (optionType: TriggerOptionOptionType) => ['PEDOE', 'PEDOEFWD', 'PEDOECSD1'].includes(optionType);

export const isEuropeanPutWithEuropeanTriggerUpOut = (optionType: TriggerOptionOptionType) => ['PEUOE', 'PEUOEFWD', 'PEUOECSD1'].includes(optionType);

export const isEuropeanPutWithAmericanTriggerDownIn = (optionType: TriggerOptionOptionType) =>
  ['PEDI', 'PEDIFWD', 'PEDICSD1', 'PEDIW', 'PEDIWFWD', 'PEDIWCSD1', 'PEDIX', 'PEDIXFWD', 'PEDIXCSD1'].includes(optionType);

export const isEuropeanPutWithAmericanTriggerUpIn = (optionType: TriggerOptionOptionType) =>
  ['PEUI', 'PEUIFWD', 'PEUICSD1', 'PEUIW', 'PEUIWFWD', 'PEUIWCSD1', 'PEUIX', 'PEUIXFWD', 'PEUIXCSD1'].includes(optionType);

export const isEuropeanPutWithAmericanTriggerDownOut = (optionType: TriggerOptionOptionType) =>
  ['PEDO', 'PEDOFWD', 'PEDOCSD1', 'PEDOW', 'PEDOWFWD', 'PEDOWCSD1', 'PEDOX', 'PEDOXFWD', 'PEDOXCSD1'].includes(optionType);

export const isEuropeanPutWithAmericanTriggerUpOut = (optionType: TriggerOptionOptionType) =>
  ['PEUO', 'PEUOFWD', 'PEUOCSD1', 'PEUOW', 'PEUOWFWD', 'PEUOWCSD1', 'PEUOX', 'PEUOXFWD', 'PEUOXCSD1'].includes(optionType);

type StandardForwardAccumulatorOptionType =
  | 'AFWAC1'
  | 'AFWAC2'
  | 'AFWACUO1'
  | 'AFWACUO1F'
  | 'AFWACUO2'
  | 'AFWACUO2F'
  | 'AFWACDO1'
  | 'AFWACDO1F'
  | 'AFWACDO2'
  | 'AFWACDO2F'
  | 'FWAC1'
  | 'FWAC2'
  | 'FWACUO1'
  | 'FWACUO1F'
  | 'FWACUO2'
  | 'FWACUO2F'
  | 'FWACDO1'
  | 'FWACDO1F'
  | 'FWACDO2'
  | 'FWACDO2F';
type PermanentForwardAccumulatorOptionType =
  | 'AFWACP1'
  | 'AFWACP2'
  | 'AFWACPDO1'
  | 'AFWACPDO1F'
  | 'AFWACPDO2'
  | 'AFWACPDO2F'
  | 'AFWACPUO1'
  | 'AFWACPUO1F'
  | 'AFWACPUO2'
  | 'AFWACPUO2F'
  | 'FWACP1'
  | 'FWACP2'
  | 'FWACPDO1'
  | 'FWACPDO1F'
  | 'FWACPDO2'
  | 'FWACPDO2F'
  | 'FWACPUO1'
  | 'FWACPUO1F'
  | 'FWACPUO2'
  | 'FWACPUO2F';
type SoftForwardAccumulatorOptionType =
  | 'AFWACSDO1'
  | 'AFWACSDO1F'
  | 'AFWACSDO2'
  | 'AFWACSDO2F'
  | 'AFWACSUO1'
  | 'AFWACSUO1F'
  | 'AFWACSUO2'
  | 'AFWACSUO2F'
  | 'FWACSDO1'
  | 'FWACSDO1F'
  | 'FWACSDO2'
  | 'FWACSDO2F'
  | 'FWACSUO1'
  | 'FWACSUO1F'
  | 'FWACSUO2'
  | 'FWACSUO2F';
type ForwardAccumulatorOptionType = StandardForwardAccumulatorOptionType | PermanentForwardAccumulatorOptionType | SoftForwardAccumulatorOptionType;

type VanillaAccumulatorOptionType =
  | 'ACAT1 CEDI'
  | 'ACAT1 CEDO'
  | 'ACAT1 PEDO'
  | 'ACAT2 CEDO'
  | 'ACAT2 CEUI'
  | 'ACAT2 CEUO'
  | 'ACAT2 PEDO'
  | 'ACAT2 PEUO'
  | 'ACDP1 CEUI'
  | 'ACDP2 CEUO'
  | 'ACDP2 PEDO'
  | 'ACDPDO1 CEUI'
  | 'ACDPDO2 CEDO'
  | 'ACDPDO2 CEUI'
  | 'ACDPDO2 CEUO'
  | 'ACDPUO1 CEUI'
  | 'ACDPUO1 PEDO'
  | 'ACDPUO2 PEDO'
  | 'ACDPUO2 PEUO'
  | 'ACVA1 CE'
  | 'ACVA1 PE'
  | 'ACVA2 CE'
  | 'ACVA2 PE'
  | 'ACVADO1 PE'
  | 'ACVADO2 CE'
  | 'ACVADO2 PE'
  | 'ACVAUO1 CE'
  | 'ACVAUO2 CE'
  | 'ACVAUO2 PE';

type AccumulatorOptionType = ForwardAccumulatorOptionType | VanillaAccumulatorOptionType;

type TradeStatus = 'BookingValidated'; // TODO JLE: Find all possible values
type TradeProduct = 'spots' | 'futures' | 'swaps' | 'options' | 'other';

type StrikeRanges = {
  beginDate: string;
  endDate: string;
  strike: number;
};

interface Trade {
  entity?: string; // TODO JLE: Check if this prop is really otpional
  currencyPair: string;
  trader?: string; // TODO JLE: Check if this prop is really otpional
  status?: TradeStatus; // TODO JLE: Check if this prop is really otpional
  masterReference?: string;
  originalReference?: string;
  reference: string;
  date: string;
  side: Way;
  product?: TradeProduct; // TODO JLE: Check if this prop is really otpional
  instrumentName: InstrumentName;
  nominal?: number;
  amountCurrency: Currency;
  remainingAmount?: number;
  strike: number;
  strikeUp?: number;
  strikeDown?: number;
  modificationDate?: string; // TODO JLE: Check if this prop is really otpional
  maturitydate: string;
  eConfirmation?: boolean; // TODO JLE: Check if this prop is really otpional
  description?: string; // TODO JLE: Check if this prop is really otpional
  sub: Exclude<MyHedgeTrade, OptionStrategy>[];
}

export interface SpotTrade extends Trade {
  instrumentName: SpotInstrumentName;
  fxSpot: number;
}

export interface ForwardTrade extends Trade {
  instrumentName: ForwardInstrumentName;
  fxSpot: number;
  farPoints: number;
}

export interface SwapTrade extends Trade {
  instrumentName: SwapInstrumentName;
  fxSpot: number;
  farPoints: number;
}

interface RegularVanillaOptionTrade extends Trade {
  instrumentName: VanillaOptionInstrumentName;
  optionType: RegularVanillaOptionOptionType;
  fxSpot: number;
}

interface ForwardVanillaOptionTrade extends Trade {
  instrumentName: VanillaOptionInstrumentName;
  optionType: ForwardVanillaOptionOptionType;
}

interface CashVanillaOptionTrade extends Trade {
  instrumentName: VanillaOptionInstrumentName;
  optionType: CashVanillaOptionOptionType;
}

interface AverageOptionTrade extends Trade {
  instrumentName: AverageOptionInstrumentName;
  optionType: AverageOptionOptionType;
}

interface DoubleAverageOptionTrade extends Trade {
  instrumentName: DoubleAverageOptionInstrumentName;
  optionType: DoubleAverageOptionOptionType;
}

type VanillaOptionTrade = RegularVanillaOptionTrade | ForwardVanillaOptionTrade | CashVanillaOptionTrade;

export type SimpleOptionTrade = VanillaOptionTrade | AverageOptionTrade | DoubleAverageOptionTrade;

export interface Barrier {
  barrierLevel: number[];
  // 1 <=> KnockIn && 2 <==> KnockOut
  knockType: 1 | 2;
}

export interface TriggerOptionTrade extends Trade {
  instrumentName: TriggerOptionInstrumentName;
  optionType: TriggerOptionOptionType;
  barriers: Barrier[];
}

export interface OptionStrategy extends Trade {
  instrumentName: OptionStrategyInstrumentName;
}

export interface XOneCalendarEntry {
  fixingDate: string;
  payDate: string;
  strike?: number;
  amount1: number;
  amount2: number;
  amount3?: number;
  fixing?: number;
  strike1?: number;
  strike2?: number;
  step?: number;
  step1?: number;
  step2?: number;
  steps?: string;
  leverage?: number;
  power: string; // TODO JLE: Obtain real type, only null values in samples
}

interface Accumulator extends Trade {
  accumulatedAmount: number;
  strikeRanges?: StrikeRanges[];
  xOneCalendar: XOneCalendarEntry[];
}

type StandardForwardAccumulatorType = 'standard';
type PermanentForwardAccumulatorType = 'permanent';
type SoftForwardAccumulatorType = 'soft';

export type ForwardAccumulatorTypes = StandardForwardAccumulatorType | SoftForwardAccumulatorType | PermanentForwardAccumulatorType;

interface StandardForwardAccumulatorTrade extends Accumulator {
  instrumentName: ForwardAccumulatorInstrumentName;
  optionType: StandardForwardAccumulatorOptionType;
  accumulatorType: StandardForwardAccumulatorType;
  barriers: Barrier[];
}

interface PermanentForwardAccumulatorTrade extends Accumulator {
  instrumentName: ForwardAccumulatorInstrumentName;
  optionType: PermanentForwardAccumulatorOptionType;
  accumulatorType: PermanentForwardAccumulatorType;
  barriers: Barrier[];
}

interface SoftForwardAccumulatorTrade extends Accumulator {
  instrumentName: ForwardAccumulatorInstrumentName;
  optionType: SoftForwardAccumulatorOptionType;
  accumulatorType: SoftForwardAccumulatorType;
  barriers: Barrier[];
}
export type ForwardAccumulatorTrade = StandardForwardAccumulatorTrade | PermanentForwardAccumulatorTrade | SoftForwardAccumulatorTrade;

interface VanillaAccumulatorTrade extends Accumulator {
  instrumentName: VanillaAccumulatorInstrumentName;
  optionType: VanillaAccumulatorOptionType;
  barriers: Barrier[];
}

export type ForwarAndVanillaAccumulatorTrade = ForwardAccumulatorTrade | VanillaAccumulatorTrade;

export type AccumulatorTrade = ForwardAccumulatorTrade | VanillaAccumulatorTrade | TargetAccumulatorTrade;

export type AccumulatorInstrumentName = AccumulatorTrade['instrumentName'];

export interface TargetAccumulatorTrade extends Accumulator {
  instrumentName: TargetAccumulatorInstrumentName;
  target: number;
  consumedTarget: number;
  leverage?: number;
  pivot?: number;
  currencyPairSide: Way;
}

export interface OtherTrade extends Trade {
  instrumentName: OtherTradeInstrumentName;
}

export type MyHedgeTrade = SpotTrade | ForwardTrade | SwapTrade | SimpleOptionTrade | TriggerOptionTrade | OptionStrategy | AccumulatorTrade | OtherTrade;

interface Formatted<TradeType extends MyHedgeTrade> {
  family: Family<TradeType['instrumentName']>;
}

export interface FormattedTrade extends Formatted<MyHedgeTrade> {
  amountCurrency: string;
  currencyPair: string;
  date: string;
  isToday: boolean;
  productName: string;
  masterReference?: string;
  maturitydate: string;
  amount: string;
  secondAmount?: string;
  remainingAmount: string;
  nominal?: string;
  originalReference?: string;
  reference: string;
  way: string;
  side: string;
  strike: string;
  badges: string[];
  showInfo: boolean;
  fxSpot?: string;
  farPoints?: string;
  subCount?: number;
  twoDaysBeforePaymentDate?: string;
}

export interface CsvFormattedTrade {
  currencyPair: string;
  date: string;
  instrumentName: InstrumentName;
  maturitydate: string;
  nominal: string;
  remainingAmount: string;
  reference: string;
  way: string;
  strike: string;
}

export type FormattedFlow = {
  paymentDate: string;
  contractId: string;
  currency: Currency | undefined;
  currencyPair: string;
  productName: string;
  isCertain?: boolean;
  amount: string;
  way: string;
  strike: string;
  twoDaysBeforePaymentDate?: string;
};

export type InvalidKind = 'OVER' | 'UNDER';

export type TradeActionType = 'rollover' | 'predeliver';
