import { FormattedMessage } from 'react-intl';
import { LeftSide, RightSide } from './Shared';

export type Product =
  | {
      name: 'FxSpot';
      spotRate: string;
    }
  | {
      name: 'FxFwd';
      spotRate: string;
      forwardPoints: string;
      forwardRate: string;
    }
  | {
      name: 'FxPredeliver';
      spotRate: string;
      predeliverPoints: string;
      predeliverRate: string;
    }
  | {
      name: 'FxRollover';
      spotRate: string;
      rolloverPoints: string;
      rolloverRate: string;
    };

export function ProductDetails({
  date,
  product,
}: { date: string; product: Product }) {
  switch (product.name) {
    case 'FxSpot':
      return (
        <>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.spotDate" />
          </LeftSide>
          <RightSide>{date}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.spotRate" />
          </LeftSide>
          <RightSide dataE2E="product-details-spot-rate">{product.spotRate}</RightSide>
        </>
      );
    case 'FxFwd':
      return (
        <>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.forwardRate" />
          </LeftSide>
          <RightSide>{product.forwardRate}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.forwardSpotRate" />
          </LeftSide>
          <RightSide dataE2E="product-details-spot-rate">{product.spotRate}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.forwardPoints" />
          </LeftSide>
          <RightSide>{product.forwardPoints}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.forwardDate" />
          </LeftSide>
          <RightSide dataE2E="forward-date">{date}</RightSide>
        </>
      );
    case 'FxPredeliver':
      return (
        <>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.predeliverRate" />
          </LeftSide>
          <RightSide>{product.predeliverRate}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.predeliverSpotRate" />
          </LeftSide>
          <RightSide>
            <span>{product.spotRate}</span>
          </RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.predeliverPoints" />
          </LeftSide>
          <RightSide>
            <span>{product.predeliverPoints}</span>
          </RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.predeliverDate" />
          </LeftSide>
          <RightSide>{date}</RightSide>
        </>
      );
    case 'FxRollover':
      return (
        <>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.rolloverRate" />
          </LeftSide>
          <RightSide>{product.rolloverRate}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.rolloverSpotRate" />
          </LeftSide>
          <RightSide dataE2E="product-details-spot-rate">{product.spotRate}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.rolloverPoints" />
          </LeftSide>
          <RightSide>{product.rolloverPoints}</RightSide>
          <LeftSide>
            <FormattedMessage id="trader.successModal.productDetails.rolloverDate" />
          </LeftSide>
          <RightSide>{date}</RightSide>
        </>
      );
    default:{
      const exhaustiveCheck: never = product
      return exhaustiveCheck
    }
  }
};
