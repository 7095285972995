import {
  AccumulatorInstrumentName,
  AccumulatorTrade,
  allTargetAccumulatorInstrumentNames,
  ForwardAccumulatorTrade,
  MyHedgeTrade,
  TargetAccumulatorInstrumentName,
  TargetAccumulatorTrade,
  TradeFamily,
} from '@/models/trade';

export function isNotNullNorUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const isTargetAccu = (trade: AccumulatorTrade): trade is TargetAccumulatorTrade =>
  allTargetAccumulatorInstrumentNames.includes(
    trade.instrumentName as TargetAccumulatorInstrumentName,
  );

export const isAccumulator = (
  trade: MyHedgeTrade,
  family: TradeFamily,
): trade is AccumulatorTrade => ['ForwardAccumulators', 'TargetAccumulators'].includes(family);

export const isForwardAccu = (
  instrumentName: AccumulatorInstrumentName,
  trade: AccumulatorTrade,
): trade is ForwardAccumulatorTrade => instrumentName === 'FxoForwardAccumulator';
