import { useSelector } from 'react-redux';
import { Navigate  } from 'react-router';
import { routes } from '../routes';
import { AccumulatorTrade } from '@/models/trade';
import { selectSelectedTrade } from '@/store/state/trades/trades.selectors';
import { CalendarPage } from './CalendarPage';

import './Calendar.scss';
import { MainLayout } from '../Layout/MainLayout';

export function TraderChecker() {
  const trade = useSelector(selectSelectedTrade()) as AccumulatorTrade;

  if (!trade) {
    return (
      <Navigate 
        to={{
          pathname: routes.HOME,
        }}
        replace 
      />
    );
  }

  return (
    <MainLayout hasCustomizedHeader>
      <CalendarPage trade={trade} />
    </MainLayout>
  );
}
