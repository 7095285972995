/* eslint-disable no-param-reassign */

import { isNotDefined } from '@sgme/fp';
import {
  DecoratedFields,
  FxoPermanentForwardAccumulatorBaseCalendarFields,
  FxoPermanentForwardAccumulatorCalendarFields,
  possibleIsCheckedFieldsName,
} from '@/models/calendar';
import { ForwardAccumulatorTrade, XOneCalendarEntry } from '@/models/trade';

export const fxoPermanentForwardAccumulatorFieldsConfig: ReadonlyArray<keyof XOneCalendarEntry> = [
  'fixingDate',
  'payDate',
  'amount3',
  'amount2',
  'amount1',
  'step1',
  'step2',
  'fixing',
  'strike',
];

const isAmount = (
  key: keyof FxoPermanentForwardAccumulatorBaseCalendarFields,
): key is 'amount1' | 'amount2' | 'amount3' => ['amount1', 'amount2', 'amount3'].includes(key);

//
//
//
//  ██████╗ ███████╗████████╗    ██╗   ██╗ █████╗ ██╗     ██╗   ██╗███████╗
// ██╔════╝ ██╔════╝╚══██╔══╝    ██║   ██║██╔══██╗██║     ██║   ██║██╔════╝
// ██║  ███╗█████╗     ██║       ██║   ██║███████║██║     ██║   ██║█████╗
// ██║   ██║██╔══╝     ██║       ╚██╗ ██╔╝██╔══██║██║     ██║   ██║██╔══╝
// ╚██████╔╝███████╗   ██║        ╚████╔╝ ██║  ██║███████╗╚██████╔╝███████╗
//  ╚═════╝ ╚══════╝   ╚═╝         ╚═══╝  ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚══════╝

const getIsCheckedForFxoPermanentForwardAccumulator = (
  rowValues: FxoPermanentForwardAccumulatorBaseCalendarFields,
  currentField: possibleIsCheckedFieldsName,
): boolean => {
  if (isNotDefined(rowValues.fixing)) {
    return false;
  }
  // TODO ; we shouldn't make non null assertion, we should have specified type for each product
  if (currentField === 'amount1') {
    return rowValues?.fixing > rowValues.step2!;
  }
  if (currentField === 'amount2') {
    return rowValues?.fixing >= rowValues.step1! && rowValues?.fixing <= rowValues.step2!;
  }
  if (currentField === 'amount3') {
    return rowValues?.fixing < rowValues.step1!;
  }

  return false;
};

export const getIsKoForFxPermanentForwardAccumulator = (
  { fixing }: FxoPermanentForwardAccumulatorBaseCalendarFields,
  { barriers, strike }: ForwardAccumulatorTrade,
): boolean => {
  // 1 <=> KnockIn && 2 <==> KnockOut
  const isKnockOut = barriers[0]?.knockType === 2;
  const barrierLevel = barriers?.[0]?.barrierLevel[0];

  if (isNotDefined(fixing)) {
    return false;
  }

  if (isKnockOut) {
    if (
      (strike <= barrierLevel && fixing >= barrierLevel) ||
      (strike > barrierLevel && fixing <= barrierLevel)
    ) {
      return true;
    }
  }

  return false;
};

const isKoAlreadyTriggered = (
  allRows: DecoratedFields<FxoPermanentForwardAccumulatorBaseCalendarFields>[],
  index: number,
): boolean =>
  allRows.some(({ fixing }, innerKey) => {
    if (innerKey <= index) {
      if (fixing.isKo) {
        return true;
      }
    }
    return false;
  });

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const computeCumulatedAmount = (
  allRows: DecoratedFields<FxoPermanentForwardAccumulatorBaseCalendarFields>[],
  index: number,
): number =>
  allRows.reduce((acc, { amount1, amount2, amount3 }, innerKey) => {
    if (innerKey <= index) {
      if (amount1?.isChecked) {
        acc += amount1.value as number;
      }

      if (amount2?.isChecked) {
        acc += amount2.value as number;
      }

      if (amount3?.isChecked) {
        acc += amount3.value as number;
      }
    }
    return acc;
  }, 0);

//
//
//
// ██████╗ ███████╗ ██████╗ ██████╗ ██████╗  █████╗ ████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║  ██║█████╗  ██║     ██║   ██║██████╔╝███████║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║
// ██║  ██║██╔══╝  ██║     ██║   ██║██╔══██╗██╔══██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██████╔╝███████╗╚██████╗╚██████╔╝██║  ██║██║  ██║   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝
// ╚═════╝ ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const decorateAmountAndFixing = (
  rowsFields: FxoPermanentForwardAccumulatorBaseCalendarFields,
  trade: ForwardAccumulatorTrade,
) => {
  const { barriers } = trade;
  // 1 <=> KnockIn && 2 <==> KnockOut
  const isFixingEditable = !(barriers[0]?.knockType === 2) && !(barriers[0]?.knockType === 1);

  return Object.entries(rowsFields).reduce((result, [key, value]) => {
    const currentKey = key as keyof FxoPermanentForwardAccumulatorBaseCalendarFields;
    if (isAmount(currentKey)) {
      result[currentKey] = {
        value,
        isChecked: getIsCheckedForFxoPermanentForwardAccumulator(
          rowsFields,
          currentKey as 'amount1' | 'amount2' | 'amount3',
        ),
        overriddenPrecision: 2,
      };
    } else if (currentKey === 'fixing') {
      result[currentKey] = {
        value,
        isFixingEditable,
        isKo: getIsKoForFxPermanentForwardAccumulator(rowsFields, trade),
      };
    } else {
      result[currentKey] = { value };
    }
    return result;
  }, {} as DecoratedFields<FxoPermanentForwardAccumulatorBaseCalendarFields>);
};

//
//
//
//  █████╗ ██████╗ ██████╗     ███████╗██╗███████╗██╗     ██████╗
// ██╔══██╗██╔══██╗██╔══██╗    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ███████║██║  ██║██║  ██║    █████╗  ██║█████╗  ██║     ██║  ██║
// ██╔══██║██║  ██║██║  ██║    ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██║  ██║██████╔╝██████╔╝    ██║     ██║███████╗███████╗██████╔╝
// ╚═╝  ╚═╝╚═════╝ ╚═════╝     ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

const addCumulatedAmountAndAdaptIsCheked = (
  rowFields: DecoratedFields<FxoPermanentForwardAccumulatorBaseCalendarFields>,
  rowsWithIsCheckedAndIsKo: DecoratedFields<FxoPermanentForwardAccumulatorBaseCalendarFields>[],
  rowIndex: number,
) =>
  Object.entries(rowFields).reduce((acc, [key, value]) => {
    const currentKey = key as keyof FxoPermanentForwardAccumulatorBaseCalendarFields;
    const isKoTriggered = isKoAlreadyTriggered(rowsWithIsCheckedAndIsKo, rowIndex);

    acc[currentKey] = value;

    if (isAmount(currentKey) && isKoTriggered) {
      acc[currentKey].isChecked = false;
    }

    if (currentKey === 'amount1') {
      const checkedField = Object.values(rowFields).find(({ isChecked }) => isChecked);

      acc.cumulatedAmount = {
        value: checkedField
          ? computeCumulatedAmount(rowsWithIsCheckedAndIsKo, rowIndex)
          : undefined,
        overriddenPrecision: 2,
        isCumulatedAmount: true,
      };
    }
    return acc;
  }, {} as DecoratedFields<FxoPermanentForwardAccumulatorCalendarFields>);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

export const computeRowFieldsForFxoPermanentForwardAccumulator = (
  baseRowsFields: FxoPermanentForwardAccumulatorBaseCalendarFields[],
  trade: ForwardAccumulatorTrade,
): Array<DecoratedFields<FxoPermanentForwardAccumulatorCalendarFields>> => {
  const rowsWithIsCheckedAndIsKo = baseRowsFields.map(rowsFields =>
    decorateAmountAndFixing(rowsFields, trade),
  );

  const finalRows = rowsWithIsCheckedAndIsKo.map((rowFields, rowIndex) =>
    addCumulatedAmountAndAdaptIsCheked(rowFields, rowsWithIsCheckedAndIsKo, rowIndex),
  );

  return finalRows;
};
