import { AccountSelector } from "@/App/AccountSelector/AccountSelector"
import { Growls } from "@/App/Growls/Growls"
import { Title } from "@/App/Header/Title"
import { OperationSummary } from "../Shared/OperationSummary"


export function RolloverHeader() {

  return (
    <div className="border-bottom pb-3">
    <div className="header2 position-relative">
      <Growls />
      <div className="d-md-flex pb-3">
        <Title />
        <div className="buttons">
          <AccountSelector />
        </div>
      </div>
      <div className='mt-2'>
      <OperationSummary type="rollover" />
      </div>
    </div>
  </div>
  )
}