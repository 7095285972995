import { Action, configureStore, Dispatch, Middleware, StoreEnhancer } from '@reduxjs/toolkit';
import { ActionsObservable, createEpicMiddleware, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppAction } from './app.actions';
import { appEpic } from './app.epic';
import { rootReducer } from './app.reducers';
import { AppState } from './app.state';
import { piwikMiddleware } from './redux-middlewares/piwik.middleware';
import { localeMiddleware } from './state/ui/locale.middleware';
import { logActionMiddleware } from './redux-middlewares/logActionMiddlewares';
import { crashHandlerMiddleware } from './redux-middlewares/tryCatchMiddleware';
import { rememberLastActionsMiddleware } from './redux-middlewares/rememberLastActionsMiddleware';
import { autosendHelpRequestMiddleware } from './redux-middlewares/sendHelpRequestMiddleware';
import { isDev } from '@/config/app.config';
import { upcomingFlowsApi } from './state/upcomingFlows/upcomingFlows.api';
import { tradesApi } from './state/trades/trades.api';
import { boostMailApi } from './boost-mail/boostMail.api';

const initialState = {};
const enhancers: StoreEnhancer[] = [];

if (!window.sgmeConfiguration.env.includes('prod')) {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// @ts-ignore
export const getNewStore = (preloadedState = {}) => {
  const lastActions: Action[] = [];

  function getLastActions(): readonly Action[] {
    return [...lastActions].reverse();
  }

  const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState>();

  // @ts-ignore
  const storeRTK = {
    ...configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
          // to catch all errors (from reducers, middlewares and thunks)
          // crashHandlerMiddleware has to be the first one
          .prepend(crashHandlerMiddleware)
          .concat(logActionMiddleware)
          .concat(rememberLastActionsMiddleware(lastActions))
          .concat(piwikMiddleware)
          .concat(localeMiddleware)
          .concat(epicMiddleware as Middleware)
          .concat(upcomingFlowsApi.middleware)
          .concat(tradesApi.middleware)
          .concat(boostMailApi.middleware)
          .concat(autosendHelpRequestMiddleware(isDev, () => storeRTK)),
      preloadedState: { ...initialState, ...preloadedState },
    }),
    getLastActions,
  };

  epicMiddleware.run(appEpic);

  return storeRTK;
};

const store = getNewStore();

export const getStore = () => store;

export type AppStore = ReturnType<typeof getNewStore>;

export type AppDispatch = Dispatch<AppAction>;

export interface DispatchExt {
  dispatch: AppDispatch;
}

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch: () => AppStore['dispatch'] = useDispatch;

export type Epic<Input extends Action = Action, Output = Input, State = unknown, Dependencies = unknown> = (
  action$: ActionsObservable<Input>,
  state$: StateObservable<State>,
  dependencies: Dependencies,
) => Observable<Output>;

export type EpicWithThunk = Epic<AppAction, AppAction, AppState, undefined>;
