import { PropsWithChildren } from 'react';
import { Header2 } from '../Header2';

interface MainLayoutProps {
  hasCustomizedHeader?: boolean;
}

export function MainLayout({ children, hasCustomizedHeader }: PropsWithChildren<MainLayoutProps>) {
  return (
    <div>
      {!hasCustomizedHeader && <Header2 />}

      <div className="main container-xxl">{children}</div>
    </div>
  );
}
