/* eslint-disable default-param-last */
import { ofType } from 'redux-observable';
import { catchError, mapTo, mergeMap, repeat, withLatestFrom } from 'rxjs/operators';
import { concat, of, SchedulerLike, timer } from 'rxjs';
import { v4 } from 'uuid';
import { AppAction } from '../app.actions';
import { CreateAlert, finishedCreateAlert } from '@/store/state/create-alert/create-alert.actions';
import { CreateAlertFormData } from '@/store/state/create-alert/create-alert.model';
import { http } from '@/utils/http';
import { Growl } from '@/store/state/growls/growls.model';
import { formatDateLocalized } from '@/utils/locale';
import { EpicWithThunk } from '@/store/store';
import { addGrowlAction, autoHideGrowlDelay, removeGrowlAction } from '@/store/state/growls/growls.actions';

export const createAlertSuccessGrowl: Growl = {
  titleId: 'alerts.createSuccess.title',
  type: 'success',
};

export const createAlertErrorGrowl: Growl = {
  titleId: 'alerts.createError.title',
  type: 'danger',
};

const callOms = (bdrId: string | undefined, email: string | undefined, c: CreateAlertFormData) =>
  http.post('oms/submit/Call?forceDirectPair=true', {
    isGtc: c.expiryDate === null,
    bdrId,
    ccyPair: `${c.baseCurrency}/${c.contraCurrency}`,
    base64Emails: email ? btoa(email) : '',
    ...(c.expiryDate ? { expiryDay: formatDateLocalized(c.expiryDate, 'yyyy-MM-dd'), expiryTime: '17:00:00' } : {}),
    limitPrice: Number(c.limitPrice),
  });

export const createAlertOmsEpic =
  (request = callOms, _autoHideGrowlDelay = autoHideGrowlDelay, newGuid: () => string = v4, scheduler?: SchedulerLike): EpicWithThunk =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, CreateAlert>('CreateAlert'),
      withLatestFrom(state$),
      mergeMap(([, s]) =>
        concat(
          request(s.user.selectedCompany?.companyBdrId, s.user.currentUser?.email, s.createAlert.data).pipe(
            withLatestFrom(state$),
            mergeMap(() => {
              const growlId = newGuid();
              return concat(
                of(addGrowlAction(createAlertSuccessGrowl, growlId), finishedCreateAlert()),
                timer(_autoHideGrowlDelay, undefined, scheduler).pipe(mapTo(removeGrowlAction(growlId))),
              );
            }),
          ),
        ),
      ),
      catchError((_) => of(addGrowlAction(createAlertErrorGrowl, newGuid()), finishedCreateAlert())),
      repeat(),
    );
