import { isSameDay, parseISO } from 'date-fns';
import { TradeFamily, MyHedgeTrade, getTradeFamily } from '@/models/trade';
import { DATE_LONG_FORMAT, DATE_SHORT_FORMAT } from '@/utils/format';
import { formatDateLocalized } from '@/utils/locale';

export interface TradesFilters {
  families: TradeFamily[];
  currencyPairs: string[];
}

const formatDate = (date: string, format: typeof DATE_LONG_FORMAT | typeof DATE_SHORT_FORMAT) =>
  formatDateLocalized(parseISO(date), format);
const formatDateLong = (date: string) => formatDate(date, DATE_LONG_FORMAT);
const formatDateShort = (date: string) => formatDate(date, DATE_SHORT_FORMAT);

export const isTodayTrades = (trades: MyHedgeTrade[]) =>
  trades.filter(trade => isSameDay(parseISO(trade.date), new Date()));

const getCurrentTrades = (trades: MyHedgeTrade[], areTodayTrades: boolean) =>
  areTodayTrades ? isTodayTrades(trades) : trades;

const getSearchedTrades = (currentTrades: MyHedgeTrade[], search = '') => {
  const regExp = new RegExp(search, 'i');

  const searchedTrades =
    search.length > 0
      ? currentTrades.filter(trade => {
          const row =
            JSON.stringify(trade) +
            formatDateLong(trade.maturitydate) +
            formatDateLong(trade.date) +
            formatDateShort(trade.maturitydate) +
            formatDateShort(trade.date);
          return row.match(regExp);
        })
      : currentTrades;

  return searchedTrades;
};

const getFilteredTrades = (
  searchedTrades: MyHedgeTrade[],
  { families, currencyPairs }: TradesFilters,
) =>
  families.length > 0 || currencyPairs.length > 0
    ? searchedTrades.filter(
        trade =>
          (families.length === 0 ||
            families.includes(getTradeFamily(trade)) ||
            trade.sub.some(subTrade => families.includes(getTradeFamily(subTrade)))) &&
          (currencyPairs.length === 0 || currencyPairs.includes(trade.currencyPair)),
      )
    : searchedTrades;

export const processTrades = (
  trades: MyHedgeTrade[],
  areTodayTrades: boolean,
  search: string,
  filters: TradesFilters,
) => {
  const currentTrades = getCurrentTrades(trades, areTodayTrades);
  const searchedTrades = getSearchedTrades(currentTrades, search);
  const filteredTrades = getFilteredTrades(searchedTrades, filters);

  return {
    currentTrades,
    searchedTrades,
    filteredTrades,
  };
};
