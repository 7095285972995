import { useSelector } from 'react-redux';
import { refreshTradesAction } from '@/store/state/trades/trades.actions';
import { TradesReceived } from './TradesReceived';
import { TradesEmpty } from './TradesEmpty';
import { FetchingData } from '../Shared/FetchingData';
import { TechnicalError } from '../Shared/TechnicalError';
import { selectReceivedTrades, selectTrades } from '@/store/state/trades/trades.selectors';

import './Trades.scss';
import { MainLayout } from '../Layout/MainLayout';
import { useAppDispatch } from '@/store/store';

export function Trades() {
  const dispatch = useAppDispatch();

  const trades = useSelector(selectTrades);
  const tradesData = useSelector(selectReceivedTrades);

  switch (trades.status) {
    case 'received':
      if (tradesData.length === 0) {
        return (
          <MainLayout>
            <TradesEmpty source="api" />
          </MainLayout>
        );
      }

      return <TradesReceived trades={tradesData} />;

    case 'fetching':
      return <FetchingData />;

    case 'error':
      return <TechnicalError refresh={() => dispatch(refreshTradesAction())} />;

    default: {
      const exhaustiveCheck: never = trades;
      return exhaustiveCheck;
    }
  }
}
