import { blockExecutionAction } from './ui.actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { AppState } from '@/store/app.state';

export const blockExecutionThunk = createAsyncThunk<void, boolean, { dispath: AppDispatch; state: AppState }>('blockExecutionThunk', (blockExecution, thunkAPI) => {
  const { ui } = thunkAPI.getState();

  if (ui.blockExecution !== blockExecution) {
    thunkAPI.dispatch(blockExecutionAction(blockExecution));
  }
});
