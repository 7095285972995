import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isTargetAccu } from '@/utils/predicates';
import { selectUserType } from '@/store/state/user/user.selectors';
import { AccumulatorTrade } from '@/models/trade';
import {
  DecoratedFields,
  FxoPauseTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
  FxTargetAccumulatorCalendarFields,
} from '@/models/calendar';
import { getCumulatedTargetSum } from './CalendarUtils';
import { useState } from 'react';

interface WarningContainerProps {
  children?: React.ReactNode;
}

function WarningContainer({ children }: WarningContainerProps) {
  return (
    <div className="card card-bordered warning sticky-top">
      <div className="d-flex justify-content-start ms-4">
        <i className="icon icon-md cursor-default line-height-1 ms-n3 mt-2" data-e2e="error-icon">
          warning_amber &nbsp;
        </i>
        <div className="text-regular fw-medium mt-2">{children} </div>
      </div>
    </div>
  );
}

type CalendarPotentialWarningProps = {
  trade: AccumulatorTrade;
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[];
};

export function CalendarPotentialWarning({ trade, computedFields }: CalendarPotentialWarningProps) {
  const userType = useSelector(selectUserType);
  const { xOneCalendar, instrumentName } = trade;
  const [computedFieldsByTradeCapture] = useState(computedFields);

  const digitNumberForConsumedTarget = isTargetAccu(trade) && numDigitsAfterDecimal(trade.consumedTarget);

  const CumulatedAlert = isTargetAccu(trade) && getCumulatedTargetSum(computedFieldsByTradeCapture, digitNumberForConsumedTarget || 5) !== trade.consumedTarget;

  const isFixingSameAsSteps = instrumentName === 'FxoPauseTargetAccumulator' && xOneCalendar.some((row) => row.fixing === row.step1 || row.fixing === row.step2);

  const isFixingSameAsStrike = ['FxoTargetAccumulator', 'FxoSquareTargetAccumulator'].includes(instrumentName) && xOneCalendar.some((row) => row.fixing === row.strike);

  const hasAlert = CumulatedAlert || isFixingSameAsSteps || isFixingSameAsStrike;

  return (
    <div className="sticky-top" style={{ top: '0px' }}>
      <div
        className="h-100 w-100 bg-white "
        style={{
          color: 'transparent',
          display: 'inline-block',
          userSelect: 'none',
        }}
      >
        {' '}
        hidden
      </div>
      <div
        className="h-100 w-100 bg-white "
        style={{
          color: 'transparent',
          display: 'inline-block',
          userSelect: 'none',
        }}
      >
        {' '}
        hidden
      </div>
      {hasAlert && (
        <WarningContainer>
          {isFixingSameAsSteps || isFixingSameAsStrike ?
            <p className="text-regular fw-medium mt-2" data-e2e="fixing-same-as-strikes-or-steps">
              <FormattedMessage id="app.calendar.fixingInfos.FixingSameAsStrikeOrSteps" />
            </p>
          : CumulatedAlert && (
              <p className="text-regular fw-medium mt-2 mb-2" data-e2e="cumulated-target-different-than-consumed-target">
                <FormattedMessage id="app.calendar.fixingInfos.cumulatedTargetDifferentThanConsumedTarget" />
              </p>
            )
          }
          {hasAlert && (userType === 'InternalSales' || userType === 'InternalTrader') && (
            <p className="fw-bold mb-2">
              <FormattedMessage id="app.calendar.fixingInfos.SgClient" />
            </p>
          )}
          <ul>
            {isFixingSameAsSteps && (userType === 'InternalSales' || userType === 'InternalTrader') && (
              <li className="text-regular fw-medium mt-2 mb-2" data-e2e="fixing-same-as-steps-internal-sale">
                <FormattedMessage id="app.calendar.fixingInfos.FixingSameAsSteps.isInternalSales" values={{ instrumentName }} />
              </li>
            )}

            {isFixingSameAsStrike && (userType === 'InternalSales' || userType === 'InternalTrader') && (
              <li className="text-regular fw-medium mt-2 mb-2" data-e2e="fixing-same-as-strike-internal-sale">
                <FormattedMessage id="app.calendar.fixingInfos.FixingSameAsStrike.isInternalSales" values={{ instrumentName }} />
              </li>
            )}

            {CumulatedAlert && (userType === 'InternalSales' || userType === 'InternalTrader') && (
              <li className="text-regular fw-medium" data-e2e="cumulated-target-different-than-consumed-target-internal-sale">
                <FormattedMessage
                  id="app.calendar.fixingInfos.cumulatedTargetDifferentThanConsumedTarget.isInternalSales"
                  values={{ cumulatedTargetSum: getCumulatedTargetSum(computedFieldsByTradeCapture, digitNumberForConsumedTarget || 5), consumedTarget: trade.consumedTarget }}
                />
              </li>
            )}
          </ul>
        </WarningContainer>
      )}
    </div>
  );
}

function numDigitsAfterDecimal(number: number) {
  var afterDecimalStr = number.toString().split('.')[1] || '';
  return afterDecimalStr.length;
}
