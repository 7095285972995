/* eslint-disable react/no-unstable-nested-components */
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { getRouteName, routes } from '@/App/routes';
import { CalendarHeaderInfos } from './CalendarHeaderInfos';
import { AccountSelector } from '../AccountSelector/AccountSelector';
import { AccumulatorTrade } from '@/models/trade';
import { getProductLocalizationId } from '@/utils/locale';
import {
  CalendarFieldsState,
  DecoratedFields,
  FxTargetAccumulatorCalendarFields,
  FxoPauseTargetAccumulatorCalendarFields,
  FxoSquareTargetAccumulatorCalendarFields,
  FxoStandardForwardAccumulatorCalendarFields,
} from '@/models/calendar';
import { CalendarHeaderInfos2 } from './CalendarHeaderInfos2';
import { CalendarPotentialWarning } from './CalendarPotentialWarning';

interface CalendarTitleProps {
  trade: AccumulatorTrade;
  rows: CalendarFieldsState[];
  computedFields:
    | DecoratedFields<FxoStandardForwardAccumulatorCalendarFields>[]
    | DecoratedFields<FxTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoSquareTargetAccumulatorCalendarFields>[]
    | DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[];
}

export function CalendarHeader({ trade, rows, computedFields }: CalendarTitleProps) {
  const router = { location: useLocation() };
  const routeName = getRouteName(router);

  const { reference } = trade;

  return (
    <>
      <div className="header2 position-relative">
        <div className='d-flex justify-content-between align-items-center'>
        <div className="title">
          <div>
            <Link
              to={{
                pathname: routes.PORTFOLIO,
                search: window.location.search,
              }}
              className="text-large line-height-1 back my-4"
            >
              <i className="icon icon-xs">arrow_back</i> <FormattedMessage id="app.previous.PORTFOLIO" />
            </Link>
            <h1 className="display-3 mb-2" data-e2e={routeName}>
              <FormattedMessage id={getProductLocalizationId(trade)} />
              &nbsp; {reference}
            </h1>
            <CalendarHeaderInfos2 rows={rows} trade={trade} computedFields={computedFields} />
          </div>  
        </div>
        <div className="buttons">
          <AccountSelector />
        </div>
        </div>
      </div>
      <div className="border-bottom pb-3">
        <CalendarHeaderInfos trade={trade} computedFields={computedFields} />
      </div>
      <CalendarPotentialWarning trade={trade} computedFields={computedFields} />
    </>
  );
}
