import { LogBuilder } from '@sgme/logger'
import { sgwtConnect } from '@/sgwtConnect';
import { getApiUrl, isTest } from '@/config/app.config';

const logBuilder = LogBuilder()

export const isNode = typeof process === 'object' && !!process.versions && !!process.versions.node

// const config = {
//   component: 'MY-FX',
//   logUrl: getApiUrl() + 'log',
//   loggingSessionId: v4(),
//   storageKey: 'sg-my-fx-logs',
//   pushInterval: 10000,
//   pushTimeout: 1000,
//   maxQueueSize: 1000,
// };

if (isTest) {
  logBuilder.enableConsole()
} else {
  logBuilder
	.enableServerLogs({
		storeName: 'main',
		url: `${getApiUrl()}log`,
		prefix: '[MyFX] : ',
    scheduler: {
      defaultInterval: 10_000,
      retryPolicy: 'retryOnFail',
      batchSize: 500,
      range: {
        min: 5_000,
        max: 20_000,
      },
    }
	})
	.setAuthTokenFactory(() => {
		if (sgwtConnect.isAuthorized()) {
			const authToken = sgwtConnect.getAuthorizationHeader()
			if (authToken) return authToken
		}
		throw new Error('No authorization header or token. Disconnected ?!')
	})
}

export const logger = logBuilder.build()