import { FormattedMessage } from 'react-intl';

function HeaderCell({
  localizationId,
  alignRight = false,
  last = false,
  first = false,
}: {
  localizationId?: string;
  alignRight?: boolean;
  last?: boolean;
  first?: boolean;
}) {
  const classNames = [
    'mb-1',
    last ? 'px-4' : '',
    alignRight ? 'text-end' : '',
    first ? '' : 'ps-4',
  ].join(' ');
  const isEmpty = localizationId === undefined;

  return (
    <div className={classNames}>
      {!isEmpty && (
        <p className="mb-0">
          <FormattedMessage id={localizationId} />
        </p>
      )}
    </div>
  );
}

export function HeaderRow({ headerMaturityDateValue }: { headerMaturityDateValue: string }) {
  return (
    <div
      className="card-grid-header header mb-1 pt-2"
      style={{ position: 'sticky', top: 0, zIndex: 5, backgroundColor: 'white' }}
    >
      <div className="card-grid-row text-secondary fw-medium ">
        <div>{headerMaturityDateValue}</div>
        <HeaderCell localizationId="trades.tradesViewer.headers.product" />
        <HeaderCell localizationId="trades.tradesViewer.headers.amount" alignRight />
        <HeaderCell localizationId="trades.tradesViewer.headers.way" />
        <HeaderCell localizationId="trades.tradesViewer.headers.rate" alignRight />
        <HeaderCell localizationId="trades.tradesViewer.headers.settlement"/>
        <HeaderCell localizationId="trades.tradesViewer.headers.actions" last />
      </div>
    </div>
  );
}
