import { useFormattingContext } from '@/utils/format';
import { TradesFilter } from './TradesFilter';

interface TradesCurrencyFilterProps {
  value: string[];
  currencyPairs: string[];
  onCurrencyPairChange?: (filter: string[]) => void;
}

export function TradesCurrencyFilter({
  value,
  currencyPairs,
  onCurrencyPairChange,
}: TradesCurrencyFilterProps) {
  const { formatMessage } = useFormattingContext();
  const items = currencyPairs.map<[string, string]>(pair => [pair, pair]);

  return (
    <>
      <TradesFilter
        label={formatMessage('trades.toolbar.currencyFilter.label')}
        currentSelection={value}
        options={items}
        onChange={onCurrencyPairChange}
        labelE2e="currencies"
      />
    </>
  );
}
